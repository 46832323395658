import { ReactComponent as BarIcon } from 'assets/icons/bar.svg';
import { ReactComponent as InfoIcon } from 'assets/icons/info.svg';
import { ReactComponent as LineIcon } from 'assets/icons/line.svg';
import { ReactComponent as RadarIcon } from 'assets/icons/radar.svg';
import { ReactComponent as TableIcon } from 'assets/icons/table.svg';
import { BaseAssessment } from 'types/api';
import { formatDate } from 'util/formatDate';
import { Bar } from 'components/Bar';
import { GraphInfo } from 'components/GraphInfo';
import { GraphTable, IDefinitions } from 'components/GraphTable';
import { Line } from 'components/Line';
import { Radar, RadarData } from 'components/Radar';

export interface ConfigData {
  barLabels: string[];
  chartData: number[][];
  dates: string[];
  definitions: IDefinitions[];
  lineData: { name: string; type: string; data: number[] }[];
  radarData: RadarData[];
  terms: string[];
}

export const useConfig = (
  title: string,
  assessments: BaseAssessment[],
  { chartData, barLabels, lineData, radarData, definitions, terms }: ConfigData,
  ignoreIds: string[] = [],
) => {
  const dates: string[] = assessments.map((x) => formatDate(x.date).toString());
  const config = [];
  if (assessments.length === 1) {
    config.push({
      id: 'barTab',
      name: 'Percentile',
      Icon: BarIcon,
      content: <Bar data={chartData[0]} labels={barLabels} />,
    });
    radarData.push({
      name: title,
      value: chartData[0],
    });
  } else if (assessments.length > 1) {
    config.push({
      id: 'lineTab',
      name: 'Percentile',
      Icon: LineIcon,
      content: <Line data={lineData} dates={dates} />,
    });
    radarData.push(
      ...chartData.map((x, i) => ({
        name: dates[i],
        value: x,
      })),
    );
  }
  return [
    ...config,
    {
      id: 'radarTab',
      name: 'Percentile',
      Icon: RadarIcon,
      content: (
        <Radar
          data={radarData}
          indicator={barLabels.map((label) => ({
            max: 100,
            name: label,
          }))}
        />
      ),
    },
    {
      id: 'tableTab',
      name: 'Test Results',
      Icon: TableIcon,
      content: (
        <GraphTable assessments={assessments} definitions={definitions} />
      ),
    },
    {
      id: 'infoTab',
      name: 'Terminology',
      Icon: InfoIcon,
      content: <GraphInfo terms={terms} />,
    },
  ].filter((x) => !ignoreIds.includes(x.id));
};
