import { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { BaseAssessment } from 'types/api';
import { formatDate } from 'util/formatDate';
import { Button } from 'components/Button';
import styles from './Form.module.css';

export interface IComparedAssessmentsFormObject extends BaseAssessment {
  selected: boolean;
}
interface IFormProps {
  formObject: IComparedAssessmentsFormObject[];
  totalAssessmentCount: number;
  formError: boolean;
  onSubmit: (form: object) => void;
}

export const CompareAssessmentsForm = ({
  formObject,
  formError,
  onSubmit,
}: IFormProps) => {
  const { register, handleSubmit } = useForm();
  const [checkedState, setCheckedState] = useState(formObject);
  const [checkedCount, setCheckedCount] = useState(0);

  useEffect(() => {
    const setInitialSelectedCount = formObject.filter((obj) => obj.selected);
    setCheckedCount(setInitialSelectedCount.length);
  }, [formObject]);

  const handleOnChange = (dictId: string) => {
    const updatedCheckedState = checkedState?.map((item) => {
      if (item.id === dictId) {
        if (item.selected) {
          item.selected = false;
        } else if (checkedCount < 4) {
          item.selected = true;
        }
        return item;
      }
      return item;
    });
    const areSelected = updatedCheckedState.filter((check) => check.selected);
    setCheckedCount(areSelected.length);
    setCheckedState(updatedCheckedState);
  };

  return (
    <div className={styles['form-container']}>
      {formError && (
        <div className={styles['form-error']}>
          Please select more than 1 assessment for comparison
        </div>
      )}
      <form className={styles.form} onSubmit={handleSubmit(onSubmit)}>
        {formObject.map((c: IComparedAssessmentsFormObject) => {
          return (
            <div className={styles['checkbox-row']} key={c.id}>
              <input
                type="checkbox"
                id={c.id}
                {...register('selected')}
                checked={c.selected}
                value={c.id}
                onChange={() => {
                  c.id && handleOnChange(c.id);
                }}
                className={styles.checked}
              />
              <label htmlFor={c.id}>{formatDate(c.date)}</label>
            </div>
          );
        })}
        <div className={styles['form-submit']}>
          <Button
            type="submit"
            className={styles.save}
            disabled={checkedCount < 2}
          >
            Compare
          </Button>
        </div>
      </form>
    </div>
  );
};
