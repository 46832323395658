import { ReactNode } from 'react';
import { BaseAssessment } from 'types/api';
import { formatDate } from 'util/formatDate';
import { trimDecimals } from 'util/trimDecimals';
import styles from './GraphTable.module.css';

export interface IDefinitions {
  key: string;
  name?: string;
  unit?: string;
  formatter?: (value: number, unit?: string) => ReactNode;
}

// eslint-disable-next-line  @typescript-eslint/no-explicit-any
const buildObjectPath = (path: string, obj: any): number => {
  return path.split('.').reduce((prev, curr) => {
    return prev ? prev[curr] : null;
  }, obj);
};

interface GraphTableProps {
  assessments: BaseAssessment[];
  definitions?: IDefinitions[];
}

const GraphTable = ({ assessments, definitions }: GraphTableProps) => {
  return (
    <table className={styles.table}>
      <thead>
        <tr>
          <th>Athlete</th>
          {assessments?.map(({ id, name }) => (
            <th key={id}>{name}</th>
          ))}
        </tr>
      </thead>
      <tbody>
        {assessments.length > 1 && (
          <tr key="date">
            <th>Assesssment Date</th>
            {assessments?.map((a, i) => {
              return <td key={i}>{formatDate(a.date)}</td>;
            })}
          </tr>
        )}
        {definitions?.map(({ key, name, unit, formatter }, i) => {
          return (
            <tr key={i}>
              <th>{name}</th>
              {assessments?.map((a) => {
                return (
                  <td key={a.id}>
                    {formatter
                      ? formatter(trimDecimals(buildObjectPath(key, a)), unit)
                      : `${trimDecimals(buildObjectPath(key, a))} ${unit}`}
                  </td>
                );
              })}
            </tr>
          );
        })}
      </tbody>
    </table>
  );
};

export { GraphTable };
