import { useQueries } from 'react-query';
import {
  ContentType,
  useAuthenticatedFetch,
} from 'hooks/util/useAuthenticatedFetch';

export const useAssessmentQueries = (
  ids: string[],
  contentType: ContentType,
) => {
  const fetch = useAuthenticatedFetch(contentType);

  return useQueries(
    ids.map((id) => {
      return {
        queryKey: ['assessments', id],
        queryFn: () =>
          fetch(`${process.env.REACT_APP_API_HOST}/Assessments/${id}`),
        enabled: !!ids,
      };
    }),
  );
};
