import { Align } from '@radix-ui/popper';
import * as PopoverPrimitive from '@radix-ui/react-popover';
import { ReactComponent as CloseIcon } from 'assets/icons/close.svg';
import classNames from 'classnames';
import { AnimatePresence, motion } from 'framer-motion';
import {
  PropsWithChildren,
  ReactNode,
  forwardRef,
  useEffect,
  useImperativeHandle,
  useState,
} from 'react';
import useOnclickOutside from 'react-cool-onclickoutside';
import { useBreakpoints } from 'hooks/util/useBreakpoints';
import styles from './Popover.module.css';

interface IPopoverProps {
  align?: Align;
  alignOffset?: number;
  contentClassName?: string;
  hideArrow?: boolean;
  isOpen?: boolean;
  onOpenChange?: (isOpen: boolean) => void;
  sideOffset?: number;
  trigger?: ReactNode;
  triggerClassName?: string;
}

export interface HandleClosePopover {
  onPopoverClose: VoidFunction;
}

export const Popover = forwardRef<
  HandleClosePopover,
  PropsWithChildren<IPopoverProps>
>((props: PropsWithChildren<IPopoverProps>, ref) => {
  const {
    align,
    alignOffset,
    children,
    contentClassName,
    hideArrow = false,
    isOpen: controlledIsOpenPopover,
    onOpenChange,
    sideOffset,
    trigger,
    triggerClassName,
  } = props;
  const { isDesktop } = useBreakpoints();
  const [isOpenPopover, setIsOpenPopover] = useState<boolean>(
    controlledIsOpenPopover ?? false,
  );
  const drawerRef = useOnclickOutside(() => setIsOpenPopover(false));

  useEffect(() => {
    if (controlledIsOpenPopover !== undefined) {
      setIsOpenPopover(controlledIsOpenPopover);
    }
  }, [controlledIsOpenPopover]);

  const handleOpenPopover = (open: boolean) => {
    setIsOpenPopover(open);
  };

  useImperativeHandle(ref, () => ({
    onPopoverClose: () => {
      setIsOpenPopover(false);
    },
  }));

  useEffect(() => {
    onOpenChange?.(isOpenPopover);
  }, [isOpenPopover, onOpenChange]);

  const renderTrigger = () => (
    <button className={triggerClassName} onClick={() => setIsOpenPopover(true)}>
      {trigger}
    </button>
  );

  const renderDrawer = () => (
    <>
      {renderTrigger()}
      <AnimatePresence initial={false}>
        {isOpenPopover && (
          <motion.div
            className={classNames(styles.drawer, contentClassName)}
            initial={{ transform: `translateY(100%)` }}
            animate={{ transform: `translateY(0%)` }}
            exit={{ transform: `translateY(100%)` }}
            transition={{ bounce: 0 }}
            ref={drawerRef}
          >
            <button
              className={styles.close}
              onClick={() => setIsOpenPopover(false)}
            >
              <CloseIcon />
            </button>
            {children}
          </motion.div>
        )}
      </AnimatePresence>
    </>
  );

  const renderPopover = () => (
    <div className={styles.wrapper}>
      <PopoverPrimitive.Root
        onOpenChange={handleOpenPopover}
        open={isOpenPopover}
      >
        <PopoverPrimitive.Trigger asChild>
          {renderTrigger()}
        </PopoverPrimitive.Trigger>
        <PopoverPrimitive.Content
          align={align}
          alignOffset={alignOffset}
          className={classNames(styles.popover, contentClassName)}
          sideOffset={sideOffset}
        >
          {children}
          {!hideArrow && (
            <PopoverPrimitive.Arrow className={styles.arrow} offset={30} />
          )}
          <PopoverPrimitive.Close className={styles.close}>
            <CloseIcon />
          </PopoverPrimitive.Close>
        </PopoverPrimitive.Content>
      </PopoverPrimitive.Root>
    </div>
  );

  return isDesktop ? renderPopover() : renderDrawer();
});
