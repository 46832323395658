import { useState } from 'react';
import styles from './Video.module.css';
import VideoJS from './VideoJS';

export const Video = ({ videos }) => {
  // by default show front camera angle. fallback to first available camara angle
  const { id: defaultVideoId } =
    videos.find(({ angle }) => angle === 'Front') ?? videos[0];
  const [videoId, setVideoId] = useState(defaultVideoId);
  const handleVideoChange = ({ target }) => {
    setVideoId(target.value);
  };

  const getLabel = (exercise, angle) => {
    if (exercise === 'SkaterLeft') return `Skate Left - ${angle} Angle`;
    if (exercise === 'SkaterRight') return `Skate Right - ${angle} Angle`;
    return `${angle} Angle`;
  };

  return (
    <div>
      <div>
        <select
          className={styles.angles}
          defaultValue={videoId}
          onChange={handleVideoChange}
        >
          {videos
            .sort((a, b) => {
              // Sort the labels on the angle dropdown
              const exerciseA = a.exercise.toLowerCase();
              const exerciseB = b.exercise.toLowerCase();
              if (exerciseA < exerciseB) return -1;
              if (exerciseA > exerciseB) return 1;
              return 0;
            })
            .map(({ id, angle, exercise }, i) => {
              return (
                <option value={id} key={i}>
                  {getLabel(exercise, angle)}
                </option>
              );
            })}
        </select>
      </div>
      <VideoJS
        options={{
          sources: [
            {
              src: videos.find(({ id }) => id === videoId)?.blobStorageUri,
              type: 'video/mp4',
            },
          ],
        }}
      />
    </div>
  );
};
