import classNames from 'classnames';
import styles from './SubHeaderH3.module.css';

interface ISubHeaderProps {
  h3?: string;
  p?: string;
  hideMobile?: boolean;
}

export const SubHeaderH3 = ({ h3, p, hideMobile }: ISubHeaderProps) => {
  return (
    <div
      className={classNames(styles.subheader, hideMobile ? 'hide-mobile' : '')}
    >
      {h3 && <h3>{h3}</h3>}
      {p && <p>{p}</p>}
    </div>
  );
};
