import classNames from 'classnames';
import { ReactElement, useMemo } from 'react';
import { useMatch, useNavigate } from 'react-router-dom';
import { BaseAssessment } from 'types/api';
import { useAssessmentsOverview } from 'hooks/util/useAssessmentsOverview';
import { useBreakpoints } from 'hooks/util/useBreakpoints';
import { formatDate } from 'util/formatDate';
import { tooltipText } from 'util/tooltipText';
import { ConvertHeightToMeters } from 'components/ConvertHeightToMeters';
import { ConvertWeightToLb } from 'components/ConvertWeight';
import { FormatHeight } from 'components/FormatHeight';
import { LargeScore } from 'components/LargeScore';
import { ProfilePicture } from 'components/ProfilePicture';
import { Stat } from 'components/Stat';
import styles from './Overview.module.css';

interface OverviewProps {
  targets?: ReactElement;
  setCurrentAssessment: (assessment: BaseAssessment) => void;
  currentAssessment: BaseAssessment;
  priorAssessments: BaseAssessment[] | null | undefined;
  resetCharts: () => void;
}

export const Overview = ({
  targets,
  setCurrentAssessment,
  currentAssessment,
  priorAssessments,
  resetCharts,
}: OverviewProps) => {
  const { isDesktop, isTablet, tabletDown, isMobile } = useBreakpoints();
  const isShared = useMatch('/share/:linkId/:assessmentId');
  const navigate = useNavigate();
  const assessments = useMemo(
    () => [currentAssessment, ...(priorAssessments || [])].filter(Boolean),
    [currentAssessment, priorAssessments],
  );

  const { info, stats } = useAssessmentsOverview(
    currentAssessment,
    assessments,
  );

  const size = useMemo(() => {
    if (isDesktop) return 160;
    if (isTablet) return 154;
    return 100;
  }, [isDesktop, isTablet]);

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const selectAssessmentHandler = (e: any) => {
    const newCurrentAssessment = assessments.find((a) => a.id === e.value);
    if (newCurrentAssessment) {
      resetCharts();
      setCurrentAssessment(newCurrentAssessment);
      if (!isShared) {
        navigate(`/assessments/${newCurrentAssessment.id}`);
      }
    }
  };

  return (
    <>
      <div className={styles.overview}>
        <div className={styles.header}>
          <div className={styles['user-info']}>
            {isTablet ? (
              <>
                <ProfilePicture
                  size={size}
                  firstName={info?.firstName}
                  lastName={info?.lastName}
                />
                <div className={styles.flex}>
                  <div className={styles['user-text']}>
                    <div className={styles['header-text']}>
                      <p>{formatDate(info?.date)}</p>
                    </div>
                    <h1>{info?.name}</h1>
                  </div>
                </div>
              </>
            ) : (
              <div className={styles.flex}>
                <div>
                  <ProfilePicture
                    size={size}
                    firstName={info?.firstName}
                    lastName={info?.lastName}
                  />
                </div>
                <div className={styles['user-text']}>
                  <div className={styles['header-text']}>
                    <p>
                      {assessments.length > 1 ? (
                        <select
                          value={currentAssessment.id}
                          onChange={({ target }) => {
                            selectAssessmentHandler(target);
                          }}
                        >
                          {assessments.map(({ date, id }, i) => (
                            <option key={i} value={id}>
                              {formatDate(date)}
                            </option>
                          ))}
                        </select>
                      ) : (
                        formatDate(info?.date)
                      )}
                    </p>
                  </div>
                  <h1>{info?.name}</h1>
                </div>
              </div>
            )}
            {isDesktop && targets && (
              <div className={styles.targets}>{targets}</div>
            )}
            <dl className={styles.score}>
              {info?.athleticismScore && (
                <LargeScore
                  title="Athleticism Score"
                  score={info?.athleticismScore || 0}
                  delta={info?.athleticismScoreDelta || 0}
                  tooltip={tooltipText.AthleticismScore as string}
                />
              )}
              {info?.mechanicsScore && (
                <LargeScore
                  title="Mechanics Score"
                  score={info?.mechanicsScore || 0}
                  delta={info?.mechanicsScoreDelta || 0}
                  tooltip={tooltipText.MechanicsScore as string}
                />
              )}
            </dl>
          </div>
        </div>
        <div className={styles['flex-stats']}>
          <div className={styles['width-control']}>
            <dl
              className={classNames(
                styles.info,
                styles[`info-grid-${Object.keys(stats || {}).length}`],
              )}
            >
              {stats &&
                Object.entries(stats).map(([key, item], index) => {
                  const { title, value, difference, unit, tooltip } = item;
                  return (
                    <Stat
                      title={title}
                      value={value}
                      unit={unit}
                      difference={difference}
                      key={`${key}-${currentAssessment.id}-${index}`}
                      tooltip={tooltip}
                    />
                  );
                })}
            </dl>
            <dl className={styles.stats}>
              <div className={styles.row}>
                <div>
                  <dt>Height</dt>
                  <dd>
                    <FormatHeight height={info?.height || 0} />{' '}
                    {!isMobile && (
                      <ConvertHeightToMeters height={info?.height || 0} />
                    )}
                  </dd>
                </div>

                <div>
                  <dt>Weight</dt>
                  <dd>
                    <ConvertWeightToLb weight={info?.weight || 0} />{' '}
                    {!isMobile && <span>({info?.weight || 0} kg)</span>}
                  </dd>
                </div>
                {tabletDown && (
                  <>
                    <div>
                      <dt>Assessments</dt>
                      <dd>{assessments.length}</dd>
                    </div>

                    <div>
                      <dt>Position</dt>
                      <dd>{info?.position || '--'}</dd>
                    </div>
                  </>
                )}
              </div>

              {!tabletDown && (
                <div className={styles.row}>
                  <div>
                    <dt>Assessments</dt>
                    <dd>{assessments.length}</dd>
                  </div>

                  <div>
                    <dt>Position</dt>
                    <dd>{info?.position || '--'}</dd>
                  </div>
                </div>
              )}
            </dl>
          </div>
        </div>
      </div>
      {!isDesktop && <div className={styles.targets}>{targets}</div>}
    </>
  );
};
