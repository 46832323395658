import { useAuth0, withAuthenticationRequired } from '@auth0/auth0-react';
import { OrganizationProvider } from 'contexts/organizationContext';
import { Navigate, Route, Routes } from 'react-router-dom';
import { AssessmentsComparison } from 'views/Assessments/Comparison';
import { AssessmentsDetails } from 'views/Assessments/Detail';
import { AssessmentsList } from 'views/Assessments/List';
import { FrequentlyAskedQuestions } from 'views/FrequentlyAskedQuestions';
import NotFound from 'views/NotFound';
import { Organization } from 'views/Organization';
import { EditAccount } from 'views/Organization/EditUserRole';
import { Profile } from 'views/Profile';
import { Share } from 'views/Share';
import { useLoggedInUser } from 'hooks/query/useLoggedInUser';
import { useOrganizationContext } from 'hooks/util/useOrganizationContext';
import { isAdmin } from 'util/isAdmin';
import { Header } from 'components/Header';
import { Loading } from 'components/Loading';
import { Role } from '../types/api';
import { MapOrganizations } from '../views/Assessments/MapOrganizations';

const AuthenticatedApp = withAuthenticationRequired(
  () => {
    const { logout } = useAuth0();
    const { error, data: loggedInUser } = useLoggedInUser();

    if (error) return <div>{error.message}</div>;
    if (!loggedInUser) return null;

    return (
      <OrganizationProvider>
        <Header
          profile={loggedInUser}
          logout={() =>
            logout({
              returnTo: window.location.origin,
            })
          }
        />
        <Wrapper />
      </OrganizationProvider>
    );
  },
  {
    onRedirecting: () => <Loading />,
  },
);

const Wrapper = () => {
  const { contextRole, contextOrg } = useOrganizationContext();

  return (
    <main className="main">
      <Routes>
        <Route path="*" element={<NotFound />} />
        <Route path="/" element={<Navigate replace to="assessments" />} />
        <Route path="assessments">
          <Route element={<AssessmentsList />} index />
          <Route element={<AssessmentsDetails />} path=":id" />
          <Route element={<AssessmentsComparison />} path="compare" />
          <Route
            element={
              contextRole === Role.SUPER_ADMIN ? (
                <MapOrganizations />
              ) : (
                <Navigate to="" replace />
              )
            }
            path="organizations"
          />
        </Route>
        <Route path="organization">
          <Route
            index
            element={
              isAdmin(contextRole) && contextOrg ? (
                <Organization />
              ) : (
                <Profile />
              )
            }
          />
          <Route
            path="account/:id/edit"
            element={
              isAdmin(contextRole) && contextOrg ? <EditAccount /> : <Profile />
            }
          />
        </Route>
        <Route path="profile">
          <Route element={<Profile />} index />
          <Route element={<Profile />} path=":id" />
        </Route>
        <Route element={<FrequentlyAskedQuestions />} path="faq" />
      </Routes>
    </main>
  );
};

export const App = () => {
  return (
    <Routes>
      <Route element={<Share />} path="share/:linkId/:assessmentId" />
      <Route element={<AuthenticatedApp />} path="*" />
    </Routes>
  );
};
