/* eslint-disable @typescript-eslint/no-explicit-any */
import { forwardRef, useImperativeHandle, useState } from 'react';
import { useForm } from 'react-hook-form';
import { UpdateTrainingTargetsRequestModel } from 'types/api';
import { TrainingTargetDisplayObject } from '../..';
import styles from './Form.module.css';

interface IFormProps {
  displayObject: TrainingTargetDisplayObject[];
  onSubmit: (form: object) => void;
}

export interface FormHandle {
  submit: VoidFunction;
}

export const Form = forwardRef<FormHandle, IFormProps>(
  ({ displayObject, onSubmit }: IFormProps, ref) => {
    const { register, handleSubmit } =
      useForm<UpdateTrainingTargetsRequestModel>();
    const [checkedState, setCheckedState] = useState(displayObject);

    const handleOnChange = (dictId: string) => {
      const maxChecked = 4; // maximum number of selected training targets

      // if the the maximum number of training targets is reached,
      if (
        checkedState.filter(({ selected }) => selected).length === maxChecked
      ) {
        // prevent selecting more targets and only allow the current target to be unselected
        if (!checkedState.find(({ id }) => id === dictId)?.selected) return;
      }

      setCheckedState(
        checkedState.map((item) => {
          if (item.id === dictId) item.selected = !item.selected;
          return item;
        }),
      );
    };

    useImperativeHandle(ref, () => ({
      submit: handleSubmit(onSubmit),
    }));

    return (
      <form className={styles.form} onSubmit={handleSubmit(onSubmit)}>
        {displayObject.map((dict: any) => {
          return (
            <div className={styles['checkbox-row']} key={dict.id}>
              <input
                type="checkbox"
                id={dict.id}
                {...register(dict.id)}
                value={dict.id}
                checked={dict.selected}
                onChange={() => handleOnChange(dict.id)}
              />
              <label htmlFor={dict.id}>{dict.displayName}</label>
            </div>
          );
        })}
        <button className={styles.save} type="submit">
          Assign
        </button>
      </form>
    );
  },
);
