import { useCallback, useEffect, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';
import {
  CreateOrUpdateOrganizationRequestModel,
  UserResponseModel,
} from 'types/api';
import { useCreateOrganization } from 'hooks/mutation/useCreateOrganization';
import { useOrganizationContext } from 'hooks/util/useOrganizationContext';
import { Button, ButtonVariants } from 'components/Button';
import { Loading } from 'components/Loading';
import { ProfilePicture } from 'components/ProfilePicture';
import { TextField } from 'components/TextField';
import styles from './StepTwo.module.css';

type NonNullableCreateOrganizationRequestModel = {
  [K in keyof CreateOrUpdateOrganizationRequestModel]: NonNullable<
    CreateOrUpdateOrganizationRequestModel[K]
  >;
};

interface StepperProps {
  profile: UserResponseModel | undefined;
  closeModal: () => void;
}

export const StepTwo = ({ closeModal }: StepperProps) => {
  const [imageUpload, setImageUpload] = useState<File>();
  const [formChangesDetected, setFormChangesDetected] =
    useState<boolean>(false);
  const [isLoadingCreateOrg, setIsLoadingCreateOrg] = useState<boolean>(false);
  const { control, handleSubmit, watch } =
    useForm<NonNullableCreateOrganizationRequestModel>({});
  const {
    data: createdOrganization,
    mutate,
    isSuccess,
  } = useCreateOrganization();
  const navigate = useNavigate();
  const { setContext, contextRole } = useOrganizationContext();

  const formSubmit = useCallback(
    (formData: FormData) => {
      mutate(formData);
    },
    [mutate],
  );

  useEffect(() => {
    if (isSuccess) {
      setContext(createdOrganization, contextRole);
      navigate('organization');
      setIsLoadingCreateOrg(false);
      closeModal();
    }
  }, [
    closeModal,
    contextRole,
    createdOrganization,
    isSuccess,
    navigate,
    setContext,
  ]);

  useEffect(() => {
    const subscription = watch((value) => {
      setFormChangesDetected(!!value);
    });
    return () => {
      subscription.unsubscribe();
    };
  }, [watch]);

  const onFormSubmit = (formData: CreateOrUpdateOrganizationRequestModel) => {
    setIsLoadingCreateOrg(true);
    const requestPayload = new FormData();
    if (imageUpload) {
      requestPayload.append('Image', imageUpload);
    }
    requestPayload.append('orgData', JSON.stringify({ ...formData }));
    setFormChangesDetected(false);
    formSubmit(requestPayload);
  };

  return (
    <>
      <div className={styles['modal-center']}>
        <h2 className={styles['create-org-header']}>
          Enter Organization Information
        </h2>
        <form
          className={styles.form}
          noValidate
          onSubmit={handleSubmit(onFormSubmit)}
        >
          <div className={styles['profile-picture-container']}>
            <ProfilePicture
              size={160}
              src={imageUpload ? URL.createObjectURL(imageUpload) : ''}
            />
            <input
              type="file"
              className={styles.upload}
              accept="image/png, image/jpeg"
              name="Image"
              onChange={({ target }) => {
                if (target.files) {
                  const file = target.files[0];
                  setImageUpload(file);
                }
              }}
            />
          </div>
          <div className="form-container">
            {isLoadingCreateOrg ? (
              <div className="inline-cover">
                <Loading />
              </div>
            ) : null}
            <div className={styles['text-field']}>
              <Controller
                control={control}
                name="organizationName"
                render={({ field, fieldState }) => (
                  <TextField
                    {...field}
                    error={fieldState.error?.message}
                    label="Organization Name"
                    required
                  />
                )}
                rules={{ required: 'Required' }}
              />
              <div className={styles.grid}>
                <Controller
                  control={control}
                  name="city"
                  render={({ field, fieldState }) => (
                    <TextField
                      {...field}
                      error={fieldState.error?.message}
                      label="City"
                    />
                  )}
                />
                <Controller
                  control={control}
                  name="state"
                  render={({ field, fieldState }) => (
                    <TextField
                      {...field}
                      error={fieldState.error?.message}
                      label="State"
                      tagName="select"
                    >
                      <option value="" />
                      <option value="AL">Alabama</option>
                      <option value="AK">Alaska</option>
                      <option value="AZ">Arizona</option>
                      <option value="AR">Arkansas</option>
                      <option value="CA">California</option>
                      <option value="CO">Colorado</option>
                      <option value="CT">Connecticut</option>
                      <option value="DE">Delaware</option>
                      <option value="DC">District Of Columbia</option>
                      <option value="FL">Florida</option>
                      <option value="GA">Georgia</option>
                      <option value="HI">Hawaii</option>
                      <option value="ID">Idaho</option>
                      <option value="IL">Illinois</option>
                      <option value="IN">Indiana</option>
                      <option value="IA">Iowa</option>
                      <option value="KS">Kansas</option>
                      <option value="KY">Kentucky</option>
                      <option value="LA">Louisiana</option>
                      <option value="ME">Maine</option>
                      <option value="MD">Maryland</option>
                      <option value="MA">Massachusetts</option>
                      <option value="MI">Michigan</option>
                      <option value="MN">Minnesota</option>
                      <option value="MS">Mississippi</option>
                      <option value="MO">Missouri</option>
                      <option value="MT">Montana</option>
                      <option value="NE">Nebraska</option>
                      <option value="NV">Nevada</option>
                      <option value="NH">New Hampshire</option>
                      <option value="NJ">New Jersey</option>
                      <option value="NM">New Mexico</option>
                      <option value="NY">New York</option>
                      <option value="NC">North Carolina</option>
                      <option value="ND">North Dakota</option>
                      <option value="OH">Ohio</option>
                      <option value="OK">Oklahoma</option>
                      <option value="OR">Oregon</option>
                      <option value="PA">Pennsylvania</option>
                      <option value="RI">Rhode Island</option>
                      <option value="SC">South Carolina</option>
                      <option value="SD">South Dakota</option>
                      <option value="TN">Tennessee</option>
                      <option value="TX">Texas</option>
                      <option value="UT">Utah</option>
                      <option value="VT">Vermont</option>
                      <option value="VA">Virginia</option>
                      <option value="WA">Washington</option>
                      <option value="WV">West Virginia</option>
                      <option value="WI">Wisconsin</option>
                      <option value="WY">Wyoming</option>
                    </TextField>
                  )}
                />
              </div>
              <Controller
                control={control}
                name="country"
                render={({ field, fieldState }) => (
                  <TextField
                    {...field}
                    error={fieldState.error?.message}
                    label="Country"
                  />
                )}
              />
            </div>
            <div className={styles.buttons}>
              <Button
                disabled={!formChangesDetected}
                variant={ButtonVariants.Primary}
                type="submit"
              >
                <div>Save</div>
              </Button>
            </div>
          </div>
        </form>
      </div>
    </>
  );
};
