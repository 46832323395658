import { Error } from 'models/Error';
import { useQuery } from 'react-query';
import { AssessmentWrapper } from 'types/api';
import {
  ContentType,
  useAuthenticatedFetch,
} from 'hooks/util/useAuthenticatedFetch';

export const useSharedAssessment = (
  linkId?: string,
  assessmentId?: string,
  password?: string,
) => {
  const fetch = useAuthenticatedFetch(ContentType.NONE);
  return useQuery<AssessmentWrapper, Error>(
    ['assessments', assessmentId],
    () =>
      fetch(
        `${process.env.REACT_APP_API_HOST}/AssessmentLink/${linkId}/Assessment/${assessmentId}`,
        { headers: { 'x-assessment-link-password': password as string } },
      ),
    {
      enabled: !!linkId && !!assessmentId && !!password,
    },
  );
};
