import { useEffect, useMemo, useRef, useState } from 'react';
import { BaseAssessment } from 'types/api';
import { Button, ButtonVariants } from 'components/Button';
import { CompareAssessmentsForm } from 'components/CompareAssessmentsForm';
import { HandleClose, Modal } from 'components/Modal';
import { SubHeaderH3 } from 'components/SubHeaderH3';
import styles from './CompareMechanics.module.css';

interface ICompareMechanicsProps {
  assessments: BaseAssessment[];
  comparedAssessments: BaseAssessment[];
  onChange: (value: BaseAssessment[]) => void;
}

export interface IComparedAssessmentsFormObject extends BaseAssessment {
  selected: boolean;
}

export const CompareMechanics = ({
  assessments,
  comparedAssessments,
  onChange,
}: ICompareMechanicsProps) => {
  const [displayObject, setDisplayObject] = useState<
    IComparedAssessmentsFormObject[]
  >([]);
  const [isComparing, setIsComparing] = useState(false);
  const [formError, setFormError] = useState(false);
  const modalClose = useRef<HandleClose>(null);

  const handleChange = () => {
    // Reset form error state
    setFormError(false);
    const selectedAssessments: BaseAssessment[] = displayObject.filter(
      (obj) => obj.selected,
    );
    if (selectedAssessments.length === 1) {
      setFormError(true);
    } else {
      onChange(selectedAssessments);
      modalClose.current?.onClose();
      setIsComparing(false);
    }
  };

  const reset = () => {
    onChange([]);
  };

  const comparedAssessmentsFormObject = useMemo(() => {
    const result: IComparedAssessmentsFormObject[] = [];
    if (!comparedAssessments.length) {
      assessments.forEach((a: BaseAssessment) => {
        const obj: IComparedAssessmentsFormObject = {
          ...a,
          selected: false,
        };
        result.push(obj);
      });
    } else {
      assessments.forEach((a: BaseAssessment) => {
        const findIfAlreadySelected = comparedAssessments.find(
          ({ id }) => id === a.id,
        );
        if (findIfAlreadySelected) {
          const obj: IComparedAssessmentsFormObject = {
            ...a,
            selected: true,
          };
          result.push(obj);
        } else {
          const obj: IComparedAssessmentsFormObject = {
            ...a,
            selected: false,
          };
          result.push(obj);
        }
      });
    }
    return result;
  }, [assessments, comparedAssessments]);

  useEffect(() => {
    setDisplayObject(comparedAssessmentsFormObject);
  }, [comparedAssessmentsFormObject]);

  return (
    <div className={styles['button-container']}>
      <SubHeaderH3 p="P3's research has pointed to the importance of the following variables. There variables are used to score joint risk on a scale − red areas warrant close attention." />
      {assessments.length > 1 && (
        <>
          <div className="d-flex">
            <button
              onClick={() => {
                setIsComparing(true);
              }}
              className={styles.button}
            >
              Compare Mechanics
            </button>
            {comparedAssessments.length > 0 && (
              <Button
                type="reset"
                variant={ButtonVariants.Tertiary}
                onClick={reset}
                className={styles['reset-button']}
              >
                Reset
              </Button>
            )}
            <Modal
              isOpen={isComparing}
              onOpenChange={setIsComparing}
              title="Select Up to 4 Assessments"
              classes={[styles.modal]}
              ref={modalClose}
            >
              <CompareAssessmentsForm
                formObject={displayObject}
                totalAssessmentCount={assessments.length}
                formError={formError}
                onSubmit={handleChange}
              />
            </Modal>
          </div>
        </>
      )}
    </div>
  );
};
