import { useEffect, useState } from 'react';
import termsDictionary from 'views/Assessments/terms.json';
import { BaseAssessment, TrainingTarget } from 'types/api';
import { Loading } from 'components/Loading';
import styles from './Targets.module.css';
import { UpdateTargets } from './UpdateTargets';

interface ITargetProps {
  assessment: BaseAssessment;
  isSuperAdmin: boolean;
  className?: string;
}
export interface DictionaryObject {
  id: string;
  term: string;
  definition?: string;
}

export interface TrainingTargetDisplayObject extends DictionaryObject {
  displayName: string;
  selected: boolean;
}

const Targets = (props: ITargetProps) => {
  const { assessment, isSuperAdmin, className } = props;
  const [displayObject, setDisplayObject] =
    useState<TrainingTargetDisplayObject[]>();
  const [athleteTrainingTargets, setAthleteTrainingTargets] = useState(
    assessment.trainingTargets,
  );

  const updateAthleteTrainingTargets = (targets: TrainingTarget[]) => {
    setAthleteTrainingTargets(targets);
  };

  useEffect(() => {
    const trainingTargetDisplayObject: TrainingTargetDisplayObject[] = [];
    const trainingTargetContent = termsDictionary[1].trainingTargets;
    const buildTargetDisplayObject = () => {
      trainingTargetContent?.forEach((dict: DictionaryObject) => {
        const isSelected = !!athleteTrainingTargets?.includes(
          dict.id as TrainingTarget,
        );
        const findDictItem: DictionaryObject | undefined =
          trainingTargetContent.find(({ id }) => id === dict.id);

        if (findDictItem) {
          const obj: TrainingTargetDisplayObject = {
            ...findDictItem,
            displayName: dict.term,
            selected: isSelected,
          };
          trainingTargetDisplayObject.push(obj);
        }
      });
    };
    buildTargetDisplayObject();
    setDisplayObject(trainingTargetDisplayObject);
  }, [athleteTrainingTargets, assessment.trainingTargets]);

  return displayObject ? (
    <div className={className}>
      {athleteTrainingTargets?.length ? (
        <>
          <h4 className={styles.title}>Training Targets</h4>
          <dl className={styles.terms}>
            {displayObject?.map((target: TrainingTargetDisplayObject) => {
              return target.selected ? (
                <div key={target.id}>
                  <dt>{target.term}</dt>
                  <dd>{target.definition}</dd>
                </div>
              ) : null;
            })}
          </dl>
        </>
      ) : (
        <>
          <h4 className={styles.title}>Training Targets</h4>
          <div>No training targets selected</div>
        </>
      )}
      {isSuperAdmin && (
        <div className={styles.wrapper}>
          <UpdateTargets
            assessmentId={assessment.id as string}
            displayObject={displayObject}
            setTrainingTargetContent={updateAthleteTrainingTargets}
          />
        </div>
      )}
    </div>
  ) : (
    <Loading />
  );
};

export { Targets };
