import { useMutation } from 'react-query';
import { toast } from 'react-toastify';
import {
  CreateOrUpdateOrganizationRequestModel,
  OrganizationResponseModel,
} from 'types/api';
import { useAuthenticatedFetch } from 'hooks/util/useAuthenticatedFetch';
import { useOrganizationContext } from 'hooks/util/useOrganizationContext';
import { queryClient } from 'util/queryClientConfig';

export const useUpdateOrganization = (id?: string) => {
  const fetch = useAuthenticatedFetch();
  const { setContext, contextRole } = useOrganizationContext();
  return useMutation(
    (body: CreateOrUpdateOrganizationRequestModel) =>
      fetch<OrganizationResponseModel>(
        `${process.env.REACT_APP_API_HOST}/organizations/${id}`,
        {
          body,
          method: 'PUT',
        },
      ),
    {
      onError: () => {
        toast.error('Error updating organization.');
      },
      onSuccess: (updatedOrganization) => {
        toast.success('Organization updated successfully.');
        setContext(updatedOrganization, contextRole);
        queryClient.setQueryData(
          ['organization', updatedOrganization.id],
          updatedOrganization,
        );
      },
    },
  );
};
