import { ReactComponent as InfoIcon } from 'assets/icons/info.svg';
import { ReactComponent as RadarIcon } from 'assets/icons/radar.svg';
import { ReactComponent as TableIcon } from 'assets/icons/table.svg';
import {
  AssessmentSoccerSLDropKick,
  AssessmentSoccerSLDropStance,
  AssessmentType,
  BasketballAssessment,
  SoccerAssessment,
} from 'types/api';
import { labels } from 'util/graphConstants';
import { unitsOfMeasure } from 'util/unitsOfMeasure';
import { GraphInfo } from 'components/GraphInfo';
import { GraphTable } from 'components/GraphTable';
import { GraphTabs } from 'components/GraphTabs';
import { Radar } from 'components/Radar';

export type ChartType = 'stance' | 'kick';

interface Props {
  assessment: BasketballAssessment | SoccerAssessment;
  chartType: ChartType;
  className?: string;
}

interface ITerms {
  key: string;
  unit?: string;
  name?: string;
}

const {
  percContactTime,
  percAnkleROM,
  percKneeActiveDeceleration,
  percPeakConcForce,
} = labels;

export const definitions = (type?: ChartType): ITerms[] => {
  let path = '';
  switch (type) {
    case 'stance':
      path = 'assessmentDetails.assessmentSoccerSLDropStance';
      break;
    case 'kick':
      path = 'assessmentDetails.assessmentSoccerSLDropKick';
  }
  return [
    {
      key: `${path}.percContactTime`,
      unit: unitsOfMeasure('percentage'),
      name: percContactTime,
    },
    {
      key: `${path}.percAnkleROM`,
      unit: unitsOfMeasure('percentage'),
      name: percAnkleROM,
    },
    {
      key: `${path}.percKneeActiveDeceleration`,
      unit: unitsOfMeasure('velocity'),
      name: percKneeActiveDeceleration,
    },
    {
      key: `${path}.percPeakConcForce`,
      unit: unitsOfMeasure('force'),
      name: percPeakConcForce,
    },
  ];
};

export const radarLabelsVert = [
  { max: 100, name: percContactTime },
  { max: 100, name: percAnkleROM },
  { max: 100, name: percKneeActiveDeceleration },
  { max: 100, name: percPeakConcForce },
];

export const Terms = (
  <GraphInfo
    terms={[
      'ContactTime',
      'AnkleROM',
      'KneeActiveDeceleration',
      'PeakConcForce',
    ]}
  />
);

export type ChartData =
  | AssessmentSoccerSLDropStance
  | (AssessmentSoccerSLDropKick & {
      average?: { value: number[]; name?: string }[];
    });

export const getSummary = (
  assessment: SoccerAssessment,
  type: ChartType,
): ChartData | undefined => {
  let summary: ChartData | undefined;
  switch (type) {
    case 'kick':
      summary = assessment.assessmentDetails?.assessmentSoccerSLDropKick;
      break;
    case 'stance':
      summary = assessment.assessmentDetails?.assessmentSoccerSLDropStance;
  }
  return summary;
};

export const DropStanceKick = (props: Props) => {
  const { assessment, className, chartType } = props;

  const summary = getSummary(assessment, chartType);

  const {
    percContactTime = 0,
    percAnkleROM = 0,
    percKneeActiveDeceleration = 0,
    percPeakConcForce = 0,
  } = summary || {};

  const data: { value: number[]; name?: string }[] = [
    {
      value: [
        percContactTime,
        percAnkleROM,
        percKneeActiveDeceleration,
        percPeakConcForce,
      ],
    },
  ];

  if (assessment.assessmentType === AssessmentType.SOCCER) {
    data.push({
      name: `${assessment?.position} Average`,
      value: Array(data[0].value.length).fill(50),
    });
  }

  const config = [
    {
      id: 'radarTab',
      name: 'Percentile',
      Icon: RadarIcon,
      content: <Radar data={data} indicator={radarLabelsVert} />,
    },
    {
      id: 'tableTab',
      name: 'Test Results',
      Icon: TableIcon,
      content: (
        <GraphTable
          assessments={[assessment]}
          definitions={definitions(chartType)}
        />
      ),
    },
    {
      id: 'infoTab',
      name: 'Terminology',
      Icon: InfoIcon,
      content: Terms,
    },
  ];

  return (
    <GraphTabs
      className={className}
      config={config}
      defaultTab="radarTab"
      heading={`SL DROP ${chartType}`}
    />
  );
};
