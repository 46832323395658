import { ReactComponent as Arrow } from 'assets/icons/arrow-right.svg';
import { useMemo } from 'react';
import styles from './Pagination.module.css';

interface PaginationProps {
  hasNextPage?: boolean;
  hasPreviousPage?: boolean;
  onNext?: () => void;
  onPrevious?: () => void;
  page?: number;
  perPage?: number;
  totalCount?: number;
}

export const Pagination = (props: PaginationProps) => {
  const {
    hasNextPage = false,
    hasPreviousPage = false,
    onNext,
    onPrevious,
    page,
    perPage = 10,
    totalCount,
  } = props;

  const range = useMemo(() => {
    if (page && perPage && totalCount) {
      const pages = Math.ceil(totalCount / perPage);
      const lower = perPage * page - (perPage - 1);
      const upper = page === pages ? totalCount : perPage * page;
      return [lower, upper];
    }
  }, [page, perPage, totalCount]);

  return (
    <div className={styles.container}>
      {range && totalCount && (
        <span>
          {range.join('-')} of {totalCount}
        </span>
      )}
      <button
        className={styles.previous}
        disabled={!hasPreviousPage}
        onClick={onPrevious}
      >
        <Arrow />
      </button>
      <button className={styles.next} disabled={!hasNextPage} onClick={onNext}>
        <Arrow />
      </button>
    </div>
  );
};
