import * as Popover from '@radix-ui/react-popover';
import cx from 'classnames';
import React, { useCallback, useMemo, useState } from 'react';
import {
  CellProps,
  useExpanded,
  useRowSelect,
  useSortBy,
  useTable,
} from 'react-table';
import {
  AssessmentGroup,
  AssessmentSortOption,
  AssessmentType,
  BaseAssessment,
  BasketballAssessment,
  SoccerAssessment,
  SortDirection,
} from 'types/api';
import { tooltipText } from 'util/tooltipText';
import { IAListTableRow } from 'components/AssessmentsListTable';
import { columnsByAssessmentType } from 'components/AssessmentsListTable/AssessmentsListColumns';
import tableStyles from 'components/AssessmentsListTable/AssessmentsListTable.module.css';
import { IndeterminateCheckbox } from 'components/IndeterminateCheckbox';
import tooltiptableStyles from 'components/Tooltip/Tooltip.module.css';
import { RowExpanded } from '../RowExpanded';
import { columns as adminColumns } from './AdminColumns';
import admintableStyles from './AdminTable.module.css';

export type AssessmentGroupJoined = Omit<AssessmentGroup, 'assessments'> & {
  assessments: Array<BasketballAssessment | SoccerAssessment> | null;
} & IAListTableRow;

interface ITableProps {
  assessmentGroups: AssessmentGroupJoined[];
  assessmentType: AssessmentType;
  onSortBy: (term: string, direction: SortDirection) => void;
  sortByDefault: AssessmentSortOption;
  onToggleSelect: (assessment?: BaseAssessment) => void;
  selectedAssessments?: BaseAssessment[];
}

const Table = ({
  assessmentGroups,
  assessmentType,
  onSortBy,
  sortByDefault,
  onToggleSelect,
  selectedAssessments,
}: ITableProps) => {
  const columns = useMemo(
    () => columnsByAssessmentType(adminColumns, assessmentType),
    [assessmentType],
  );
  const [visiblePopovers, setVisiblePopovers] = useState<string[]>([]);
  const { data, getTableProps, getTableBodyProps, headers, rows, prepareRow } =
    useTable<AssessmentGroupJoined>(
      {
        columns,
        data: assessmentGroups,
        /* table config */
        manualSortBy: true,
        initialState: {
          sortBy: [
            {
              id: sortByDefault,
            },
          ],
        },
        getRowId: (dataRow) => {
          return dataRow.latestAssessmentId as string;
        },
        useControlledState: (state) => {
          return useMemo(
            () => ({
              ...state,
              selectedRowIds:
                selectedAssessments?.reduce(
                  (accumulatedSel: Record<string, boolean>, assessment) => {
                    return {
                      [assessment.id as string]: true,
                      ...accumulatedSel,
                    };
                  },
                  {},
                ) || {},
            }),
            // eslint-disable-next-line -- linter complains selectedAssessments isn't being used
            [state, selectedAssessments],
          );
        },
      },
      /* table plugins */
      useSortBy,
      useExpanded,
      useRowSelect,
      (hooks) => {
        if (assessmentType !== AssessmentType.ALL) {
          hooks.visibleColumns.push((columns) => [
            {
              id: 'select',
              Header: false,
              Cell: ({ row, state }: CellProps<AssessmentGroupJoined>) => {
                const { onChange, ...props } = row.getToggleRowSelectedProps();

                const isSelectionAllowed =
                  Object.values(state.selectedRowIds).length < 4;
                const isDisabled = !props.checked && !isSelectionAllowed;

                return (
                  <IndeterminateCheckbox
                    {...props}
                    isDisabled={isDisabled}
                    notAllowedMessage="The maximum number of four (4) players has been reached for a comparison view."
                    title={`select-${row.original.name}`}
                    onChange={(e) => {
                      if (onChange && onToggleSelect) {
                        // react table row select default handler
                        onChange(e);
                        // update selected row state
                        onToggleSelect(
                          row.original.assessments?.find(
                            (assessment) => assessment.id === row.id,
                          ),
                        );
                      }
                    }}
                  />
                );
              },
            },
            ...columns,
          ]);
        }
      },
    );

  const handleHeaderMouseEnter = useCallback((id: string) => {
    return () => {
      setVisiblePopovers((prev) => {
        return tooltipText[id] ? [id, ...prev] : prev;
      });
    };
  }, []);

  const handleHeaderMouseOut = useCallback((id: string) => {
    return () => {
      setVisiblePopovers((prev) => prev.filter((p) => p !== id));
    };
  }, []);

  return (
    <div className={admintableStyles.main}>
      <table {...getTableProps()} className={cx([tableStyles['table-main']])}>
        <thead className={tableStyles['table-header']}>
          <tr>
            {headers.map(
              (
                {
                  getHeaderProps,
                  getSortByToggleProps,
                  isSorted,
                  isSortedDesc,
                  toggleSortBy,
                  render,
                  id,
                },
                headerIndex,
              ) => {
                return (
                  <th
                    {...getHeaderProps(getSortByToggleProps())}
                    className={cx([
                      tableStyles['header-cell'],
                      admintableStyles[id],
                      tableStyles[id],
                    ])}
                    key={headerIndex}
                    onClick={() => {
                      const newSortDirection = isSortedDesc
                        ? SortDirection.ASCENDING
                        : SortDirection.DESCENDING;

                      toggleSortBy(!isSortedDesc);
                      onSortBy(id, newSortDirection);
                    }}
                  >
                    <Popover.Root
                      open={visiblePopovers.includes(id)}
                      key={`popover-${id}`}
                    >
                      <Popover.Trigger asChild>
                        <span
                          onMouseEnter={handleHeaderMouseEnter(id)}
                          onMouseOut={handleHeaderMouseOut(id)}
                          onTouchStart={handleHeaderMouseEnter(id)}
                          onTouchEnd={handleHeaderMouseOut(id)}
                        >
                          {render('Header')}
                        </span>
                      </Popover.Trigger>
                      <Popover.Content
                        className={`${tooltiptableStyles.tooltip}`}
                        sideOffset={12}
                        side="top"
                      >
                        <Popover.Arrow className={tooltiptableStyles.arrow} />
                        <small>{tooltipText[id] as string}</small>
                      </Popover.Content>
                    </Popover.Root>
                    <span
                      className={cx({
                        [tableStyles['sort-icon']]: true,
                        [tableStyles.sorted]: isSorted,
                        [tableStyles['sorted-desc']]: isSorted && isSortedDesc,
                        [tableStyles['sorted-asc']]: isSorted && !isSortedDesc,
                      })}
                    />
                  </th>
                );
              },
            )}
          </tr>
        </thead>
        <tbody {...getTableBodyProps()} className={tableStyles['table-body']}>
          {rows.map((row) => {
            prepareRow(row);
            const rowProps = row.getRowProps();
            const rowKey = row.original.latestAssessmentId;
            return (
              <React.Fragment key={rowKey}>
                <tr
                  {...rowProps}
                  id={row.original.latestAssessmentId}
                  className={cx({
                    [tableStyles['expanded-row']]: row.isExpanded,
                  })}
                >
                  {row.cells.map((cell, cellIndex) => {
                    return (
                      <td
                        {...cell.getCellProps()}
                        className={cx([
                          tableStyles['table-cell'],
                          tableStyles[cell.column.id],
                          admintableStyles[cell.column.id],
                        ])}
                        key={cellIndex}
                      >
                        {cell.render('Cell')}
                      </td>
                    );
                  })}
                </tr>
                {row.isExpanded ? (
                  <tr className={tableStyles['expanded-view']}>
                    <td
                      className={tableStyles['expanded-view-container']}
                      colSpan={100}
                    >
                      <RowExpanded
                        assessments={data[row.index].assessments}
                        index={0}
                        isAdmin
                      />
                    </td>
                  </tr>
                ) : null}
              </React.Fragment>
            );
          })}
        </tbody>
      </table>
    </div>
  );
};

export { Table };
