import { ReactComponent as MenuIcon } from 'assets/icons/menu.svg';
import { ReactComponent as SettingsIcon } from 'assets/icons/settings.svg';
import { ReactComponent as Logo } from 'assets/images/logo.svg';
import cx from 'classnames';
import history from 'history/browser';
import { useRef, useState } from 'react';
import { useMediaQuery } from 'react-responsive';
import { Link, Route, Routes, useLocation } from 'react-router-dom';
import { UserResponseModel } from 'types/api';
import { StepOne } from 'components/CreateOrganizationStepper/StepOne';
import { StepTwo } from 'components/CreateOrganizationStepper/StepTwo';
import { HandleClose, Modal } from 'components/Modal';
import { HandleClosePopover, Popover } from 'components/Popover';
import { ProfilePicture } from 'components/ProfilePicture';
import { Settings } from 'components/Settings';
import { ShareAssessment } from 'components/ShareAssessment';
import styles from './Header.module.css';

interface HeaderProps {
  profile?: UserResponseModel;
  logout?: () => void;
}

export const Header = (props: HeaderProps) => {
  const isComparsionPage = useLocation().pathname === '/assessments/compare';
  const { logout, profile } = props;

  const isMobile = !useMediaQuery({
    query: '(min-width: 768px)',
  });
  const triggerName = isMobile ? <MenuIcon /> : <SettingsIcon />;

  const [step, setStep] = useState(1);
  const [showCreateOrgModal, setShowCreateOrgModal] = useState<boolean>(false);

  const modalClose = useRef<HandleClose>(null);
  const popoverClose = useRef<HandleClosePopover>(null);

  const handleCloseModal = () => {
    modalClose.current?.onClose();
    setShowCreateOrgModal(false);
  };

  const handleCloseSettings = () => {
    popoverClose.current?.onPopoverClose();
  };

  const handleCreateOrgModal = () => {
    history.push('#organizations');
    popoverClose.current?.onPopoverClose();
    setStep(1);
    setShowCreateOrgModal(true);
  };

  history.listen(({ action, location }) => {
    if (action === 'POP') {
      if (location.hash === '#organizations') {
        popoverClose.current?.onPopoverClose();
        setStep(1);
        setShowCreateOrgModal(true);
      } else {
        modalClose.current?.onClose();
      }
    }
  });

  return (
    <header
      className={cx(
        styles.header,
        isComparsionPage ? styles['full-width'] : '',
      )}
    >
      <div className={styles.container}>
        <div className={styles['logo-container']}>
          <Link to="/">
            <Logo />
          </Link>
        </div>
        {profile && (
          <>
            <Routes>
              <Route element={<ShareAssessment />} path="assessments/:id" />
            </Routes>
            <div className={styles['settings-icon']}>
              <Popover
                contentClassName={styles.popover}
                trigger={triggerName}
                ref={popoverClose}
                hideArrow
              >
                <Settings
                  logout={logout}
                  profile={profile}
                  closeModal={handleCloseSettings}
                  handleCreateOrgModal={handleCreateOrgModal}
                />
              </Popover>
            </div>
            {showCreateOrgModal && (
              <div>
                <Modal
                  isOpen={showCreateOrgModal}
                  onOpenChange={setShowCreateOrgModal}
                  classes={[styles['switch-org-modal']]}
                  ref={modalClose}
                >
                  {step === 1 ? (
                    <StepOne
                      setStep={setStep}
                      profile={profile}
                      closeModal={handleCloseModal}
                    />
                  ) : (
                    <StepTwo closeModal={handleCloseModal} profile={profile} />
                  )}
                </Modal>
              </div>
            )}
            {isMobile ? null : (
              <div className={styles['profile-icon']}>
                <Link to="/profile">
                  <ProfilePicture
                    src={profile.blobImageUri as string}
                    size={40}
                    firstName={profile.firstName as string}
                    lastName={profile.lastName as string}
                  />
                </Link>
              </div>
            )}
          </>
        )}
      </div>
    </header>
  );
};
