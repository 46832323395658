import classNames from 'classnames';
import { HTMLProps, PropsWithChildren } from 'react';
import styles from './Button.module.css';

export enum ButtonVariants {
  Primary = 'primary',
  Secondary = 'secondary',
  Tertiary = 'tertiary',
}

interface ButtonProps extends HTMLProps<HTMLButtonElement> {
  type?: 'button' | 'reset' | 'submit';
  variant?: ButtonVariants;
  disabled?: boolean;
}

export const Button = (props: PropsWithChildren<ButtonProps>) => {
  const {
    children,
    className,
    type = 'button',
    variant = ButtonVariants.Primary,
    disabled = false,
    ...rest
  } = props;

  return (
    <button
      {...rest}
      className={classNames(styles.button, styles[variant], className)}
      disabled={disabled}
      type={type}
    >
      {children}
    </button>
  );
};
