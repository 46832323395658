import {
  BasketballAssessment,
  PositionlessVerticalLateralAbility,
} from 'types/api';
import { useAssessmentsByName } from 'hooks/query/useAssessmentsByName';
import { useBreakpoints } from 'hooks/util/useBreakpoints';
import { useChart } from 'hooks/util/useChart';
import { getMarkPoint } from 'util/getMarkPoint';

interface ScatterChartProps {
  data?: Record<string, Array<PositionlessVerticalLateralAbility>> | null;
  assessment: BasketballAssessment | undefined;
  priorAssessments: BasketballAssessment[] | null | undefined;
}

export const useBasketballChart = ({
  data,
  assessment,
  priorAssessments,
}: ScatterChartProps) => {
  const playerName = assessment?.name;
  const { data: assessments } = useAssessmentsByName(playerName);
  const playerVerticalPercentile =
    assessment?.assessmentDetails?.assessmentOverallPerformanceFactors
      ?.verticalPercentile;
  const playerLateralPercentile =
    assessment?.assessmentDetails?.assessmentOverallPerformanceFactors
      ?.lateralPercentile;
  const { isMobile } = useBreakpoints();

  const coordinates = assessments?.map((assessment: BasketballAssessment) => {
    const lat =
      assessment.assessmentDetails?.assessmentOverallPerformanceFactors
        ?.lateralPercentile;
    const vert =
      assessment.assessmentDetails?.assessmentOverallPerformanceFactors
        ?.verticalPercentile;
    return [lat, vert];
  });

  // If the assessment is a single shared assessment, assessments will be undefined.
  // In that situation, use the current assessment to plot the athlete's markpoint.
  let assessmentsForMarkPoint = priorAssessments;

  if (assessment && (assessments === undefined || assessments.length === 0)) {
    if (priorAssessments && priorAssessments.length > 0) {
      assessmentsForMarkPoint = [assessment].concat(priorAssessments);
    } else {
      // If it's a single shared assessment, there will be no prior assessments.
      // Set the current assessment as the markpoint
      assessmentsForMarkPoint = [assessment];
    }
  }

  return useChart({
    grid: {
      borderColor: 'transparent',
      bottom: isMobile ? '20%' : '18%',
      containLabel: true,
      left: '6%',
      right: '7%',
      show: true,
      top: '3%',
    },
    legend: {
      data: ['Big', 'Wing', 'Guard', `${playerName}`],
      bottom: isMobile ? 10 : 'auto',
      itemHeight: isMobile ? 3 : 14,
      itemWidth: isMobile ? 6 : 25,
    },
    xAxis: [
      {
        min: 0,
        max: 100,
        name: 'Lateral Ability',
        nameLocation: 'middle',
        nameTextStyle: {
          padding: [24, 0, 0, 0],
          color: '#ffffff',
          fontFamily: 'D-Din',
          fontSize: 16,
        },
        showGrid: true,
        type: 'value',
        scale: true,
        axisLine: {
          lineStyle: {
            color: '#ffffff',
          },
          symbolOffset: [50, 50],
        },
        axisLabel: {
          formatter: '{value} %',
        },
        splitLine: {
          show: true,
          lineStyle: {
            opacity: 0.5,
          },
        },
        interval: 50,
      },
    ],
    yAxis: [
      {
        type: 'value',
        min: 0,
        max: 100,
        scale: true,
        name: 'Vertical Ability',
        nameLocation: 'middle',
        nameTextStyle: {
          padding: [0, 0, 30, 0],
          color: '#ffffff',
          fontFamily: 'D-Din',
          fontSize: 16,
        },
        showGrid: true,
        axisLine: {
          lineStyle: {
            color: '#ffffff',
          },
        },
        axisLabel: {
          formatter: '{value} %',
        },
        splitLine: {
          show: true,
          lineStyle: {
            opacity: 0.5,
          },
        },
        interval: 50,
      },
    ],
    tooltip: {
      showDelay: 0,
      /* eslint-disable-next-line @typescript-eslint/no-explicit-any */
      formatter: (params: any) => {
        const { value, data } = params;
        let labelValues = value;
        if (data.name) {
          labelValues = data.coord;
        }
        return `
          <div>${data.name || ''}</div>
          <div>Lateral: ${labelValues[0]}</div>
          <div>Vertical: ${labelValues[1]}</div>`;
      },
      padding: [10, 20, 10, 20],
      textStyle: {
        lineHeight: 12,
        fontFamily: 'D-Din',
        align: 'center',
      },
      backgroundColor: '#ffffff',
      borderColor: '#ffffff',
      axisPointer: {
        show: true,
        type: 'cross',
        lineStyle: {
          type: 'dashed',
          width: 1,
        },
        label: {
          color: 'black',
        },
      },

      xAxis: [
        {
          type: 'value',
          scale: true,
          axisLabel: {
            formatter: '{value} %',
          },
          splitLine: {
            show: false,
          },
        },
      ],
      yAxis: [
        {
          type: 'value',
          scale: true,
          axisLabel: {
            formatter: '{value} %',
          },
          splitLine: {
            show: false,
          },
        },
      ],
    },
    series: [
      {
        type: 'scatter',
        z: 10,
        data: coordinates,
        name: `${playerName}`,
        color: '#ffffff',
        markPoint: getMarkPoint(
          playerLateralPercentile,
          playerVerticalPercentile,
          assessments && assessments.length > 0
            ? assessments
            : assessmentsForMarkPoint,
        ),
        symbolSize: 7,
        emphasis: {
          focus: 'series',
        },
      },
      {
        type: 'scatter',
        z: -1,
        data: data?.wing?.map(Object.values),
        name: 'Wing',
        color: '#4892ff',
        symbolSize: 7,
        markArea: {
          silent: true,
          itemStyle: {
            color: 'transparent',
            borderWidth: 1,
            borderType: 'dashed',
          },
          data: [
            [
              {
                xAxis: 'min',
                yAxis: 'min',
              },
              {
                xAxis: 'max',
                yAxis: 'max',
              },
            ],
          ],
        },
        emphasis: {
          focus: 'series',
        },
      },
      {
        type: 'scatter',
        z: -1,
        data: data?.guard?.map(Object.values),
        color: '#7bffb2',
        symbolSize: 7,
        name: 'Guard',
        markArea: {
          silent: true,
          itemStyle: {
            color: 'transparent',
            borderWidth: 1,
            borderType: 'dashed',
          },
          data: [
            [
              {
                xAxis: 'min',
                yAxis: 'min',
              },
              {
                xAxis: 'max',
                yAxis: 'max',
              },
            ],
          ],
        },
        emphasis: {
          focus: 'series',
        },
      },
      {
        type: 'scatter',
        z: -1,
        data: data?.big?.map(Object.values),
        color: '#E66BFF',
        symbolSize: 7,
        name: 'Big',
        markArea: {
          silent: true,
          itemStyle: {
            color: 'transparent',
            borderWidth: 1,
            borderType: 'dashed',
          },
          data: [
            [
              {
                xAxis: 'min',
                yAxis: 'min',
              },
              {
                xAxis: 'max',
                yAxis: 'max',
              },
            ],
          ],
        },
        emphasis: {
          focus: 'series',
        },
      },
    ],
  });
};
