interface ConvertHeightProps {
  height: number;
}
export const ConvertHeightToMeters = ({ height }: ConvertHeightProps) => {
  const convertedHeight = (height * 0.0254).toFixed(2);
  if (convertedHeight.endsWith('.00')) {
    return <span> ({convertedHeight.split('.')[0]} cm)</span>;
  }
  return <span> ({convertedHeight}m)</span>;
};
