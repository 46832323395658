import { ReactComponent as ErrorTriangleIcon } from 'assets/icons/error-triangle.svg';
import { ReactComponent as LockIcon } from 'assets/icons/lock.svg';
import { ReactComponent as LogoImage } from 'assets/images/logo.svg';
import { Controller, useForm } from 'react-hook-form';
import { Button } from 'components/Button';
import { TextField } from 'components/TextField';
import styles from './Unauthorized.module.css';

interface UnauthorizedProps {
  isError?: boolean;
  onPasswordSubmit?: (password: string) => void;
}

export const Unauthorized = (props: UnauthorizedProps) => {
  const { isError, onPasswordSubmit } = props;
  const { control, handleSubmit } = useForm({
    defaultValues: {
      password: '',
    },
  });

  const onSubmit = (data: { password: string }) =>
    onPasswordSubmit?.(data.password);

  return (
    <div className={styles.container}>
      {isError ? (
        <>
          <header className={styles.header}>
            <ErrorTriangleIcon className={styles.icon} />
            <h1>An error occurred</h1>
          </header>
          <section className={styles.main}>
            <p>
              Please try again. If the problem persists, contact the sender.
            </p>
          </section>
        </>
      ) : (
        <>
          <header className={styles.header}>
            <LockIcon className={styles.icon} />
            <h1>This link is password protected</h1>
          </header>
          <section className={styles.main}>
            <p>
              You will need a password from the sender to view this assessment
            </p>
            <form
              className={styles.form}
              noValidate
              onSubmit={handleSubmit(onSubmit)}
            >
              <Controller
                control={control}
                name="password"
                render={({ field, fieldState }) => (
                  <TextField
                    {...field}
                    error={fieldState.error?.message}
                    label="Enter Password From Sender"
                    required
                    type="password"
                  />
                )}
              />
              <Button type="submit">View Assessment</Button>
            </form>
          </section>
        </>
      )}

      <footer className={styles.footer}>
        <LogoImage />
      </footer>
    </div>
  );
};
