import { useEffect, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { Role, UserResponseModel } from 'types/api';
import { UpdateUserRoleRequestModel } from 'types/api/models/UpdateUserRoleRequestModel';
import { useOrganizationContext } from 'hooks/util/useOrganizationContext';
import { Button, ButtonVariants } from 'components/Button';
import { TextField } from 'components/TextField';
import styles from './EditRoleForm.module.css';

interface IFormProps {
  onSubmit: (form: UpdateUserRoleRequestModel) => void;
  user: UserResponseModel | undefined;
}

type NonNullableUpdateUserRoleRequestModel = {
  [K in keyof UpdateUserRoleRequestModel]: NonNullable<
    UpdateUserRoleRequestModel[K]
  >;
};

export const EditRoleForm = ({ user, onSubmit }: IFormProps) => {
  const { contextOrg } = useOrganizationContext();
  const [formChangesDetected, setFormChangesDetected] =
    useState<boolean>(false);

  const userRole: Role | undefined = user?.organizationRoles?.find(
    (x) => x.organizationId === contextOrg?.id,
  )?.role;

  const { control, handleSubmit, watch } =
    useForm<NonNullableUpdateUserRoleRequestModel>({
      defaultValues: {
        role: userRole,
        organizationId: contextOrg?.id || '',
      },
    });

  useEffect(() => {
    const subscription = watch(
      (value: NonNullableUpdateUserRoleRequestModel) => {
        setFormChangesDetected(!!value);
      },
    );
    return () => subscription.unsubscribe();
  }, [watch]);

  const onFormSubmit = (formData: UpdateUserRoleRequestModel) => {
    setFormChangesDetected(false);
    onSubmit(formData);
  };

  return (
    <>
      <section className={styles['form-section']}>
        <form
          className={styles.form}
          noValidate
          onSubmit={handleSubmit(onFormSubmit)}
        >
          <fieldset>
            <div className={styles.flex}>
              <legend>Information</legend>
              <span className={styles.buttons}>
                <Button
                  variant={ButtonVariants.Primary}
                  disabled={!formChangesDetected}
                  type="submit"
                >
                  Save
                </Button>
              </span>
            </div>
            <div className={styles.fieldset}>
              <TextField
                label="First Name"
                value={user?.firstName ? user.firstName : ''}
                className={styles.disabled}
                readOnly
              />
              <TextField
                label="Last Name"
                value={user?.lastName ? user.lastName : ''}
                className={styles.disabled}
                readOnly
              />
              <Controller
                control={control}
                name="role"
                render={({ field, fieldState }) => (
                  <TextField
                    {...field}
                    error={fieldState.error?.message}
                    label="Role"
                    tagName="select"
                  >
                    <option value="" />
                    <option value={Role.ORGANIZATION_ADMIN}>
                      Administrator
                    </option>
                    <option value={Role.ORGANIZATION_STAFF}>Staff</option>
                  </TextField>
                )}
              />
            </div>
          </fieldset>
        </form>
      </section>
    </>
  );
};
