import cx from 'classnames';
import {
  FormEvent,
  HTMLProps,
  Ref,
  useCallback,
  useRef,
  useState,
} from 'react';
import styles from './SearchBar.module.css';

export const DefaultMinLength = 2;
export interface ISearchBarProps {
  onSearch: (e: FormEvent<HTMLFormElement>) => void;
  onToggleOpen: () => void;
  clear: (...args: unknown[]) => void;
  isValid?: boolean;
  inputProps?: HTMLProps<HTMLInputElement>;
  inputRef: Ref<HTMLInputElement>;
}

const SearchBar = ({
  onSearch,
  onToggleOpen,
  clear,
  inputRef,
  inputProps,
}: ISearchBarProps) => {
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const formRef = useRef<HTMLFormElement>(null);
  const resetForm = useCallback(() => {
    formRef.current?.reset();
    setIsOpen(false);

    clear();
  }, [clear]);

  return (
    <div
      className={cx(styles['search-bar'], {
        [styles.open]: isOpen,
      })}
    >
      <span
        className={styles.icon}
        onClick={() => {
          setIsOpen(!isOpen);
          onToggleOpen();
        }}
      />
      <form
        ref={formRef}
        onSubmit={(e) => {
          e.preventDefault();
          onSearch(e);
        }}
      >
        <input {...inputProps} type="text" ref={inputRef} />
      </form>

      <span className={cx([styles.clear, styles.icon])} onClick={resetForm} />
    </div>
  );
};

export { SearchBar };
