import { useEffect, useState } from 'react';
import { Role, UserResponseModel } from 'types/api';
import {
  ContentType,
  useAuthenticatedFetch,
} from 'hooks/util/useAuthenticatedFetch';
import { useOrganizationContext } from 'hooks/util/useOrganizationContext';
import { queryClient } from 'util/queryClientConfig';
import { ProfilePicture } from 'components/ProfilePicture';
import styles from './Header.module.css';

interface HeaderProps {
  profile?: UserResponseModel | undefined;
  noOrgAssigned?: boolean | undefined;
  mechanicsScore?: number;
  athleticismScore?: number;
}

export const Header = ({
  profile,
  mechanicsScore,
  athleticismScore,
  noOrgAssigned,
}: HeaderProps) => {
  const { blobImageUri, firstName, lastName, id } = profile || {};

  const [profileImage, setProfileImage] = useState<string>();
  const [imageUploadError, setImageUploadError] = useState<string>();
  const { contextOrg, contextRole } = useOrganizationContext();

  // If user image not available, use default org's image
  const profileImageUri = blobImageUri || contextOrg?.blobImageUri;

  useEffect(() => {
    if (profileImageUri) {
      setProfileImage(profileImageUri);
    }
  }, [profileImageUri]);

  const fetch = useAuthenticatedFetch(ContentType.NONE);

  async function uploadFile(id: string, file: File) {
    const formData = new FormData();
    formData.append('Image', file);
    const postImage = await fetch(
      `${process.env.REACT_APP_API_HOST}/users/${id}/image`,
      {
        method: 'POST',
        body: formData,
      },
    );
    if (postImage.ErrorCode) {
      setImageUploadError(postImage.Message);
    } else {
      setImageUploadError(undefined);
      // The POST request above returns a URL, which takes a split second to load.
      // To avoid a jumpy experience, we will set the profile image state to the uploaded file itself rather than the returned URL.
      setProfileImage(URL.createObjectURL(file));
      queryClient.invalidateQueries('loggedInUser');
    }
  }

  return (
    <>
      <div className={styles.header}>
        <div className={styles['user-info']}>
          <div>
            <div className={styles['profile-picture-container']}>
              <ProfilePicture
                size={160}
                src={profileImage as string}
                firstName={firstName as string}
                lastName={lastName as string}
              />
              <input
                type="file"
                className={styles.upload}
                accept="image/png, image/jpeg"
                name="Image"
                onChange={({ target }) => {
                  if (target.files) {
                    const file = target.files[0];
                    uploadFile(id as string, file);
                  }
                }}
              />
            </div>
            {imageUploadError && (
              <div className={styles['image-upload-error']}>
                <p>Error uploading image: {imageUploadError}</p>
              </div>
            )}
          </div>
          <div className={styles['user-stats']}>
            <h1>
              {profile?.firstName} {profile?.lastName}
            </h1>
            {noOrgAssigned && contextRole !== Role.SUPER_ADMIN && (
              <div className={styles['no-org-error']}>
                <p>
                  Not presently assigned to an organization. Please contact your
                  organization or P3.
                </p>
              </div>
            )}
            <ul className={styles.tags} />
            <h2>{contextOrg?.organizationName}</h2>
            {profile?.city && profile?.state && (
              <address
                className={styles.location}
              >{`${profile?.city}, ${profile?.state}`}</address>
            )}
          </div>
        </div>
        <ul className={styles['header-info']}>
          {mechanicsScore && (
            <li className={styles.age}>
              <span>
                {mechanicsScore === 0 || !mechanicsScore
                  ? '-'
                  : Math.round(mechanicsScore)}
              </span>
              Mechanics Score
            </li>
          )}
          {athleticismScore && (
            <li className={styles.score}>
              <span>
                {athleticismScore === 0 || !athleticismScore
                  ? '-'
                  : Math.round(athleticismScore)}
              </span>
              Athleticism Score
            </li>
          )}
          <li className={styles['user-email']}>
            <a href={`mailto:${profile?.email}`}>{profile?.email}</a>
          </li>
        </ul>
      </div>
    </>
  );
};
