import { AssessmentType, BaseAssessment } from 'types/api';
import { GraphTabs } from 'components/GraphTabs';
import styles from './Mechanics.module.css';
import { useConfig } from './useConfig';
import { useLowBackData } from './useLowBackData';

interface Props {
  assessments: BaseAssessment[];
  className?: string;
}

export const LowBackCompare = (props: Props) => {
  const { assessments, className } = props;
  const [first] = assessments;

  const data = useLowBackData(assessments);
  const ignoreIds =
    first?.assessmentType === AssessmentType.SOCCER ? ['radarTab'] : [];
  const config = useConfig('Low Back Mechanics', assessments, data, ignoreIds);
  return (
    <GraphTabs
      className={className}
      config={config}
      defaultTab="lineTab"
      headerClassName={styles.header}
      heading="Low Back Mechanics"
    />
  );
};
