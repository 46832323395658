export const HyperAthleticGuardContent = (props: { name: string }) => {
  const showName = props.name ? (
    <h2>{props.name} is a Hyper-Athletic Guard</h2>
  ) : (
    <h2>Hyper-Athletic Guards</h2>
  );
  return (
    <div>
      {showName}
      <p>
        Athletes in this cluster tend to lack the typical height / length of an
        NBA athlete. However, they make up for these anthropometric measures
        with elite force production and kinematic efficiency. The rare athlete
        in this cluster with NBA-average height / length will possess a series
        of physical advantages.
      </p>
      <ul>
        <li>
          Generally, lack the size/reach of the average NBA athlete, even
          somewhat undersized relative to positional norms.
        </li>
        <li>
          Make up for lacking measurables with impressive ability to move in all
          directions.
        </li>
        <li>Efficient and powerful athletes.</li>
      </ul>
      <h2>Hyper Athletic Guards</h2>
      <p>
        Though exceptional athletes, Hyper Athletic Guards stick in the NBA at a
        lower rate than any other guard-centric cluster.
      </p>
    </div>
  );
};
