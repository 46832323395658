import { Error } from 'models/Error';
import { useQuery } from 'react-query';
import { useMatch } from 'react-router-dom';
import { toast } from 'react-toastify';
import { AssessmentPaginatedList, AssessmentType, Role } from 'types/api';
import {
  ContentType,
  useAuthenticatedFetch,
} from 'hooks/util/useAuthenticatedFetch';
import { useOrganizationContext } from 'hooks/util/useOrganizationContext';

export interface IUseAssessmentList {
  searchTerm?: string;
  page: number;
  sortBy: string;
  sortDirection: string;
  assessmentType?: AssessmentType;
  orderByFirst?: string;
  athleteName?: string | null | undefined;
  contentType?: ContentType;
}

export const useAssessmentList = ({
  page = 1,
  sortBy,
  sortDirection,
  searchTerm,
  assessmentType,
  orderByFirst,
  athleteName,
  contentType = ContentType.NONE,
}: IUseAssessmentList) => {
  const { contextOrg, contextRole } = useOrganizationContext();

  const orderByFirstPath = `&orderByFirst=${orderByFirst}`;
  const assessmentTypePath = `&assessmentType=${assessmentType}`;
  const sortAndPageIndex = `pageIndex=${page}&sortOption=${sortBy}&sortDirection=${sortDirection}`;
  const athlete = `AthleteName=${athleteName}`;
  const isShared = useMatch('/share/:linkId/:assessmentId');

  const fetch = useAuthenticatedFetch(contentType);

  let path = searchTerm
    ? `/Assessments/Search?&partialName=${searchTerm}&${sortAndPageIndex}`
    : `/Assessments?${sortAndPageIndex}`;
  if (athleteName) {
    path = `/Assessments?${athlete}&${sortAndPageIndex}`;
  }

  if (orderByFirst) {
    path += orderByFirstPath;
  }

  if (assessmentType) {
    path += assessmentTypePath;
  }

  let enabled = true;
  if (!contextOrg && contextRole !== Role.SUPER_ADMIN) {
    enabled = false;
  }
  if (isShared) {
    enabled = false;
  }
  return useQuery<AssessmentPaginatedList, Error>(
    [
      'assessmentsList',
      { page, searchTerm, sortBy, sortDirection, orderByFirst, assessmentType },
    ],
    () => fetch(`${process.env.REACT_APP_API_HOST}${path}`),
    {
      enabled,
      keepPreviousData: true,
      onError: () => {
        toast.error('Error fetching assessments.');
      },
    },
  );
};
