/* Generates a paginated list of Organizations */
import cuid from 'cuid';
import {
  OrganizationResponseModel,
  OrganizationResponseModelPaginatedList,
} from 'types/api';
import { OrganizationGenerator } from './OrganizationGenerator';

interface IOrganizationListGenerator {
  generate: (p: { items: number }) => OrganizationResponseModelPaginatedList;
  generateItems: (items: number) => OrganizationResponseModel[];
  generateId: () => string;
}

export const OrganizationListGenerator: IOrganizationListGenerator = {
  generateId: (): string => {
    return cuid();
  },
  generate: ({ items }) => {
    return {
      items: OrganizationListGenerator.generateItems(items),
      hasNextPage: false,
      hasPreviousPage: false,
      pageIndex: 0,
      totalPage: 0,
    };
  },

  /* generates the `items` array in /Organizations */
  generateItems: (items: number): OrganizationResponseModel[] => {
    return Array.from({ length: items }, (): OrganizationResponseModel => {
      return OrganizationGenerator.generate();
    }) as OrganizationResponseModel[];
  },
};
