export const labels = {
  activeAnkleDecelerationKick: '# - Active Ankle\nDecel Kick',
  activeAnkleDecelerationStance: '# - Active Ankle\nDecel Stance',
  activeDorsiflexionKick: '# - Active Dorsiflexion\nKick',
  activeDorsiflexionStance: '# - Active\nDorsiflexion\nStance',
  activeDorsiFlexL: 'Active Dorsiflexion',
  activeDorsiFlexR: 'Active Dorsiflexion',
  ankleDecelL: 'Ankle\nDecel',
  ankleDecelR: 'Ankle\nDecel',
  ankleDorsiflexionAverage: 'Ankle Dorsiflexion',
  ankleExtAccel: 'Ankle Ext Accel',
  averageTotalMovementTime: 'Avg Total Mvmt Time',
  concRelFF: 'Concentric Force',
  deltaFemoralRotateL: 'Delta Femoral Rotation',
  deltaFemoralRotateR: 'Delta Femoral Rotation',
  deltaHipFlexionAverage: 'Delta Hip Flexion',
  deltaHipFlexionKick: '# - Delta Hip\nFlexion Kick',
  deltaHipFlexionStance: '# - Delta Hip\nFlexion Stance',
  deltaHipFlexL: 'L - Delta\nHip Flexion',
  deltaHipFlexR: 'R - Delta\nHip Flexion',
  eccRelFF: 'Ecc Rel FF',
  eversionKick: '# - Eversion Kick',
  eversionStance: '# - Eversion\nStance',
  hipAbductionL: 'L - Hip\nAbduction',
  hipAbductionR: 'R - Hip\nAbduction',
  hipActiveDecelL: 'Max Hip\nActive\nDecel',
  hipActiveDecelR: 'Max Hip\nActive\nDecel',
  hipExtVelocityL: 'L - Hip Ext\nVelocity',
  hipExtVelocityR: 'R - Hip Ext\nVelocity',
  impactRawL: 'L -\nImpact\nRaw',
  impactRawR: 'R -\nImpact\nRaw',
  impulseAsym: 'Impulse\nAsymmetry',
  inversionKick: '# - Inversion Kick',
  inversionL: 'Inversion',
  inversionR: 'Inversion',
  inversionStance: '# - Inversion\nStance',
  kneeExtAccel: 'Knee Ext\nAccel',
  kneeExtensionAcceleration: 'Knee Ext\nAccel',
  kneeExtensionVelocity: 'Knee Ext\nVelocity',
  kneeExtVel: 'Knee Ext\nVelocity',
  lateralForceAvg: 'Lateral\nForce Avg',
  lateralForceL: 'L - Lateral Force',
  lateralForceR: 'R - Lateral Force',
  leftHipExtensionVelocity: 'Hip Extension\nVelocity (L)',
  leftLateralForce: 'Lateral Force (L)',
  leftMaxHipAbduction: 'Peak Hip\nAbduction (L)',
  loadRelFF: 'Eccentric Force',
  maxRelRotationL: 'Max Rel Rotation',
  maxRelRotationR: 'Max Rel Rotation',
  netImpact1: 'Net Impact 1',
  netImpact2L: 'L - Net\nImpact 2',
  netImpact2R: 'R - Net\nImpact 2',
  netRelConcForce: 'Net Concentric Force',
  percAnkleROM: 'Ankle ROM',
  percContactTime: 'Contact Time',
  percKneeActiveDeceleration: 'Knee Active Decel',
  percPeakConcForce: 'Peak Conc Force',
  relativeRotationKick: '# - Rel Rotation\nKick',
  relativeRotationStance: '# - Rel Rotation\nStance',
  rightHipExtensionVelocity: 'Hip Extension\nVelocity (R)',
  rightLateralForce: 'Lateral Force (R)',
  rightMaxHipAbduction: 'Peak Hip\nAbduction (R)',
  translationKick: '# - Translation\nKick',
  translationL: 'Translation',
  translationR: 'Translation',
  translationStance: '# - Translation\nStance',
  trunkStabilityL: 'L -\nTrunk Stability',
  trunkStabilityR: 'R -\nTrunk Stability',
};
