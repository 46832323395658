import {
  AssessmentType,
  AssessmentWrapper,
  BasketballAssessment,
  SoccerAssessment,
  UserResponseModel,
} from 'types/api';
import { BasketballAssessmentsDetail } from './BasketballAssessmentDetail';
import { SoccerAssessmentsDetail } from './SoccerAssessmentDetail';

interface AssessmentDetailProps {
  assessmentWrapper: AssessmentWrapper;
  user?: UserResponseModel;
}

type Assessment = BasketballAssessment | SoccerAssessment;

export const AssessmentsDetail = (props: AssessmentDetailProps) => {
  const { assessmentWrapper = {} } = props;
  const { currentAssessment } = assessmentWrapper;
  const { assessmentType } = currentAssessment as Assessment;

  if (!currentAssessment) return null;

  switch (assessmentType) {
    case AssessmentType.BASKETBALL:
      return <BasketballAssessmentsDetail {...props} />;
    case AssessmentType.SOCCER:
      return <SoccerAssessmentsDetail {...props} />;
    default:
      return <>No assessment type specified</>;
  }
};
