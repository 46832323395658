export const KinematicMoverContent = (props: { name: string }) => {
  const showName = props.name ? (
    <h2>{props.name} is a Kinematic Mover</h2>
  ) : (
    <h2>Kinematic Movers</h2>
  );
  return (
    <div>
      {showName}
      <p>
        While these athletes possess middling traditional performance measures
        (and force output), they possess elite kinematic measures. Lateral plane
        movement efficiency generally stands out as a strength for athletes in
        this cluster.
      </p>
      <ul>
        <li>Efficient lateral movement skills. More smooth, less explosive.</li>
        <li>Generally, below average measurables (height/reach).</li>
        <li>Relative to the league average-above average jumpers.</li>
        <li>Relative to guards/wings average vertically.</li>
      </ul>
      <h2>Kinematic Movers</h2>
      <p>
        This cluster represents the highest density of All-Stars among the P3
        Clusters.
      </p>
    </div>
  );
};
