import * as Accordion from '@radix-ui/react-accordion';
import { ReactComponent as ArrowRight } from 'assets/icons/arrow-right.svg';
import styles from './FrequentlyAskedQuestions.module.css';
import questions from './questions.json';

export const FrequentlyAskedQuestions = () => {
  return (
    <article className={styles.wrapper}>
      <header className={styles.header}>
        <section className={styles.container}>
          <h1>FAQ</h1>
          <p>
            P3's FAQ may not reflect your organization's implementation or it's
            rules and permissions.
          </p>
        </section>
      </header>
      <section className={styles.content}>
        <section className={styles.container}>
          <Accordion.Root type="single">
            {questions.map(({ question, answer }, index) => (
              <Accordion.Item key={index} value={`question-${index}`}>
                <Accordion.Header>
                  <Accordion.Trigger className={styles.trigger}>
                    <ArrowRight className={styles.arrow} />
                    <span>{question}</span>
                  </Accordion.Trigger>
                </Accordion.Header>
                <Accordion.Content asChild>
                  <div
                    className={styles.answer}
                    dangerouslySetInnerHTML={{ __html: answer }}
                  />
                </Accordion.Content>
              </Accordion.Item>
            ))}
          </Accordion.Root>
        </section>
      </section>
    </article>
  );
};
