import { BarChart } from 'echarts/charts';
import {
  GridComponent,
  LegendComponent,
  TooltipComponent,
} from 'echarts/components';
import * as echarts from 'echarts/core';
import type { CategoryAxisBaseOption } from ' echarts/echarts';
import { SVGRenderer } from 'echarts/renderers';
import { useChart } from 'hooks/util/useChart';
import styles from './Line.module.css';

echarts.use([
  SVGRenderer,
  TooltipComponent,
  GridComponent,
  LegendComponent,
  BarChart,
]);

// Each of these line graph objects will be contain data for a mechanic (ie: ImpulseAsym). The object is not grouped by assessment data
export interface ILineGraphData {
  // name is the mechanic, ie: Impulse Asym
  name: string;
  // type will always be 'line'
  type: string;
  // Elements of this data array must be values for each assessment date, all for the same mechanic
  // Example:
  // Assessment date 10/12/20's ImpulseAsym value is 20
  // Assessment date 10/15/20's ImpulseAsym value is 30
  // The data array should look like: [20,30]
  data: number[];
}

interface LineProps {
  data?: ILineGraphData[];
  dates?: CategoryAxisBaseOption.Data;
}

export const Line = (props: LineProps) => {
  const { data = [], dates = [] } = props;
  const chartRef = useChart({
    grid: {
      containLabel: true,
      bottom: 10,
      left: 20,
      right: 25,
      top: 60,
    },
    legend: {
      data: data.map((instance) => ({
        name: instance.name,
        icon: 'circle',
      })),
      inactiveColor: 'rgba(255, 255, 255, 0.3)',
      itemGap: 10,
      itemStyle: {
        color: {
          type: 'radial',
          x: 0.5,
          y: 0.5,
          r: 0.5,
          colorStops: [
            {
              offset: 0,
              color: '#fff', // color at 0%
            },
            {
              offset: 0.5,
              color: '#fff', // color at 50%
            },
            {
              offset: 0.5,
              color: '#000', // color at 50%
            },
            {
              offset: 0.7,
              color: '#000', // color at 70%
            },
            {
              offset: 1,
              color: '#fff', // color at 100%
            },
          ],
        },
      },
      itemHeight: 12,
      itemWidth: 12,
      lineStyle: {
        opacity: 0,
      },
      selectedMode: 'single',
      textStyle: {
        color: '#fff',
        fontFamily: 'D-Din, sans-serif',
        fontSize: 12,
        padding: 0,
      },
      left: 'center',
      top: 'top',
    },
    series: data.map((instance) => ({
      ...instance,
      lineStyle: {
        color: '#fff',
        width: 2,
      },
      showSymbol: false,
      symbolSize: 0,
      type: 'line',
    })),
    xAxis: {
      axisLabel: {
        padding: [5, 0, 0, 0],
      },
      axisTick: {
        show: false,
      },
      boundaryGap: false,
      data: dates,
      offset: 5,
      splitLine: {
        alignWithLabel: true,
        lineStyle: {
          color: '#979797',
          opacity: 0.5,
        },
        show: true,
      },
      type: 'category',
    },
    yAxis: {
      axisLabel: {
        padding: [0, 10, 0, 0],
      },
      interval: 20,
      max: 100,
      min: 0,
      splitLine: {
        lineStyle: {
          opacity: 0.5,
        },
      },
      type: 'value',
    },
  });

  return <div className={styles.chart} ref={chartRef} />;
};
