import {
  AssessmentType,
  BasketballAssessment,
  SoccerAssessment,
} from 'types/api';
import { formatDate } from './formatDate';

export const getMarkPoint = (
  x = 0,
  y = 0,
  assessments: BasketballAssessment[] | SoccerAssessment[] | null | undefined,
) => {
  // Adjust x, y coordinates of marker to prevent clipping
  const yCutoff = y > 91;
  // Flip on x-axis if value on far left side
  const xCutoff = x < 5;
  const symbolRotate = yCutoff ? 125 * (xCutoff ? -1 : 1) : 0;
  const offset = yCutoff ? [5 * (xCutoff ? 1 : -1), 9] : [0, 0];

  if (!assessments) {
    return null;
  }
  const [first] = assessments;

  let coordinates;
  switch (first?.assessmentType) {
    case AssessmentType.BASKETBALL:
      coordinates = assessments?.map(
        (assessment: BasketballAssessment, i: number) => {
          return {
            name: formatDate(assessment.date),
            coord: [
              assessment.assessmentDetails?.assessmentOverallPerformanceFactors
                ?.lateralPercentile,
              assessment.assessmentDetails?.assessmentOverallPerformanceFactors
                ?.verticalPercentile,
            ],
            value: i + 1,
          };
        },
      );
      break;
    case AssessmentType.SOCCER:
      coordinates = assessments?.map(
        (assessment: SoccerAssessment, i: number) => {
          return {
            name: formatDate(assessment.date),
            coord: [
              assessment.assessmentDetails
                ?.assessmentSoccerVerticalLateralAbility?.lateralAcceleration,
              assessment.assessmentDetails
                ?.assessmentSoccerVerticalLateralAbility?.verticalAcceleration,
            ],
            value: i + 1,
          };
        },
      );
  }

  return {
    data: coordinates,
    emphasis: { disabled: true },
    label: {
      offset,
    },
    symbolRotate,
  };
};
