import { useMemo } from 'react';
import {
  AssessmentType,
  BasketballAssessment,
  BasketballAssessmentDetails,
  ExerciseSide,
  SoccerAssessment,
  SoccerAssessmentDetails,
} from 'types/api';
import { formatDate } from 'util/formatDate';
import { labels } from 'util/graphConstants';
import { unitsOfMeasure } from 'util/unitsOfMeasure';
import { IDefinitions } from 'components/GraphTable';
import { RadarData } from 'components/Radar';
import {
  kickAnkleTableGraphTerms,
  stanceAnkleGraphTerms,
} from './SoccerTableGraphTerms';
import { ConfigData } from './useConfig';

const {
  activeDorsiFlexR,
  ankleDecelR,
  inversionR,
  translationR,
  activeAnkleDecelerationStance,
  activeDorsiflexionStance,
  inversionStance,
  eversionStance,
} = labels;

const basketballDefinitions: IDefinitions[] = [
  {
    key: 'assessmentDetails.assessmentBasketballMechanicsSummary.activeDorsiflexionRight',
    unit: unitsOfMeasure('flexion'),
    name: labels.activeDorsiFlexR,
  },
  {
    key: 'assessmentDetails.assessmentBasketballMechanicsSummary.ankleActiveDecelerationRight',
    unit: unitsOfMeasure('deceleration'),
    name: labels.ankleDecelR,
  },
  {
    key: 'assessmentDetails.assessmentBasketballMechanicsSummary.inversionRight',
    unit: unitsOfMeasure('inversion'),
    name: labels.inversionR,
  },
  {
    key: 'assessmentDetails.assessmentBasketballMechanicsSummary.translationRight',
    unit: unitsOfMeasure('translation'),
    name: labels.translationR,
  },
];

export const useRightFootData = (
  assessments: Partial<SoccerAssessment | BasketballAssessment>[],
): ConfigData => {
  return useMemo(() => {
    const chartData: Array<number>[] = [];
    const radarData: RadarData[] = [];
    let barLabels: Array<string> = [];
    let definitions: IDefinitions[] = [];
    let terms: string[] = [];
    const dates: string[] = assessments.map((x) =>
      formatDate(x.date).toString(),
    );

    const lineData: { name: string; type: string; data: number[] }[] = [
      {
        name: 'Active Dorsiflexion (R)',
        type: 'line',
        data: [],
      },
      {
        name: 'Ankle Deceleration (R)',
        type: 'line',
        data: [],
      },
      {
        name: 'Inversion (R)',
        type: 'line',
        data: [],
      },
      {
        name: 'Translation (R)',
        type: 'line',
        data: [],
      },
    ];
    const [first] = assessments;
    switch (first?.assessmentType) {
      case AssessmentType.SOCCER:
        terms = [
          'ankleActDecel',
          'activeDorsiflexion',
          'inversion',
          'eversion',
        ];
        assessments?.forEach((assessment) => {
          const details =
            assessment?.assessmentDetails as SoccerAssessmentDetails;

          const {
            /* kick ankle */
            percActiveAnkleDecelerationKick = 0,
            percActiveDorsiflexionKick = 0,
            percInversionKick = 0,
            percEversionKick = 0,
            /* stance ankle */
            percActiveAnkleDecelerationStance = 0,
            percActiveDorsiflexionStance = 0,
            percInversionStance = 0,
            percEversionStance = 0,
            stanceLeg,
          } = details?.assessmentSoccerMechanicsSummary || {};
          let values = [];
          if (stanceLeg === ExerciseSide.RIGHT) {
            /* left kick leg? use kick ankle */
            values = [
              percActiveAnkleDecelerationStance,
              percActiveDorsiflexionStance,
              percInversionStance,
              percEversionStance,
            ];
            chartData.push(values);
            barLabels = [
              activeAnkleDecelerationStance,
              activeDorsiflexionStance,
              inversionStance,
              eversionStance,
            ].map((label) => label.replace('#', 'R'));

            definitions = kickAnkleTableGraphTerms;
          } else {
            /* right kick leg, use stance ankle */
            values = [
              percActiveAnkleDecelerationKick,
              percActiveDorsiflexionKick,
              percInversionKick,
              percEversionKick,
            ];
            chartData.push(values);
            barLabels = [
              activeAnkleDecelerationStance,
              activeDorsiflexionStance,
              inversionStance,
              eversionStance,
            ].map((label) => label.replace('#', 'R'));
            definitions = stanceAnkleGraphTerms;
          }
          values.forEach((value, index) => lineData[index].data.push(value));
        });
        definitions = definitions.map((term) => ({
          ...term,
          name: term?.name?.replace('#', 'R'),
        }));
        break;
      case AssessmentType.BASKETBALL:
      default:
        terms = [
          'activeDorsiflexion',
          'ankleActDecel',
          'inversion',
          'translation',
        ];
        definitions = basketballDefinitions;
        barLabels = [activeDorsiFlexR, ankleDecelR, inversionR, translationR];
        assessments?.forEach((assessment) => {
          const details =
            assessment?.assessmentDetails as BasketballAssessmentDetails;

          const {
            percActiveDorsiflexionRight = 0,
            percAnkleActiveDecelerationRight = 0,
            percInversionRight = 0,
            percTranslationRight = 0,
          } = details?.assessmentBasketballMechanicsSummary || {};

          lineData[0].data.push(percActiveDorsiflexionRight);
          lineData[1].data.push(percAnkleActiveDecelerationRight);
          lineData[2].data.push(percInversionRight);
          lineData[3].data.push(percTranslationRight);

          chartData.push([
            percActiveDorsiflexionRight,
            percAnkleActiveDecelerationRight,
            percInversionRight,
            percTranslationRight,
          ]);
        });
    }
    return {
      chartData,
      radarData,
      barLabels,
      definitions,
      dates,
      lineData,
      terms,
    };
  }, [assessments]);
};
