import { useMutation } from 'react-query';
import { toast } from 'react-toastify';
import { UserResponseModel } from 'types/api';
import { UpdateUserRoleRequestModel } from 'types/api/models/UpdateUserRoleRequestModel';
import {
  ContentType,
  useAuthenticatedFetch,
} from 'hooks/util/useAuthenticatedFetch';
import { queryClient } from 'util/queryClientConfig';

export const useUpdateUserRole = (userId?: string) => {
  const fetch = useAuthenticatedFetch(ContentType.NO_ORGANIZATION_HEADER);
  return useMutation(
    (body: UpdateUserRoleRequestModel) =>
      fetch<UserResponseModel>(
        `${process.env.REACT_APP_API_HOST}/users/${userId}/role`,
        {
          body,
          method: 'PUT',
        },
      ),
    {
      onError: () => {
        toast.error('Error updating profile.');
      },
      onSuccess: (updatedUser) => {
        toast.success('Profile updated successfully.');
        queryClient.setQueryData(['profile', updatedUser.id], updatedUser);
      },
    },
  );
};
