import { useCallback, useEffect, useRef, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { Header } from 'views/Profile/Header';
import { Role, User } from 'types/api';
import { UpdateUserRoleRequestModel } from 'types/api/models/UpdateUserRoleRequestModel';
import { useDeleteUserRole } from 'hooks/mutation/useDeleteUserRole';
import { useUpdateUserRole } from 'hooks/mutation/useUpdateUserRole';
import { useUser } from 'hooks/query/useUser';
import { useOrganizationContext } from 'hooks/util/useOrganizationContext';
import { isAdmin } from 'util/isAdmin';
import { Button, ButtonVariants } from 'components/Button';
import { HandleClose, Modal } from 'components/Modal';
import styles from './EditAccount.module.css';
import { EditRoleForm } from './EditRoleForm';

interface ILocationState {
  account: User;
}

export const EditAccount = () => {
  const location = useLocation();
  const state = location.state as ILocationState;
  const { account } = state;
  const { data: user, isLoading } = useUser(account?.id);
  const { mutateAsync } = useUpdateUserRole(account?.id);
  const modalClose = useRef<HandleClose>(null);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const { contextRole, contextOrg } = useOrganizationContext();
  const navigate = useNavigate();

  const { mutateAsync: mutateDeleteUser, isSuccess } = useDeleteUserRole(
    account.id,
  );

  const updateForm = useCallback(
    (form: UpdateUserRoleRequestModel) => {
      mutateAsync(form);
    },
    [mutateAsync],
  );

  const handleCloseModal = () => {
    modalClose.current?.onClose();
  };

  const handleDeleteUser = () => {
    const findRole: Role | undefined = account?.organizationRoles?.find(
      (x) => x.organizationId === contextOrg?.id,
    )?.role;
    if (findRole) {
      const deleteUserRequestBody: UpdateUserRoleRequestModel = {
        organizationId: contextOrg?.id,
        role: findRole,
      };
      mutateDeleteUser(deleteUserRequestBody);
    }
  };

  useEffect(() => {
    if (isSuccess) {
      handleCloseModal();
      navigate('/organization');
    }
  });

  return (
    <>
      <Header profile={account} />
      <div className={styles['profile-form-container']}>
        <article className={styles.article}>
          {!isLoading && <EditRoleForm user={user} onSubmit={updateForm} />}
        </article>
        {isAdmin(contextRole) && (
          <div className={styles['remove-account-button-container']}>
            <Button
              className={styles['remove-account-button']}
              variant={ButtonVariants.Secondary}
              onClick={() => {
                setIsModalOpen(true);
              }}
            >
              Remove Account
            </Button>
            <Modal
              isOpen={isModalOpen}
              onOpenChange={setIsModalOpen}
              title="Remove Account"
              ref={modalClose}
            >
              <p>
                <strong>Are you sure you want to remove this account?</strong>
              </p>
              <p>Upon removal no data will be stored. </p>
              <div className={styles['confirmation-button-container']}>
                <Button
                  className={styles.cancel}
                  variant={ButtonVariants.Secondary}
                  onClick={() => handleCloseModal()}
                >
                  Cancel
                </Button>
                <Button
                  variant={ButtonVariants.Primary}
                  onClick={() => handleDeleteUser()}
                >
                  Remove
                </Button>
              </div>
            </Modal>
          </div>
        )}
      </div>
    </>
  );
};
