import { ReactComponent as HelpIcon } from 'assets/icons/help.svg';
import { ReactComponent as LogoutIcon } from 'assets/icons/logout.svg';
import { ReactComponent as OrganizationIcon } from 'assets/icons/organization.svg';
import { ReactComponent as ProfileIcon } from 'assets/icons/profile.svg';
import { useCallback, useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import {
  Organization,
  OrganizationResponseModel,
  Role,
  UserOrganizationRole,
  UserResponseModel,
} from 'types/api';
import { useOrganization } from 'hooks/query/useOrganization';
import { useOrganizationByIds } from 'hooks/query/useOrganizationByIds';
import { useOrganizationContext } from 'hooks/util/useOrganizationContext';
import { isAdmin } from 'util/isAdmin';
import { roleDisplayName } from 'util/roleDisplayName';
import { ProfilePicture } from 'components/ProfilePicture';
import styles from './Settings.module.css';

interface SettingsProps {
  logout?: () => void;
  profile: UserResponseModel;
  handleCreateOrgModal: () => void;
  closeModal: () => void;
}

export const Settings = ({
  logout,
  profile,
  closeModal,
  handleCreateOrgModal,
}: SettingsProps) => {
  const { firstName, lastName, organizationRoles, email, blobImageUri } =
    profile;
  const [isUserAdmin, setIsUserAdmin] = useState<boolean>(false);
  const [usersOrganizations, setUsersOrganizations] = useState<string[]>([]);
  const { contextOrg, contextRole, setContext } = useOrganizationContext();
  const { data } = useOrganization(false, true);
  const getUsersOrganizations = useOrganizationByIds(usersOrganizations);

  const setSelectedOrganization = useCallback(
    (organizationId: string | undefined, role: Role | undefined) => {
      const selectedOrg: OrganizationResponseModel | undefined =
        data?.items?.find((org) => org.id === organizationId);
      setContext(selectedOrg, role);
    },
    [data, setContext],
  );

  // To display the organization's blobimage in the settings popup, we need to retrieve the full Organization rather than just the OrganizationRole
  // If user's start being a part of 8+ different Organizations, we'll want to re-think the retrieval of full Organizations here to make it more performant.
  useEffect(() => {
    const ids: string[] = [];
    profile?.organizationRoles?.forEach((role: UserOrganizationRole) => {
      if (role.organizationId) {
        ids.push(role.organizationId);
      }
    });
    setUsersOrganizations(ids);
  }, [profile?.organizationRoles]);
  const usersFullOrganizations: Organization[] = [];
  const isSuccess = getUsersOrganizations.some((result) => result.isSuccess);
  if (isSuccess) {
    getUsersOrganizations?.forEach((org) => {
      if (org.data) {
        usersFullOrganizations.push(org.data);
      }
    });
  }

  useEffect(() => {
    setIsUserAdmin(isAdmin(contextRole));
  }, [contextRole]);

  return (
    <div className={styles.settings}>
      <div className={styles.user}>
        <div>
          <ProfilePicture
            src={blobImageUri as string}
            size={58}
            firstName={firstName as string}
            lastName={lastName as string}
          />
        </div>
        <div className={styles.header}>
          <div className={styles.username}>
            {firstName} {lastName}
          </div>
          <div className={styles['header-org-name']}>
            {contextOrg?.organizationName}
          </div>
          <div>
            <a href={`mailto:${email}`}>{email}</a>
          </div>
        </div>
      </div>
      <div className={styles['icons-container']}>
        <nav>
          <ul>
            {isUserAdmin && (
              <li className={styles.org}>
                <button onClick={handleCreateOrgModal}>
                  <OrganizationIcon />
                  Organizations
                </button>
              </li>
            )}
            <li>
              <Link to="/profile" onClick={closeModal}>
                <ProfileIcon />
                Profile
              </Link>
            </li>
            <li>
              <Link to="/faq" onClick={closeModal}>
                <span>
                  <HelpIcon />
                </span>
                <span>Help</span>
              </Link>
            </li>
            <li>
              <button onClick={logout}>
                <span>
                  <LogoutIcon />
                </span>
                <span>Sign Out</span>
              </button>
            </li>
          </ul>
        </nav>
      </div>
      {organizationRoles?.length ? (
        <div>
          <div className={styles['accounts-header']}>Switch Accounts</div>
          <ul>
            {organizationRoles?.map(
              ({ organizationId, organizationName, role }) => {
                const selected = contextOrg?.id === organizationId;
                return (
                  <li key={organizationId}>
                    <a
                      className={styles.organization}
                      onClick={() => {
                        setSelectedOrganization(organizationId, role);
                      }}
                    >
                      <div>
                        <ProfilePicture
                          size={58}
                          src={
                            usersFullOrganizations.find(
                              (x) => x.id === organizationId,
                            )?.blobImageUri || ''
                          }
                        />
                      </div>
                      <div className={styles['role-container']}>
                        <div className={styles['organization-name']}>
                          {organizationName}
                        </div>
                        <div className={styles.role}>
                          {roleDisplayName(role)}
                        </div>
                      </div>
                      <div />
                      <div className={styles['selected-icon']}>
                        <span className={styles[`selected-${selected}`]} />
                      </div>
                    </a>
                  </li>
                );
              },
            )}
          </ul>
        </div>
      ) : null}
    </div>
  );
};
