import { Error } from 'models/Error';
import { useQuery } from 'react-query';
import { useMatch } from 'react-router-dom';
import { toast } from 'react-toastify';
import { AssessmentPaginatedList, AssessmentType } from 'types/api';
import {
  ContentType,
  useAuthenticatedFetch,
} from 'hooks/util/useAuthenticatedFetch';

export interface IUseAssessmentListSharedLink {
  linkId?: string;
  assessmentId?: string;
  password?: string;
  page: number;
  sortBy: string;
  sortDirection: string;
  assessmentType?: AssessmentType;
  athleteName?: string | null | undefined;
  contentType?: ContentType;
}

export const useAssessmentListSharedLink = ({
  password,
  page = 1,
  sortBy,
  sortDirection,
  assessmentType,
  athleteName,
  contentType = ContentType.NONE,
}: IUseAssessmentListSharedLink) => {
  const sortAndPageIndex = `pageIndex=${page}&sortOption=${sortBy}&sortDirection=${sortDirection}`;
  const athlete = `AthleteName=${athleteName}`;
  const type = `AssessmentType=${assessmentType}`;
  const isShared = useMatch('/share/:linkId/:assessmentId');

  const url = new URL(window.location.href);
  const urlPathname = url.pathname;
  const splitPath = urlPathname.split('/');
  const linkId = splitPath[2];
  const assessmentId = splitPath[3];

  const fetch = useAuthenticatedFetch(contentType);

  const basePath = `/AssessmentLink/${linkId}/Assessment/${assessmentId}/paginated`;
  const path = `${basePath}?${athlete}&${sortAndPageIndex}&${type}`;

  return useQuery<AssessmentPaginatedList, Error>(
    [
      'assessmentsList',
      {
        athleteName,
        page,
        sortBy,
        sortDirection,
        assessmentType,
      },
    ],
    () =>
      fetch(`${process.env.REACT_APP_API_HOST}${path}`, {
        headers: { 'x-assessment-link-password': password as string },
      }),
    {
      enabled: !!isShared,
      keepPreviousData: true,
      onError: () => {
        toast.error('Error fetching assessments.');
      },
    },
  );
};
