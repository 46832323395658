import * as echarts from 'echarts';
import { GridComponent, TooltipComponent } from 'echarts/components';
import 'echarts/lib/component/grid';
import {
  AssessmentType,
  BaseAssessment,
  BasketballAssessment,
  PositionlessVerticalLateralAbility,
  SoccerAssessment,
} from 'types/api';
import styles from './../../Charts.module.css';
import { useBasketballChart } from './useBasketballChart';
import { useSoccerChart } from './useSoccerChart';

/* eslint-disable-next-line */
// @ts-ignore
echarts.use([TooltipComponent, GridComponent]);

type Props = {
  data?: Record<string, Array<PositionlessVerticalLateralAbility>> | null;
  assessment: BaseAssessment | undefined;
  assessments?: BasketballAssessment[] | SoccerAssessment[];
  priorAssessments:
    | BasketballAssessment[]
    | SoccerAssessment[]
    | null
    | undefined;
};

const Basketball = (props: Props) => {
  const ref = useBasketballChart(props);
  return <div ref={ref} className={styles['scatter-plot-chart']} />;
};

const Soccer = (props: Props) => {
  const ref = useSoccerChart(props);
  return <div ref={ref} className={styles['scatter-plot-chart']} />;
};

export const VerticalLateralAbilitiesChart = (props: Props) => {
  return (
    <div className={styles['chart-container']}>
      <h2 className={styles['chart-title']}>Vertical Lateral Ability</h2>
      <p className={styles['chart-subtext']}>
        Axes represent average for all P3 tested athletes. Fill color indicates
        position. Use the key below to filter data.
      </p>
      {(() => {
        switch (props.assessment?.assessmentType) {
          case AssessmentType.BASKETBALL:
            return <Basketball {...props} />;
          case AssessmentType.SOCCER:
            return <Soccer {...props} />;
          default:
            return <></>;
        }
      })()}
    </div>
  );
};
