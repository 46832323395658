import { Error } from 'models/Error';
import { useQuery } from 'react-query';
import { toast } from 'react-toastify';
import { UserResponseModel } from 'types/api';
import {
  ContentType,
  useAuthenticatedFetch,
} from 'hooks/util/useAuthenticatedFetch';

export const useUser = (id?: string) => {
  const fetch = useAuthenticatedFetch(ContentType.NO_ORGANIZATION_HEADER);

  return useQuery<UserResponseModel, Error>(
    ['profile', id],
    () => fetch(`${process.env.REACT_APP_API_HOST}/users/${id}`),
    {
      enabled: !!id,
      onError: () => {
        toast.error('Error fetching profile.');
      },
    },
  );
};
