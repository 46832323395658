import { ReactComponent as InfoIcon } from 'assets/icons/info.svg';
import { ReactComponent as RadarIcon } from 'assets/icons/radar.svg';
import { ReactComponent as TableIcon } from 'assets/icons/table.svg';
import { ReactComponent as VideoIcon } from 'assets/icons/video.svg';
import { useMemo } from 'react';
import { AssessmentType, BasketballAssessment } from 'types/api';
import { radarGraphLegendText } from 'util/radarGraphLegendText';
import { GraphTable } from 'components/GraphTable';
import { GraphTabs } from 'components/GraphTabs';
import { Radar, RadarData } from 'components/Radar';
import { Video } from 'components/Video';
import {
  infoTermsLateralSkater,
  lateralSkaterTableGraphTerms,
  radarLabels,
} from './LateralSkater';

interface LateralSkaterCompareProps {
  assessments: BasketballAssessment[];
  className?: string;
}

export const LateralSkaterCompare = (props: LateralSkaterCompareProps) => {
  const { assessments, className } = props;

  const radarData = useMemo(() => {
    const result: RadarData[] = [];
    assessments.forEach((a: BasketballAssessment) => {
      if (
        !a.assessmentDetails ||
        !a.assessmentDetails.assessmentSkaterSummary
      ) {
        return;
      }
      const {
        percLeftLateralForce = 0,
        percRightMaxHipAbduction = 0,
        percRightMaxHipExtensionVelocity = 0,
        percRightLateralForce = 0,
        percLeftMaxHipExtensionVelocity = 0,
        percLeftMaxHipAbduction = 0,
      } = a.assessmentDetails.assessmentSkaterSummary;

      const radarObject: RadarData = {
        name: radarGraphLegendText(a.name, a.date),
        value: [
          percLeftLateralForce,
          percLeftMaxHipAbduction,
          percLeftMaxHipExtensionVelocity,
          percRightLateralForce,
          percRightMaxHipExtensionVelocity,
          percRightMaxHipAbduction,
        ],
      };
      result.push(radarObject);
    });
    if (
      assessments.length &&
      assessments[0].assessmentType === AssessmentType.SOCCER
    ) {
      result.push({
        name: `${assessments[0]?.position} Average`,
        value: Array(result[0].value.length).fill(50),
      });
    }
    return result;
  }, [assessments]);

  const config = [
    {
      id: 'radarTab',
      name: 'Percentile',
      Icon: RadarIcon,
      content: <Radar data={radarData} indicator={radarLabels} />,
    },
    {
      id: 'tableTab',
      name: 'Test Results',
      Icon: TableIcon,
      content: (
        <GraphTable
          assessments={assessments}
          definitions={lateralSkaterTableGraphTerms}
        />
      ),
    },
    {
      id: 'infoTab',
      name: 'Terminology',
      Icon: InfoIcon,
      content: infoTermsLateralSkater,
    },
  ];

  const videos = assessments[0]?.videos?.filter((video) =>
    video.exercise?.includes('Skater'),
  );
  if (videos?.length) {
    config.push({
      id: 'videoTab',
      name: 'Video',
      Icon: VideoIcon,
      content: <Video videos={videos} />,
    });
  }

  return (
    <GraphTabs
      className={className}
      config={config}
      defaultTab="radarTab"
      heading="Lateral Skater"
    />
  );
};
