import styles from './NotFound.module.css';

const NotFound = () => (
  <div className={styles.container}>
    <div className={styles.title}>
      <h1>Page Not Found</h1>
    </div>
    <div className={styles.image} />
  </div>
);

export default NotFound;
