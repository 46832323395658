import classNames from 'classnames';
import { RadarChart } from 'echarts/charts';
import { LegendComponent, TitleComponent } from 'echarts/components';
import * as echarts from 'echarts/core';
import { CanvasRenderer } from 'echarts/renderers';
import { useChart } from 'hooks/util/useChart';
import styles from './Radar.module.css';

export interface RadarData {
  name?: string;
  value: number[];
  itemStyle?: { color?: string };
}

interface RadarIndicator {
  max: number;
  name: string;
}

interface RadarProps {
  data?: RadarData[];
  indicator?: RadarIndicator[];
  className?: string;
  fontSize?: number;
  center?: [string, string];
  radius?: string;
}

echarts.use([TitleComponent, LegendComponent, RadarChart, CanvasRenderer]);

export const Radar = (props: RadarProps) => {
  const {
    className,
    data,
    indicator,
    center = ['50%', '40%'],
    fontSize,
    radius = '55%',
  } = props;
  const chartRef = useChart({
    legend: {
      bottom: 0,
      left: 'center',
      icon: 'circle',
      itemHeight: 10,
      itemWidth: 10,
      textStyle: { color: '#ffffff', padding: [0, 5] },
    },
    radar: {
      indicator,
      radius,
      center,
      splitArea: {
        areaStyle: {
          color: [],
        },
      },
      splitLine: {
        lineStyle: {
          width: 0.5,
          opacity: 0.5,
        },
      },
      axisLine: {
        lineStyle: {
          width: 0.5,
          opacity: 0.5,
        },
      },
      axisName: {
        textStyle: {
          fontSize,
        },
      },
    },
    series: [
      {
        data,
        type: 'radar',
        symbolSize: 6,
      },
    ],
  });

  return <div className={classNames(styles.chart, className)} ref={chartRef} />;
};
