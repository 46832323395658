import React, { useCallback, useMemo, useRef, useState } from 'react';
import {
  AssessmentSortOption,
  AssignAssessmentRequestModel,
  BaseAssessment,
  SortDirection,
} from 'types/api';
import { useDeleteAssessmentOwner } from 'hooks/mutation/useDeleteAssessmentOwner';
import { useUpdateAssessmentOwner } from 'hooks/mutation/useUpdateAssessmentOwner';
import { useAssessmentList } from 'hooks/query/useAssessmentList';
import { useQueryParam } from 'hooks/util/useQueryParam';
import { radarGraphLegendText } from 'util/radarGraphLegendText';
import { AsssessmentToOrgModal } from 'components/AssessmentToOrgModal';
import modalStyles from 'components/AssessmentToOrgModal/AssessmentToOrgModal.module.css';
import { Button } from 'components/Button';
import { Loading } from 'components/Loading';
import { HandleClose, Modal } from 'components/Modal';
import { Pagination } from 'components/Pagination';
import { DefaultMinLength, SearchBar } from 'components/SearchBar';
import { SelectedPill } from 'components/SelectedPill';
import listStyles from '../List/List.module.css';
import { Empty } from '../List/Table/Empty';
import styles from './MapOrgs.module.css';
import { MapOrgsTable } from './MapOrgsTable';
import { parseMapToOrgs } from './ParseMapToOrgsTableData';
import { TrainingTargetsWarningModal } from './TrainingTargetsWarningModal';
import targetsModalStyles from './TrainingTargetsWarningModal.module.css';

const MapOrganizations = () => {
  const [selectedAssessments, setSelectedAssessments] = useState<
    BaseAssessment[]
  >([]);
  const [sortBy, setSortBy] = useQueryParam<AssessmentSortOption>(
    'sortOption',
    AssessmentSortOption.LAST,
  );
  const [sortDirection, setSortDirection] = useQueryParam<SortDirection>(
    'sortDirection',
    SortDirection.DESCENDING,
  );
  const [page, setPage] = useQueryParam('page', 1);
  const [searchTerm, setSearchTerm] = useQueryParam('searchTerm');
  const searchInputRef = useRef<HTMLInputElement>(null);

  const [isAttachModalOpen, setIsAttachModalOpen] = useState(false);
  const [isTrainingTargetsModalOpen, setIsTrainingTargetsModalOpen] =
    useState(false);
  const modalClose = useRef<HandleClose>(null);
  const trainingTargetsModalClose = useRef<HandleClose>(null);
  const {
    data: assessmentGroups,
    isPreviousData,
    isFetching,
    refetch,
  } = useAssessmentList({
    page,
    sortBy,
    sortDirection,
    searchTerm,
  });

  const parsed = useMemo(() => {
    if (assessmentGroups && assessmentGroups.items) {
      return parseMapToOrgs(assessmentGroups.items);
    }

    return [];
  }, [assessmentGroups]);

  const handleNextPage = useCallback(() => {
    setPage(page + 1);
  }, [page, setPage]);

  const handlePreviousPage = useCallback(() => {
    setPage(page - 1);
  }, [page, setPage]);

  const onSearchSubmit = useCallback(() => {
    const term = searchInputRef.current?.value;
    const isTermValid = term && term.length >= DefaultMinLength;
    if (isTermValid) {
      setSearchTerm(term);
      setPage(1);
    } else {
      setSearchTerm(undefined);
    }
  }, [setPage, setSearchTerm]);

  const onSortBy = (id: string, sortDirection: string) => {
    setSortBy(id as AssessmentSortOption);
    setSortDirection(sortDirection as SortDirection);
  };

  const onClear = useCallback(() => {
    setSearchTerm(undefined);
    setPage(1);
  }, [setPage, setSearchTerm]);

  const onToggleSelectRow = useCallback((assessment) => {
    setSelectedAssessments((prevSelected) => {
      const existing = prevSelected.find((prev) => prev.id === assessment.id);
      if (existing) {
        return prevSelected.filter((prev) => prev.id !== existing.id);
      } else {
        return [...prevSelected, assessment];
      }
    });
  }, []);

  const { mutateAsync: mutateDeleteOwner } = useDeleteAssessmentOwner();
  const { mutateAsync: mutateUpdateOwner } = useUpdateAssessmentOwner();

  const onDetachOrg = useCallback(
    ({ organizationId, assessmentId }) => {
      mutateDeleteOwner({
        assessmentIds: [assessmentId],
        organizationIds: [organizationId],
      }).then(() => {
        refetch();
      });
    },
    [mutateDeleteOwner, refetch],
  );

  const onAttachOrgs = useCallback(
    (body: AssignAssessmentRequestModel) => {
      mutateUpdateOwner(body).then(() => {
        refetch();
      });
      modalClose.current?.onClose();
      setIsAttachModalOpen(false);
    },
    [mutateUpdateOwner, refetch],
  );

  const onAttachFromTrainingTargets = useCallback(() => {
    trainingTargetsModalClose.current?.onClose();
    setIsTrainingTargetsModalOpen(false);
    setIsAttachModalOpen(true);
  }, [trainingTargetsModalClose]);

  if ((!parsed || !parsed.length) && isFetching) return <Loading />;

  return (
    <section
      className={`${listStyles.main} ${styles.main}`}
      data-test-id="organization-summary"
    >
      <div className={styles['table-toolbar']}>
        <h2>All Assessments ({assessmentGroups?.totalCount})</h2>
        <div className={listStyles['search-bar']}>
          <SearchBar
            onSearch={onSearchSubmit}
            onToggleOpen={() => {
              searchInputRef.current?.focus();
            }}
            inputRef={searchInputRef}
            clear={onClear}
            inputProps={{ minLength: DefaultMinLength, placeholder: 'Search' }}
          />
        </div>
        {selectedAssessments && (
          <Button
            className={styles['attach-btn']}
            disabled={selectedAssessments.length < 1}
            onClick={() => {
              if (
                selectedAssessments.length === 1 &&
                selectedAssessments[0].trainingTargets?.length === 0
              ) {
                setIsTrainingTargetsModalOpen(true);
              } else {
                setIsAttachModalOpen(true);
              }
            }}
          >
            Attach
          </Button>
        )}
        {selectedAssessments.length > 0 && (
          <div className={listStyles['selected-rows']}>
            <span className={listStyles['selected-rows-label']}>Selected:</span>
            <ul>
              {selectedAssessments.map(({ id, name }) => {
                return (
                  <SelectedPill
                    label={radarGraphLegendText(name)}
                    id={id as string}
                    key={`selected-${id}`}
                    onClose={(id) =>
                      onToggleSelectRow(
                        selectedAssessments.find(
                          (selected) => selected?.id === id,
                        ),
                      )
                    }
                  />
                );
              })}
            </ul>
          </div>
        )}
      </div>
      {parsed && parsed.length > 0 ? (
        <div className={`${styles['table-container']}`}>
          <MapOrgsTable
            onToggleSelect={onToggleSelectRow}
            data={parsed}
            onSortBy={onSortBy}
            sortBy={sortBy as AssessmentSortOption}
            isDesc={sortDirection === SortDirection.DESCENDING}
            selectedAssessments={selectedAssessments}
            onDetachOrg={onDetachOrg}
            isLoading={isFetching}
          />
          <div className={styles.pagination}>
            <Pagination
              hasNextPage={!isPreviousData && assessmentGroups?.hasNextPage}
              hasPreviousPage={
                !isPreviousData && assessmentGroups?.hasPreviousPage
              }
              onNext={handleNextPage}
              onPrevious={handlePreviousPage}
              page={page}
              totalCount={assessmentGroups?.totalCount}
            />
          </div>
        </div>
      ) : (
        <Empty />
      )}
      <Modal
        isOpen={isAttachModalOpen}
        onOpenChange={setIsAttachModalOpen}
        classes={[modalStyles.main]}
        ref={modalClose}
      >
        <AsssessmentToOrgModal
          assessments={selectedAssessments}
          onRemoveAssessment={onToggleSelectRow}
          onAttachOrgs={(selectedOrgs) => {
            const body: AssignAssessmentRequestModel = {
              assessmentIds: selectedAssessments.map<string>(
                (assessment) => assessment.id as string,
              ),
              organizationIds: selectedOrgs.map<string>(
                (organization) => organization.id as string,
              ),
            };

            onAttachOrgs(body);
          }}
          setIsAttachModalOpen={setIsAttachModalOpen}
        />
      </Modal>
      <Modal
        isOpen={isTrainingTargetsModalOpen}
        onOpenChange={setIsTrainingTargetsModalOpen}
        classes={[targetsModalStyles.main]}
        ref={trainingTargetsModalClose}
      >
        <TrainingTargetsWarningModal
          assessment={selectedAssessments[0]}
          onAttachClick={onAttachFromTrainingTargets}
        />
      </Modal>
    </section>
  );
};

export { MapOrganizations };
