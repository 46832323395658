export const TraditionalBigContent = (props: { name: string }) => {
  const showName = props.name ? (
    <h2>{props.name} is a Traditional Big</h2>
  ) : (
    <h2>Traditional Bigs</h2>
  );
  return (
    <div>
      {showName}
      <p>
        Size tends to be the dominant physical characteristic of athletes in
        this cluster. Both Height and Length considerably outpace the typical
        NBA athlete. Improved power output - either vertically or laterally -
        can push an athlete into the Bigs Plus category.
      </p>
      <ul>
        <li>Size is the dominant characteristic in this group.</li>
        <li>Lack explosiveness in all directions.</li>
        <li>
          Relative to NBA, athletes in this cluster have a short career lifespan
          without an elite skill.
        </li>
      </ul>
      <h2>Traditional Bigs</h2>
      <p>
        Though a smaller number of athletes in this cluster tend to stick in the
        NBA, Traditional Bigs possess more All-Star appearances than the Bigs
        Plus cluster.
      </p>
    </div>
  );
};
