/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

export enum AssessmentVideoExercise {
    UNKNOWN = 'Unknown',
    SKATER_LEFT = 'SkaterLeft',
    SKATER_RIGHT = 'SkaterRight',
    DROP_JUMP = 'DropJump',
    STANDING_VERTICAL = 'StandingVertical',
}