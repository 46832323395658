export const getRiskLevel = (value: number): 'red' | 'yellow' | 'green' => {
  if (value <= 30) {
    return 'red';
  } else if (value > 30 && value < 61) {
    return 'yellow';
  } else {
    return 'green';
  }
};

export const isValidAssessmentId = (id?: string) => {
  if (!id) return false;

  const regexExp =
    /^[0-9a-fA-F]{8}\b-[0-9a-fA-F]{4}\b-[0-9a-fA-F]{4}\b-[0-9a-fA-F]{4}\b-[0-9a-fA-F]{12}$/gi;

  return regexExp.test(id);
};
