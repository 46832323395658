import type {
  ApiContract,
  Response,
} from 'lib/api-mocking/interfaces/ApiContract';
import { defaultErrorResponse } from 'mockApi/responses/defaultErrorResponse';
import type { Error } from 'models/Error';
import { BaseAssessment } from 'types/api';
import { AssessmentGenerator } from '../generators/AssessmentGenerator';

const contract: ApiContract = {
  url: `/Assessments/:id`,
  method: 'GET',
  success: (): Response<BaseAssessment> => {
    return {
      status: 200,
      body: AssessmentGenerator.generate(),
    };
  },
  error: (): Response<Error> => ({
    status: 500,
    body: defaultErrorResponse,
  }),
  scope: {
    test: true,
    runtime: false,
  },
};

export default contract;
