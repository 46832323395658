import { useEffect, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { UpdateUserRequestModel, UserResponseModel } from 'types/api';
import { Button, ButtonVariants } from 'components/Button';
import { TextField } from 'components/TextField';
import styles from './Form.module.css';

interface IFormProps {
  onSubmit: (form: UpdateUserRequestModel) => void;
  user: UserResponseModel;
}

type NonNullableUpdateUserRequestModel = {
  [K in keyof UpdateUserRequestModel]: NonNullable<UpdateUserRequestModel[K]>;
};

export const Form = ({ user, onSubmit }: IFormProps) => {
  const [formChangesDetected, setFormChangesDetected] =
    useState<boolean>(false);
  const {
    control,
    handleSubmit,
    watch,
    formState: { isSubmitting },
  } = useForm<NonNullableUpdateUserRequestModel>({
    defaultValues: {
      firstName: user.firstName || '',
      lastName: user.lastName || '',
      address: user.address || '',
      city: user.city || '',
      phone: user.phone || '',
      state: user.state || '',
      zipCode: user.zipCode || '',
    },
  });

  useEffect(() => {
    const subscription = watch((value) => {
      setFormChangesDetected(!!value);
    });
    return () => {
      subscription.unsubscribe();
    };
  }, [watch]);

  const onFormSubmit = (formData: UpdateUserRequestModel) => {
    setFormChangesDetected(false);
    onSubmit(formData);
  };

  return (
    <>
      <section className={styles['form-section']}>
        <form
          className={styles.form}
          noValidate
          onSubmit={handleSubmit(onFormSubmit)}
        >
          <fieldset className={styles.fieldset}>
            <legend>Personal Information</legend>
            <Controller
              control={control}
              name="firstName"
              render={({ field, fieldState }) => (
                <TextField
                  {...field}
                  error={fieldState.error?.message}
                  label="First Name"
                  required
                />
              )}
              rules={{ required: 'Required' }}
            />
            <Controller
              control={control}
              name="lastName"
              render={({ field, fieldState }) => (
                <TextField
                  {...field}
                  error={fieldState.error?.message}
                  label="Last Name"
                  required
                />
              )}
              rules={{ required: 'Required' }}
            />
            <Controller
              control={control}
              name="phone"
              render={({ field, fieldState }) => (
                <TextField
                  {...field}
                  error={fieldState.error?.message}
                  inputMode="tel"
                  label="Phone Number"
                  type="tel"
                />
              )}
            />
          </fieldset>
          <fieldset className={styles.fieldset}>
            <legend>Location</legend>
            <Controller
              control={control}
              name="address"
              render={({ field, fieldState }) => (
                <TextField
                  {...field}
                  className={styles.address}
                  error={fieldState.error?.message}
                  label="Address"
                />
              )}
            />
            <Controller
              control={control}
              name="city"
              render={({ field, fieldState }) => (
                <TextField
                  {...field}
                  error={fieldState.error?.message}
                  label="City"
                />
              )}
            />
            <Controller
              control={control}
              name="state"
              render={({ field, fieldState }) => (
                <TextField
                  {...field}
                  error={fieldState.error?.message}
                  label="State"
                  tagName="select"
                >
                  <option value="" />
                  <option value="AL">Alabama</option>
                  <option value="AK">Alaska</option>
                  <option value="AZ">Arizona</option>
                  <option value="AR">Arkansas</option>
                  <option value="CA">California</option>
                  <option value="CO">Colorado</option>
                  <option value="CT">Connecticut</option>
                  <option value="DE">Delaware</option>
                  <option value="DC">District Of Columbia</option>
                  <option value="FL">Florida</option>
                  <option value="GA">Georgia</option>
                  <option value="HI">Hawaii</option>
                  <option value="ID">Idaho</option>
                  <option value="IL">Illinois</option>
                  <option value="IN">Indiana</option>
                  <option value="IA">Iowa</option>
                  <option value="KS">Kansas</option>
                  <option value="KY">Kentucky</option>
                  <option value="LA">Louisiana</option>
                  <option value="ME">Maine</option>
                  <option value="MD">Maryland</option>
                  <option value="MA">Massachusetts</option>
                  <option value="MI">Michigan</option>
                  <option value="MN">Minnesota</option>
                  <option value="MS">Mississippi</option>
                  <option value="MO">Missouri</option>
                  <option value="MT">Montana</option>
                  <option value="NE">Nebraska</option>
                  <option value="NV">Nevada</option>
                  <option value="NH">New Hampshire</option>
                  <option value="NJ">New Jersey</option>
                  <option value="NM">New Mexico</option>
                  <option value="NY">New York</option>
                  <option value="NC">North Carolina</option>
                  <option value="ND">North Dakota</option>
                  <option value="OH">Ohio</option>
                  <option value="OK">Oklahoma</option>
                  <option value="OR">Oregon</option>
                  <option value="PA">Pennsylvania</option>
                  <option value="RI">Rhode Island</option>
                  <option value="SC">South Carolina</option>
                  <option value="SD">South Dakota</option>
                  <option value="TN">Tennessee</option>
                  <option value="TX">Texas</option>
                  <option value="UT">Utah</option>
                  <option value="VT">Vermont</option>
                  <option value="VA">Virginia</option>
                  <option value="WA">Washington</option>
                  <option value="WV">West Virginia</option>
                  <option value="WI">Wisconsin</option>
                  <option value="WY">Wyoming</option>
                </TextField>
              )}
            />
            <Controller
              control={control}
              name="zipCode"
              render={({ field, fieldState }) => (
                <TextField
                  {...field}
                  disabled={isSubmitting}
                  error={fieldState.error?.message}
                  inputMode="decimal"
                  label="Zip Code"
                />
              )}
            />
          </fieldset>
          <Button
            disabled={isSubmitting || !formChangesDetected}
            variant={ButtonVariants.Primary}
            type="submit"
          >
            Save
          </Button>
        </form>
      </section>
    </>
  );
};
