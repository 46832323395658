import { Error } from 'models/Error';
import { useQuery } from 'react-query';
import { useMatch } from 'react-router-dom';
import { toast } from 'react-toastify';
import { BaseAssessment } from 'types/api';
import {
  ContentType,
  useAuthenticatedFetch,
} from 'hooks/util/useAuthenticatedFetch';

export const useAssessmentsByName = (name?: string | null) => {
  const fetch = useAuthenticatedFetch(ContentType.NO_ORGANIZATION_HEADER);

  const isShared = useMatch('/share/:linkId/:assessmentId');

  let enabled;

  if (name) {
    enabled = true;
  }

  if (isShared) {
    enabled = false;
  }

  return useQuery<BaseAssessment[], Error>(
    ['assessments', name],
    () =>
      fetch(
        `${process.env.REACT_APP_API_HOST}/Assessments/ByName?fullName=${name}`,
      ),
    {
      enabled,
      onError: () => {
        toast.error('Error fetching assessments by name.');
      },
    },
  );
};
