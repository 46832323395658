import { ReactComponent as InfoIcon } from 'assets/icons/info.svg';
import { ReactComponent as RadarIcon } from 'assets/icons/radar.svg';
import { ReactComponent as TableIcon } from 'assets/icons/table.svg';
import { ReactComponent as VideoIcon } from 'assets/icons/video.svg';
import { useMemo } from 'react';
import { AssessmentType, BaseAssessment } from 'types/api';
import { radarGraphLegendText } from 'util/radarGraphLegendText';
import { GraphTable } from 'components/GraphTable';
import { GraphTabs } from 'components/GraphTabs';
import { Radar, RadarData } from 'components/Radar';
import { Video } from 'components/Video';
import {
  InfoTermsVertStanding,
  getSummary,
  radarLabelsVert,
  vertStandingTableGraphTerms,
} from './VertStanding';

interface VertStandingCompareProps {
  assessments: BaseAssessment[];
  className?: string;
}

export const VertStandingCompare = (props: VertStandingCompareProps) => {
  const { assessments, className } = props;
  const { assessmentType } = assessments[0] || {};
  const radarData = useMemo(() => {
    const result: RadarData[] = [];

    assessments.forEach((a: BaseAssessment) => {
      const summary = getSummary(a);
      if (!summary) {
        return;
      }

      const {
        percMaxAnklePlantarFlexionAccelerationAvg = 0,
        percMaxKneeExtensionAccelerationAvg = 0,
        percConcRelFF = 0,
        percLoadRelFF = 0,
        percMaxKneeExtensionVelocityAvg = 0,
      } = summary;

      const radarObject: RadarData = {
        name: radarGraphLegendText(a.name, a.date),
        value: [
          percMaxAnklePlantarFlexionAccelerationAvg,
          percMaxKneeExtensionAccelerationAvg,
          percConcRelFF,
          percLoadRelFF,
          percMaxKneeExtensionVelocityAvg,
        ],
      };
      result.push(radarObject);
    });
    if (
      assessments.length &&
      assessments[0].assessmentType === AssessmentType.SOCCER
    ) {
      result.push({
        name: `${assessments[0]?.position} Average`,
        value: Array(result[0].value.length).fill(50),
      });
    }
    return result;
  }, [assessments]);

  const config = [
    {
      id: 'radarTab',
      name: 'Percentile',
      Icon: RadarIcon,
      content: <Radar data={radarData} indicator={radarLabelsVert} />,
    },
    {
      id: 'tableTab',
      name: 'Test Results',
      Icon: TableIcon,
      content: (
        <GraphTable
          assessments={assessments}
          definitions={vertStandingTableGraphTerms(assessmentType)}
        />
      ),
    },
    {
      id: 'infoTab',
      name: 'Terminology',
      Icon: InfoIcon,
      content: <InfoTermsVertStanding type={assessmentType} />,
    },
  ];

  const videos = assessments[0]?.videos?.filter(
    (video) => video.exercise === 'StandingVertical',
  );
  if (videos?.length) {
    config.push({
      id: 'videoTab',
      name: 'Video',
      Icon: VideoIcon,
      content: <Video videos={videos} />,
    });
  }

  return (
    <GraphTabs
      className={className}
      config={config}
      defaultTab="radarTab"
      heading="Vertical: Standing"
    />
  );
};
