import * as Popover from '@radix-ui/react-popover';
import { ReactComponent as CheckmarkIcon } from 'assets/icons/checkmark.svg';
import { ReactComponent as ShareIcon } from 'assets/icons/share.svg';
import { useEffect, useMemo, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { useParams } from 'react-router-dom';
import { useCreateAssessmentShareLink } from 'hooks/mutation/useCreateAssessmentShareLink';
import { isValidAssessmentId } from 'util/assessment';
import { Button, ButtonVariants } from 'components/Button';
import { Modal } from 'components/Modal';
import { TextField } from 'components/TextField';
import styles from './ShareAssessment.module.css';

export const ShareAssessment = () => {
  const [isCopied, setIsCopied] = useState<boolean>(false);
  const [isSharing, setIsSharing] = useState<boolean>(false);
  const [linkId, setLinkId] = useState<string>();
  const { id } = useParams();
  const { mutateAsync } = useCreateAssessmentShareLink(id);
  const shareLink = useMemo(() => {
    const origin = window.location.origin;
    if (linkId) {
      return `${origin}/share/${linkId}/${id}`;
    }
  }, [id, linkId]);

  const { control, handleSubmit, formState, reset } = useForm({
    defaultValues: {
      password: '',
      isSingle: true,
    },
  });

  const [checkedState, setCheckedState] = useState<boolean>(true);
  const [radio1Disabled, setRadio1Disabled] = useState<boolean>(false);
  const [radio2Disabled, setRadio2Disabled] = useState<boolean>(false);

  const onSubmit = async (data: { password: string; isSingle: boolean }) => {
    data.isSingle = checkedState;
    if (data.isSingle) {
      setRadio2Disabled(true);
    } else {
      setRadio1Disabled(true);
    }
    setLinkId(await mutateAsync(data));
  };

  const onCopy = () => {
    navigator.clipboard.writeText(shareLink as string);
    setIsCopied(true);
    setTimeout(() => setIsCopied(false), 3000);
  };

  useEffect(() => {
    reset();
    setIsCopied(false);
    setLinkId(undefined);
    setRadio1Disabled(false);
    setRadio2Disabled(false);
  }, [isSharing, reset]);

  if (!isValidAssessmentId(id)) return null;

  return (
    <>
      <button onClick={() => setIsSharing(true)}>
        <ShareIcon />
      </button>
      {isSharing && (
        <Modal
          isOpen={isSharing}
          onOpenChange={setIsSharing}
          title="Share Via Password Protected Link"
        >
          <form
            className={styles.form}
            noValidate
            onSubmit={handleSubmit(onSubmit)}
          >
            <Controller
              control={control}
              name="isSingle"
              render={() => {
                return (
                  <>
                    <div className={styles['radio-field']}>
                      <input
                        checked={checkedState === true}
                        className="form-check-input"
                        disabled={radio1Disabled}
                        id="singleAssessment"
                        name="isSingle"
                        onChange={() => setCheckedState(true)}
                        type="radio"
                      />
                      <label htmlFor="singleAssessment">
                        Share Selected Assessment Only
                      </label>
                    </div>
                    <div className={styles['radio-field']}>
                      <input
                        checked={checkedState === false}
                        className="form-check-input"
                        disabled={radio2Disabled}
                        id="allowMultiple"
                        name="isSingle"
                        onChange={() => setCheckedState(false)}
                        type="radio"
                      />
                      <label htmlFor="allowMultiple">
                        Share All Athlete Assessments
                      </label>
                    </div>
                  </>
                );
              }}
            />
            <p>
              Create a password. You must share this password as well as the
              link.
            </p>
            <div className={styles['password-field']}>
              <Controller
                control={control}
                name="password"
                render={({ field, fieldState }) => (
                  <TextField
                    {...field}
                    disabled={!!shareLink || formState.isSubmitting}
                    error={fieldState.error?.message}
                    label="Create Password"
                    maxLength={60}
                    minLength={5}
                    required
                    type="password"
                  />
                )}
                rules={{
                  maxLength: {
                    message: 'Must not exceed 60 characters',
                    value: 60,
                  },
                  minLength: {
                    message: 'Must be at least 5 characters',
                    value: 5,
                  },
                  required: 'Required',
                }}
              />
              <Button
                disabled={!!shareLink || formState.isSubmitting}
                type="submit"
                variant={
                  shareLink ? ButtonVariants.Secondary : ButtonVariants.Primary
                }
              >
                {shareLink ? (
                  <CheckmarkIcon className={styles.check} />
                ) : (
                  'Save'
                )}
              </Button>
            </div>
          </form>
          {shareLink && (
            <div className={styles.result}>
              <TextField
                className={styles.input}
                disabled
                name="password"
                value={shareLink}
              />
              <Popover.Root open={isCopied} onOpenChange={setIsCopied}>
                <Popover.Trigger>
                  <button className={styles.copy} onClick={onCopy}>
                    Copy
                  </button>
                </Popover.Trigger>
                <Popover.Content className={styles.copied} side="top">
                  Copied to Clipboard
                  <Popover.Arrow className={styles.arrow} />
                </Popover.Content>
              </Popover.Root>
            </div>
          )}
        </Modal>
      )}
    </>
  );
};
