import * as Dialog from '@radix-ui/react-dialog';
import { ReactComponent as CloseIcon } from 'assets/icons/close.svg';
import cx from 'classnames';
import {
  PropsWithChildren,
  forwardRef,
  useEffect,
  useImperativeHandle,
  useState,
} from 'react';
import styles from './Modal.module.css';

interface ModalProps {
  isOpen?: boolean;
  onOpenChange?: (isOpen: boolean) => void;
  title?: string;
  // Additional css classnames
  classes?: string[];
}

export interface HandleClose {
  onClose: VoidFunction;
}

export const Modal = forwardRef<HandleClose, PropsWithChildren<ModalProps>>(
  (props: PropsWithChildren<ModalProps>, ref) => {
    const {
      children,
      isOpen: controlledIsOpen,
      onOpenChange,
      title,
      classes,
    } = props;
    const [isOpen, setIsOpen] = useState<boolean>(controlledIsOpen ?? false);

    useEffect(() => {
      if (controlledIsOpen !== undefined) {
        setIsOpen(controlledIsOpen);
      }
    }, [controlledIsOpen]);

    const handleOpen = (open: boolean) => {
      setIsOpen(open);
    };

    useImperativeHandle(ref, () => ({
      onClose: () => {
        setIsOpen(false);
      },
    }));

    useEffect(() => {
      onOpenChange?.(isOpen);
    }, [isOpen, onOpenChange]);

    return (
      <Dialog.Root onOpenChange={handleOpen} open={isOpen}>
        <Dialog.Portal>
          <Dialog.Overlay className={styles.overlay} />
          <Dialog.Content className={cx(styles.modal, classes)}>
            <Dialog.Close className={styles.close}>
              <CloseIcon />
            </Dialog.Close>
            {title && (
              <Dialog.Title className={styles.title}>{title}</Dialog.Title>
            )}
            {children}
          </Dialog.Content>
        </Dialog.Portal>
      </Dialog.Root>
    );
  },
);
