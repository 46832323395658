import { useMemo, useState } from 'react';
import { useMatch } from 'react-router-dom';
import {
  AssessmentWrapper,
  BaseAssessment,
  BasketballAssessment,
  SoccerAssessment,
  UserResponseModel,
} from 'types/api';
import { useAssessmentQueries } from 'hooks/query/useAssessmentQueries';
import { ContentType } from './useAuthenticatedFetch';

type Assessment = BasketballAssessment | SoccerAssessment;

export function useAssessmentDetail({
  assessmentWrapper,
  user,
  single,
}: {
  assessmentWrapper: AssessmentWrapper;
  user: UserResponseModel | undefined;
  single?: boolean;
}) {
  const { currentAssessment, priorAssessments, verticalLateralAbilities } =
    assessmentWrapper;
  const assessments = useMemo(
    () =>
      [
        currentAssessment,
        // prevents re-adding the current assessment from priorAssessments
        ...(priorAssessments?.filter(
          (prior) => prior?.id !== currentAssessment?.id,
        ) || []),
      ] as Assessment[],
    [currentAssessment, priorAssessments],
  );

  const [comparedMechanicsAssessments, setComparedMechanicsAssessments] =
    useState<Assessment[]>([]);

  const [assessmentIdsToCompare, setAssessmentIdsToCompare] = useState<
    string[]
  >(currentAssessment?.id ? [currentAssessment.id] : []);

  // const [comparedAssessments, setComparedAssessments] = useState<
  //   BaseAssessment[]
  // >([]);

  const isSuperAdmin = user?.isSuperAdmin ?? false;
  const isShared = useMatch('/share/:linkId/:assessmentId');

  const updateComparedMechanicsAssessments = (toCompare: Assessment[]) => {
    setComparedMechanicsAssessments(toCompare);
  };

  const resetCharts = () => {
    setAssessmentIdsToCompare(
      currentAssessment?.id ? [currentAssessment.id] : [],
    );
  };

  const updateComparedAthleticismAssessments = (toCompare: string[][]) => {
    if (toCompare && toCompare.flat().length > 0) {
      const fullSetOfIds = toCompare.flat();
      setAssessmentIdsToCompare(fullSetOfIds);
    }
  };

  let contentType = ContentType.NONE;
  if (single) {
    contentType = ContentType.OVERRIDE_ADD_ORGANIZATION_HEADER;
  }

  const getAssessmentData = useAssessmentQueries(
    assessmentIdsToCompare,
    contentType,
  );

  let comparedAssessments: BaseAssessment[] = [];
  if (!isShared) {
    const assessmentsToCompare: BaseAssessment[] = [];
    const isLoading = getAssessmentData?.some((result) => result.isLoading);
    if (!isLoading && getAssessmentData?.length > 0) {
      getAssessmentData?.forEach((x) => {
        if (getAssessmentData && x?.data?.currentAssessment) {
          assessmentsToCompare?.push(x?.data?.currentAssessment);
        }
      });
    }
    comparedAssessments = assessmentsToCompare;
  } else {
    let newArray: BaseAssessment[] = [];
    if (currentAssessment) {
      newArray = [currentAssessment];
    }
    for (let i = 1; i < assessmentIdsToCompare.length; i++) {
      const find = priorAssessments?.find(
        (x) => x.id === assessmentIdsToCompare[i],
      );
      if (find) {
        newArray.push(find);
      }
    }
    comparedAssessments = newArray;
  }

  return {
    assessments,
    comparedAssessments,
    comparedMechanicsAssessments,
    currentAssessment,
    isSuperAdmin,
    priorAssessments,
    resetCharts,
    updateComparedAthleticismAssessments,
    updateComparedMechanicsAssessments,
    verticalLateralAbilities,
  };
}
