import { DateOnly } from 'types/api';
import { formatDate } from './formatDate';

export const radarGraphLegendText = (
  name: string | null | undefined,
  date?: DateOnly | undefined,
): string => {
  if (!name) {
    return `${formatDate(date).toString()}`;
  } else {
    const splitName = name.split(' ');

    return `${splitName[splitName.length - 1]}, ${splitName[0].replace(
      /\B\w+/,
      '.',
    )}\n${formatDate(date).toString()}`;
  }
};
