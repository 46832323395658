import { useMutation } from 'react-query';
import { toast } from 'react-toastify';
import { UserResponseModel } from 'types/api';
import { UpdateUserRoleRequestModel } from 'types/api/models/UpdateUserRoleRequestModel';
import { useAuthenticatedFetch } from 'hooks/util/useAuthenticatedFetch';

export const useDeleteUserRole = (id?: string) => {
  const fetch = useAuthenticatedFetch();
  return useMutation(
    (body: UpdateUserRoleRequestModel) =>
      fetch<UserResponseModel>(
        `${process.env.REACT_APP_API_HOST}/users/${id}/role`,
        {
          body,
          method: 'DELETE',
        },
      ),
    {
      onError: () => {
        toast.error('Error deleting user.');
      },
      onSuccess: () => {
        toast.success('Account successfully deleted.');
      },
    },
  );
};
