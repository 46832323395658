import cuid from 'cuid';
import * as faker from 'faker';
import { ObjectGenerator } from 'lib/api-mocking/interfaces/ObjectGenerator';
import {
  BasketballAssessment as Assessment,
  AssessmentBasketballSummary as AssessmentSummary,
} from 'types/api';

interface IAssessmentGenerator extends ObjectGenerator<Assessment, string> {
  generate: () => Assessment;
  generateSummary: () => AssessmentSummary;
}

export const AssessmentGenerator: IAssessmentGenerator = {
  generateId: (): string => {
    return cuid();
  },

  generate: () => {
    const date = faker.date.past(50, '2000-01-01');

    return {
      assessmentDetails: {
        assessmentDropJumpSummary: {
          percMaxKneeExtensionVelocityAvg: 0,
          percMaxKneeExtensionAccelerationAvg: 0,
          percNetImpact1Avg: 0,
          percNetImpact2LeftRaw: 0,
          percNetImpact2RightRaw: 0,
          percNetRelConcForce: 0,
          maxKneeExtensionVelocityAvg: 0,
          maxKneeExtensionAccelerationAvg: 0,
          impact1Avg: 0,
          impact2Left: 0,
          impact2Right: 0,
          netRelativeConcentricForce: 0,
        },
        assessmentOverallPerformanceFactors: {
          verticalPercentile: 0,
          lateralPercentile: 0,
          accelerationPercentile: 0,
          decelerationPercentile: 0,
        },
        assessmentSkaterSummary: {
          percLeftLateralForce: 0,
          percLeftMaxHipExtensionVelocity: 0,
          percLeftMaxHipAbduction: 0,
          percRightLateralForce: 0,
          percRightMaxHipExtensionVelocity: 0,
          percRightMaxHipAbduction: 0,
          maxLeftLateralForce: 0,
          maxRightLateralForce: 0,
          maxLeftHipExtensionVelocity: 0,
          maxRightHipExtensionVelocity: 0,
          maxLeftHipAbduction: 0,
          maxRightHipAbduction: 0,
        },
        assessmentStandingJumpSummary: {
          percMaxKneeExtensionAccelerationAvg: 0,
          percMaxKneeExtensionVelocityAvg: 0,
          percConcRelFF: 0,
          percLoadRelFF: 0,
          percMaxAnklePlantarFlexionAccelerationAvg: 0,
          concentricRelativeFF: 0,
          loadRelativeFF: 0,
          maxKneeExtensionVelocityAvg: 0,
          maxKneeExtensionAccelerationAvg: 0,
          maxAnklePlantarFlexionAccelerationAvg: 0,
        },
        assessmentSummary: {
          weight: 32,
          height: 0,
          reach: 0,
          vertJump: 0,
          dropJump: 0,
          latForce: 0,
          vertJumpVsAverage: 0,
          dropJumpVsAverage: 0,
          latForceVsAverage: 0,
          athleticismScore: 0,
          mechanicsScore: 0,
        },
        assessmentBasketballMechanicsSummary: {
          impulseAsymmetry: 0,
          impact2LeftRaw: 0,
          impact2RightRaw: 0,
          deltaHipFlexionAverage: 0,
          deltaHipFlexionLeft: 0,
          deltaHipFlexionRight: 0,
          translationLeft: 0,
          translationRight: 0,
          maxRelativeRotationLeft: 0,
          maxRelativeRotationRight: 0,
          deltaFemoralRotationLeft: 0,
          deltaFemoralRotationRight: 0,
          maxHipActiveDecelerationLeft: 0,
          maxHipActiveDecelerationRight: 0,
          inversionLeft: 0,
          inversionRight: 0,
          activeDorsiflexionLeft: 0,
          activeDorsiflexionRight: 0,
          ankleActiveDecelerationLeft: 0,
          ankleActiveDecelerationRight: 0,
        },
      },
      date: {
        day: date.getDay(),
        month: date.getMonth(),
        year: date.getFullYear(),
      },
      name: `${faker.name.firstName()} ${faker.name.lastName()}`,
      id: AssessmentGenerator.generateId(),
    };
  },

  generateList: (count: number): Assessment[] => {
    return Array.from({ length: count }, (): Assessment => {
      return AssessmentGenerator.generate();
    }) as Assessment[];
  },

  generateSummary: (): AssessmentSummary => {
    return {
      athleticismScore: faker.datatype.number({ min: 0, max: 100 }),
      dropJump: faker.datatype.number(),
      dropJumpVsAverage: faker.datatype.number(),
      height: faker.datatype.number(),
      latForce: faker.datatype.number(),
      latForceVsAverage: faker.datatype.number(),
      mechanicsScore: faker.datatype.number({ min: 0, max: 100 }),
      reach: faker.datatype.number(),
      vertJump: faker.datatype.number(),
      vertJumpVsAverage: faker.datatype.number(),
      weight: faker.datatype.number(),
    };
  },
};
