import classNames from 'classnames';
import { useEffect, useRef, useState } from 'react';
import {
  AssessmentWrapper,
  BaseAssessment,
  SoccerAssessment,
  UserResponseModel,
} from 'types/api';
import { useAssessmentDetail } from 'hooks/util/useAssessmentDetail';
import { SubHeaderH2 } from 'components/SubHeaderH2';
import { SubHeaderH3 } from 'components/SubHeaderH3';
import { CompareAthleticism } from './Athleticism/CompareAthleticism';
import { DropStanceKick } from './Athleticism/DropStanceKick';
import { DropStanceKickCompare } from './Athleticism/DropStanceKickCompare';
import { LateralSkater } from './Athleticism/LateralSkater';
import { LateralSkaterCompare } from './Athleticism/LateralSkaterCompare';
import { VertStanding } from './Athleticism/VertStanding';
import { VertStandingCompare } from './Athleticism/VertStandingCompare';
import styles from './Detail.module.css';
import { InjuryRisk } from './InjuryRisk';
import { LeftFoot, LeftKnee, LowBack, RightFoot, RightKnee } from './Mechanics';
import { CompareMechanics } from './Mechanics/CompareMechanics';
import { LeftFootCompare } from './Mechanics/LeftFootCompare';
import { LeftKneeCompare } from './Mechanics/LeftKneeCompare';
import { LowBackCompare } from './Mechanics/LowBackCompare';
import { RightFootCompare } from './Mechanics/RightFootCompare';
import { RightKneeCompare } from './Mechanics/RightKneeCompare';
import { Overview } from './Overview';
import { PerformanceFactorComparison } from './Statistics/PerformanceFactorComparison';
import { VerticalLateralAbilitiesChart } from './Statistics/VerticalLateralAbilitiesChart/VerticalLateralAbilitiesChart';
import { Targets } from './Targets';

interface AssessmentDetailProps {
  assessmentWrapper: AssessmentWrapper;
  user?: UserResponseModel;
  single?: boolean;
  password?: string;
}

export const SoccerAssessmentsDetail = (props: AssessmentDetailProps) => {
  const { user, single, password } = props;

  const [assessmentWrapper, setAssessmentWrapper] = useState<AssessmentWrapper>(
    props.assessmentWrapper,
  );

  const [currentAssessment, setCurrentAssessment] = useState<
    BaseAssessment | undefined
  >(assessmentWrapper.currentAssessment);

  const [priorAssessments, setPriorAssessments] = useState(
    assessmentWrapper.priorAssessments,
  );

  let allAssessments: SoccerAssessment[] | undefined;
  if (assessmentWrapper.currentAssessment) {
    allAssessments = assessmentWrapper.priorAssessments?.concat([
      assessmentWrapper.currentAssessment,
    ]);
  }
  const allAssessmentsRef = useRef<SoccerAssessment[] | undefined>(
    allAssessments,
  );

  useEffect(() => {
    const newPriors = allAssessmentsRef.current?.filter(
      (a: SoccerAssessment) => a.id !== currentAssessment?.id,
    );

    setPriorAssessments(newPriors);
  }, [currentAssessment]);

  useEffect(() => {
    const buildAssessmentWrapper = (): AssessmentWrapper => {
      return {
        name: currentAssessment?.name,
        currentAssessment: currentAssessment,
        priorAssessments: priorAssessments,
        verticalLateralAbilities:
          props.assessmentWrapper.verticalLateralAbilities,
      };
    };
    setAssessmentWrapper(buildAssessmentWrapper);
  }, [
    currentAssessment,
    priorAssessments,
    props.assessmentWrapper.verticalLateralAbilities,
  ]);

  const results = useAssessmentDetail({
    assessmentWrapper,
    user,
    single,
  });

  const {
    assessments,
    comparedAssessments,
    comparedMechanicsAssessments,
    isSuperAdmin,
    resetCharts,
    updateComparedAthleticismAssessments,
    updateComparedMechanicsAssessments,
    verticalLateralAbilities,
  } = results;

  const targets = currentAssessment && (
    <Targets assessment={currentAssessment} isSuperAdmin={isSuperAdmin} />
  );

  if (!currentAssessment) return null;

  return (
    <div>
      <Overview
        targets={targets}
        setCurrentAssessment={setCurrentAssessment}
        currentAssessment={currentAssessment}
        priorAssessments={priorAssessments}
        resetCharts={resetCharts}
      />
      <div className={styles['assessment-results-container']}>
        <div className={styles['width-control']}>
          <SubHeaderH2
            h2="Athleticism Summary"
            p={`The sections that follow provide a detailed account of ${currentAssessment.name}'s biomechanical data as it relates to athleticism. All data included come from in-house research conducted by the P3 team.`}
          />
          <div className={styles['background-container']}>
            <SubHeaderH3 h3="Statistics" hideMobile />
            <hr
              className={classNames(styles['horizontal-rule'], 'hide-mobile')}
            />
          </div>
          <div
            className={classNames(
              styles['mobile-black'],
              styles['two-col-grid'],
            )}
          >
            <div>
              <VerticalLateralAbilitiesChart
                data={verticalLateralAbilities}
                assessment={currentAssessment}
                priorAssessments={priorAssessments}
              />
            </div>
            <PerformanceFactorComparison assessment={currentAssessment} />
          </div>
          <div
            className={classNames(
              styles['background-container'],
              styles['mobile-black'],
            )}
          >
            <div className={styles.flex}>
              <div className={styles['athleticism-header']}>
                <SubHeaderH3 h3="Test Results" />
              </div>
              <CompareAthleticism
                priorAssessments={priorAssessments}
                assessments={assessments}
                currentAssessment={currentAssessment}
                comparedAssessments={comparedAssessments}
                updateComparedAssessments={updateComparedAthleticismAssessments}
                resetCharts={resetCharts}
                password={password}
              />
            </div>
            <hr
              className={classNames(styles['horizontal-rule'], 'hide-mobile')}
            />
            <div className={styles['two-col-grid']}>
              {comparedAssessments.length > 1 ? (
                <>
                  <div className={styles['athleticism-graph']}>
                    <VertStandingCompare
                      assessments={comparedAssessments}
                      className={styles['vert-standing']}
                    />
                  </div>
                  <div className={styles['athleticism-graph']}>
                    <LateralSkaterCompare
                      assessments={comparedAssessments}
                      className={styles['lateral-skater']}
                    />
                  </div>
                  <div className={styles['athleticism-graph']}>
                    <DropStanceKickCompare
                      assessments={comparedAssessments}
                      chartType="stance"
                    />
                  </div>
                  <div className={styles['athleticism-graph']}>
                    <DropStanceKickCompare
                      assessments={comparedAssessments}
                      chartType="kick"
                    />
                  </div>
                </>
              ) : (
                <>
                  <div className={styles['athleticism-graph']}>
                    <VertStanding
                      assessment={currentAssessment}
                      className={styles['vert-standing']}
                    />
                  </div>
                  <div className={styles['athleticism-graph']}>
                    <LateralSkater
                      assessment={currentAssessment}
                      className={styles['lateral-skater']}
                    />
                  </div>
                  <div className={styles['athleticism-graph']}>
                    <DropStanceKick
                      assessment={currentAssessment}
                      chartType="stance"
                    />
                  </div>
                  <div className={styles['athleticism-graph']}>
                    <DropStanceKick
                      assessment={currentAssessment}
                      chartType="kick"
                    />
                  </div>
                </>
              )}
            </div>
          </div>
          <SubHeaderH2
            h2="Mechanics Summary"
            p={`This section of the report provides additional details about ${currentAssessment.name}'s biomechanical data as it relates to their prospective risk of potential injury. All variables included here come from in-house P3 research on the topic.`}
          />
          <div
            className={classNames(
              styles['background-container'],
              styles['mobile-black'],
            )}
          >
            {priorAssessments && priorAssessments.length > 0 && (
              <CompareMechanics
                assessments={assessments}
                comparedAssessments={comparedMechanicsAssessments}
                onChange={updateComparedMechanicsAssessments}
              />
            )}
            <br />
            <div className={styles.layout}>
              <div className={styles.graphs}>
                {comparedMechanicsAssessments.length ? (
                  <>
                    <LowBackCompare
                      assessments={comparedMechanicsAssessments}
                    />
                    <LeftKneeCompare
                      assessments={comparedMechanicsAssessments}
                    />
                    <RightKneeCompare
                      assessments={comparedMechanicsAssessments}
                    />
                    <LeftFootCompare
                      assessments={comparedMechanicsAssessments}
                    />
                    <RightFootCompare
                      assessments={comparedMechanicsAssessments}
                    />
                  </>
                ) : (
                  <>
                    <LowBack assessment={currentAssessment} />
                    <LeftKnee assessment={currentAssessment} />
                    <RightKnee assessment={currentAssessment} />
                    <LeftFoot assessment={currentAssessment} />
                    <RightFoot assessment={currentAssessment} />
                  </>
                )}
              </div>
              <div className={styles['injury-risk-container']}>
                <SubHeaderH3
                  h3="Risk Stratification"
                  p="Interact with injury risk locations to explore strategies to
                  reduce injury risk."
                />
                <InjuryRisk assessment={currentAssessment} />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
