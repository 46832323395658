import { EChartsCoreOption, init } from 'echarts/core';
import { RefObject, useEffect, useRef } from 'react';
import p3 from 'themes/p3/p3.project.json';

export const useChart = (
  option: EChartsCoreOption,
): RefObject<HTMLDivElement> => {
  const chartRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (chartRef.current) {
      const chart = init(chartRef.current, p3.theme);
      chart.setOption(option);

      const handleResize = () => chart.resize();
      window.addEventListener('resize', handleResize);

      return () => {
        chart.dispose();
        window.removeEventListener('resize', handleResize);
      };
    }
  }, [chartRef, option]);

  return chartRef;
};
