import { Role, UserResponseModel } from 'types/api';
import { OrganizationResponseModel } from 'types/api/models/OrganizationResponseModel';
import { constants } from './updateLocalStorage';

export interface ILocalStorageContext {
  localStorageOrg: OrganizationResponseModel | undefined;
  localStorageRole: Role | undefined;
}
export const getContextFromLocalStorage = (
  loggedInUser: UserResponseModel | undefined,
) => {
  const localStorageOrg: string | null = localStorage.getItem(
    constants.localStorageOrg,
  );
  const localStorageRole: string | null = localStorage.getItem(
    constants.localStorageRole,
  );

  if (!localStorageOrg && !localStorageRole) {
    return {
      localStorageOrg: undefined,
      localStorageRole: undefined,
    };
  }

  // If user was a super admin and is not anymore, reset their local storage role and trigger useDefaultContextAssignment to pick up their new default role
  if (
    !loggedInUser?.isSuperAdmin &&
    localStorageRole === Role.SUPER_ADMIN.toString()
  ) {
    return {
      localStorageOrg: undefined,
      localStorageRole: undefined,
    };
  }

  // Verify that the org/role the user has in their local storage, is an org/role that their user has access to.
  const ensureValidLocalStorageRole = loggedInUser?.organizationRoles?.some(
    (x) => {
      return (
        x.role === localStorageRole &&
        localStorageOrg &&
        x.organizationId === JSON.parse(localStorageOrg).id
      );
    },
  );

  if (!ensureValidLocalStorageRole) {
    return {
      localStorageOrg: undefined,
      localStorageRole: undefined,
    };
  }

  let toModel: OrganizationResponseModel | undefined;
  let toEnum: Role | undefined;
  if (localStorageOrg) {
    toModel = JSON.parse(localStorageOrg);
  }
  if (localStorageRole) {
    toEnum = localStorageRole as Role;
  }

  return {
    localStorageOrg: toModel,
    localStorageRole: toEnum,
  };
};
