import { useMemo } from 'react';
import {
  AssessmentType,
  BasketballAssessment,
  BasketballAssessmentDetails,
  ExerciseSide,
  SoccerAssessment,
  SoccerAssessmentDetails,
} from 'types/api';
import { formatDate } from 'util/formatDate';
import { labels } from 'util/graphConstants';
import { unitsOfMeasure } from 'util/unitsOfMeasure';
import { IDefinitions } from 'components/GraphTable';
import { RadarData } from 'components/Radar';
import {
  kickKneeTableGraphTerms,
  stanceKneeTableGraphTerms,
} from './SoccerTableGraphTerms';
import { ConfigData } from './useConfig';

const {
  deltaFemoralRotateR,
  hipActiveDecelR,
  maxRelRotationR,
  translationR,
  translationKick,
  relativeRotationKick,
  deltaHipFlexionKick,
  translationStance,
  relativeRotationStance,
  deltaHipFlexionStance,
} = labels;

const basketballDefinitions: IDefinitions[] = [
  {
    key: 'assessmentDetails.assessmentBasketballMechanicsSummary.deltaFemoralRotationRight',
    unit: unitsOfMeasure('rotation'),
    name: deltaFemoralRotateR,
  },
  {
    key: 'assessmentDetails.assessmentBasketballMechanicsSummary.maxHipActiveDecelerationRight',
    unit: unitsOfMeasure('deceleration'),
    name: hipActiveDecelR,
  },
  {
    key: 'assessmentDetails.assessmentBasketballMechanicsSummary.maxRelativeRotationRight',
    unit: unitsOfMeasure('rotation'),
    name: maxRelRotationR,
  },
  {
    key: 'assessmentDetails.assessmentBasketballMechanicsSummary.translationRight',
    unit: unitsOfMeasure('translation'),
    name: translationR,
  },
];

export const useRightKneeData = (
  assessments: Partial<SoccerAssessment | BasketballAssessment>[],
): ConfigData => {
  return useMemo(() => {
    const chartData: Array<number>[] = [];
    const radarData: RadarData[] = [];
    let barLabels: Array<string> = [];
    let definitions: IDefinitions[] = [];
    let terms: string[] = [];
    const dates: string[] = assessments.map((x) =>
      formatDate(x.date).toString(),
    );

    const lineData: { name: string; type: string; data: number[] }[] = [
      {
        name: 'Translation',
        type: 'line',
        data: [],
      },
      {
        name: 'Rel Rotation',
        type: 'line',
        data: [],
      },
      {
        name: 'Hip Decel',
        type: 'line',
        data: [],
      },
    ];
    const [first] = assessments;
    switch (first?.assessmentType) {
      case AssessmentType.SOCCER:
        terms = ['relRotation', 'translation', 'deltaHipFlex'];
        assessments?.forEach((assessment) => {
          const details =
            assessment?.assessmentDetails as SoccerAssessmentDetails;

          const {
            percTranslationKick = 0,
            percRelativeRotationKick = 0,
            percDeltaHipFlexionKick = 0,
            percTranslationStance = 0,
            percRelativeRotationStance = 0,
            percDeltaHipFlexionStance = 0,
            stanceLeg,
          } = details?.assessmentSoccerMechanicsSummary || {};
          let values = [];
          if (stanceLeg === ExerciseSide.RIGHT) {
            values = [
              percTranslationStance,
              percRelativeRotationStance,
              percDeltaHipFlexionStance,
            ];
            chartData.push(values);
            barLabels = [
              translationStance,
              relativeRotationStance,
              deltaHipFlexionStance,
            ].map((label) => label.replace('#', 'R'));

            definitions = stanceKneeTableGraphTerms;
          } else {
            values = [
              percRelativeRotationKick,
              percDeltaHipFlexionKick,
              percTranslationKick,
            ];
            chartData.push(values);

            barLabels = [
              relativeRotationKick,
              deltaHipFlexionKick,
              translationKick,
            ].map((label) => label.replace('#', 'R'));
            definitions = kickKneeTableGraphTerms;
          }
          values.forEach((value, index) => lineData[index].data.push(value));
        });
        definitions = definitions.map((term) => ({
          ...term,
          name: term?.name?.replace('#', 'R'),
        }));
        break;
      case AssessmentType.BASKETBALL:
      default:
        terms = [
          'femoralRotation',
          'hipActDecel',
          'relRotation',
          'translation',
        ];
        definitions = basketballDefinitions;
        barLabels = [
          deltaFemoralRotateR,
          hipActiveDecelR,
          maxRelRotationR,
          translationR,
        ];
        lineData.push({
          name: 'Femoral Rotation',
          type: 'line',
          data: [],
        });
        assessments?.forEach((assessment) => {
          const details =
            assessment?.assessmentDetails as BasketballAssessmentDetails;

          const {
            percDeltaFemoralRotationRight = 0,
            percMaxHipActiveDecelerationRight = 0,
            percMaxRelativeRotationRight = 0,
            percTranslationRight = 0,
          } = details?.assessmentBasketballMechanicsSummary || {};
          const values = [
            percDeltaFemoralRotationRight,
            percMaxHipActiveDecelerationRight,
            percMaxRelativeRotationRight,
            percTranslationRight,
          ];
          chartData.push(values);
          values.forEach((value, index) => lineData[index].data.push(value));
        });
    }
    return {
      chartData,
      radarData,
      barLabels,
      definitions,
      dates,
      lineData,
      terms,
    };
  }, [assessments]);
};
