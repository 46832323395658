import { useMemo } from 'react';
import {
  AssessmentType,
  BasketballAssessment,
  BasketballAssessmentDetails,
  ExerciseSide,
  SoccerAssessment,
  SoccerAssessmentDetails,
} from 'types/api';
import { formatDate } from 'util/formatDate';
import { labels } from 'util/graphConstants';
import { unitsOfMeasure } from 'util/unitsOfMeasure';
import { IDefinitions } from 'components/GraphTable';
import { RadarData } from 'components/Radar';
import {
  kickAnkleTableGraphTerms,
  stanceAnkleGraphTerms,
} from './SoccerTableGraphTerms';
import { ConfigData } from './useConfig';

const {
  activeDorsiFlexL,
  ankleDecelL,
  inversionL,
  translationL,
  activeAnkleDecelerationKick,
  activeDorsiflexionKick,
  inversionKick,
  eversionKick,
  activeAnkleDecelerationStance,
  activeDorsiflexionStance,
  inversionStance,
  eversionStance,
} = labels;

const basketballDefinitions: IDefinitions[] = [
  {
    key: 'assessmentDetails.assessmentBasketballMechanicsSummary.activeDorsiflexionLeft',
    unit: unitsOfMeasure('flexion'),
    name: activeDorsiFlexL,
  },
  {
    key: 'assessmentDetails.assessmentBasketballMechanicsSummary.ankleActiveDecelerationLeft',
    unit: unitsOfMeasure('deceleration'),
    name: ankleDecelL,
  },
  {
    key: 'assessmentDetails.assessmentBasketballMechanicsSummary.inversionLeft',
    unit: unitsOfMeasure('inversion'),
    name: inversionL,
  },
  {
    key: 'assessmentDetails.assessmentBasketballMechanicsSummary.translationLeft',
    unit: unitsOfMeasure('translation'),
    name: translationL,
  },
];

export const useLeftFootData = (
  assessments: Partial<SoccerAssessment | BasketballAssessment>[],
): ConfigData => {
  return useMemo(() => {
    const chartData: Array<number>[] = [];
    const radarData: RadarData[] = [];
    let barLabels: Array<string> = [];
    let definitions: IDefinitions[] = [];
    let terms: string[] = [];
    const dates: string[] = assessments.map((x) =>
      formatDate(x.date).toString(),
    );

    const lineData: { name: string; type: string; data: number[] }[] = [
      {
        name: 'Active Dorsiflexion (L)',
        type: 'line',
        data: [],
      },
      {
        name: 'Ankle Deceleration (L)',
        type: 'line',
        data: [],
      },
      {
        name: 'Inversion (L)',
        type: 'line',
        data: [],
      },
      {
        name: 'Translation (L)',
        type: 'line',
        data: [],
      },
    ];
    const [first] = assessments;
    switch (first?.assessmentType) {
      case AssessmentType.SOCCER:
        terms = [
          'ankleActDecel',
          'activeDorsiflexion',
          'inversion',
          'eversion',
        ];
        assessments?.forEach((assessment) => {
          const details =
            assessment?.assessmentDetails as SoccerAssessmentDetails;

          const {
            /* kick ankle */
            percActiveAnkleDecelerationKick = 0,
            percActiveDorsiflexionKick = 0,
            percInversionKick = 0,
            percEversionKick = 0,
            /* stance ankle */
            percActiveAnkleDecelerationStance = 0,
            percActiveDorsiflexionStance = 0,
            percInversionStance = 0,
            percEversionStance = 0,
            kickLeg,
          } = details?.assessmentSoccerMechanicsSummary || {};

          if (kickLeg === ExerciseSide.LEFT) {
            /* left kick leg? use kick ankle */
            chartData.push([
              percActiveAnkleDecelerationKick,
              percActiveDorsiflexionKick,
              percInversionKick,
              percEversionKick,
            ]);

            lineData[0].data.push(percActiveDorsiflexionKick);
            lineData[1].data.push(percActiveAnkleDecelerationKick);
            lineData[2].data.push(percInversionKick);
            lineData[3].data.push(percEversionKick);

            barLabels = [
              activeAnkleDecelerationKick,
              activeDorsiflexionKick,
              inversionKick,
              eversionKick,
            ].map((label) => label.replace('#', 'L'));

            definitions = kickAnkleTableGraphTerms;
          } else {
            /* right kick leg, use stance ankle */
            chartData.push([
              percActiveAnkleDecelerationStance,
              percActiveDorsiflexionStance,
              percInversionStance,
              percEversionStance,
            ]);

            barLabels = [
              activeAnkleDecelerationStance,
              activeDorsiflexionStance,
              inversionStance,
              eversionStance,
            ].map((label) => label.replace('#', 'L'));

            lineData[0].data.push(percActiveAnkleDecelerationStance);
            lineData[1].data.push(percActiveDorsiflexionStance);
            lineData[2].data.push(percInversionStance);
            lineData[3].data.push(percEversionStance);

            definitions = stanceAnkleGraphTerms;
          }
        });
        definitions = definitions.map((term) => ({
          ...term,
          name: term?.name?.replace('#', 'L'),
        }));
        break;
      case AssessmentType.BASKETBALL:
      default:
        terms = [
          'activeDorsiflexion',
          'ankleActDecel',
          'inversion',
          'translation',
        ];
        definitions = basketballDefinitions;
        barLabels = [activeDorsiFlexL, ankleDecelL, inversionL, translationL];
        assessments?.forEach((assessment) => {
          const details =
            assessment?.assessmentDetails as BasketballAssessmentDetails;

          const {
            percActiveDorsiflexionLeft = 0,
            percAnkleActiveDecelerationLeft = 0,
            percInversionLeft = 0,
            percTranslationLeft = 0,
          } = details?.assessmentBasketballMechanicsSummary || {};

          lineData[0].data.push(percActiveDorsiflexionLeft);
          lineData[1].data.push(percAnkleActiveDecelerationLeft);
          lineData[2].data.push(percInversionLeft);
          lineData[3].data.push(percTranslationLeft);

          chartData.push([
            percActiveDorsiflexionLeft,
            percAnkleActiveDecelerationLeft,
            percInversionLeft,
            percTranslationLeft,
          ]);
        });
    }
    return {
      chartData,
      radarData,
      barLabels,
      definitions,
      dates,
      lineData,
      terms,
    };
  }, [assessments]);
};
