/* eslint-disable @typescript-eslint/no-explicit-any */
import { PropsWithChildren, useRef } from 'react';
import { UpdateTrainingTargetsRequestModel } from 'types/api';
import { useUpdateTrainingTargets } from 'hooks/mutation/useUpdateTrainingTargets';
import { Button } from 'components/Button';
import { HandleClosePopover, Popover } from 'components/Popover';
import { TrainingTargetDisplayObject } from '..';
import { Form, FormHandle } from './Form';
import styles from './UpdateTargets.module.css';

interface IUpdateTargetsProps {
  assessmentId: string;
  displayObject: TrainingTargetDisplayObject[];
  setTrainingTargetContent: any;
  onSubmit?: () => void;
}

export const UpdateTargets = (
  props: PropsWithChildren<IUpdateTargetsProps>,
) => {
  const { displayObject, assessmentId } = props;
  const formHandle = useRef<FormHandle>(null);
  const popoverClose = useRef<HandleClosePopover>(null);
  const { mutateAsync } = useUpdateTrainingTargets(assessmentId);

  const noneChecked =
    displayObject.filter(({ selected }) => selected).length === 0;

  let triggerName = 'Edit';
  if (noneChecked) {
    triggerName = 'Assign';
  }

  const handleSubmit = () => {
    formHandle.current?.submit();
    popoverClose.current?.onPopoverClose();

    props.onSubmit && props.onSubmit();
  };

  const handleUpdate = (updatedTargets: object) => {
    const objToRequestBodyFormat = (): UpdateTrainingTargetsRequestModel => {
      const objToArray = Object.values(updatedTargets);
      const removeFalses = objToArray.filter(Boolean);
      props.setTrainingTargetContent(removeFalses);
      return { trainingTargets: [...removeFalses] };
    };
    mutateAsync(objToRequestBodyFormat());
  };

  return (
    <div>
      <Popover
        align="end"
        alignOffset={-35}
        contentClassName={styles.popover}
        hideArrow
        sideOffset={-55}
        trigger={triggerName}
        triggerClassName={styles.button}
        ref={popoverClose}
      >
        <header className={styles.header}>
          <h3>Select Training Targets</h3>
          <Button
            onClick={handleSubmit}
            type="button"
            className={styles.assign}
          >
            Assign
          </Button>
        </header>
        <div className={styles.content}>
          <span className={styles.suggested}>Select up to 4</span>
          <Form
            displayObject={displayObject}
            ref={formHandle}
            onSubmit={handleUpdate}
          />
        </div>
      </Popover>
    </div>
  );
};
