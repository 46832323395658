import cx from 'classnames';
import React from 'react';
import {
  TableState,
  UseTableOptions,
  useExpanded,
  useSortBy,
  useTable,
} from 'react-table';
import {
  AssessmentGroup,
  AssessmentSortOption,
  AssessmentType,
  BaseAssessment,
  SortDirection,
} from 'types/api';
import styles from './AssessmentsListTable.module.css';

export enum AListTrend {
  UP = 'up',
  DOWN = 'down',
  EQUAL = 'equal',
}
export interface ISelectedRows {
  [key: string]: string[];
}

export interface IAListTableRow extends AssessmentGroup {
  type: string | undefined;
  position: string | undefined;
  athleticismScore: { value: string; trend: AListTrend };
  mechanicsScore: { value: string; trend: AListTrend };
  latestAssessmentId: string | undefined;
  assessmentType?: AssessmentType;
}

interface CustomTableOptions extends UseTableOptions<IAListTableRow> {
  selectedSubRows?: { [key: string]: string[] };
}

interface CustomTableState extends TableState {
  selectedSubRows?: BaseAssessment[];
}

export interface IATableProps {
  onSortBy: (
    sortOption: AssessmentSortOption,
    sortDirection: SortDirection,
  ) => void;
  useTableOptions: CustomTableOptions;
  renderSubRowContent: (props: {
    state: CustomTableState;
    assessments: BaseAssessment[];
  }) => React.ReactNode;
}

export const AListTable = ({
  onSortBy,
  useTableOptions,
  renderSubRowContent,
}: IATableProps) => {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  let expandedRowName: any;
  if (useTableOptions?.data[0]?.name) {
    expandedRowName = `${useTableOptions.data[0].name}_${useTableOptions.data[0].latestAssessmentId}`;
    expandedRowName = expandedRowName.replace(/\s/g, '');
  }

  const initialExpanded = React.useMemo(() => {
    return {
      expanded: {
        [expandedRowName]: true,
      },
    };
  }, [expandedRowName]);

  const { getTableProps, getTableBodyProps, headers, rows, prepareRow, state } =
    useTable<IAListTableRow>(
      {
        initialState: initialExpanded,
        manualSortBy: true,
        autoResetExpanded: false,
        ...useTableOptions,
      },
      /* table plugins */
      useSortBy,
      useExpanded,
    );

  return (
    <table {...getTableProps()} className={styles['table-main']}>
      <thead className={styles['table-header']}>
        <tr>
          {headers.map(
            (
              {
                getHeaderProps,
                getSortByToggleProps,
                isSorted,
                isSortedDesc,
                toggleSortBy,
                render,
                id,
              },
              headerIndex,
            ) => {
              return (
                <th
                  {...getHeaderProps(getSortByToggleProps())}
                  className={cx([styles['header-cell'], id])}
                  key={headerIndex}
                  onClick={() => {
                    const sortTerm = id as AssessmentSortOption;

                    const newSortDirection = isSortedDesc
                      ? SortDirection.ASCENDING
                      : SortDirection.DESCENDING;

                    toggleSortBy(!isSortedDesc);
                    onSortBy(sortTerm, newSortDirection);
                  }}
                >
                  {render('Header')}
                  <span
                    className={cx({
                      [styles['sort-icon']]: true,
                      [styles.sorted]: isSorted,
                      [styles['sorted-desc']]: isSorted && isSortedDesc,
                      [styles['sorted-asc']]: isSorted && !isSortedDesc,
                    })}
                  />
                </th>
              );
            },
          )}
        </tr>
      </thead>
      <tbody {...getTableBodyProps()} className={styles['table-body']}>
        {rows.map((row) => {
          prepareRow(row);
          const rowProps = row.getRowProps();
          const rowKey = row.id;
          return (
            <React.Fragment key={rowKey}>
              <tr
                {...rowProps}
                id={row.id}
                className={cx({
                  [styles['expanded-row']]: row.isExpanded,
                })}
              >
                {row.cells.map((cell, cellIndex) => {
                  return (
                    <td
                      {...cell.getCellProps()}
                      className={cx([styles['table-cell'], cell.column.id])}
                      key={cellIndex}
                    >
                      {cell.render('Cell')}
                    </td>
                  );
                })}
              </tr>
              {row.isExpanded ? (
                <tr className={styles['expanded-view']}>
                  <td
                    className={cx(
                      styles['expanded-view-container'],
                      'expanded-view',
                    )}
                    colSpan={100}
                  >
                    {renderSubRowContent({
                      state,
                      assessments: row.original.assessments || [],
                    })}
                  </td>
                </tr>
              ) : null}
            </React.Fragment>
          );
        })}
      </tbody>
    </table>
  );
};
