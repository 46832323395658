import { Auth0Provider } from '@auth0/auth0-react';
import { AppInsightsContext } from '@microsoft/applicationinsights-react-js';
import { StrictMode } from 'react';
import { render } from 'react-dom';
import { QueryClientProvider } from 'react-query';
import { BrowserRouter } from 'react-router-dom';
import 'react-toastify/dist/ReactToastify.css';
import 'styles/index.css';
import { App } from './app';
import { reactPlugin } from './appInsights';
import { ToastContainer } from './components/ToastContainer';
import { apiContracts } from './mockApi/contracts';
import reportWebVitals from './reportWebVitals';
import { pushFetchImpl } from './util/fetch';
import { queryClient } from './util/queryClientConfig';

const {
  REACT_APP_AUTH0_DOMAIN = '',
  REACT_APP_AUTH0_CLIENT_ID = '',
  REACT_APP_API_HOST = '',
} = process.env;

const renderApp = () => {
  render(
    <StrictMode>
      <Auth0Provider
        domain={REACT_APP_AUTH0_DOMAIN}
        clientId={REACT_APP_AUTH0_CLIENT_ID}
        audience={REACT_APP_API_HOST}
        redirectUri={window.location.origin}
        cacheLocation="localstorage"
      >
        <AppInsightsContext.Provider value={reactPlugin}>
          <QueryClientProvider client={queryClient}>
            <BrowserRouter>
              <App />
              <ToastContainer />
            </BrowserRouter>
          </QueryClientProvider>
        </AppInsightsContext.Provider>
      </Auth0Provider>
    </StrictMode>,
    document.getElementById('root'),
  );
};

/***************************************************************************************************************
 * We conditionally load the following dependencies so that they don't add to page weight in production builds *
 ***************************************************************************************************************/
const promises: Array<Promise<unknown>> = [];
if (process.env.REACT_APP_USE_MOCK_BACKEND === 'true') {
  const fetchPromise = import('lib/api-mocking/initFetchMock').then(
    (initFetchMock) => {
      // for development only, we want to use fetchMock for our fetch Implementation
      pushFetchImpl(initFetchMock.initFetchMockForDevelopment(apiContracts));
    },
  );
  promises.push(fetchPromise);
} else {
  pushFetchImpl(window.fetch);
}

Promise.all(promises).then(() => {
  renderApp();
});

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
