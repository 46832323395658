import { useMutation } from 'react-query';
import { toast } from 'react-toastify';
import { UserResponseModel } from 'types/api';
import {
  ContentType,
  useAuthenticatedFetch,
} from 'hooks/util/useAuthenticatedFetch';
import { queryClient } from 'util/queryClientConfig';

export const useCreateUser = () => {
  const fetch = useAuthenticatedFetch(ContentType.NONE);
  return useMutation(
    (createUser: FormData) =>
      fetch<UserResponseModel>(`${process.env.REACT_APP_API_HOST}/users`, {
        body: createUser,
        method: 'POST',
      }),
    {
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      onError: (error: any) => {
        toast.error(`Error creating profile. ${error.response._data.Message}`);
      },
      onSuccess: () => {
        toast.success('Profile created successfully.');
        queryClient.invalidateQueries(['users', 'userList']);
      },
    },
  );
};
