import { Error } from 'models/Error';
import { useQuery } from 'react-query';
import { toast } from 'react-toastify';
import { AssessmentGroup } from 'types/api';
import { useAuthenticatedFetch } from 'hooks/util/useAuthenticatedFetch';

export const useAssessmentCompare = (assessmentIds: string[] = []) => {
  const fetch = useAuthenticatedFetch();

  return useQuery<AssessmentGroup[], Error>(
    ['assessments', assessmentIds],
    () =>
      fetch(`${process.env.REACT_APP_API_HOST}/Assessments/Compare`, {
        params: {
          assessmentIds,
        },
      }),
    {
      enabled: !!assessmentIds.length,
      onError: () => {
        toast.error('Error fetching assessment.');
      },
    },
  );
};
