import { useState } from 'react';
import { useParams } from 'react-router-dom';
import { AssessmentType } from 'types/api';
import { useSharedAssessment } from 'hooks/query/useSharedAssessment';
import { BasketballAssessmentsDetail } from 'components/AssessmentDetail/BasketballAssessmentDetail';
import { SoccerAssessmentsDetail } from 'components/AssessmentDetail/SoccerAssessmentDetail';
import { Header } from 'components/Header';
import { Unauthorized } from './Unauthorized';

export const Share = () => {
  const { linkId, assessmentId } = useParams();
  const [password, setPassword] = useState<string>();
  const { data: assessmentWrapper, isError } = useSharedAssessment(
    linkId,
    assessmentId?.toLowerCase(),
    password,
  );

  if (!assessmentWrapper)
    return <Unauthorized isError={isError} onPasswordSubmit={setPassword} />;

  let single = false;

  if (assessmentWrapper.priorAssessments?.length === 0) {
    single = true;
  }

  const assessmentType = assessmentWrapper.currentAssessment?.assessmentType;

  return (
    <>
      <Header />
      {assessmentType === AssessmentType.BASKETBALL ? (
        <BasketballAssessmentsDetail
          assessmentWrapper={assessmentWrapper}
          single={single}
          password={password}
        />
      ) : (
        <SoccerAssessmentsDetail
          assessmentWrapper={assessmentWrapper}
          single={single}
          password={password}
        />
      )}
    </>
  );
};
