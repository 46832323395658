import { useEffect, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import {
  CreateOrUpdateOrganizationRequestModel,
  Organization,
  Role,
  User,
} from 'types/api';
import { useOrganizationById } from 'hooks/query/useOrganizationById';
import { useOrganizationContext } from 'hooks/util/useOrganizationContext';
import { Button, ButtonVariants } from 'components/Button';
import { TextField } from 'components/TextField';
import styles from './EditOrganizationForm.module.css';

interface IFormProps {
  onSubmit: (form: CreateOrUpdateOrganizationRequestModel) => void;
  organization: Organization | undefined;
}

type NonNullableUpdateOrganizationRequestModel = {
  [K in keyof CreateOrUpdateOrganizationRequestModel]: NonNullable<
    CreateOrUpdateOrganizationRequestModel[K]
  >;
};

export const EditOrganizationForm = ({
  organization,
  onSubmit,
}: IFormProps) => {
  const { contextRole } = useOrganizationContext();
  const { control, handleSubmit, watch } =
    useForm<NonNullableUpdateOrganizationRequestModel>({
      defaultValues: {
        organizationName: organization?.organizationName || undefined,
        city: organization?.city || '',
        state: organization?.state || '',
        country: organization?.country || '',
        primaryAdminId: organization?.primaryAdmin?.id || '',
      },
    });
  const [allAdmins, setAllAdmins] = useState<User[]>([]);
  const [formChangesDetected, setFormChangesDetected] =
    useState<boolean>(false);
  const { data: users } = useOrganizationById(organization?.id, true);

  useEffect(() => {
    const subscription = watch((value) => {
      setFormChangesDetected(!!value);
    });
    return () => subscription.unsubscribe();
  }, [watch]);

  useEffect(() => {
    // Collect all the users who are admins in this organization to populate the Primary Admin dropdown selector
    const collectAdmins: User[] = [];
    users?.members?.forEach((user) => {
      const rolesArray = user.organizationRoles;
      return rolesArray?.forEach((x) => {
        if (
          (x.role === Role.ORGANIZATION_ADMIN || x.role === Role.SUPER_ADMIN) &&
          x.organizationId === organization?.id
        ) {
          collectAdmins.push(user);
        }
      });
    });
    setAllAdmins(collectAdmins);
  }, [organization?.id, users?.members]);

  const onFormSubmit = (formData: CreateOrUpdateOrganizationRequestModel) => {
    setFormChangesDetected(false);
    onSubmit(formData);
  };

  return (
    <>
      <section className={styles['form-section']}>
        <form
          className={styles.form}
          noValidate
          onSubmit={handleSubmit(onFormSubmit)}
        >
          <fieldset>
            <div className={styles.flex}>
              <legend>General</legend>
              <Button
                variant={ButtonVariants.Primary}
                disabled={!formChangesDetected}
                type="submit"
              >
                Save
              </Button>
            </div>
            <div className={styles.fieldset}>
              {contextRole === Role.SUPER_ADMIN ? (
                <Controller
                  control={control}
                  name="organizationName"
                  render={({ field, fieldState }) => (
                    <TextField
                      {...field}
                      error={fieldState.error?.message}
                      label="Organization Name"
                    />
                  )}
                />
              ) : (
                <TextField
                  label="Organization Name"
                  value={
                    organization?.organizationName
                      ? organization.organizationName
                      : ''
                  }
                  className={styles.disabled}
                  readOnly
                />
              )}
            </div>
          </fieldset>
          <fieldset className={styles.fieldset}>
            <legend>Primary Admin</legend>
            <Controller
              control={control}
              name="primaryAdminId"
              render={({ field, fieldState }) => (
                <TextField
                  {...field}
                  error={fieldState.error?.message}
                  label="Primary Admin"
                  tagName="select"
                >
                  <option value="" />
                  {allAdmins &&
                    allAdmins.map((admin, i) => {
                      return (
                        <option key={`${admin.id}-${i}`} value={admin.id}>
                          {admin.firstName} {admin.lastName}
                        </option>
                      );
                    })}
                </TextField>
              )}
            />
          </fieldset>
          <fieldset className={styles.fieldset}>
            <legend>Location</legend>
            <Controller
              control={control}
              name="city"
              render={({ field, fieldState }) => (
                <TextField
                  {...field}
                  error={fieldState.error?.message}
                  label="City"
                />
              )}
            />
            <Controller
              control={control}
              name="state"
              render={({ field, fieldState }) => (
                <TextField
                  {...field}
                  error={fieldState.error?.message}
                  label="State"
                  tagName="select"
                >
                  <option value="" />
                  <option value="AL">Alabama</option>
                  <option value="AK">Alaska</option>
                  <option value="AZ">Arizona</option>
                  <option value="AR">Arkansas</option>
                  <option value="CA">California</option>
                  <option value="CO">Colorado</option>
                  <option value="CT">Connecticut</option>
                  <option value="DE">Delaware</option>
                  <option value="DC">District Of Columbia</option>
                  <option value="FL">Florida</option>
                  <option value="GA">Georgia</option>
                  <option value="HI">Hawaii</option>
                  <option value="ID">Idaho</option>
                  <option value="IL">Illinois</option>
                  <option value="IN">Indiana</option>
                  <option value="IA">Iowa</option>
                  <option value="KS">Kansas</option>
                  <option value="KY">Kentucky</option>
                  <option value="LA">Louisiana</option>
                  <option value="ME">Maine</option>
                  <option value="MD">Maryland</option>
                  <option value="MA">Massachusetts</option>
                  <option value="MI">Michigan</option>
                  <option value="MN">Minnesota</option>
                  <option value="MS">Mississippi</option>
                  <option value="MO">Missouri</option>
                  <option value="MT">Montana</option>
                  <option value="NE">Nebraska</option>
                  <option value="NV">Nevada</option>
                  <option value="NH">New Hampshire</option>
                  <option value="NJ">New Jersey</option>
                  <option value="NM">New Mexico</option>
                  <option value="NY">New York</option>
                  <option value="NC">North Carolina</option>
                  <option value="ND">North Dakota</option>
                  <option value="OH">Ohio</option>
                  <option value="OK">Oklahoma</option>
                  <option value="OR">Oregon</option>
                  <option value="PA">Pennsylvania</option>
                  <option value="RI">Rhode Island</option>
                  <option value="SC">South Carolina</option>
                  <option value="SD">South Dakota</option>
                  <option value="TN">Tennessee</option>
                  <option value="TX">Texas</option>
                  <option value="UT">Utah</option>
                  <option value="VT">Vermont</option>
                  <option value="VA">Virginia</option>
                  <option value="WA">Washington</option>
                  <option value="WV">West Virginia</option>
                  <option value="WI">Wisconsin</option>
                  <option value="WY">Wyoming</option>
                </TextField>
              )}
            />
            <Controller
              control={control}
              name="country"
              render={({ field, fieldState }) => (
                <TextField
                  {...field}
                  error={fieldState.error?.message}
                  label="Country"
                />
              )}
            />
          </fieldset>
        </form>
      </section>
    </>
  );
};
