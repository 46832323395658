import { Link } from 'react-router-dom';
import { BaseAssessment } from 'types/api';
import { Button, ButtonVariants } from 'components/Button';
import styles from './TrainingTargetsWarningModal.module.css';

interface ITrainingTargetsWarningModal {
  assessment: BaseAssessment;
  onAttachClick: () => void;
}

export const TrainingTargetsWarningModal = ({
  assessment,
  onAttachClick,
}: ITrainingTargetsWarningModal) => {
  return (
    <>
      <h2 className={styles.header}>Unassigned Training Targets</h2>
      <p className={styles.description}>
        It&apos;s recommended to assign Training Targets prior to attaching an
        assessment to an organization(s).
      </p>
      <div className={styles.assessment}>
        <span>{assessment.name}</span>
        <span>{assessment.date}</span>
        <Link to={`/assessments/${assessment.id}`} target="_blank">
          <Button
            variant={ButtonVariants.Secondary}
            className={styles['assign-btn']}
          >
            Assign
          </Button>
        </Link>
      </div>
      <div className={styles['attach-btn']}>
        <Button className={styles['attach-btn']} onClick={onAttachClick}>
          Attach
        </Button>
      </div>
    </>
  );
};
