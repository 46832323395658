import cx from 'classnames';
import { useEffect, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { Organization, Role, User } from 'types/api';
import { UpdateUserRoleRequestModel } from 'types/api/models/UpdateUserRoleRequestModel';
import { Button, ButtonVariants } from 'components/Button';
import { Loading } from 'components/Loading';
import { TextField } from 'components/TextField';
import styles from './StepTwoUpdateExistingUserForm.module.css';

interface IFormProps {
  isExistingUser: User;
  isLoading: boolean;
  onSubmit: (form: UpdateUserRoleRequestModel) => void;
  organization: Organization;
  setIsLoading: (value: boolean) => void;
}

type NonNullableUpdateUserRoleRequestModel = {
  [K in keyof UpdateUserRoleRequestModel]: NonNullable<
    UpdateUserRoleRequestModel[K]
  >;
};

export const StepTwoUpdateExistingUserForm = (props: IFormProps) => {
  const { isExistingUser, isLoading, organization, onSubmit, setIsLoading } =
    props;

  const existingUsersRole = isExistingUser?.organizationRoles?.find(
    (x) => x.organizationId === organization.id,
  )?.role;
  const [formChangesDetected, setFormChangesDetected] =
    useState<boolean>(false);
  const { control, handleSubmit, register, watch } =
    useForm<NonNullableUpdateUserRoleRequestModel>({
      defaultValues: {
        organizationId: organization.id || '',
        role: existingUsersRole || undefined,
      },
    });

  useEffect(() => {
    const subscription = watch((value) => {
      setFormChangesDetected(!!value);
    });
    return () => {
      subscription.unsubscribe();
    };
  }, [watch]);

  const onFormSubmit = (formData: UpdateUserRoleRequestModel) => {
    setIsLoading(true);
    setFormChangesDetected(false);
    onSubmit(formData);
  };

  return (
    <>
      <section className={styles['form-section']}>
        <form
          className={styles.form}
          noValidate
          onSubmit={handleSubmit(onFormSubmit)}
        >
          {isLoading ? (
            <div className={styles.loading}>
              <Loading />
            </div>
          ) : (
            <>
              <h2 className={styles['modal-title']}>
                Add User To Your Organization
              </h2>

              <fieldset className={styles.fieldset}>
                <legend>{organization.organizationName}</legend>
                <TextField
                  label="Organization Name"
                  value={
                    organization.organizationName
                      ? organization.organizationName
                      : ''
                  }
                  className={cx([styles['text-field'], styles.disabled])}
                  readOnly
                  required
                />
                <TextField
                  label="First Name"
                  value={isExistingUser.firstName || ''}
                  className={cx([styles['text-field'], styles.disabled])}
                  readOnly
                />
                <TextField
                  label="Last Name"
                  value={isExistingUser.lastName || ''}
                  className={cx([styles['text-field'], styles.disabled])}
                  readOnly
                />
                {existingUsersRole && (
                  <p className={styles['notification-text']}>
                    User already exists in this organization, and their current
                    role is below. You may update this user's role here.
                  </p>
                )}
                <Controller
                  control={control}
                  name="role"
                  render={({ field, fieldState }) => (
                    <TextField
                      {...field}
                      error={fieldState.error?.message}
                      className={styles['text-field']}
                      label="Role"
                      tagName="select"
                      required
                    >
                      <option value="" />
                      <option value={Role.SUPER_ADMIN}>Super Admin</option>
                      <option value={Role.ORGANIZATION_ADMIN}>
                        Administrator
                      </option>
                      <option value={Role.ORGANIZATION_STAFF}>Staff</option>
                    </TextField>
                  )}
                />
                <input {...register('organizationId')} type="hidden" />
              </fieldset>
              <div className={styles.buttons}>
                <Button
                  disabled={!formChangesDetected}
                  variant={ButtonVariants.Primary}
                  type="submit"
                >
                  {isLoading ? (
                    <div className={styles.loading}>
                      <Loading size={40} />
                    </div>
                  ) : (
                    <div>Save</div>
                  )}
                </Button>
              </div>
            </>
          )}
        </form>
      </section>
    </>
  );
};
