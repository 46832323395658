import { ReactComponent as InfoIcon } from 'assets/icons/info.svg';
import { ReactComponent as RadarIcon } from 'assets/icons/radar.svg';
import { ReactComponent as TableIcon } from 'assets/icons/table.svg';
import { useEffect, useState } from 'react';
import { BasketballAssessment } from 'types/api';
import { radarGraphLegendText } from 'util/radarGraphLegendText';
import { GraphTable } from 'components/GraphTable';
import { GraphTabs } from 'components/GraphTabs';
import { Radar, RadarData } from 'components/Radar';
import {
  accelDecelTableGraphTerms,
  infoTermsAccelDecel,
  radarLabels,
} from './AccelDecel';

interface AccelDecelCompareProps {
  assessments: BasketballAssessment[];
  className?: string;
}

export const AccelDecelCompare = (props: AccelDecelCompareProps) => {
  const { assessments, className } = props;
  const [radarData, setRadarData] = useState<RadarData[]>([]);

  useEffect(() => {
    const buildRadarData: RadarData[] = [];
    assessments.forEach((a: BasketballAssessment) => {
      if (
        !a.assessmentDetails ||
        !a.assessmentDetails.assessmentAccelerationDecelerationDetails
      ) {
        return;
      }
      const {
        percConcRelFF = 0,
        percLateralForceAvg = 0,
        percLoadRelFF = 0,
        percMaxKneeExtensionVelocityAvg = 0,
      } = a.assessmentDetails.assessmentAccelerationDecelerationDetails;

      const radarObject: RadarData = {
        name: radarGraphLegendText(a.name, a.date),
        value: [
          percConcRelFF,
          percMaxKneeExtensionVelocityAvg,
          percLoadRelFF,
          percLateralForceAvg,
        ],
      };
      buildRadarData.push(radarObject);
    });
    setRadarData(buildRadarData);
  }, [assessments]);

  const config = [
    {
      id: 'radarTab',
      name: 'Percentile',
      Icon: RadarIcon,
      content: <Radar data={radarData} indicator={radarLabels} />,
    },
    {
      id: 'tableTab',
      name: 'Test Results',
      Icon: TableIcon,
      content: (
        <GraphTable
          assessments={assessments}
          definitions={accelDecelTableGraphTerms}
        />
      ),
    },
    {
      id: 'infoTab',
      name: 'Terminology',
      Icon: InfoIcon,
      content: infoTermsAccelDecel,
    },
  ];

  return (
    <GraphTabs
      className={className}
      config={config}
      defaultTab="radarTab"
      heading="Acceleration / Deceleration"
    />
  );
};
