import { useCallback, useEffect, useRef, useState } from 'react';
import { useMediaQuery } from 'react-responsive';
import { StepTwoCreateUserForm } from 'views/Organization/CreateUser/StepTwoCreateUserForm';
import { Organization, User } from 'types/api';
import { UpdateUserRoleRequestModel } from 'types/api/models/UpdateUserRoleRequestModel';
import { useCreateUser } from 'hooks/mutation/useCreateUser';
import { useUpdateUserRole } from 'hooks/mutation/useUpdateUserRole';
import { queryClient } from 'util/queryClientConfig';
import { Button } from 'components/Button';
import { HandleClose, Modal } from 'components/Modal';
import styles from './CreateUser.module.css';
import { StepOneUserEmailForm } from './StepOneUserEmailForm';
import { StepTwoUpdateExistingUserForm } from './StepTwoUpdateExistingUserForm';

interface ICreateUserProps {
  organization: Organization;
}

export const CreateUser = (props: ICreateUserProps) => {
  const { organization } = props;
  const [isCreatingAccount, setIsCreatingAccount] = useState(false);
  const [step, setStep] = useState(1);
  const [newUserEmail, setNewUserEmail] = useState<string>();
  const [isExistingUser, setIsExistingUser] = useState<User | undefined>();
  const [isLoadingCreateUser, setIsLoadingCreateUser] =
    useState<boolean>(false);
  const [isLoadingUpdateRole, setIsLoadingUpdateRole] =
    useState<boolean>(false);
  const {
    mutate: mutateCreateUser,
    isSuccess: createUserIsSuccess,
    isError: createUserIsError,
  } = useCreateUser();
  const {
    mutate: mutateUserRole,
    isSuccess: userRoleIsSuccess,
    isError: userRoleIsError,
  } = useUpdateUserRole(isExistingUser?.id);
  const modalClose = useRef<HandleClose>(null);
  const isMobile = !useMediaQuery({
    query: '(min-width: 768px)',
  });

  const createNewUserFormSubmit = useCallback(
    (formData: FormData) => {
      mutateCreateUser(formData);
    },
    [mutateCreateUser],
  );

  const updateRoleFormSubmit = useCallback(
    (formData: UpdateUserRoleRequestModel) => {
      mutateUserRole(formData);
    },
    [mutateUserRole],
  );
  // If profile creation is successful, close modal.
  // If profile creation fails, the toast will show at the top of the screen and the modal will stay open.
  useEffect(() => {
    if (createUserIsSuccess || userRoleIsSuccess) {
      modalClose.current?.onClose();
      setIsCreatingAccount(false);
      if (createUserIsSuccess) {
        setIsLoadingCreateUser(false);
      }
      if (userRoleIsSuccess) {
        setIsLoadingUpdateRole(false);
      }
      queryClient.invalidateQueries('userList');
    } else if (createUserIsError) {
      setIsLoadingCreateUser(false);
    } else if (userRoleIsError) {
      setIsLoadingUpdateRole(false);
    }
  }, [
    createUserIsSuccess,
    createUserIsError,
    userRoleIsSuccess,
    userRoleIsError,
  ]);
  return (
    <div>
      <Button
        onClick={() => {
          setStep(1);
          setNewUserEmail(undefined);
          setIsCreatingAccount(true);
        }}
        className={styles['small-button']}
      >
        {isMobile ? <span>Create</span> : <span>Create Account</span>}
      </Button>
      <Modal isOpen={isCreatingAccount} onOpenChange={setIsCreatingAccount}>
        {step === 1 && (
          <StepOneUserEmailForm
            newUserEmail={newUserEmail}
            organization={organization}
            setStep={setStep}
            setNewUserEmail={setNewUserEmail}
            setIsExistingUser={setIsExistingUser}
          />
        )}
        {step > 1 &&
          (!isExistingUser ? (
            <StepTwoCreateUserForm
              isLoading={isLoadingCreateUser}
              newUserEmail={newUserEmail}
              onSubmit={createNewUserFormSubmit}
              organization={organization}
              setIsLoading={setIsLoadingCreateUser}
            />
          ) : (
            <StepTwoUpdateExistingUserForm
              isExistingUser={isExistingUser}
              isLoading={isLoadingUpdateRole}
              onSubmit={updateRoleFormSubmit}
              organization={organization}
              setIsLoading={setIsLoadingUpdateRole}
            />
          ))}
      </Modal>
    </div>
  );
};
