import * as Tabs from '@radix-ui/react-tabs';
import classNames from 'classnames';
import { Fragment, FunctionComponent, ReactNode } from 'react';
import styles from './GraphTabs.module.css';

interface GraphTabsConfig {
  Icon: FunctionComponent;
  content: ReactNode;
  id: string;
  name?: string;
}
export interface GraphTabsProps {
  className?: string;
  config: GraphTabsConfig[];
  defaultTab: string;
  headerClassName?: string;
  heading: string;
}

const GraphTabs = (props: GraphTabsProps) => {
  const { className, config, defaultTab, headerClassName, heading } = props;
  return (
    <Tabs.Root
      className={classNames(styles.container, className)}
      defaultValue={defaultTab}
      orientation="vertical"
    >
      {config.map(({ id, content, name }, i) => (
        <Fragment key={i}>
          <Tabs.Content asChild value={id}>
            <header className={classNames(styles.header, headerClassName)}>
              <h3>{heading}</h3>
              <p>{name}</p>
            </header>
          </Tabs.Content>
          <Tabs.Content asChild value={id}>
            <div className={styles.content}>{content}</div>
          </Tabs.Content>
        </Fragment>
      ))}
      <Tabs.List aria-label="tabs example" className={styles.triggers}>
        {config.map(({ id, Icon }, i) => {
          return (
            <Tabs.Trigger value={id} key={i}>
              <Icon />
            </Tabs.Trigger>
          );
        })}
      </Tabs.List>
    </Tabs.Root>
  );
};

export { GraphTabs };
