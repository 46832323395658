export const BigPlusContent = (props: { name: string }) => {
  const showName = props.name ? (
    <h2>{props.name} is a Bigs Plus</h2>
  ) : (
    <h2>Bigs Plus</h2>
  );
  return (
    <div>
      {showName}
      <p>
        While anthropometric measures tend to be the dominant physical traits of
        this group, Bigs Plus athletes pair impressive size with league-average
        vertical and lateral force production. 'Plus' size alongside adequate
        movement skills can lead to physical advantages for the athletes in this
        category.
      </p>
      <ul>
        <li>
          Generally, possess more impressive athletic skill set than the
          "Traditional Big".
        </li>
        <li>Vertical plane movement surpasses NBA average.</li>
        <li>Lateral plane explosiveness outpaces most NBA bigs.</li>
        <li>
          Lack consistent change of direction ability in the lateral plane
          relative to smaller players.
        </li>
      </ul>
      <h2>Bigs Plus</h2>
      <p>
        Athletes in the Bigs Plus cluster stick in the NBA at a rate higher than
        any of the other clusters studied in this analysis.
      </p>
    </div>
  );
};
