import { Role, UserResponseModel } from 'types/api';

export interface OrgAndRoleResponse {
  defaultOrgId: string | undefined;
  role: Role | undefined;
}

export const getDefaultOrgAndRole = (
  loggedInUserData: UserResponseModel | undefined,
): OrgAndRoleResponse | undefined => {
  let defaultOrgId;
  let role: Role | undefined;
  // Get a list of the organizations a user belongs to.
  // Select the first organization in the list as the default organization
  if (
    loggedInUserData &&
    loggedInUserData.organizationRoles &&
    loggedInUserData.organizationRoles?.length > 0
  ) {
    defaultOrgId = loggedInUserData.organizationRoles[0].organizationId;
    role = loggedInUserData.organizationRoles[0].role;
    if (loggedInUserData.isSuperAdmin) {
      role = Role.SUPER_ADMIN;
    }
    return {
      defaultOrgId,
      role,
    };
  } else {
    return {
      defaultOrgId: undefined,
      role: loggedInUserData?.isSuperAdmin ? Role.SUPER_ADMIN : undefined,
    };
  }
};
