import { useMutation } from 'react-query';
import { toast } from 'react-toastify';
import { AssignAssessmentRequestModel, BaseAssessment } from 'types/api';
import { useAuthenticatedFetch } from 'hooks/util/useAuthenticatedFetch';

export const useUpdateAssessmentOwner = () => {
  const fetch = useAuthenticatedFetch();
  return useMutation(
    (body: AssignAssessmentRequestModel) =>
      fetch<BaseAssessment[]>(
        `${process.env.REACT_APP_API_HOST}/assessments/owners`,
        {
          body,
          method: 'PUT',
        },
      ),
    {
      onError: () => {
        toast.error('Error mapping assessments to organizations.');
      },
      onSuccess: () => {
        toast.success('Assessments successfully attached to organizations.');
      },
    },
  );
};
