import classNames from 'classnames';
import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useBreakpoints } from 'hooks/util/useBreakpoints';
import { getRiskLevel } from 'util/assessment';
import { cumulativeYOffset } from 'util/dom';
import { Button, ButtonVariants } from 'components/Button';
import { Popover } from 'components/Popover';
import { InjuryRiskZones } from '..';
import { MechanicSlider } from './MechanicSlider';
import styles from './Zone.module.css';

interface MechanicsAttribute {
  label: string;
  value?: number;
}

interface ZoneProps {
  attributes: MechanicsAttribute[];
  containerRef?: React.RefObject<HTMLDivElement>;
  title: string;
  zone: InjuryRiskZones;
  readonly?: boolean;
}

export const Zone = (props: ZoneProps) => {
  const { attributes, containerRef, title, zone, readonly } = props;
  const [isPopoverOpen, setIsPopoverOpen] = useState(false);
  const [values, setValues] = useState(
    attributes.map((attribute) => attribute.value || 0),
  );
  const average = useMemo(
    () => values.reduce((acc, value) => (acc += value), 0) / values.length,
    [values],
  );
  const { isMobile } = useBreakpoints();

  const handleChange = useCallback(
    (index: number) => (value: number) => {
      setValues((prevValues) =>
        prevValues.map((prevValue, valueIndex) => {
          return valueIndex === index ? value : prevValue;
        }),
      );
    },
    [],
  );

  const handleReset = useCallback(() => {
    setValues(attributes.map((attribute) => attribute.value || 0));
  }, [attributes]);
  const triggerRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    // Scroll near active trigger on mobile to avoid overlap
    if (isPopoverOpen && isMobile) {
      if (
        triggerRef?.current &&
        containerRef?.current &&
        document.scrollingElement
      ) {
        const y = cumulativeYOffset(triggerRef.current);
        document.scrollingElement.scrollTop =
          y - containerRef.current.clientHeight / 2;
      }
    }
  }, [isPopoverOpen, containerRef, isMobile]);

  return !readonly ? (
    <Popover
      contentClassName={styles.popover}
      isOpen={isPopoverOpen}
      onOpenChange={setIsPopoverOpen}
      sideOffset={10}
      triggerClassName={classNames(
        styles.zone,
        styles[zone],
        styles[getRiskLevel(average)],
        { [styles.open]: isPopoverOpen },
      )}
      trigger={<div ref={triggerRef} />}
    >
      <header className={styles.header}>
        <h3>{title}</h3>
        <p>Expect no more than 10% improvement per training cycle.</p>
      </header>
      <div className={styles.grid}>
        <div className={styles.labels}>
          {attributes.map((attribute, index) => (
            <span key={index}>{attribute.label}</span>
          ))}
        </div>
        <div className={styles.sliders}>
          {values.map((value, index) => (
            <MechanicSlider
              key={index}
              defaultValue={attributes[index]?.value || 0}
              onChange={handleChange(index)}
              value={value}
            />
          ))}
          <span className={classNames(styles.number, styles['value-0'])}>
            0
          </span>
          <span className={classNames(styles.number, styles['value-25'])}>
            25
          </span>
          <span className={classNames(styles.number, styles['value-50'])}>
            50
          </span>
          <span className={classNames(styles.number, styles['value-75'])}>
            75
          </span>
          <span className={classNames(styles.number, styles['value-100'])}>
            100
          </span>
        </div>
      </div>
      <div className={styles.buttons}>
        <Button
          variant={ButtonVariants.Primary}
          onClick={handleReset}
          type="button"
        >
          Reset
        </Button>
      </div>
    </Popover>
  ) : (
    <div
      className={`${styles.zone} ${styles[zone]} 
      ${styles[getRiskLevel(average)]} ${styles.readonly}`}
    />
  );
};
