import { PropsWithChildren, useRef, useState } from 'react';
import { useMatch } from 'react-router-dom';
import { BaseAssessment, Role } from 'types/api';
import { useOrganizationContext } from 'hooks/util/useOrganizationContext';
import { Button, ButtonVariants } from 'components/Button';
import { HandleClose, Modal } from 'components/Modal';
import { Athlete } from './Athlete';
import styles from './CompareAthleticism.module.css';
import { FormHandle, SelectionModal } from './SelectionModal';

interface ICompareAthleticismProps {
  priorAssessments?: BaseAssessment[] | null | undefined;
  assessments: BaseAssessment[];
  currentAssessment: BaseAssessment;
  comparedAssessments: BaseAssessment[];
  password?: string;
  updateComparedAssessments: (value: string[][]) => void;
  resetCharts: (value: BaseAssessment[]) => void;
}

export const CompareAthleticism = (
  props: PropsWithChildren<ICompareAthleticismProps>,
) => {
  const {
    priorAssessments,
    comparedAssessments,
    currentAssessment,
    resetCharts,
    updateComparedAssessments,
    password,
  } = props;
  const isShared = useMatch('/share/:linkId/:assessmentId');
  const [isComparing, setIsComparing] = useState(false);
  const [
    selectedAssessmentsForComparison,
    setSelectedAssessmentsForComparison,
  ] = useState<string[][]>([]);
  const { contextRole } = useOrganizationContext();
  const modalClose = useRef<HandleClose>(null);
  const formHandle = useRef<FormHandle>(null);

  const reset = () => {
    resetCharts([]);
    setSelectedAssessmentsForComparison([]);
  };

  const handleAssessmentsToCompare = (assessments: string[][]) => {
    setSelectedAssessmentsForComparison(assessments);
    updateComparedAssessments(assessments);
  };

  const handleSubmit = () => {
    formHandle.current?.submit();
    modalClose.current?.onClose();
    setIsComparing(false);
  };

  return (
    <div className={styles['button-container']}>
      <>
        {isShared && !priorAssessments?.length ? null : (
          <button
            onClick={() => {
              setIsComparing(true);
            }}
            className={styles.button}
          >
            Compare Athleticism
          </button>
        )}
        {selectedAssessmentsForComparison.length > 0 && (
          <Button
            type="reset"
            variant={ButtonVariants.Tertiary}
            onClick={reset}
            className={styles['reset-button']}
          >
            Reset
          </Button>
        )}
        <Modal
          isOpen={isComparing}
          onOpenChange={setIsComparing}
          title="Select Up to 3 Assessments"
          classes={[styles.modal]}
          ref={modalClose}
        >
          {(() => {
            if (contextRole === Role.ORGANIZATION_MEMBER) {
              return <Athlete {...props} />;
            } else {
              return (
                <div>
                  <SelectionModal
                    currentAssessment={currentAssessment}
                    comparedAssessments={comparedAssessments}
                    onSubmit={handleSubmit}
                    ref={formHandle}
                    setComparedAssessments={handleAssessmentsToCompare}
                    password={password}
                  />
                </div>
              );
            }
          })()}
        </Modal>
      </>
    </div>
  );
};
