import { BaseAssessment } from 'types/api';
import { GraphTabs } from 'components/GraphTabs';
import styles from './Mechanics.module.css';
import { useConfig } from './useConfig';
import { useRightKneeData } from './useRightKneeData';

interface RightKneeProps {
  assessments: BaseAssessment[];
  className?: string;
}

export const RightKneeCompare = (props: RightKneeProps) => {
  const { assessments, className } = props;
  const data = useRightKneeData(assessments);
  const config = useConfig('Right Knee Mechanics', assessments, data);
  return (
    <GraphTabs
      className={className}
      config={config}
      defaultTab="lineTab"
      headerClassName={styles.header}
      heading="Right Knee Mechanics"
    />
  );
};
