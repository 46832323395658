import { Error } from 'models/Error';
import { useQuery } from 'react-query';
import { toast } from 'react-toastify';
import { OrganizationResponseModelPaginatedList } from 'types/api';
import {
  ContentType,
  useAuthenticatedFetch,
} from 'hooks/util/useAuthenticatedFetch';

export const useOrganizationList = (
  removeOrganizationHeader = false,
  enabled = true,
  { page = 1 },
) => {
  const fetch = useAuthenticatedFetch(
    removeOrganizationHeader
      ? ContentType.NO_ORGANIZATION_HEADER
      : ContentType.APPLICATIONJSON,
  );
  return useQuery<OrganizationResponseModelPaginatedList, Error>(
    ['organizationList', { page }],
    () =>
      fetch(
        `${process.env.REACT_APP_API_HOST}/Organizations?pageIndex=${page}`,
      ),
    {
      enabled,
      keepPreviousData: true,
      onError: () => {
        toast.error('Error fetching organizations.');
      },
    },
  );
};
