import { Error } from 'models/Error';
import { useQuery } from 'react-query';
import { toast } from 'react-toastify';
import { OrganizationResponseModel } from 'types/api';
import {
  ContentType,
  useAuthenticatedFetch,
} from 'hooks/util/useAuthenticatedFetch';

export const useOrganizationById = (id?: string, includeUsers = false) => {
  const fetch = useAuthenticatedFetch(ContentType.NO_ORGANIZATION_HEADER);

  return useQuery<OrganizationResponseModel, Error>(
    ['organization', id],
    () =>
      fetch(
        `${process.env.REACT_APP_API_HOST}/Organizations/${id}?includeUsers=${includeUsers}`,
      ),
    {
      enabled: !!id,
      onError: () => {
        toast.error('Error fetching organization.');
      },
    },
  );
};
