import { useMemo } from 'react';
import {
  AssessmentType,
  BasketballAssessment,
  BasketballAssessmentDetails,
  SoccerAssessment,
  SoccerAssessmentDetails,
} from 'types/api';
import { formatDate } from 'util/formatDate';
import { labels } from 'util/graphConstants';
import { unitsOfMeasure } from 'util/unitsOfMeasure';
import { IDefinitions } from 'components/GraphTable';
import { RadarData } from 'components/Radar';
import { soccerLowBackTableGraphTerms } from './SoccerTableGraphTerms';
import { ConfigData } from './useConfig';

const {
  /* basketball */
  impactRawL,
  impactRawR,
  impulseAsym,
  /* soccer */
  ankleDorsiflexionAverage,
  deltaHipFlexionAverage,
  trunkStabilityL,
  trunkStabilityR,
} = labels;

const basketballDefinitions: IDefinitions[] = [
  {
    key: 'assessmentDetails.assessmentBasketballMechanicsSummary.deltaHipFlexionLeft',
    unit: unitsOfMeasure('flexion'),
    name: deltaHipFlexionAverage,
  },
  {
    key: 'assessmentDetails.assessmentBasketballMechanicsSummary.impact2LeftRaw',
    unit: unitsOfMeasure('impact'),
    name: impactRawL,
  },
  {
    key: 'assessmentDetails.assessmentBasketballMechanicsSummary.impact2RightRaw',
    unit: unitsOfMeasure('impact'),
    name: impactRawR,
  },
  {
    key: 'assessmentDetails.assessmentBasketballMechanicsSummary.impulseAsymmetry',
    unit: unitsOfMeasure('impulse'),
    name: impulseAsym,
    formatter: (value: number, unit?: string) => {
      const label = value < 0 ? 'L > R' : 'R > L';
      return `${Math.abs(value)} ${unit} ${label}`;
    },
  },
];

export const useLowBackData = (
  assessments: Partial<SoccerAssessment | BasketballAssessment>[],
): ConfigData => {
  return useMemo(() => {
    const chartData: Array<number>[] = [];
    const radarData: RadarData[] = [];
    let barLabels: Array<string> = [];
    let definitions: IDefinitions[] = [];
    let terms: string[] = [];
    const dates: string[] = assessments.map((x) =>
      formatDate(x.date).toString(),
    );

    let lineData: { name: string; type: string; data: number[] }[];
    const [first] = assessments;
    switch (first?.assessmentType) {
      case AssessmentType.SOCCER:
        terms = ['activeDorsiflexion', 'deltaHipFlex', 'trunkStability'];
        lineData = [
          {
            name: 'Dorsal Flex',
            type: 'line',
            data: [],
          },
          {
            name: 'Delta Hip Flex',
            type: 'line',
            data: [],
          },
          {
            name: 'L - Trunk Stability',
            type: 'line',
            data: [],
          },
          {
            name: 'R - Trunk Stability',
            type: 'line',
            data: [],
          },
        ];
        assessments?.forEach((assessment) => {
          const details =
            assessment?.assessmentDetails as SoccerAssessmentDetails;

          const {
            percAnkleDorsiflexionAverage = 0,
            percDeltaHipFlexionAverage = 0,
            percLeftTrunkStability = 0,
            percRightTrunkStability = 0,
          } = details?.assessmentSoccerMechanicsSummary || {};
          let values = [];

          values = [
            percAnkleDorsiflexionAverage,
            percDeltaHipFlexionAverage,
            percLeftTrunkStability,
            percRightTrunkStability,
          ];
          chartData.push(values);

          barLabels = [
            ankleDorsiflexionAverage,
            deltaHipFlexionAverage,
            trunkStabilityL,
            trunkStabilityR,
          ];

          definitions = soccerLowBackTableGraphTerms;

          values.forEach((value, index) => lineData[index].data.push(value));
        });
        definitions = definitions.map((term) => ({
          ...term,
          name: term?.name,
        }));
        break;
      case AssessmentType.BASKETBALL:
      default:
        terms = ['deltaHipFlex', 'netImpact2', 'impulseAsym'];
        lineData = [
          {
            name: 'Delta Hip Flex',
            type: 'line',
            data: [],
          },
          {
            name: 'Impact 2 (L)',
            type: 'line',
            data: [],
          },
          {
            name: 'Impact 2 (R)',
            type: 'line',
            data: [],
          },
          {
            name: 'Impulse Asym',
            type: 'line',
            data: [],
          },
        ];
        definitions = basketballDefinitions;
        barLabels = [
          deltaHipFlexionAverage,
          impactRawL,
          impactRawR,
          impulseAsym,
        ];
        assessments?.forEach((assessment) => {
          const details =
            assessment?.assessmentDetails as BasketballAssessmentDetails;

          const {
            percDeltaHipFlexionAverage = 0,
            percImpact2LeftRaw = 0,
            percImpact2RightRaw = 0,
            percImpulseAsymmetry = 0,
          } = details?.assessmentBasketballMechanicsSummary || {};
          const values = [
            percDeltaHipFlexionAverage,
            percImpact2LeftRaw,
            percImpact2RightRaw,
            percImpulseAsymmetry,
          ];
          chartData.push(values);
          values.forEach((value, index) => lineData[index].data.push(value));
        });
    }
    return {
      chartData,
      radarData,
      barLabels,
      definitions,
      dates,
      lineData,
      terms,
    };
  }, [assessments]);
};
