import { ReactComponent as InfoIcon } from 'assets/icons/info.svg';
import { ReactComponent as RadarIcon } from 'assets/icons/radar.svg';
import { ReactComponent as TableIcon } from 'assets/icons/table.svg';
import { ReactComponent as VideoIcon } from 'assets/icons/video.svg';
import { BasketballAssessment } from 'types/api';
import { labels } from 'util/graphConstants';
import { unitsOfMeasure } from 'util/unitsOfMeasure';
import { GraphInfo } from 'components/GraphInfo';
import { GraphTable } from 'components/GraphTable';
import { GraphTabs } from 'components/GraphTabs';
import { Radar } from 'components/Radar';
import { Video } from 'components/Video';

interface VertDropJumpProps {
  assessment: BasketballAssessment;
  className?: string;
}

interface ITerms {
  key: string;
  unit?: string;
  name?: string;
}

const {
  netRelConcForce,
  kneeExtAccel,
  kneeExtVel,
  netImpact2L,
  netImpact1,
  netImpact2R,
} = labels;

export const vertDropJumpTableGraphTerms: ITerms[] = [
  {
    key: 'assessmentDetails.assessmentDropJumpSummary.netRelativeConcentricForce',
    unit: unitsOfMeasure('force'),
    name: netRelConcForce,
  },
  {
    key: 'assessmentDetails.assessmentDropJumpSummary.maxKneeExtensionAccelerationAvg',
    unit: unitsOfMeasure('acceleration'),
    name: kneeExtAccel,
  },
  {
    key: 'assessmentDetails.assessmentDropJumpSummary.maxKneeExtensionVelocityAvg',
    unit: unitsOfMeasure('velocity'),
    name: kneeExtVel,
  },
  {
    key: 'assessmentDetails.assessmentDropJumpSummary.impact1Avg',
    unit: unitsOfMeasure('impact'),
    name: netImpact1,
  },
  {
    key: 'assessmentDetails.assessmentDropJumpSummary.impact2Left',
    unit: unitsOfMeasure('impact'),
    name: netImpact2L,
  },
  {
    key: 'assessmentDetails.assessmentDropJumpSummary.impact2Right',
    unit: unitsOfMeasure('impact'),
    name: netImpact2R,
  },
];

export const radarLabels = [
  { max: 100, name: netRelConcForce },
  { max: 100, name: kneeExtAccel },
  { max: 100, name: netImpact2L },
  { max: 100, name: netImpact1 },
  { max: 100, name: netImpact2R },
  { max: 100, name: kneeExtVel },
];

export const infoTermsVertDrop = (
  <GraphInfo
    terms={[
      'netRelConcentricForce',
      'kneeExtAccel',
      'kneeExtVel',
      'netImp1',
      'netImpact2',
    ]}
  />
);

export const VertDropJump = (props: VertDropJumpProps) => {
  const { assessment, className } = props;
  const {
    percNetRelConcForce = 0,
    percMaxKneeExtensionVelocityAvg = 0,
    percNetImpact2RightRaw = 0,
    percNetImpact1Avg = 0,
    percNetImpact2LeftRaw = 0,
    percMaxKneeExtensionAccelerationAvg = 0,
  } = assessment?.assessmentDetails?.assessmentDropJumpSummary || {};

  const config = [
    {
      id: 'radarTab',
      name: 'Percentile',
      Icon: RadarIcon,
      content: (
        <Radar
          data={[
            {
              value: [
                percNetRelConcForce,
                percMaxKneeExtensionAccelerationAvg,
                percNetImpact2LeftRaw,
                percNetImpact1Avg,
                percNetImpact2RightRaw,
                percMaxKneeExtensionVelocityAvg,
              ],
            },
          ]}
          indicator={radarLabels}
        />
      ),
    },
    {
      id: 'tableTab',
      name: 'Test Results',
      Icon: TableIcon,
      content: (
        <GraphTable
          assessments={[assessment]}
          definitions={vertDropJumpTableGraphTerms}
        />
      ),
    },
    {
      id: 'infoTab',
      name: 'Terminology',
      Icon: InfoIcon,
      content: infoTermsVertDrop,
    },
  ];

  const videos = assessment?.videos?.filter(
    (video) => video.exercise === 'DropJump',
  );
  if (videos?.length) {
    config.push({
      id: 'videoTab',
      name: 'Video',
      Icon: VideoIcon,
      content: <Video videos={videos} />,
    });
  }

  return (
    <GraphTabs
      className={className}
      config={config}
      defaultTab="radarTab"
      heading="Vertical: Drop Jump"
    />
  );
};
