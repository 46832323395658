import { useState } from 'react';
import { Carousel } from 'react-responsive-carousel';
import 'react-responsive-carousel/lib/styles/carousel.min.css';
import { Link } from 'react-router-dom';
import { useAssessmentCompare } from 'hooks/query/useAssessmentCompare';
import { useBreakpoints } from 'hooks/util/useBreakpoints';
import { useQueryParam } from 'hooks/util/useQueryParam';
import { Loading } from 'components/Loading';
import { ComparisonCard } from './Card';
import styles from './Comparison.module.css';

export enum IComparisonGraphType {
  VERTICAL = 'vertical',
  LATERAL = 'lateral',
  LINEAR = 'linear',
  ACCEL = 'accel',
}

const AssessmentsComparison = () => {
  const [metric, setMetric] = useState<IComparisonGraphType>(
    IComparisonGraphType.LATERAL,
  );
  const { isMobile } = useBreakpoints();
  const [assessmentIds] = useQueryParam('assessmentIds');

  const {
    isLoading: compareIsLoading,
    error: compareError,
    data: items,
  } = useAssessmentCompare(assessmentIds?.split(','));

  if (compareIsLoading) <Loading />;

  if (compareError) {
    return <div>{compareError?.message}</div>;
  }
  if (!items) {
    return null;
  }
  return (
    <>
      <div className={styles.links}>
        <Link to="/assessments" className={styles.dashboard}>
          Dashboard
        </Link>
      </div>
      {isMobile ? (
        <div>
          <Carousel
            showIndicators
            infiniteLoop
            swipeable
            showThumbs={false}
            showArrows={false}
            showStatus={false}
            swipeScrollTolerance={100}
            preventMovementUntilSwipeScrollTolerance
          >
            {items?.map((item, i) => {
              return (
                <ComparisonCard
                  item={item}
                  key={i}
                  index={i}
                  metric={metric}
                  setMetric={setMetric}
                />
              );
            })}
          </Carousel>
        </div>
      ) : (
        <div className={styles.grid}>
          {items?.map((item, i) => {
            return (
              <ComparisonCard
                item={item}
                key={i}
                index={i}
                metric={metric}
                setMetric={setMetric}
              />
            );
          })}
        </div>
      )}
    </>
  );
};

export { AssessmentsComparison };
