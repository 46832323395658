import classNames from 'classnames';
import { useState } from 'react';
import { Link } from 'react-router-dom';
import {
  AssessmentGroup,
  AssessmentType,
  BasketballAssessment,
  SoccerAssessment,
} from 'types/api';
import { formatDate } from 'util/formatDate';
import { tooltipText } from 'util/tooltipText';
import { trendClassName } from 'util/trendClassName';
import { trimDecimals } from 'util/trimDecimals';
import { LargeScore } from 'components/LargeScore';
import { Radar } from 'components/Radar';
import { Tooltip } from 'components/Tooltip';
import { IComparisonGraphType } from '..';
import { useComparisonData } from '../useComparisonData';
import { useComparisonGraphData } from '../useComparisonGraphData';
import styles from './Card.module.css';

interface ComparisonCardProps {
  item: AssessmentGroup;
  index: number;
  metric: IComparisonGraphType;
  setMetric: (metric: IComparisonGraphType) => void;
}

const ComparisonCard = ({
  item,
  metric,
  setMetric,
  index,
}: ComparisonCardProps) => {
  /* eslint-disable-next-line @typescript-eslint/no-explicit-any */
  const { assessments } = item as any;
  const [assessmentId, setAssessmentId] = useState(assessments[0]?.id);
  const [graphView, setGraphView] = useState(false);
  const assessment = assessments?.find(
    (assessment: SoccerAssessment | BasketballAssessment) =>
      assessment.id === assessmentId,
  );

  const sportType = assessment?.assessmentType;

  const { compositeScore, vertKneeAccAvg, vertKneeVelAvg, linearScore } =
    useComparisonData(assessments, assessment);

  const lateralScore =
    assessment.assessmentDetails[
      'assessmentOverallPerformanceFactors' ||
        'assessmentSoccerVerticalLateralAbility'
    ]?.lateralPercentile;

  const verticalScore =
    assessment.assessmentDetails.assessmentOverallPerformanceFactors
      ?.verticalPercentile;

  const accelDecelScore =
    ((assessment.assessmentDetails.assessmentOverallPerformanceFactors
      ?.accelerationPercentile || 0) +
      (assessment.assessmentDetails.assessmentOverallPerformanceFactors
        ?.decelerationPercentile || 0)) /
    2;

  const { mechanicsScore, athleticismScore } =
    assessment.assessmentDetails.assessmentSummary;

  const graphData = useComparisonGraphData(
    metric,
    assessment,
    vertKneeVelAvg,
    vertKneeAccAvg,
  );

  const scoreTitle = () => {
    if (metric === IComparisonGraphType.VERTICAL) {
      return 'Vertical score calculated';
    }
    if (metric === IComparisonGraphType.LATERAL) {
      return 'Lateral score calculated';
    }
    if (metric === IComparisonGraphType.LINEAR) {
      return 'Linear score calculated';
    }
    if (metric === IComparisonGraphType.ACCEL) {
      return 'Accel/Decel score calculated';
    }
    return '';
  };

  return (
    <div className={classNames(styles.container, styles[`container-${index}`])}>
      {sportType === AssessmentType.BASKETBALL && compositeScore && (
        <>
          <p
            className={classNames(styles.composite, {
              'vis-hide': compositeScore === Infinity,
            })}
          >
            <span className={styles.text}>
              <Tooltip
                triggerText="Composite Score"
                tooltipText={tooltipText.CompositeScore}
              />
            </span>
            <span
              className={classNames([
                styles[trendClassName(compositeScore)],
                styles[`trend-icon`],
              ])}
            />
            <span className={styles.unit}>{trimDecimals(compositeScore)}%</span>
          </p>
          <div className={styles.flex}>
            <div className={styles.score}>
              <LargeScore
                title="Athleticism Score"
                score={athleticismScore || 0}
                scoreSizeMedium
                delta={0}
                tooltip={tooltipText.AthleticismScore as string}
              />
            </div>
            <div className={styles.score}>
              <LargeScore
                title="Mechanics Score"
                score={mechanicsScore || 0}
                scoreSizeMedium
                delta={0}
                tooltip={tooltipText.MechanicsScore as string}
              />
            </div>
          </div>
        </>
      )}
      <div className={styles.name}>
        <div>
          <h1>{assessment?.name}</h1>
          {sportType === AssessmentType.BASKETBALL ? (
            <Tooltip
              triggerText={`${assessment?.position} / ${assessment?.archetype}`}
              tooltipText={tooltipText.Position}
            />
          ) : (
            <Tooltip
              triggerText={assessment?.position}
              tooltipText={tooltipText.Position}
            />
          )}
        </div>
        {item?.assessments && item?.assessments?.length > 1 ? (
          <select
            onChange={(event) => setAssessmentId(event.target.value)}
            name="assessment"
            id="date"
            value={assessmentId}
          >
            {item?.assessments?.map((assessment) => (
              <option key={assessment.id} value={assessment.id}>
                {formatDate(assessment?.date)}
              </option>
            ))}
          </select>
        ) : (
          <span className={styles.date}>{formatDate(assessment?.date)}</span>
        )}
      </div>
      <div className={styles.scores}>
        <button
          onClick={() => setMetric(IComparisonGraphType.LATERAL)}
          className={classNames(styles['text-score'], {
            [styles.active]: metric === IComparisonGraphType.LATERAL,
          })}
        >
          <Tooltip
            triggerText="Lateral Score"
            tooltipText={tooltipText.LateralScore}
          />
          <p>{lateralScore}</p>
        </button>
        {sportType === AssessmentType.SOCCER && (
          <>
            <button
              onClick={() => setMetric(IComparisonGraphType.LINEAR)}
              className={classNames(styles['text-score'], {
                [styles.active]: metric === IComparisonGraphType.LINEAR,
              })}
            >
              <Tooltip
                triggerText="Linear Score"
                tooltipText={tooltipText.LinearScore}
              />
              <p>{linearScore}</p>
            </button>
          </>
        )}
        {sportType === AssessmentType.BASKETBALL && (
          <>
            <button
              onClick={() => setMetric(IComparisonGraphType.VERTICAL)}
              className={classNames(styles['text-score'], {
                [styles.active]: metric === IComparisonGraphType.VERTICAL,
              })}
            >
              <Tooltip
                triggerText="Vertical Score"
                tooltipText={tooltipText.VerticalScore}
              />
              <p> {verticalScore}</p>
            </button>
            <button
              onClick={() => setMetric(IComparisonGraphType.ACCEL)}
              className={classNames(styles['text-score'], {
                [styles.active]: metric === IComparisonGraphType.ACCEL,
              })}
            >
              <Tooltip
                triggerText="Accel Decel Score"
                tooltipText={tooltipText.AccelDecelScore}
              />
              <p>{accelDecelScore}</p>
            </button>
          </>
        )}
      </div>
      <div className={styles['calc-score']}>
        <div className={styles['calc-title']}>
          <span className={styles['score-title']}>{scoreTitle()}</span>
          {!graphView ? (
            <span
              className={styles['graph-link']}
              onClick={() => setGraphView(true)}
            >
              Graph
            </span>
          ) : (
            <span
              className={styles['graph-link']}
              onClick={() => setGraphView(false)}
            >
              Table
            </span>
          )}
        </div>
        {!graphView ? (
          <div className={styles['bar-graph-container']}>
            {graphData?.map((row, i) => {
              return (
                <div className={styles['bar-row']} key={i}>
                  <div
                    style={{
                      width: `${row.value}%`,
                    }}
                    className={styles.bar}
                  />
                  <p>{row.value}</p>
                  <Tooltip triggerText={row.label} tooltipText={row.tooltip} />
                </div>
              );
            })}
          </div>
        ) : (
          <Radar
            data={[
              {
                itemStyle: {
                  color:
                    index === 1
                      ? 'rgb(110 240 160 / 50%)'
                      : index === 2
                      ? 'rgb(255 153 72 / 50%)'
                      : index === 3
                      ? 'rgb(84 255 242 / 50%)'
                      : 'rgb(84 166 254 / 50%)',
                },
                value: graphData?.map((row) => row.value || 0) || [],
              },
            ]}
            indicator={graphData?.map((row) => ({
              max: 100,
              name: row.label,
            }))}
          />
        )}
      </div>
      <div className={styles['button-container']}>
        <Link to={`/assessments/${assessmentId}`}>View Assessment</Link>
      </div>
    </div>
  );
};

export { ComparisonCard };
