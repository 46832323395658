import * as Popover from '@radix-ui/react-popover';
import cx from 'classnames';
import { useState } from 'react';
import { tooltipText } from 'util/tooltipText';
import { trendClassName } from 'util/trendClassName';
import tooltipStyles from 'components/Tooltip/Tooltip.module.css';
import styles from './LargeScore.module.css';

interface LargeScoreProps {
  title: string;
  score: number;
  scoreSizeMedium?: boolean;
  delta: number;
  tooltip: string;
}

export const LargeScore = ({
  title,
  score,
  scoreSizeMedium,
  delta,
  tooltip,
}: LargeScoreProps) => {
  const [isTitlePopoverVisible, setIsTitlePopoverVisible] =
    useState<boolean>(false);
  const [isDeltaPopoverVisible, setIsDeltaPopoverVisible] =
    useState<boolean>(false);

  const handleTitleMouseEnter = () => setIsTitlePopoverVisible(true);
  const handleTitleMouseOut = () => setIsTitlePopoverVisible(false);
  const handleDeltaMouseEnter = () => setIsDeltaPopoverVisible(true);
  const handleDeltaMouseOut = () => setIsDeltaPopoverVisible(false);

  return (
    <div>
      <Popover.Root key="popover-title" open={isTitlePopoverVisible}>
        <Popover.Trigger asChild>
          <dt
            className={styles.title}
            onMouseEnter={handleTitleMouseEnter}
            onMouseOut={handleTitleMouseOut}
            onTouchStart={handleTitleMouseEnter}
            onTouchEnd={handleTitleMouseOut}
          >
            {title}
          </dt>
        </Popover.Trigger>
        <Popover.Content
          className={`${tooltipStyles.tooltip}`}
          sideOffset={12}
          side="top"
        >
          <Popover.Arrow className={tooltipStyles.arrow} />
          <small>{tooltip}</small>
        </Popover.Content>
      </Popover.Root>
      <dd className={scoreSizeMedium ? styles['med-score'] : styles.score}>
        {Math.round(score)}
        {delta !== 0 && (
          <Popover.Root key="popover-delta" open={isDeltaPopoverVisible}>
            <Popover.Trigger asChild>
              <span
                className={cx([
                  styles[trendClassName(delta)],
                  styles[`trend-icon`],
                ])}
                onMouseEnter={handleDeltaMouseEnter}
                onMouseOut={handleDeltaMouseOut}
                onTouchStart={handleDeltaMouseEnter}
                onTouchEnd={handleDeltaMouseOut}
              />
            </Popover.Trigger>
            <Popover.Content
              className={`${tooltipStyles.tooltip}`}
              sideOffset={12}
              side="top"
            >
              <Popover.Arrow className={tooltipStyles.arrow} />
              <small>{tooltipText.PercentageChange}</small>
            </Popover.Content>
          </Popover.Root>
        )}
      </dd>
    </div>
  );
};
