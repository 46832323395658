import { BaseAssessment } from 'types/api';
import { GraphTabs } from 'components/GraphTabs';
import styles from './Mechanics.module.css';
import { useConfig } from './useConfig';
import { useLeftKneeData } from './useLeftKneeData';

interface Props {
  assessments: BaseAssessment[];
  className?: string;
}

export const LeftKneeCompare = (props: Props) => {
  const { assessments, className } = props;
  const data = useLeftKneeData(assessments);
  const config = useConfig('Left Knee Mechanics', assessments, data);
  return (
    <GraphTabs
      className={className}
      config={config}
      defaultTab="lineTab"
      headerClassName={styles.header}
      heading="Left Knee Mechanics"
    />
  );
};
