import terms from 'views/Assessments/terms.json';

const { tooltips } = terms.filter((term) => term.tooltips)[0] || {};

// Use an existing general dictionary term rather than a special tooltip term
const getDictionaryTerm = (word: string) => {
  return terms[0]?.general?.find((term) => term.id === word)?.definition;
};

export const tooltipText: {
  [key: string]: string | undefined;
} = {
  AccelDecelScore: tooltips?.accelDecelScore,
  AthleticismScore: tooltips?.athleticismScore,
  CompositeScore: tooltips?.compositeScore,
  ConcentricForce: getDictionaryTerm('concRelFF'),
  Count: tooltips?.count,
  DropJump: tooltips?.dropJump,
  EccentricForce: tooltips?.eccentricForce,
  KneeExtVelocity: getDictionaryTerm('kneeExtVel'),
  KneeExtAccel: getDictionaryTerm('kneeExtAccel'),
  Last: tooltips?.last,
  LateralScore: tooltips?.lateralScore,
  LatForce: getDictionaryTerm('latForce'),
  LeftHipAbduction: tooltips?.leftHipAbduction,
  LeftHipExtVelocity: getDictionaryTerm('hipExtVel'),
  LeftLatForce: getDictionaryTerm('latForce'),
  LinearScore: tooltips?.linearScore,
  LoadRelForce: getDictionaryTerm('loadRelFF'),
  MechanicsScore: tooltips?.mechanicsScore,
  NetRelConcentricForce: getDictionaryTerm('netRelConcentricForce'),
  PercentageChange: tooltips?.percentageChange,
  Position: tooltips?.position,
  Reach: getDictionaryTerm('reach'),
  RelativePower: tooltips?.relativePower,
  RightHipExtVelocity: getDictionaryTerm('hipExtVel'),
  RightHipAbduction: tooltips?.rightHipAbduction,
  RightLatForce: getDictionaryTerm('latForce'),
  Type: tooltips?.type,
  VerticalScore: tooltips?.verticalScore,
  VertJump: tooltips?.vertJump,
};
