import cx from 'classnames';
import React, { useMemo } from 'react';
import { useRowSelect, useSortBy, useTable } from 'react-table';
import {
  AssessmentOwner,
  AssessmentSortOption,
  BaseAssessment,
  DateOnly,
  SortDirection,
} from 'types/api';
import styles from 'components/AssessmentsListTable/AssessmentsListTable.module.css';
import { Loading } from 'components/Loading';
import { IMapOrgsColumns, MapOrgsColumns } from './MapOrgsColumns';
import mapOrgsTableStyles from './MapOrgsTable.module.css';

export interface IMapOrgsTableRow {
  id: string;
  name: string;
  sport: string;
  date?: DateOnly;
  trainingTargets: 'Assigned' | 'Unassigned';
  organizations: Array<AssessmentOwner>;
  latestAssessment: BaseAssessment | undefined;
}

interface IMapOrgsTableProps {
  onSortBy: (
    sortOption: AssessmentSortOption,
    sortDirection: SortDirection,
  ) => void;
  sortBy: AssessmentSortOption;
  isDesc: boolean;
  onToggleSelect: (assessment?: BaseAssessment) => void;
  onDetachOrg: IMapOrgsColumns['detachOrg'];
  selectedAssessments?: BaseAssessment[];
  data: IMapOrgsTableRow[];
  isLoading: boolean;
}

export const MapOrgsTable = ({
  onSortBy,
  sortBy,
  isDesc,
  onToggleSelect,
  onDetachOrg,
  data,
  selectedAssessments,
  isLoading,
}: IMapOrgsTableProps) => {
  const { getTableProps, getTableBodyProps, headers, rows, prepareRow } =
    useTable<IMapOrgsTableRow>(
      {
        manualSortBy: true,
        autoResetExpanded: false,
        getRowId: (originalRow) => originalRow.id as string,
        columns: MapOrgsColumns({ onToggleSelect, detachOrg: onDetachOrg }),
        data,
        initialState: {
          sortBy: [{ id: sortBy, desc: isDesc }],
        },
        useControlledState: (state) => {
          return useMemo(() => {
            return {
              ...state,
              selectedRowIds:
                selectedAssessments?.reduce(
                  (accumulatedSel: Record<string, boolean>, assessment) => {
                    return {
                      [assessment.id as string]: true,
                      ...accumulatedSel,
                    };
                  },
                  {},
                ) || {},
            };
            // eslint-disable-next-line -- linter complains selectedAssessments isn't being used
          }, [state, sortBy, selectedAssessments]);
        },
      },
      useSortBy,
      useRowSelect,
    );

  return (
    <div className={styles.main}>
      <table
        {...getTableProps()}
        className={`${styles['table-main']} ${mapOrgsTableStyles['table-main']}`}
      >
        <thead className={styles['table-header']}>
          <tr>
            {headers.map(
              (
                {
                  getHeaderProps,
                  getSortByToggleProps,
                  isSorted,
                  isSortedDesc,
                  toggleSortBy,
                  render,
                  id,
                  canSort,
                },
                headerIndex,
              ) => {
                return (
                  <th
                    {...getHeaderProps(getSortByToggleProps())}
                    className={cx([styles['header-cell'], id])}
                    key={headerIndex}
                    onClick={() => {
                      if (canSort) {
                        const sortTerm = id as AssessmentSortOption;
                        const newSortDirection = isSortedDesc
                          ? SortDirection.ASCENDING
                          : SortDirection.DESCENDING;

                        toggleSortBy(!isSortedDesc);
                        onSortBy(sortTerm, newSortDirection);
                      }
                    }}
                  >
                    {render('Header')}
                    <span
                      className={cx({
                        [styles['sort-icon']]: true,
                        [styles.sorted]: isSorted,
                        [styles['sorted-desc']]: isSorted && isSortedDesc,
                        [styles['sorted-asc']]: isSorted && !isSortedDesc,
                      })}
                    />
                  </th>
                );
              },
            )}
          </tr>
        </thead>

        <tbody {...getTableBodyProps()} className={styles['table-body']}>
          {isLoading ? (
            <tr>
              <td colSpan={100} className={mapOrgsTableStyles.loading}>
                <Loading />
              </td>
            </tr>
          ) : (
            rows.map((row) => {
              prepareRow(row);
              const rowProps = row.getRowProps();
              const rowKey = row.id;
              return (
                <React.Fragment key={rowKey}>
                  <tr
                    {...rowProps}
                    id={row.id}
                    className={cx({
                      [styles['expanded-row']]: row.isExpanded,
                    })}
                  >
                    {row.cells.map((cell, cellIndex) => {
                      return (
                        <td
                          {...cell.getCellProps()}
                          className={cx([styles['table-cell'], cell.column.id])}
                          key={cellIndex}
                        >
                          {cell.render('Cell')}
                        </td>
                      );
                    })}
                  </tr>
                </React.Fragment>
              );
            })
          )}
        </tbody>
      </table>
    </div>
  );
};
