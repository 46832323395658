import { AssessmentType, BaseAssessment } from 'types/api';
import { formatDate } from 'util/formatDate';
import { getScores } from 'util/getScores';
import { trimDecimals } from 'util/trimDecimals';
import { IndeterminateCheckbox } from 'components/IndeterminateCheckbox';
import styles from './SelectionModalExpandedRow.module.css';

interface ISelectionModalExpandedRow {
  assessments: BaseAssessment[];
  selectedSubRows?: BaseAssessment[];
  onToggleSelect: (assessment: BaseAssessment) => void;
}

export const SelectionModalExpandedRow = ({
  assessments,
  selectedSubRows,
  onToggleSelect,
}: ISelectionModalExpandedRow) => {
  const assessmentType = assessments[0].assessmentType;
  return (
    <>
      {assessments.map((assessment) => {
        const { athleticismScore, mechanicsScore } = getScores(assessment);

        // TOTAL number of sub rows selected, not just for a parent row
        let totalSelection = 0;
        let canSelect = true;
        const isChecked = !!selectedSubRows?.find(
          (selected) => selected.id === assessment.id,
        );

        if (selectedSubRows) {
          totalSelection += selectedSubRows.length;
        }

        canSelect = totalSelection < 4;

        return (
          <div
            key={`select-subrow-${assessment.id}`}
            className={styles['sub-row']}
          >
            <IndeterminateCheckbox
              isDisabled={!canSelect && !isChecked}
              notAllowedMessage="The maximum number of four (4) players has been reached for a comparison view."
              checked={isChecked || false}
              onChange={() => {
                onToggleSelect(assessment);
              }}
            />
            <span>{formatDate(assessment.date)}</span>
            {assessmentType === AssessmentType.BASKETBALL && (
              <span className={styles.scores}>
                {trimDecimals(athleticismScore || 0)} <i>/</i>
                {trimDecimals(mechanicsScore || 0)}
              </span>
            )}
          </div>
        );
      })}
    </>
  );
};
