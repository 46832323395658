import { ReactComponent as InfoIcon } from 'assets/icons/info.svg';
import { ReactComponent as RadarIcon } from 'assets/icons/radar.svg';
import { ReactComponent as TableIcon } from 'assets/icons/table.svg';
import { ReactComponent as VideoIcon } from 'assets/icons/video.svg';
import {
  AssessmentType,
  BasketballAssessment,
  SoccerAssessment,
} from 'types/api';
import { labels } from 'util/graphConstants';
import { unitsOfMeasure } from 'util/unitsOfMeasure';
import { GraphInfo } from 'components/GraphInfo';
import { GraphTable } from 'components/GraphTable';
import { GraphTabs } from 'components/GraphTabs';
import { Radar } from 'components/Radar';
import { Video } from 'components/Video';

interface LateralSkaterProps {
  assessment: BasketballAssessment | SoccerAssessment;
  className?: string;
}

interface ITerms {
  key: string;
  unit?: string;
  name?: string;
}

const {
  lateralForceL,
  lateralForceR,
  hipAbductionL,
  hipAbductionR,
  hipExtVelocityL,
  hipExtVelocityR,
} = labels;

export const lateralSkaterTableGraphTerms: ITerms[] = [
  {
    key: 'assessmentDetails.assessmentSkaterSummary.maxLeftLateralForce',
    unit: unitsOfMeasure('force'),
    name: lateralForceL,
  },
  {
    key: 'assessmentDetails.assessmentSkaterSummary.maxRightLateralForce',
    unit: unitsOfMeasure('force'),
    name: lateralForceR,
  },
  {
    key: 'assessmentDetails.assessmentSkaterSummary.maxLeftHipAbduction',
    unit: unitsOfMeasure('abduction'),
    name: hipAbductionL,
  },
  {
    key: 'assessmentDetails.assessmentSkaterSummary.maxRightHipAbduction',
    unit: unitsOfMeasure('abduction'),
    name: hipAbductionR,
  },
  {
    key: 'assessmentDetails.assessmentSkaterSummary.maxLeftHipExtensionVelocity',
    unit: unitsOfMeasure('velocity'),
    name: hipExtVelocityL,
  },
  {
    key: 'assessmentDetails.assessmentSkaterSummary.maxRightHipExtensionVelocity',
    unit: unitsOfMeasure('velocity'),
    name: hipExtVelocityR,
  },
];

export const radarLabels = [
  { max: 100, name: lateralForceL },
  { max: 100, name: hipAbductionL },
  { max: 100, name: hipExtVelocityL },
  { max: 100, name: lateralForceR },
  { max: 100, name: hipExtVelocityR },
  { max: 100, name: hipAbductionR },
];

export const infoTermsLateralSkater = (
  <GraphInfo terms={['lateralForce', 'maxHipAbd', 'hipExtVel']} />
);

export const LateralSkater = (props: LateralSkaterProps) => {
  const { assessment, className } = props;
  const {
    percLeftLateralForce = 0,
    percRightMaxHipAbduction = 0,
    percRightMaxHipExtensionVelocity = 0,
    percRightLateralForce = 0,
    percLeftMaxHipExtensionVelocity = 0,
    percLeftMaxHipAbduction = 0,
  } = assessment?.assessmentDetails?.assessmentSkaterSummary || {};

  const data: { value: number[]; name?: string }[] = [
    {
      value: [
        percLeftLateralForce,
        percLeftMaxHipAbduction,
        percLeftMaxHipExtensionVelocity,
        percRightLateralForce,
        percRightMaxHipExtensionVelocity,
        percRightMaxHipAbduction,
      ],
    },
  ];

  if (assessment.assessmentType === AssessmentType.SOCCER) {
    data.push({
      name: `${assessment?.position} Average`,
      value: Array(data[0].value.length).fill(50),
    });
  }

  const config = [
    {
      id: 'radarTab',
      name: 'Percentile',
      Icon: RadarIcon,
      content: <Radar data={data} indicator={radarLabels} />,
    },
    {
      id: 'tableTab',
      name: 'Test Results',
      Icon: TableIcon,
      content: (
        <GraphTable
          assessments={[assessment]}
          definitions={lateralSkaterTableGraphTerms}
        />
      ),
    },
    {
      id: 'infoTab',
      name: 'Terminology',
      Icon: InfoIcon,
      content: infoTermsLateralSkater,
    },
  ];

  const videos = assessment?.videos?.filter((video) =>
    video.exercise?.includes('Skater'),
  );
  if (videos?.length) {
    config.push({
      id: 'videoTab',
      name: 'Video',
      Icon: VideoIcon,
      content: <Video videos={videos} />,
    });
  }

  return (
    <GraphTabs
      className={className}
      config={config}
      defaultTab="radarTab"
      heading="Lateral Skater"
    />
  );
};
