/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

export enum TrainingTarget {
    HIP_STABILITY = 'HipStability',
    TRUNK_STABILITY = 'TrunkStability',
    INVERSION = 'Inversion',
    TRANSLATION = 'Translation',
    EVERSION = 'Eversion',
    ACTIVE_DORSIFLEXION = 'ActiveDorsiflexion',
    ANKLE_STIFFNESS = 'AnkleStiffness',
    YIELDING = 'Yielding',
    POSTERIOR_YIELDING = 'PosteriorYielding',
    IMPULSE_ASYM = 'ImpulseAsym',
    HIP_MOBILITY = 'HipMobility',
    ANKLE_MOBILITY = 'AnkleMobility',
    LEPOWER = 'LEPower',
    LATERAL_DRIVE = 'LateralDrive',
    LESTRENGTH = 'LEStrength',
}