import React from 'react';
import { Button, ButtonVariants } from 'components/Button';
import styles from './Empty.module.css';

export enum EmptyMessages {
  NO_ASSESSMENTS = 'You have no previous assessments to view.',
  NO_USERS_IN_ORG = 'No users exist in this organization.',
}
interface IEmptyProps {
  message?: EmptyMessages;
  hideCTA?: boolean;
}

const Empty = ({
  message = EmptyMessages.NO_ASSESSMENTS,
  hideCTA = false,
}: IEmptyProps) => {
  return (
    <div className={styles.main}>
      <p className={styles.message}>{message}</p>
      {hideCTA ? (
        <></>
      ) : (
        <Button
          type="button"
          variant={ButtonVariants.Secondary}
          className={styles['contact-btn']}
        >
          Contact Admin
        </Button>
      )}
    </div>
  );
};

export { Empty };
