import { useEffect, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { Organization, User } from 'types/api';
import { useUserByEmail } from 'hooks/query/useUserByEmail';
import { Button, ButtonVariants } from 'components/Button';
import { TextField } from 'components/TextField';
import styles from './StepOneUserEmailForm.module.css';

interface CreateUserStepperProps {
  newUserEmail: string | undefined;
  organization: Organization;
  setStep: (value: number) => void;
  setNewUserEmail: (value: string) => void;
  setIsExistingUser: (value: User | undefined) => void;
}

export const StepOneUserEmailForm = ({
  newUserEmail,
  organization,
  setStep,
  setNewUserEmail,
  setIsExistingUser,
}: CreateUserStepperProps) => {
  const [formChangesDetected, setFormChangesDetected] =
    useState<boolean>(false);
  const [formError, setFormError] = useState<string>();
  const { control, handleSubmit, watch } = useForm({
    defaultValues: {
      email: '',
    },
  });
  const { data: getUserByEmail, isSuccess } = useUserByEmail(newUserEmail);
  useEffect(() => {
    // Endpoint returns a 204 no content with an '' if a user email is not found in our system.
    if ((getUserByEmail === '' || getUserByEmail) && isSuccess) {
      if (getUserByEmail && getUserByEmail !== '') {
        const isUserAlreadyInOrg = (getUserByEmail: User): boolean => {
          const findExistingOrg = getUserByEmail?.organizationRoles?.find(
            (x) => x.organizationId === organization.id,
          );
          if (findExistingOrg) {
            return true;
          } else return false;
        };
        if (isUserAlreadyInOrg(getUserByEmail)) {
          setFormError(
            `${getUserByEmail.email} is already a member of this organization.`,
          );
        } else {
          setStep(2);
          setIsExistingUser(getUserByEmail);
        }
      } else {
        setStep(2);
        setIsExistingUser(getUserByEmail);
      }
    }
  }, [getUserByEmail, isSuccess, organization.id, setStep, setIsExistingUser]);

  useEffect(() => {
    const subscription = watch((value) => {
      setFormChangesDetected(!!value);
      setFormError(undefined);
    });
    return () => {
      subscription.unsubscribe();
    };
  }, [watch]);

  const onFormSubmit = (formData: { email: string }) => {
    setFormChangesDetected(false);
    setNewUserEmail(formData.email);
  };

  return (
    <>
      <div className={styles['modal-center']}>
        <h2 className={styles['create-org-header']}>
          Enter Email Address of User to Add
        </h2>
        <form className={styles.form} onSubmit={handleSubmit(onFormSubmit)}>
          <div className={styles['text-field']}>
            <Controller
              control={control}
              name="email"
              render={({ field }) => (
                <TextField
                  {...field}
                  label="Email Address"
                  required
                  type="email"
                />
              )}
            />
          </div>
          <div className={styles.buttons}>
            <Button
              disabled={!formChangesDetected}
              variant={ButtonVariants.Primary}
              type="submit"
            >
              Invite New User
            </Button>
          </div>
          {formError && !formChangesDetected && (
            <p className={styles.error}>{formError}</p>
          )}
        </form>
      </div>
    </>
  );
};
