import React, {
  PropsWithChildren,
  useCallback,
  useEffect,
  useState,
} from 'react';
import { useNavigate } from 'react-router-dom';
import { OrganizationResponseModel, Role } from 'types/api';
import { useLoggedInUser } from 'hooks/query/useLoggedInUser';
import { useDefaultContextAssignment } from 'hooks/util/useDefaultContextAssignment';
import {
  ILocalStorageContext,
  getContextFromLocalStorage,
} from 'util/getContextFromLocalStorage';
import { queryClient } from 'util/queryClientConfig';
import { updateLocalStorage } from 'util/updateLocalStorage';

export interface IContext {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  setContext: any;
  contextOrg: OrganizationResponseModel | undefined;
  contextRole: Role | undefined;
  noOrgAssigned: boolean | undefined;
}

export const OrganizationContext = React.createContext<IContext>({
  contextOrg: undefined,
  contextRole: undefined,
  noOrgAssigned: undefined,
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  setContext: () => {},
});

export const OrganizationProvider = (props: PropsWithChildren<unknown>) => {
  const { children } = props;
  const { data: loggedInUser } = useLoggedInUser();
  const navigate = useNavigate();
  // Check local storage for an existing selected organization.
  // If an organization exists, set that in global context.
  const contextFromLocalStorage: ILocalStorageContext =
    getContextFromLocalStorage(loggedInUser);
  const [contextOrg, setOrganization] = useState<
    OrganizationResponseModel | undefined
  >(contextFromLocalStorage?.localStorageOrg);
  const [contextRole, setContextRole] = useState<Role | undefined>(
    contextFromLocalStorage.localStorageRole,
  );
  const [noOrgAssigned, setNoOrgAssigned] = useState<boolean>(false);
  // Sets global state for organization ID - Can be called by child components to update global context
  const setContext = useCallback(
    (
      organization: OrganizationResponseModel | undefined,
      role: Role | undefined,
    ) => {
      setOrganization(organization);
      if (loggedInUser?.isSuperAdmin) {
        setContextRole(Role.SUPER_ADMIN);
      } else {
        setContextRole(role);
      }
    },
    [loggedInUser?.isSuperAdmin],
  );
  // If logged in user doesn't have any organization roles on their user, send them to the profile page.
  useEffect(() => {
    if (
      !loggedInUser?.isSuperAdmin &&
      (!loggedInUser?.organizationRoles ||
        !loggedInUser?.organizationRoles?.length ||
        loggedInUser?.organizationRoles?.length < 0)
    ) {
      // If roles change and user loses access to an organization, we need to reset context here.
      setNoOrgAssigned(true);
      setContext(undefined, undefined);
      return () => navigate('profile');
    }
  }, [
    loggedInUser?.organizationRoles,
    loggedInUser?.isSuperAdmin,
    setContext,
    noOrgAssigned,
    navigate,
  ]);

  // Default organization assignment if local storage is not populated with a selected organization ID
  useDefaultContextAssignment(
    contextFromLocalStorage,
    setContext,
    setNoOrgAssigned,
    loggedInUser,
  );

  // Whenever global context is updated, invalidate any queries and trigger a refetch of data
  useEffect(() => {
    updateLocalStorage(contextOrg, contextRole, loggedInUser);
    queryClient.invalidateQueries('assessments');
    queryClient.invalidateQueries('assessmentsList');
  }, [contextOrg, contextRole, loggedInUser]);

  let render;
  if (!noOrgAssigned && contextOrg) {
    render = children;
  } else {
    render = children;
  }
  return (
    <OrganizationContext.Provider
      value={{ contextOrg, contextRole, setContext, noOrgAssigned }}
    >
      {render}
    </OrganizationContext.Provider>
  );
};
