import { useMemo } from 'react';
import {
  AssessmentType,
  BasketballAssessment,
  SoccerAssessment,
} from 'types/api';

export const useComparisonData = (
  assessments: Partial<SoccerAssessment[] | BasketballAssessment[]>,
  assessment: Partial<SoccerAssessment | BasketballAssessment>,
) => {
  return useMemo(() => {
    let latestAssessment;
    let latestAssessmentSummary;
    let latestAssessmentDetails;
    let selectedAssessment;
    let selectedAssessmentDetails;
    let selectedAssessmentSummary;
    // basketball composite score
    let selectedCombinedScore;
    let latestCombinedScore;
    let compositeScore;

    let lateralScore;
    let verticalScore;
    let accelDecelScore;
    let vertKneeAccAvg;
    let vertKneeVelAvg;
    let athleticismScore;
    let mechanicsScore;
    // soccer totals
    let linearScore;

    switch (assessment?.assessmentType) {
      case AssessmentType.SOCCER:
        latestAssessment = assessments
          ? (assessments[0] as SoccerAssessment)
          : ({} as SoccerAssessment);

        latestAssessmentDetails = latestAssessment.assessmentDetails;
        selectedAssessment =
          (assessment as SoccerAssessment) || ({} as SoccerAssessment);
        if (selectedAssessment.id === latestAssessment?.id) {
          selectedAssessment = (assessments as SoccerAssessment)
            ? (assessments[1] as SoccerAssessment)
            : ({} as SoccerAssessment);
        }
        selectedAssessmentDetails = selectedAssessment?.assessmentDetails;

        lateralScore =
          latestAssessmentDetails?.assessmentSoccerVerticalLateralAbility
            ?.lateralAcceleration;

        linearScore =
          latestAssessmentDetails?.assessmentSoccerVerticalLateralAbility
            ?.verticalAcceleration;

        accelDecelScore = 100;

        vertKneeAccAvg =
          ((latestAssessmentDetails?.assessmentSoccerStandingJumpSummary
            ?.percMaxKneeExtensionAccelerationAvg || 0) +
            (latestAssessmentDetails?.assessmentSoccerStandingJumpSummary
              ?.percMaxKneeExtensionAccelerationAvg || 0)) /
          2;
        vertKneeVelAvg =
          ((latestAssessmentDetails?.assessmentSoccerStandingJumpSummary
            ?.percMaxKneeExtensionAccelerationAvg || 0) +
            (latestAssessmentDetails?.assessmentSoccerStandingJumpSummary
              ?.percMaxKneeExtensionAccelerationAvg || 0)) /
          2;

        break;
      case AssessmentType.BASKETBALL:
        latestAssessment = assessments
          ? (assessments[0] as BasketballAssessment)
          : ({} as BasketballAssessment);

        latestAssessmentDetails = latestAssessment.assessmentDetails;
        latestAssessmentSummary = latestAssessmentDetails?.assessmentSummary;
        selectedAssessment =
          (assessment as BasketballAssessment) || ({} as BasketballAssessment);

        if (selectedAssessment.id === latestAssessment?.id) {
          selectedAssessment = (assessments as BasketballAssessment)
            ? (assessments[1] as BasketballAssessment)
            : ({} as BasketballAssessment);
        }

        selectedAssessmentDetails = selectedAssessment?.assessmentDetails;

        selectedAssessmentSummary =
          selectedAssessmentDetails?.assessmentSummary;

        athleticismScore = latestAssessmentSummary?.athleticismScore || 0;

        mechanicsScore = latestAssessmentSummary?.mechanicsScore || 0;

        selectedCombinedScore =
          (selectedAssessmentSummary?.athleticismScore || 0) +
          (selectedAssessmentSummary?.mechanicsScore || 0);

        latestCombinedScore =
          (latestAssessmentSummary?.athleticismScore || 0) +
          (latestAssessmentSummary?.mechanicsScore || 0);

        compositeScore =
          ((latestCombinedScore - selectedCombinedScore) /
            selectedCombinedScore) *
          100;

        accelDecelScore =
          ((latestAssessmentDetails?.assessmentOverallPerformanceFactors
            ?.accelerationPercentile || 0) +
            (latestAssessmentDetails?.assessmentOverallPerformanceFactors
              ?.decelerationPercentile || 0)) /
          2;

        lateralScore =
          latestAssessmentDetails?.assessmentOverallPerformanceFactors
            ?.lateralPercentile;

        verticalScore =
          latestAssessmentDetails?.assessmentOverallPerformanceFactors
            ?.verticalPercentile;

        vertKneeAccAvg =
          ((latestAssessmentDetails?.assessmentStandingJumpSummary
            ?.percMaxKneeExtensionAccelerationAvg || 0) +
            (latestAssessmentDetails?.assessmentDropJumpSummary
              ?.percMaxKneeExtensionAccelerationAvg || 0)) /
          2;
        vertKneeVelAvg =
          ((latestAssessmentDetails?.assessmentStandingJumpSummary
            ?.percMaxKneeExtensionAccelerationAvg || 0) +
            (latestAssessmentDetails?.assessmentDropJumpSummary
              ?.percMaxKneeExtensionAccelerationAvg || 0)) /
          2;
    }

    return {
      compositeScore,
      accelDecelScore,
      vertKneeAccAvg,
      vertKneeVelAvg,
      athleticismScore,
      mechanicsScore,
      lateralScore,
      linearScore,
      verticalScore,
    };
  }, [assessment, assessments]);
};
