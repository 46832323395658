import cuid from 'cuid';
import * as faker from 'faker';
import { ObjectGenerator } from 'lib/api-mocking/interfaces/ObjectGenerator';
import { Role, UserOrganizationRole, UserResponseModel } from 'types/api/';
import { getEnumValues } from 'util/enum';

interface IUserResponseGenerator
  extends ObjectGenerator<UserResponseModel, string> {
  generate: () => UserResponseModel;
  generateRole: () => UserOrganizationRole;
}

export const UserResponseGenerator: IUserResponseGenerator = {
  generateId: (): string => {
    return cuid();
  },

  generateRole: () => {
    return {
      organizationId: faker.datatype.uuid(),
      organizationName: faker.company.companyName(),
      role: getEnumValues<Role>(Role).find((role: string, i, roles) => {
        return i === faker.datatype.number({ min: 0, max: roles.length - 1 });
      }),
    };
  },

  generate: () => {
    return {
      email: faker.internet.email(),
      id: UserResponseGenerator.generateId(),
      auth0Id: `Auth0|${UserResponseGenerator.generateId()}`,
      firstName: faker.name.firstName(),
      lastName: faker.name.lastName(),
      isSuperAdmin: faker.datatype.boolean(),
      phone: faker.phone.phoneNumber(),
      address: faker.address.streetAddress(),
      zipCode: faker.address.zipCode(),
      state: faker.address.state(),
      blobImageUri: faker.image.imageUrl(),
      city: faker.address.cityName(),
      // generates one user role only
      organizationRoles: [UserResponseGenerator.generateRole()],
    };
  },

  generateList: (count: number): UserResponseModel[] => {
    return Array.from({ length: count }, (): UserResponseModel => {
      return UserResponseGenerator.generate();
    }) as UserResponseModel[];
  },
};
