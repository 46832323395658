import { Error } from 'models/Error';
import { useQuery } from 'react-query';
import { toast } from 'react-toastify';
import {
  OrganizationResponseModel,
  UserResponseModelPaginatedList,
} from 'types/api';
import {
  ContentType,
  useAuthenticatedFetch,
} from 'hooks/util/useAuthenticatedFetch';

export interface IUseUsersList {
  page: number;
  contextOrg?: OrganizationResponseModel | undefined;
}

export const useUsersList = ({ page = 1, contextOrg }: IUseUsersList) => {
  const fetch = useAuthenticatedFetch(
    ContentType.OVERRIDE_ADD_ORGANIZATION_HEADER,
  );

  return useQuery<UserResponseModelPaginatedList, Error>(
    ['userList', contextOrg, { page }],
    () => fetch(`${process.env.REACT_APP_API_HOST}/users?pageIndex=${page}`),
    {
      keepPreviousData: true,
      onError: () => {
        toast.error('Error fetching users.');
      },
    },
  );
};
