import { Link } from 'react-router-dom';
import styles from './Sidebar.module.css';

export const Sidebar = () => {
  return (
    <>
      <aside>
        <div className={styles['sidebar-card']}>
          <p className={styles.uppercase}>Contact Info</p>
          <h3>P3 Applied Sports Science</h3>
          <p className={styles.text}>
            Our labs are located in Santa Barbara, CA and Atlanta, Georgia.
          </p>
          <a href="mailto:contact@p3.md" className={styles['contact-link']}>
            contact@p3.md
          </a>
          <a href="tel:805-886-0303" className={styles['contact-link']}>
            805.886.0303
          </a>
        </div>
        <div className={styles['sidebar-card']}>
          <h3>Need Help?</h3>
          <p className={styles.text}>
            Please visit our supporting documentation.
          </p>
          <Link to="/faq" className={styles.faq}>
            <i className={styles['arrow-right']} />
            View FAQs
          </Link>
        </div>
      </aside>
    </>
  );
};
