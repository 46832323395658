import { ReactComponent as InfoIcon } from 'assets/icons/info.svg';
import { ReactComponent as RadarIcon } from 'assets/icons/radar.svg';
import { ReactComponent as TableIcon } from 'assets/icons/table.svg';
import { BasketballAssessment } from 'types/api';
import { labels } from 'util/graphConstants';
import { unitsOfMeasure } from 'util/unitsOfMeasure';
import { GraphInfo } from 'components/GraphInfo';
import { GraphTable } from 'components/GraphTable';
import { GraphTabs } from 'components/GraphTabs';
import { Radar } from 'components/Radar';

interface ITerms {
  key: string;
  unit?: string;
  name?: string;
}

const { concRelFF, lateralForceAvg, loadRelFF, kneeExtAccel } = labels;

export const accelDecelTableGraphTerms: ITerms[] = [
  {
    key: 'assessmentDetails.assessmentAccelerationDecelerationDetails.concentricRelativeFF',
    unit: unitsOfMeasure('force'),
    name: concRelFF,
  },
  {
    key: 'assessmentDetails.assessmentAccelerationDecelerationDetails.maxLateralForceAvg',
    unit: unitsOfMeasure('force'),
    name: lateralForceAvg,
  },
  {
    key: 'assessmentDetails.assessmentAccelerationDecelerationDetails.loadRelativeFF',
    unit: unitsOfMeasure('force'),
    name: loadRelFF,
  },
  {
    key: 'assessmentDetails.assessmentStandingJumpSummary.maxKneeExtensionAccelerationAvg',
    unit: unitsOfMeasure('velocity'),
    name: kneeExtAccel,
  },
];

export const radarLabels = [
  { max: 100, name: concRelFF },
  { max: 100, name: kneeExtAccel },
  { max: 100, name: loadRelFF },
  { max: 100, name: lateralForceAvg },
];

interface AccelDecelProps {
  assessment: BasketballAssessment;
  className?: string;
}

export const infoTermsAccelDecel = (
  <GraphInfo terms={['concRelFF', 'lateralForce', 'loadRelFF', 'kneeExtVel']} />
);

export const AccelDecel = (props: AccelDecelProps) => {
  const { assessment, className } = props;
  const {
    percConcRelFF = 0,
    percLateralForceAvg = 0,
    percLoadRelFF = 0,
    percMaxKneeExtensionVelocityAvg = 0,
  } = assessment?.assessmentDetails
    ?.assessmentAccelerationDecelerationDetails || {};

  const config = [
    {
      id: 'radarTab',
      name: 'Percentile',
      Icon: RadarIcon,
      content: (
        <Radar
          data={[
            {
              value: [
                percConcRelFF,
                percMaxKneeExtensionVelocityAvg,
                percLoadRelFF,
                percLateralForceAvg,
              ],
            },
          ]}
          indicator={radarLabels}
        />
      ),
    },
    {
      id: 'tableTab',
      name: 'Test Results',
      Icon: TableIcon,
      content: (
        <GraphTable
          assessments={[assessment]}
          definitions={accelDecelTableGraphTerms}
        />
      ),
    },
    {
      id: 'infoTab',
      name: 'Terminology',
      Icon: InfoIcon,
      content: infoTermsAccelDecel,
    },
  ];

  return (
    <GraphTabs
      className={className}
      config={config}
      defaultTab="radarTab"
      heading="Acceleration / Deceleration"
    />
  );
};
