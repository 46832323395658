/* Generates a single Organization */
import cuid from 'cuid';
import * as faker from 'faker';
import { OrganizationResponseModel } from 'types/api';

interface IOrganizationGenerator {
  generate: () => OrganizationResponseModel;
  generateId: () => string;
}

export const OrganizationGenerator: IOrganizationGenerator = {
  generateId: (): string => {
    return cuid();
  },

  generate: () => {
    const city = faker.address.cityName();

    return {
      organizationName: `${city} ${faker.animal.cat()}s`,
      city,
      state: faker.address.state(),
      imageUrl: faker.image.animals(),
      country: faker.address.country(),
      id: OrganizationGenerator.generateId(),
      blobImageUri: faker.image.animals(),
      /* TODO create generator for User */
      members: [],
    };
  },
};
