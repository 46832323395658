import { useMutation } from 'react-query';
import { toast } from 'react-toastify';
import { UpdateUserRequestModel, UserResponseModel } from 'types/api';
import {
  ContentType,
  useAuthenticatedFetch,
} from 'hooks/util/useAuthenticatedFetch';
import { queryClient } from 'util/queryClientConfig';

export const useUpdateUser = (id?: string, isCurrentUser = false) => {
  const fetch = useAuthenticatedFetch(ContentType.NO_ORGANIZATION_HEADER);
  return useMutation(
    (body: UpdateUserRequestModel) =>
      fetch<UserResponseModel>(
        `${process.env.REACT_APP_API_HOST}/users/${id}`,
        {
          body,
          method: 'PUT',
        },
      ),
    {
      onError: () => {
        toast.error('Error updating profile.');
      },
      onSuccess: (updatedUser) => {
        toast.success('Profile updated successfully.');
        queryClient.setQueryData(
          ['profile', isCurrentUser ? 'my' : updatedUser.id],
          updatedUser,
        );
      },
    },
  );
};
