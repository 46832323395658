import * as Popover from '@radix-ui/react-popover';
import * as Slider from '@radix-ui/react-slider';
import { useMemo, useState } from 'react';
import tooltipStyles from 'components/Tooltip/Tooltip.module.css';
import styles from './MechanicSlider.module.css';

interface MechanicSliderProps {
  defaultValue: number;
  onChange: (value: number) => void;
  value: number;
}

const maxChange = 10;

export const MechanicSlider = (props: MechanicSliderProps) => {
  const { defaultValue, onChange, value } = props;
  const [isPopoverOpen, setIsPopoverOpen] = useState<boolean>(false);

  const min = useMemo(() => {
    const result = defaultValue - maxChange;
    return result < 0 ? 0 : result;
  }, [defaultValue]);

  const max = useMemo(() => {
    const result = defaultValue + maxChange;
    return result > 100 ? 100 : result;
  }, [defaultValue]);

  const percentageChange = useMemo<string>(() => {
    const delta = value - defaultValue;
    return delta > 0 ? `+${delta}%` : `${delta}%`;
  }, [defaultValue, value]);

  const handleChange = (values: number[]) => {
    const newValue = values[0];
    if (newValue >= min && newValue <= max) {
      onChange(newValue);
    }
  };

  const handleThumbMouseEnter = () => {
    setIsPopoverOpen(true);
  };

  const handleThumbMouseOut = () => {
    setIsPopoverOpen(false);
  };

  return (
    <Popover.Root open={isPopoverOpen}>
      <Slider.Root
        className={styles.root}
        defaultValue={[defaultValue]}
        onValueChange={handleChange}
        step={1}
        value={[value]}
      >
        <Slider.Track className={styles.track}>
          <Slider.Range className={styles.range} />
        </Slider.Track>

        {/* Thumb with Popover */}
        <Slider.Thumb asChild>
          <Popover.Trigger asChild>
            <button
              className={styles.thumb}
              onMouseEnter={handleThumbMouseEnter}
              onMouseOut={handleThumbMouseOut}
              onTouchStart={handleThumbMouseEnter}
              onTouchEnd={handleThumbMouseOut}
            >
              <Popover.Anchor />
            </button>
          </Popover.Trigger>
        </Slider.Thumb>
      </Slider.Root>
      <Popover.Content
        className={tooltipStyles.tooltip}
        side="top"
        sideOffset={20}
      >
        <span>
          {value}% ({percentageChange})
        </span>
        <small>Assessment: {defaultValue}%</small>
        <Popover.Arrow className={tooltipStyles.arrow} />
      </Popover.Content>
    </Popover.Root>
  );
};
