import styles from './SelectedPill.module.css';

interface ISelectedPillProps {
  id: string;
  label: string;
  onClose?: (id: string, label: string) => void;
}

const SelectedPill = ({ id, label, onClose }: ISelectedPillProps) => {
  return (
    <li className={styles.pill}>
      <span>{label}</span>
      <button type="button" className={styles.remove}>
        <span
          className={styles.icon}
          onClick={() => onClose && onClose(id, label)}
        />
      </button>
    </li>
  );
};

export { SelectedPill };
