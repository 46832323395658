import { useEffect, useState } from 'react';
import { Organization } from 'types/api';
import {
  ContentType,
  useAuthenticatedFetch,
} from 'hooks/util/useAuthenticatedFetch';
import { useOrganizationContext } from 'hooks/util/useOrganizationContext';
import { queryClient } from 'util/queryClientConfig';
import { ProfilePicture } from 'components/ProfilePicture';
import styles from './OrganizationHeader.module.css';

interface HeaderProps {
  organization?: Organization;
}

export const OrganizationHeader = ({ organization }: HeaderProps) => {
  const {
    blobImageUri,
    city,
    id,
    isAdmin: isAdminOrg,
    organizationName,
    primaryAdmin,
    state,
  } = organization || {};

  const [profileImage, setProfileImage] = useState<string>();
  const [imageUploadError, setImageUploadError] = useState<string>();
  const { contextOrg } = useOrganizationContext();

  // If user image not available, use default org's image
  const profileImageUri = blobImageUri || contextOrg?.blobImageUri;

  useEffect(() => {
    if (profileImageUri) {
      setProfileImage(profileImageUri);
    }
  }, [profileImageUri]);

  const fetch = useAuthenticatedFetch(ContentType.NONE);

  async function uploadFile(id: string, file: File) {
    const formData = new FormData();
    formData.append('Image', file);
    const postImage = await fetch(
      `${process.env.REACT_APP_API_HOST}/organizations/${id}/image`,
      {
        method: 'POST',
        body: formData,
      },
    );
    if (postImage.ErrorCode) {
      setImageUploadError(postImage.Message);
    } else {
      setImageUploadError(undefined);
      // The POST request above returns a URL, which takes a split second to load.
      // To avoid a jumpy experience, we will set the profile image state to the uploaded file itself rather than the returned URL.
      setProfileImage(URL.createObjectURL(file));
      queryClient.invalidateQueries('organization');
    }
  }

  return (
    <>
      <div className={styles.header}>
        <div className={styles['user-info']}>
          <div>
            <div className={styles['profile-picture-container']}>
              <ProfilePicture size={160} src={profileImage as string} />
              {!isAdminOrg && (
                <input
                  type="file"
                  className={styles.upload}
                  accept="image/png, image/jpeg"
                  name="Image"
                  onChange={({ target }) => {
                    if (target.files) {
                      const file = target.files[0];
                      uploadFile(id as string, file);
                    }
                  }}
                />
              )}
            </div>
            {imageUploadError && (
              <div className={styles['image-upload-error']}>
                <p>Error uploading image: {imageUploadError}</p>
              </div>
            )}
          </div>
          <div className={styles['user-stats']}>
            <h1>{organizationName}</h1>
            {city && state && (
              <address
                className={styles.location}
              >{`${city}, ${state}`}</address>
            )}
          </div>
        </div>
        <ul className={styles['header-info']}>
          <li className={styles['user-email']}>
            <a href={`mailto:${primaryAdmin?.email}`}>{primaryAdmin?.email}</a>
          </li>
        </ul>
      </div>
    </>
  );
};
