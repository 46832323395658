import { ReactComponent as ErrorIcon } from 'assets/icons/error.svg';
import classNames from 'classnames';
import {
  ChangeEvent,
  ElementType,
  FocusEvent,
  ForwardedRef,
  HTMLProps,
  forwardRef,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from 'react';
import styles from './TextField.module.css';

interface TextFieldProps extends HTMLProps<HTMLInputElement> {
  error?: string;
  info?: string;
  label?: string;
  tagName?: ElementType;
}

let count = 0;

export const TextField = forwardRef(
  (props: TextFieldProps, ref: ForwardedRef<HTMLInputElement>) => {
    const {
      className,
      defaultValue,
      error,
      info,
      label,
      onBlur,
      onChange,
      onFocus,
      tagName,
      type = 'text',
      value: controlledValue,
      ...inputProps
    } = props;
    const [value, setValue] = useState<string | number | readonly string[]>(
      controlledValue || defaultValue || '',
    );
    const [isFocused, setIsFocused] = useState<boolean>(false);

    const Element = tagName || 'input';
    const id = useMemo(() => ++count, []);

    const handleBlur = useCallback(
      (e: FocusEvent<HTMLInputElement>) => {
        setIsFocused(false);
        onBlur?.(e);
      },
      [onBlur],
    );

    const handleChange = useCallback(
      (e: ChangeEvent<HTMLInputElement>) => {
        setValue(e.target.value);
        onChange?.(e);
      },
      [onChange],
    );

    const handleFocus = useCallback(
      (e: FocusEvent<HTMLInputElement>) => {
        setIsFocused(true);
        onFocus?.(e);
      },
      [onFocus],
    );

    useEffect(() => {
      if (typeof controlledValue !== 'undefined') {
        setValue(controlledValue);
      }
    }, [controlledValue]);

    return (
      <div
        className={classNames(
          styles.container,
          {
            [styles['has-label']]: !!label,
            [styles.errored]: !!error,
            [styles.filled]:
              (Array.isArray(value) ? value.length : String(value).length) > 0,
            [styles.focused]: isFocused,
          },
          className,
        )}
      >
        <Element
          {...inputProps}
          className={styles.element}
          id={`text-field-${id}`}
          onBlur={handleBlur}
          onChange={handleChange}
          onFocus={handleFocus}
          ref={ref}
          type={type}
          value={value}
        />
        {label && (
          <label className={styles.label} htmlFor={`text-field-${id}`}>
            {label}
          </label>
        )}
        {error ? (
          <>
            <ErrorIcon className={styles.icon} />
            <span className={styles.error}>{error}</span>
          </>
        ) : (
          info && <span className={styles.info}>{info}</span>
        )}
      </div>
    );
  },
);
