import * as Avatar from '@radix-ui/react-avatar';
import styles from './ProfilePicture.module.css';

interface ProfilePictureProps {
  src?: string;
  firstName?: string;
  lastName?: string;
  size?: number | null;
}

export const ProfilePicture = ({
  src = '',
  firstName = '',
  lastName = '',
  size = 100,
}: ProfilePictureProps) => {
  return (
    <Avatar.Root
      className={styles['profile-picture']}
      style={{
        height: size || 100,
        width: size || 100,
        display: 'block',
      }}
    >
      <Avatar.Image src={src} title={`${firstName} ${lastName}`} />

      <Avatar.Fallback
        className={styles['profile-initials']}
        style={{
          height: size || 100,
          width: size || 100,
          fontSize: (size || 100) / 3,
        }}
      >
        {firstName ? firstName?.[0].toUpperCase() : ''}
        {lastName ? lastName?.[0].toUpperCase() : ''}
      </Avatar.Fallback>
    </Avatar.Root>
  );
};
