import { useMutation } from 'react-query';
import { toast } from 'react-toastify';
import { OrganizationResponseModel } from 'types/api';
import {
  ContentType,
  useAuthenticatedFetch,
} from 'hooks/util/useAuthenticatedFetch';
import { queryClient } from 'util/queryClientConfig';

export const useCreateOrganization = () => {
  const fetch = useAuthenticatedFetch(ContentType.NONE);
  return useMutation(
    (createOrganization: FormData) =>
      fetch<OrganizationResponseModel>(
        `${process.env.REACT_APP_API_HOST}/organizations`,
        {
          body: createOrganization,
          method: 'POST',
        },
      ),
    {
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      onError: (error: any) => {
        toast.error(
          `Error creating organization. ${error.response._data.Message}`,
        );
      },
      onSuccess: () => {
        toast.success('Organization created successfully.');
        queryClient.invalidateQueries('organizations, profile');
      },
    },
  );
};
