/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

export enum Position {
    GUARD = 'Guard',
    WING = 'Wing',
    BIG = 'Big',
    FORWARD = 'Forward',
    MIDFIELD = 'Midfield',
    DEFENSE = 'Defense',
    GOALIE = 'Goalie',
    OF = 'OF',
    C = 'C',
    IF = 'IF',
    P = 'P',
}