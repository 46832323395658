export const unitsOfMeasure = (keyword: string) => {
  switch (keyword) {
    case 'abduction':
      return 'deg';
    case 'acceleration':
      return 'deg/sec²';
    case 'deceleration':
      return 'deg/sec²';
    case 'degrees':
      return 'deg';
    case 'drive':
      return 'N/kg';
    case 'impact':
      return 'N/kg';
    case 'impulse':
      return '%';
    case 'inversion':
      return 'deg';
    case 'flexion':
      return 'deg';
    case 'force':
      return 'N/kg';
    case 'rotation':
      return 'deg';
    case 'translation':
      return 'deg';
    case 'velocity':
      return 'deg/sec';
    case 'jump':
      return 'in';
    case 'percentage':
      return '%';
  }
};
