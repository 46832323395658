import { AssessmentType, BaseAssessment } from 'types/api';
import { GraphTabs } from 'components/GraphTabs';
import styles from './Mechanics.module.css';
import { useConfig } from './useConfig';
import { useLowBackData } from './useLowBackData';

interface Props {
  assessment: BaseAssessment;
  className?: string;
}

export const LowBack = (props: Props) => {
  const { assessment, className } = props;
  const data = useLowBackData([assessment]);
  const ignoreIds =
    assessment.assessmentType === AssessmentType.SOCCER ? ['radarTab'] : [];
  const config = useConfig('Low Back Mechanics', [assessment], data, ignoreIds);
  return (
    <GraphTabs
      className={className}
      config={config}
      defaultTab="barTab"
      headerClassName={styles.header}
      heading="Low Back Mechanics"
    />
  );
};
