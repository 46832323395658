import { useMutation } from 'react-query';
import { toast } from 'react-toastify';
import { BaseAssessment, UpdateTrainingTargetsRequestModel } from 'types/api';
import {
  ContentType,
  useAuthenticatedFetch,
} from 'hooks/util/useAuthenticatedFetch';

export const useUpdateTrainingTargets = (assessmentId?: string) => {
  const fetch = useAuthenticatedFetch(ContentType.NO_ORGANIZATION_HEADER);
  return useMutation(
    (body: UpdateTrainingTargetsRequestModel) =>
      fetch<BaseAssessment>(
        `${process.env.REACT_APP_API_HOST}/Assessments/${assessmentId}/TrainingTargets`,
        {
          body,
          method: 'PUT',
        },
      ),
    {
      onError: () => {
        toast.error('Error updating training targets.');
      },
      onSuccess: async () => {
        toast.success('Training targets updated successfully!');
      },
    },
  );
};
