import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import {
  AssessmentSortOption,
  AssessmentType,
  BaseAssessment,
  OrganizationResponseModel,
  Role,
  SortDirection,
} from 'types/api';
import { useAssessmentList } from 'hooks/query/useAssessmentList';
import { useAssessmentTypes } from 'hooks/query/useAssessmentTypes';
import { useOrganizationContext } from 'hooks/util/useOrganizationContext';
import { useClearQueryParams, useQueryParam } from 'hooks/util/useQueryParam';
import { Button } from 'components/Button';
import { Loading } from 'components/Loading';
import { Pagination } from 'components/Pagination';
import { DefaultMinLength, SearchBar } from 'components/SearchBar';
import { SelectedPill } from 'components/SelectedPill';
import styles from './List.module.css';
import { parseTableData } from './ParseTableData';
import { Summary } from './Summary';
import { Table } from './Table/Admin';
import { Empty } from './Table/Empty';

interface IAdminView {
  p3Org?: OrganizationResponseModel;
}

const AdminView = (props: IAdminView) => {
  const { contextOrg, contextRole } = useOrganizationContext();
  const { p3Org } = props;
  const [displayedOrg, setDisplayedOrg] = useState<
    OrganizationResponseModel | undefined
  >(contextOrg);

  const navigate = useNavigate();
  const clearQueryParams = useClearQueryParams();
  const [page, setPage] = useQueryParam('page', 1);
  const [searchTerm, setSearchTerm] = useQueryParam('searchTerm');
  const [sortBy, setSortBy] = useQueryParam<AssessmentSortOption>(
    'sortOption',
    AssessmentSortOption.PLAYER_NAME,
  );
  const [sortDirection, setSortDirection] = useQueryParam<SortDirection>(
    'sortDirection',
    SortDirection.ASCENDING,
  );

  const { data: types = [] } = useAssessmentTypes();
  const [assessmentType, setAssessmentQueryParam] =
    useQueryParam<AssessmentType>('type');

  const searchInputRef = useRef<HTMLInputElement>(null);

  const setAssessmentType = useCallback(
    (value: AssessmentType) => {
      // Clear any search params when you switch assessment type
      clearQueryParams();
      setAssessmentQueryParam(value);
      setSelectedAssessments([]);
      setPage(1);
    },
    [clearQueryParams, setPage, setAssessmentQueryParam],
  );

  useEffect(() => {
    if (!assessmentType && types?.length) {
      setAssessmentType(types.filter((x) => x !== AssessmentType.ALL)[1]);
    }
  }, [types, assessmentType, setAssessmentType]);

  const {
    data: assessmentGroups,
    isPreviousData,
    isFetching,
  } = useAssessmentList({
    page,
    sortBy,
    sortDirection,
    searchTerm,
    assessmentType: assessmentType as AssessmentType,
  });

  const parsedData = useMemo(() => {
    if (assessmentGroups && assessmentGroups.items) {
      return parseTableData(assessmentGroups);
    } else return [];
  }, [assessmentGroups]);

  const handleNextPage = useCallback(() => {
    setPage(page + 1);
  }, [page, setPage]);

  const handlePreviousPage = useCallback(() => {
    setPage(page - 1);
  }, [page, setPage]);

  const onSearchSubmit = useCallback(() => {
    const term = searchInputRef.current?.value;
    const isTermValid = term && term.length >= DefaultMinLength;

    if (isTermValid) {
      setSearchTerm(term);
      setPage(1);
    } else {
      setSearchTerm(undefined);
    }
  }, [setPage, setSearchTerm]);

  const onClear = useCallback(() => {
    setSearchTerm(undefined);
    setPage(1);
  }, [setPage, setSearchTerm]);

  const onSortBy = (id: string, sortDirection: SortDirection) => {
    setSortBy(id as AssessmentSortOption);
    setSortDirection(sortDirection);
  };

  const [selectedAssessments, setSelectedAssessments] = useState<
    BaseAssessment[]
  >([]);

  const onToggleSelectRow = useCallback((assessment) => {
    setSelectedAssessments((prevSelected) => {
      const existing = prevSelected.find((prev) => prev.id === assessment.id);
      // if selection exists
      if (existing) {
        return prevSelected.filter((prev) => prev.id !== existing.id);
      } else {
        return [...prevSelected, assessment];
      }
    });
  }, []);

  // If the user is a super admin with no org/roles, then display the P3 organization
  useEffect(() => {
    if (!contextOrg && contextRole === Role.SUPER_ADMIN) {
      setDisplayedOrg(p3Org);
    } else {
      setDisplayedOrg(contextOrg);
    }
  }, [contextOrg, contextRole, p3Org]);

  if (!assessmentGroups || !types) return <Loading />;
  return (
    <section className={`${styles.main}`} data-test-id="organization-summary">
      <div className={styles['summary-container']}>
        {displayedOrg && (
          <>
            <Summary
              organization={displayedOrg}
              role={contextRole}
              activeAthletes={assessmentGroups?.totalCount}
              assessmentsCount={assessmentGroups?.totalAssessmentCount}
            />
          </>
        )}
      </div>

      <div className={styles['table-toolbar']}>
        {types?.length === 1 && (
          <h2 className={styles['table-title']}>Team Assessments</h2>
        )}
        {types?.length > 1 && (
          <select
            onChange={(event) =>
              setAssessmentType(event.target.value as AssessmentType)
            }
            name="assessmentType"
            id="type"
            value={assessmentType}
          >
            {types?.map((type) => (
              <option value={type} key={type}>
                {type === AssessmentType.ALL ? `${type} Assessments` : type}
              </option>
            ))}
          </select>
        )}
        <div className={styles['search-bar']}>
          <SearchBar
            onSearch={onSearchSubmit}
            onToggleOpen={() => {
              searchInputRef.current?.focus();
            }}
            inputRef={searchInputRef}
            clear={onClear}
            inputProps={{ minLength: DefaultMinLength, placeholder: 'Search' }}
          />
        </div>
        {selectedAssessments && assessmentType !== AssessmentType.ALL && (
          <Button
            className={styles['compare-btn']}
            disabled={selectedAssessments.length < 2}
            onClick={() => {
              const idQueryParams = selectedAssessments
                .map((selected) => selected.id)
                .join(',');
              const compareURL = `/assessments/compare?assessmentIds=${idQueryParams}`;

              navigate(compareURL);
            }}
          >
            Compare
          </Button>
        )}
        {selectedAssessments.length > 0 && (
          <div className={styles['selected-rows']}>
            <span className={styles['selected-rows-label']}>Selected:</span>
            <ul>
              {selectedAssessments.map(({ id, name }) => {
                return (
                  <SelectedPill
                    label={name?.split(' ')[1] as string}
                    id={id as string}
                    key={`selected-${id}`}
                    onClose={(id) =>
                      onToggleSelectRow(
                        selectedAssessments.find(
                          (selected) => selected.id === id,
                        ),
                      )
                    }
                  />
                );
              })}
            </ul>
          </div>
        )}
        {parsedData.length === 0 && (
          <div className={styles['table-toolbar-empty']}>
            <Empty hideCTA />
          </div>
        )}
      </div>

      {parsedData.length > 0 && (
        <div className={styles['table-container']}>
          {isFetching ? (
            <div className={styles.spinner}>
              <Loading />
            </div>
          ) : (
            <>
              <Table
                assessmentGroups={parsedData}
                assessmentType={assessmentType as AssessmentType}
                onSortBy={onSortBy}
                sortByDefault={sortBy as AssessmentSortOption}
                onToggleSelect={onToggleSelectRow}
                selectedAssessments={selectedAssessments}
              />
              <Pagination
                hasNextPage={!isPreviousData && assessmentGroups?.hasNextPage}
                hasPreviousPage={
                  !isPreviousData && assessmentGroups?.hasPreviousPage
                }
                onNext={handleNextPage}
                onPrevious={handlePreviousPage}
                page={page}
                totalCount={assessmentGroups?.totalCount}
              />
            </>
          )}
        </div>
      )}
    </section>
  );
};

export { AdminView };
