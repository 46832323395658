export const ForceMoverContent = (props: { name: string }) => {
  const showName = props.name ? (
    <h2>{props.name} is a Force Mover</h2>
  ) : (
    <h2>Force Movers</h2>
  );
  return (
    <div>
      {showName}
      <p>
        Force production - in all planes of movement - separate this group from
        the other clusters. While these athletes do possess impressive power
        output - leading to elite traditional performance measures - they may
        lack kinematic efficiency.
      </p>
      <ul>
        <li>
          Force production numbers (general explosiveness) rate impressively.
        </li>
        <li>Movement efficiency less impressive.</li>
        <li>
          Relative to the league generally possess strong traditional
          performance metrics.
        </li>
      </ul>
      <h2>Force Movers</h2>
      <p>
        Force Movers represent the lowest percentage of NBA All Stars and All
        Star Appearances among P3's Clusters.
      </p>
    </div>
  );
};
