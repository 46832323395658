import { Role } from 'types/api';
import { useOrganization } from 'hooks/query/useOrganization';
import { useOrganizationContext } from 'hooks/util/useOrganizationContext';
import { AdminView } from './AdminView';
import { Empty } from './Table/Empty';

export const AssessmentsList = () => {
  const { data: organization } = useOrganization();
  const { contextRole, noOrgAssigned } = useOrganizationContext();

  if (noOrgAssigned && contextRole !== Role.SUPER_ADMIN) {
    return <Empty hideCTA />;
  }

  if (!noOrgAssigned || (noOrgAssigned && contextRole === Role.SUPER_ADMIN)) {
    if (organization?.items) {
      return <AdminView p3Org={organization?.items[0]} />;
    } else {
      return <AdminView />;
    }
  } else {
    return <AdminView />;
  }
};
