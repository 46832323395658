import { useCallback } from 'react';
import { useParams } from 'react-router-dom';
import { UpdateUserRequestModel } from 'types/api';
import { useUpdateUser } from 'hooks/mutation/useUpdateUser';
import { useAssessmentsByName } from 'hooks/query/useAssessmentsByName';
import { useUser } from 'hooks/query/useUser';
import { useOrganizationContext } from 'hooks/util/useOrganizationContext';
import { getScores } from 'util/getScores';
import { Loading } from 'components/Loading';
import { Form } from './Form';
import { Header } from './Header';
import styles from './Profile.module.css';
import { Sidebar } from './Sidebar';

const Profile = () => {
  const { id = 'my' } = useParams();
  const { noOrgAssigned, contextOrg } = useOrganizationContext();
  const noOrgsAssigned = !!(noOrgAssigned && !contextOrg);
  const {
    isLoading: profileIsLoading,
    error: profileError,
    data: profile,
  } = useUser(id);
  const { mutateAsync } = useUpdateUser(profile?.id, id === 'my');

  const fullName = `${profile?.firstName} ${profile?.lastName}`;
  const { data: assessmentsByName } = useAssessmentsByName(fullName);

  let mostRecentMechanicsScore;
  let mostRecentAthleticismScore;

  if (assessmentsByName?.length) {
    const assessment = assessmentsByName[assessmentsByName.length - 1];
    const { athleticismScore, mechanicsScore } = getScores(assessment);
    mostRecentMechanicsScore = athleticismScore;
    mostRecentAthleticismScore = mechanicsScore;
  }
  const updateForm = useCallback(
    (form: UpdateUserRequestModel) => {
      mutateAsync(form);
    },
    [mutateAsync],
  );

  if (profileIsLoading) <Loading />;

  if (profileError) {
    return (
      <>
        <div>{profileError?.message}</div>
      </>
    );
  }

  if (profile) {
    return (
      <>
        <div>
          <Header
            noOrgAssigned={noOrgsAssigned}
            profile={profile}
            mechanicsScore={mostRecentMechanicsScore}
            athleticismScore={mostRecentAthleticismScore}
          />
          <div className={styles['profile-form-container']}>
            <article className={styles.article}>
              <Form user={profile} onSubmit={updateForm} />
              <Sidebar />
            </article>
          </div>
        </div>
      </>
    );
  }
  return null;
};

export { Profile };
