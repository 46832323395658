import { useAuth0 } from '@auth0/auth0-react';
import { $fetch, FetchOptions } from 'ohmyfetch';
import { useCallback, useEffect, useRef } from 'react';
import { Role } from 'types/api';
import { useOrganizationContext } from './useOrganizationContext';

export enum ContentType {
  APPLICATIONJSON = 'application/json',
  NONE = 'none',
  NO_ORGANIZATION_HEADER = 'none',
  // Even if user is a super admin, add the x-organization header to the request
  OVERRIDE_ADD_ORGANIZATION_HEADER = 'add-organization-header',
}

export const useAuthenticatedFetch = (
  contentType: string = ContentType.APPLICATIONJSON,
) => {
  const { getAccessTokenSilently } = useAuth0();
  const { contextOrg, contextRole } = useOrganizationContext();
  const refContextOrg = useRef(contextOrg?.id);
  const refContextRole = useRef(contextRole);

  useEffect(() => {
    refContextOrg.current = contextOrg?.id;
    refContextRole.current = contextRole;
  }, [contextOrg?.id, contextRole]);

  return useCallback(
    /* eslint-disable-next-line @typescript-eslint/no-explicit-any */
    async <TResponse = any>(
      url: RequestInfo,
      opts?: FetchOptions,
    ): Promise<TResponse> => {
      const headers = new Headers();
      headers.set('Content-Type', contentType);

      try {
        const token = await getAccessTokenSilently();
        headers.set('Authorization', `Bearer ${token}`);
      } catch {}

      // If no context role is set, do not allow the user access to the API
      if (!refContextRole.current) {
        headers.set('x-organization', 'xxx');
      }

      if (refContextOrg.current) {
        headers.set('x-organization', refContextOrg.current);
      }

      if (opts?.headers) {
        Object.entries(opts?.headers).forEach(([key, value]) => {
          headers.set(key, value);
        });
      }

      if (contentType === ContentType.NONE) {
        headers.delete('Content-Type');
      }

      // Removing the x-organization header can give the user access to more organizations than they are allowed to view.
      // Please ensure that you check user permissions when removing this header from your query.
      if (
        contentType === ContentType.NO_ORGANIZATION_HEADER ||
        refContextRole.current === Role.SUPER_ADMIN
      ) {
        headers.delete('x-organization');
      }

      if (contentType === ContentType.OVERRIDE_ADD_ORGANIZATION_HEADER) {
        if (refContextOrg.current) {
          headers.set('x-organization', refContextOrg.current);
        }
      }

      return $fetch(url, {
        ...opts,
        headers,
      });
    },
    [getAccessTokenSilently, contentType],
  );
};
