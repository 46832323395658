import { ReactComponent as Arrow } from 'assets/icons/arrow-right.svg';
import fallbackLogo from 'assets/images/org_fallback_logo.png';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import {
  Organization,
  Role,
  UserOrganizationRole,
  UserResponseModel,
} from 'types/api';
import { useOrganizationByIds } from 'hooks/query/useOrganizationByIds';
import { useOrganizationList } from 'hooks/query/useOrganizationList';
import { useOrganizationContext } from 'hooks/util/useOrganizationContext';
import { Button } from 'components/Button';
import { Loading } from 'components/Loading';
import { Pagination } from 'components/Pagination';
import { ProfilePicture } from 'components/ProfilePicture';
import styles from './StepOne.module.css';

interface StepperProps {
  profile: UserResponseModel | undefined;
  closeModal: () => void;
  setStep: (value: number) => void;
}

export const StepOne = ({ closeModal, profile, setStep }: StepperProps) => {
  const { setContext, contextRole } = useOrganizationContext();
  const navigate = useNavigate();

  const handleSelectOrg = (organization: Organization) => {
    // To set context properly, check for the user's role in the selected organization
    const userRole: Role | undefined = profile?.organizationRoles?.find(
      (x) => x.organizationId === organization?.id,
    )?.role;
    setContext(organization, userRole);
    navigate('organization');
    closeModal();
  };

  const [usersOrganizations, setUsersOrganizations] = useState<string[]>([]);

  const [isSuperAdmin, setSuperAdmin] = useState<boolean>(
    contextRole === Role.SUPER_ADMIN,
  );

  const [page, setPage] = useState<number>(1);

  // If user is a Super Admin, get all organizations in the P3 Database and paginate the results
  const {
    data: organizations,
    isPreviousData,
    isLoading,
  } = useOrganizationList(true, isSuperAdmin, { page });

  const parsedData = useMemo(() => {
    if (organizations && organizations.items) {
      return organizations.items;
    } else return [];
  }, [organizations]);

  const handleNextPage = useCallback(() => {
    setPage(page + 1);
  }, [page, setPage]);

  const handlePreviousPage = useCallback(() => {
    setPage(page - 1);
  }, [page, setPage]);

  const getUsersOrganizations = useOrganizationByIds(usersOrganizations);

  useEffect(() => {
    // If user is a Super Admin, we want to display all organizations in the P3 database.
    if (contextRole === Role.SUPER_ADMIN) {
      setSuperAdmin(true);
    }
    // If user is an Admin, we want to display only the organizations that user is an Admin of.
    if (contextRole === Role.ORGANIZATION_ADMIN) {
      const ids: string[] = [];
      profile?.organizationRoles?.forEach((role: UserOrganizationRole) => {
        if (
          role.organizationId &&
          (role.role === Role.ORGANIZATION_ADMIN ||
            role.role === Role.SUPER_ADMIN)
        ) {
          ids.push(role.organizationId);
        }
      });
      setUsersOrganizations(ids);
    }
  }, [contextRole, profile?.organizationRoles]);

  // If user is an Admin, get only the Organizations they belong to. We need to do get of the full Organization model to populate the organization blob image in the modal.
  let usersFullOrganizations: Organization[] = [];
  if (contextRole === Role.SUPER_ADMIN) {
    usersFullOrganizations = parsedData;
  }
  if (contextRole === Role.ORGANIZATION_ADMIN) {
    const isSuccess = getUsersOrganizations.some((result) => result.isSuccess);
    if (isSuccess) {
      getUsersOrganizations?.forEach((org) => {
        if (org.data) {
          usersFullOrganizations.push(org.data);
        }
      });
    }
  }

  return (
    <>
      <div className={styles['modal-header']}>
        <h2>Organizations</h2>
        {contextRole === Role.SUPER_ADMIN && (
          <Button
            onClick={() => setStep(2)}
            className={styles['create-button']}
          >
            Create
          </Button>
        )}
      </div>
      {isLoading && (
        <div className={styles.loader}>
          <Loading />
        </div>
      )}

      <ul>
        {usersFullOrganizations?.map((organization, i) => (
          <li key={i}>
            <button
              className={styles.link}
              onClick={() => handleSelectOrg(organization)}
            >
              {organization?.blobImageUri ? (
                <span className={styles['org-image']}>
                  <img
                    role="presentation"
                    src={organization?.blobImageUri}
                    onError={(e) => {
                      const target = e.target as HTMLImageElement;
                      target.src = fallbackLogo;
                    }}
                  />
                </span>
              ) : (
                <ProfilePicture
                  size={43}
                  firstName={
                    organization.organizationName
                      ? organization.organizationName
                      : ''
                  }
                />
              )}
              <span className={styles['org-name']}>
                {organization?.organizationName}
              </span>
              <span className={styles['switch-modal-arrow']}>
                <Arrow />
              </span>
            </button>
          </li>
        ))}
        {contextRole === Role.SUPER_ADMIN && (
          <Pagination
            hasNextPage={!isPreviousData && organizations?.hasNextPage}
            hasPreviousPage={!isPreviousData && organizations?.hasPreviousPage}
            onNext={handleNextPage}
            onPrevious={handlePreviousPage}
            page={page}
            totalCount={organizations?.totalCount}
          />
        )}
      </ul>
    </>
  );
};
