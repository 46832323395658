import { useEffect, useState } from 'react';
import { BaseAssessment } from 'types/api';
import {
  CompareAssessmentsForm,
  IComparedAssessmentsFormObject,
} from 'components/CompareAssessmentsForm';

interface ICompareAthleticismProps {
  assessments: BaseAssessment[];
  comparedAssessments: BaseAssessment[];
}

export const Athlete = ({
  assessments,
  comparedAssessments,
}: ICompareAthleticismProps) => {
  const [formError, setFormError] = useState(false);
  const initializeDisplayObject: IComparedAssessmentsFormObject[] = [];
  const [displayObject, setDisplayObject] = useState(initializeDisplayObject);
  const handleChange = () => {
    // Reset form error state
    setFormError(false);
    const selectedAssessments: BaseAssessment[] = displayObject.filter(
      (obj) => obj.selected,
    );
    if (selectedAssessments.length === 1) {
      setFormError(true);
    }
  };

  useEffect(() => {
    const comparedAssessmentsFormObject: IComparedAssessmentsFormObject[] = [];

    const buildComparedAssessmentsFormObject = () => {
      if (!comparedAssessments.length) {
        assessments.forEach((a: BaseAssessment) => {
          const obj: IComparedAssessmentsFormObject = {
            ...a,
            selected: false,
          };
          comparedAssessmentsFormObject.push(obj);
        });
      } else {
        assessments.forEach((a: BaseAssessment) => {
          const findIfAlreadySelected = comparedAssessments.find(
            ({ id }) => id === a.id,
          );
          if (findIfAlreadySelected) {
            const obj: IComparedAssessmentsFormObject = {
              ...a,
              selected: true,
            };
            comparedAssessmentsFormObject.push(obj);
          } else {
            const obj: IComparedAssessmentsFormObject = {
              ...a,
              selected: false,
            };
            comparedAssessmentsFormObject.push(obj);
          }
        });
      }
    };
    buildComparedAssessmentsFormObject();
    setDisplayObject(comparedAssessmentsFormObject);
  }, [assessments, comparedAssessments]);

  return (
    <>
      <CompareAssessmentsForm
        formObject={displayObject}
        totalAssessmentCount={assessments.length}
        formError={formError}
        onSubmit={handleChange}
      />
    </>
  );
};
