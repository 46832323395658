export const ArchetypeSVG = ({
  archetype,
  handleOver,
  handleOut,
  hideAxes,
}) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 1200 800"
      style={{
        width: '100%',
        height: '100%',
        transform: 'translate3d(0,0,0)',
      }}
      data-archetype={archetype}
      className={`${hideAxes ? 'hide-axes' : ''}`}
    >
      <defs>
        <clipPath id="a">
          <path d="M0 0h1200v800H0z" />
        </clipPath>
        <clipPath id="n">
          <path d="M0 0h1200v800H0z" />
        </clipPath>
        <clipPath id="c">
          <path d="M0 0h1300v800H0z" />
        </clipPath>
        <clipPath id="m">
          <path d="M0 0h8v800H0z" />
        </clipPath>
        <clipPath id="l">
          <path d="M0 0h8v800H0z" />
        </clipPath>
        <clipPath id="k">
          <path d="M0 0h8v800H0z" />
        </clipPath>
        <clipPath id="j">
          <path d="M0 0h8v800H0z" />
        </clipPath>
        <clipPath id="i">
          <path d="M0 0h8v800H0z" />
        </clipPath>
        <clipPath id="h">
          <path d="M0 0h8v800H0z" />
        </clipPath>
        <clipPath id="g">
          <path d="M0 0h8v800H0z" />
        </clipPath>
        <clipPath id="f">
          <path d="M0 0h8v800H0z" />
        </clipPath>
        <clipPath id="e">
          <path d="M0 0h8v800H0z" />
        </clipPath>
        <clipPath id="d">
          <path d="M0 0h8v800H0z" />
        </clipPath>
        <clipPath id="b">
          <path d="M0 0h1200v800H0z" />
        </clipPath>
      </defs>
      <g clipPath="url(#a)">
        <g
          onMouseEnter={handleOver}
          onTouchStart={handleOver}
          onMouseOut={handleOut}
          onTouchEnd={handleOut}
          data-archetype="TraditionalBig"
        >
          <path d="m245 273 34 2 113 44 22 34-3 35-69 98-185 45L36 370l209-97z" />
          <path
            strokeLinecap="round"
            stroke="#FF727B"
            strokeWidth={2}
            d="m245 273 34 2 113 44 22 34-3 35-69 98-185 45L36 370l209-97z"
          />
        </g>
        <g
          onMouseEnter={handleOver}
          onTouchStart={handleOver}
          onMouseOut={handleOut}
          onTouchEnd={handleOut}
          data-archetype="BigPlus"
        >
          <path d="m531 447-110 12-43 10-60 18-29 115 80 73 88-6 129-90 10-53-65-79z" />
          <path
            stroke="#54A6FE"
            strokeWidth={2}
            d="m531 447-110 12-43 10-60 18-29 115 80 73 88-6 129-90 10-53-65-79z"
          />
        </g>
        <g
          onMouseEnter={handleOver}
          onTouchStart={handleOver}
          onMouseOut={handleOut}
          onTouchEnd={handleOut}
          data-archetype="MiscPerimeter"
        >
          <path d="m436 256 132 1 15 114-36 74-67 19-30-20-76-73-3.121-15.381L360 302l76-46z" />
          <path
            stroke="#6EF0A0"
            strokeWidth={2}
            d="m436 256 132 1 15 114-36 74-67 19-30-20-76-73-3.121-15.381L360 302l76-46z"
          />
        </g>
        <g
          onMouseEnter={handleOver}
          onTouchStart={handleOver}
          onMouseOut={handleOut}
          onTouchEnd={handleOut}
          data-archetype="Specimen"
        >
          <path d="m818 361 152 142-278 191-123-131 19-136 230-66z" />
          <path
            stroke="#FEDE69"
            strokeWidth={2}
            d="m818 361 152 142-278 191-123-131 19-136 230-66z"
          />
        </g>
        <g
          onMouseEnter={handleOver}
          onTouchStart={handleOver}
          onMouseOut={handleOut}
          onTouchEnd={handleOut}
          data-archetype="HyperAthleticGuard"
        >
          <path d="m777 275-43 108 103 135 193-49 27-85-225-107-55-2z" />
          <path
            stroke="#FBAD6F"
            strokeWidth={2}
            d="m777 275-43 108 103 135 193-49 27-85-225-107-55-2z"
          />
        </g>
        <g
          onMouseEnter={handleOver}
          onTouchStart={handleOver}
          onMouseOut={handleOut}
          onTouchEnd={handleOut}
          data-archetype="KinematicMover"
        >
          <path d="m723 113 64 130 36 90-85 61-131 14-104-23-12-124 29-122 203-26z" />
          <path
            stroke="#54FFF2"
            strokeWidth={2}
            d="m723 113 64 130 36 90-85 61-131 14-104-23-12-124 29-122 203-26z"
          />
        </g>
        <g
          onMouseEnter={handleOver}
          onTouchStart={handleOver}
          onMouseOut={handleOut}
          onTouchEnd={handleOut}
          data-archetype="ForceMover"
        >
          <path d="m633 354-85 32-10 116 39 14 196-15 11-60-116-70-35-17z" />
          <path
            stroke="#E66BFF"
            strokeWidth={2}
            d="m633 354-85 32-10 116 39 14 196-15 11-60-116-70-35-17z"
          />
        </g>
        <g clipPath="url(#b)" transform="translate(-50)">
          <path
            fill="#FF727B"
            d="M3 0a3 3 0 1 1-6 0 3 3 0 0 1 6 0z"
            transform="translate(87.287 369.054)"
          />
          <path
            fill="#FF727B"
            d="M3 0a3 3 0 1 1-6 0 3 3 0 0 1 6 0z"
            transform="translate(142.078 355.013)"
          />
          <path
            fill="#FF727B"
            d="M3 0a3 3 0 1 1-6 0 3 3 0 0 1 6 0z"
            transform="translate(122.636 416.664)"
          />
          <path
            fill="#FF727B"
            d="M3 0a3 3 0 1 1-6 0 3 3 0 0 1 6 0z"
            transform="translate(174.583 343.086)"
          />
          <path
            fill="#FF727B"
            d="M3 0a3 3 0 1 1-6 0 3 3 0 0 1 6 0z"
            transform="translate(181.583 380.711)"
          />
          <path
            fill="#FF727B"
            d="M3 0a3 3 0 1 1-6 0 3 3 0 0 1 6 0z"
            transform="translate(204.845 380.711)"
          />
          <path
            fill="#FF727B"
            d="M3 0a3 3 0 1 1-6 0 3 3 0 0 1 6 0z"
            transform="translate(204.845 428.296)"
          />
          <path
            fill="#FF727B"
            d="M3 0a3 3 0 1 1-6 0 3 3 0 0 1 6 0z"
            transform="translate(256.583 465.578)"
          />
          <path
            fill="#FF727B"
            d="M3 0a3 3 0 1 1-6 0 3 3 0 0 1 6 0z"
            transform="translate(248.983 363.054)"
          />
          <path
            fill="#FF727B"
            d="M3 0a3 3 0 1 1-6 0 3 3 0 0 1 6 0z"
            transform="translate(250.583 386.711)"
          />
          <path
            fill="#FF727B"
            d="M3 0a3 3 0 1 1-6 0 3 3 0 0 1 6 0z"
            transform="translate(281.185 408.267)"
          />
          <path
            fill="#FF727B"
            d="M3 0a3 3 0 1 1-6 0 3 3 0 0 1 6 0z"
            transform="translate(289.08 413.664)"
          />
          <path
            fill="#FF727B"
            d="M3 0a3 3 0 1 1-6 0 3 3 0 0 1 6 0z"
            transform="translate(283.08 400.829)"
          />
          <path
            fill="#FF727B"
            d="M3 0a3 3 0 1 1-6 0 3 3 0 0 1 6 0z"
            transform="translate(296.655 392.711)"
          />
          <path
            fill="#FF727B"
            d="M3 0a3 3 0 1 1-6 0 3 3 0 0 1 6 0z"
            transform="translate(326.588 395.711)"
          />
          <path
            fill="#FF727B"
            d="M3 0a3 3 0 1 1-6 0 3 3 0 0 1 6 0z"
            transform="translate(360.792 403.829)"
          />
          <path
            fill="#FF727B"
            d="M3 0a3 3 0 1 1-6 0 3 3 0 0 1 6 0z"
            transform="translate(256.583 506.388)"
          />
          <path
            fill="#FF727B"
            d="M3 0a3 3 0 1 1-6 0 3 3 0 0 1 6 0z"
            transform="translate(208.896 528.924)"
          />
          <path
            fill="#FF727B"
            d="M3 0a3 3 0 1 1-6 0 3 3 0 0 1 6 0z"
            transform="translate(388.163 487.132)"
          />
          <path
            fill="#FF727B"
            d="M3 0a3 3 0 1 1-6 0 3 3 0 0 1 6 0z"
            transform="translate(419.741 445.93)"
          />
          <path
            fill="#FF727B"
            d="M3 0a3 3 0 1 1-6 0 3 3 0 0 1 6 0z"
            transform="translate(461.997 386.711)"
          />
          <path
            fill="#FF727B"
            d="M3 0a3 3 0 1 1-6 0 3 3 0 0 1 6 0z"
            transform="translate(463.369 352.013)"
          />
          <path
            fill="#FF727B"
            d="M3 0a3 3 0 1 1-6 0 3 3 0 0 1 6 0z"
            transform="translate(442.95 320.815)"
          />
          <path
            fill="#FF727B"
            d="M3 0a3 3 0 1 1-6 0 3 3 0 0 1 6 0z"
            transform="translate(329.588 274.924)"
          />
          <path
            fill="#FF727B"
            d="M3 0a3 3 0 1 1-6 0 3 3 0 0 1 6 0z"
            transform="translate(296.497 273.704)"
          />
          <path
            fill="#FF727B"
            d="M3 0a3 3 0 1 1-6 0 3 3 0 0 1 6 0z"
            transform="translate(251.983 301.117)"
          />
          <path
            fill="#FF727B"
            d="M3 0a3 3 0 1 1-6 0 3 3 0 0 1 6 0z"
            transform="translate(257.983 307.117)"
          />
          <path
            fill="#FF727B"
            d="M3 0a3 3 0 1 1-6 0 3 3 0 0 1 6 0z"
            transform="translate(269.535 323.815)"
          />
          <path
            fill="#FF727B"
            d="M3 0a3 3 0 1 1-6 0 3 3 0 0 1 6 0z"
            transform="translate(290.497 346.086)"
          />
          <path
            fill="#FF727B"
            d="M3 0a3 3 0 1 1-6 0 3 3 0 0 1 6 0z"
            transform="translate(304.137 355.013)"
          />
          <path
            fill="#FF727B"
            d="M3 0a3 3 0 1 1-6 0 3 3 0 0 1 6 0z"
            transform="translate(323.588 355.013)"
          />
          <path
            fill="#FF727B"
            d="M3 0a3 3 0 1 1-6 0 3 3 0 0 1 6 0z"
            transform="translate(335.588 352.086)"
          />
          <path
            fill="#FF727B"
            d="M3 0a3 3 0 1 1-6 0 3 3 0 0 1 6 0z"
            transform="translate(369.259 314.815)"
          />
          <path
            fill="#FF727B"
            d="M3 0a3 3 0 1 1-6 0 3 3 0 0 1 6 0z"
            transform="translate(400.238 319.356)"
          />
          <path
            fill="#FF727B"
            d="M3 0a3 3 0 1 1-6 0 3 3 0 0 1 6 0z"
            transform="translate(422.741 338.704)"
          />
          <path
            fill="#FF727B"
            d="M3 0a3 3 0 1 1-6 0 3 3 0 0 1 6 0z"
            transform="translate(428.741 366.054)"
          />
          <path
            fill="#FF727B"
            d="M3 0a3 3 0 1 1-6 0 3 3 0 0 1 6 0z"
            transform="translate(397.238 380.711)"
          />
          <path
            fill="#FF727B"
            d="M3 0a3 3 0 1 1-6 0 3 3 0 0 1 6 0z"
            transform="translate(373.574 354.013)"
          />
          <path
            fill="#FF727B"
            d="M3 0a3 3 0 1 1-6 0 3 3 0 0 1 6 0z"
            transform="translate(376.574 364.458)"
          />
          <path
            fill="#FF727B"
            d="M3 0a3 3 0 1 1-6 0 3 3 0 0 1 6 0z"
            transform="translate(389.745 375.03)"
          />
          <path
            fill="#FF727B"
            d="M3 0a3 3 0 1 1-6 0 3 3 0 0 1 6 0z"
            transform="translate(383.745 392.711)"
          />
          <path
            fill="#FF727B"
            d="M3 0a3 3 0 1 1-6 0 3 3 0 0 1 6 0z"
            transform="translate(386.745 398.711)"
          />
          <path
            fill="#FF727B"
            d="M3 0a3 3 0 1 1-6 0 3 3 0 0 1 6 0z"
            transform="translate(398.804 408.267)"
          />
          <path
            fill="#FF727B"
            d="M3 0a3 3 0 1 1-6 0 3 3 0 0 1 6 0z"
            transform="translate(394.238 428.296)"
          />
          <path
            fill="#FF727B"
            d="M3 0a3 3 0 1 1-6 0 3 3 0 0 1 6 0z"
            transform="translate(293.497 478.179)"
          />
          <path
            fill="#FF727B"
            d="M3 0a3 3 0 1 1-6 0 3 3 0 0 1 6 0z"
            transform="translate(311.963 468.578)"
          />
          <path
            fill="#FF727B"
            d="M3 0a3 3 0 1 1-6 0 3 3 0 0 1 6 0z"
            transform="translate(328.495 484.132)"
          />
          <path
            fill="#FF727B"
            d="M3 0a3 3 0 1 1-6 0 3 3 0 0 1 6 0z"
            transform="translate(367.574 459.578)"
          />
          <path
            fill="#FF727B"
            d="M3 0a3 3 0 1 1-6 0 3 3 0 0 1 6 0z"
            transform="translate(357.792 493.132)"
          />
          <path
            fill="#FF727B"
            d="M3 0a3 3 0 1 1-6 0 3 3 0 0 1 6 0z"
            transform="translate(364.574 289.362)"
          />
          <path
            fill="#54A6FE"
            d="M3 0a3 3 0 1 1-6 0 3 3 0 0 1 6 0z"
            transform="translate(389.745 543.197)"
          />
          <path
            fill="#54A6FE"
            d="M3 0a3 3 0 1 1-6 0 3 3 0 0 1 6 0z"
            transform="translate(367.574 492.228)"
          />
          <path
            fill="#54A6FE"
            d="M3 0a3 3 0 1 1-6 0 3 3 0 0 1 6 0z"
            transform="translate(338.588 602.775)"
          />
          <path
            fill="#54A6FE"
            d="M3 0a3 3 0 1 1-6 0 3 3 0 0 1 6 0z"
            transform="translate(481.692 571.822)"
          />
          <path
            fill="#54A6FE"
            d="M3 0a3 3 0 1 1-6 0 3 3 0 0 1 6 0z"
            transform="translate(419.741 675.243)"
          />
          <path
            fill="#54A6FE"
            d="M3 0a3 3 0 1 1-6 0 3 3 0 0 1 6 0z"
            transform="translate(491.006 610.407)"
          />
          <path
            fill="#54A6FE"
            d="M3 0a3 3 0 1 1-6 0 3 3 0 0 1 6 0z"
            transform="translate(464.997 507.467)"
          />
          <path
            fill="#54A6FE"
            d="M3 0a3 3 0 1 1-6 0 3 3 0 0 1 6 0z"
            transform="translate(442.95 511.926)"
          />
          <path
            fill="#54A6FE"
            d="M3 0a3 3 0 1 1-6 0 3 3 0 0 1 6 0z"
            transform="translate(428.741 468.578)"
          />
          <path
            fill="#54A6FE"
            d="M3 0a3 3 0 1 1-6 0 3 3 0 0 1 6 0z"
            transform="translate(453.457 515.926)"
          />
          <path
            fill="#54A6FE"
            d="M3 0a3 3 0 1 1-6 0 3 3 0 0 1 6 0z"
            transform="translate(469.369 460.035)"
          />
          <path
            fill="#54A6FE"
            d="M3 0a3 3 0 1 1-6 0 3 3 0 0 1 6 0z"
            transform="translate(458.369 542.124)"
          />
          <path
            fill="#54A6FE"
            d="M3 0a3 3 0 1 1-6 0 3 3 0 0 1 6 0z"
            transform="translate(636.461 576.822)"
          />
          <path
            fill="#54A6FE"
            d="M3 0a3 3 0 1 1-6 0 3 3 0 0 1 6 0z"
            transform="translate(645.461 526.816)"
          />
          <path
            fill="#54A6FE"
            d="M3 0a3 3 0 1 1-6 0 3 3 0 0 1 6 0z"
            transform="translate(539.465 634.041)"
          />
          <path
            fill="#54A6FE"
            d="M3 0a3 3 0 1 1-6 0 3 3 0 0 1 6 0z"
            transform="translate(506.717 668.29)"
          />
          <path
            fill="#54A6FE"
            d="M3 0a3 3 0 1 1-6 0 3 3 0 0 1 6 0z"
            transform="translate(515.717 536.124)"
          />
          <path
            fill="#54A6FE"
            d="M3 0a3 3 0 1 1-6 0 3 3 0 0 1 6 0z"
            transform="translate(548.856 531.197)"
          />
          <path
            fill="#6EF0A0"
            d="M3 0a3 3 0 1 1-6 0 3 3 0 0 1 6 0z"
            transform="translate(630.461 372.03)"
          />
          <path
            fill="#6EF0A0"
            d="M3 0a3 3 0 1 1-6 0 3 3 0 0 1 6 0z"
            transform="translate(503.006 319.356)"
          />
          <path
            fill="#6EF0A0"
            d="M3 0a3 3 0 1 1-6 0 3 3 0 0 1 6 0z"
            transform="translate(500.006 442.93)"
          />
          <path
            fill="#54A6FE"
            d="M3 0a3 3 0 1 1-6 0 3 3 0 0 1 6 0z"
            transform="translate(586.485 474.474)"
          />
          <path
            fill="#54A6FE"
            d="M3 0a3 3 0 1 1-6 0 3 3 0 0 1 6 0z"
            transform="translate(596.369 465.815)"
          />
          <path
            fill="#54A6FE"
            d="M3 0a3 3 0 1 1-6 0 3 3 0 0 1 6 0z"
            transform="translate(555.856 489.228)"
          />
          <path
            fill="#54A6FE"
            d="M3 0a3 3 0 1 1-6 0 3 3 0 0 1 6 0z"
            transform="translate(561.856 495.228)"
          />
          <path
            fill="#54A6FE"
            d="M3 0a3 3 0 1 1-6 0 3 3 0 0 1 6 0z"
            transform="translate(573.407 511.926)"
          />
          <path
            fill="#54A6FE"
            d="M3 0a3 3 0 1 1-6 0 3 3 0 0 1 6 0z"
            transform="translate(594.369 534.197)"
          />
          <path
            fill="#54A6FE"
            d="M3 0a3 3 0 1 1-6 0 3 3 0 0 1 6 0z"
            transform="translate(608.01 543.124)"
          />
          <path
            fill="#54A6FE"
            d="M3 0a3 3 0 1 1-6 0 3 3 0 0 1 6 0z"
            transform="translate(627.461 543.124)"
          />
          <path
            fill="#54A6FE"
            d="M3 0a3 3 0 1 1-6 0 3 3 0 0 1 6 0z"
            transform="translate(639.461 540.197)"
          />
          <path
            fill="#6EF0A0"
            d="M3 0a3 3 0 1 1-6 0 3 3 0 0 1 6 0z"
            transform="translate(469.369 363.054)"
          />
          <path
            fill="#6EF0A0"
            d="M3 0a3 3 0 1 1-6 0 3 3 0 0 1 6 0z"
            transform="translate(422.741 372.03)"
          />
          <path
            fill="#6EF0A0"
            d="M3 0a3 3 0 1 1-6 0 3 3 0 0 1 6 0z"
            transform="translate(528.679 463.035)"
          />
          <path
            fill="#6EF0A0"
            d="M3 0a3 3 0 1 1-6 0 3 3 0 0 1 6 0z"
            transform="translate(470.369 383.711)"
          />
          <path
            fill="#6EF0A0"
            d="M3 0a3 3 0 1 1-6 0 3 3 0 0 1 6 0z"
            transform="translate(596.369 445.93)"
          />
          <path
            fill="#54A6FE"
            d="M3 0a3 3 0 1 1-6 0 3 3 0 0 1 6 0z"
            transform="translate(580.485 445.93)"
          />
          <path
            fill="#6EF0A0"
            d="M3 0a3 3 0 1 1-6 0 3 3 0 0 1 6 0z"
            transform="translate(485.692 257.307)"
          />
          <path
            fill="#6EF0A0"
            d="M3 0a3 3 0 1 1-6 0 3 3 0 0 1 6 0z"
            transform="translate(618.001 259.307)"
          />
          <path
            fill="#54A6FE"
            d="M3 0a3 3 0 1 1-6 0 3 3 0 0 1 6 0z"
            transform="translate(545.856 462.578)"
          />
          <path
            fill="#54A6FE"
            d="M3 0a3 3 0 1 1-6 0 3 3 0 0 1 6 0z"
            transform="translate(617.001 490.132)"
          />
          <path
            fill="#6EF0A0"
            d="M3 0a3 3 0 1 1-6 0 3 3 0 0 1 6 0z"
            transform="translate(411.627 302.117)"
          />
          <path
            fill="#54A6FE"
            d="M3 0a3 3 0 1 1-6 0 3 3 0 0 1 6 0z"
            transform="translate(583.485 523.816)"
          />
          <path
            fill="#54A6FE"
            d="M3 0a3 3 0 1 1-6 0 3 3 0 0 1 6 0z"
            transform="translate(500.006 559.165)"
          />
          <path
            fill="#54A6FE"
            d="M3 0a3 3 0 1 1-6 0 3 3 0 0 1 6 0z"
            transform="translate(509.717 556.165)"
          />
          <path
            fill="#54A6FE"
            d="M3 0a3 3 0 1 1-6 0 3 3 0 0 1 6 0z"
            transform="translate(553.856 576.822)"
          />
          <path
            fill="#54A6FE"
            d="M3 0a3 3 0 1 1-6 0 3 3 0 0 1 6 0z"
            transform="translate(509.717 539.124)"
          />
          <path
            fill="#54A6FE"
            d="M3 0a3 3 0 1 1-6 0 3 3 0 0 1 6 0z"
            transform="translate(525.679 563.142)"
          />
          <path
            fill="#6EF0A0"
            d="M3 0a3 3 0 1 1-6 0 3 3 0 0 1 6 0z"
            transform="translate(458.369 363.054)"
          />
          <path
            fill="#6EF0A0"
            d="M3 0a3 3 0 1 1-6 0 3 3 0 0 1 6 0z"
            transform="translate(561.856 326.815)"
          />
          <path
            fill="#6EF0A0"
            d="M3 0a3 3 0 1 1-6 0 3 3 0 0 1 6 0z"
            transform="translate(580.485 338.704)"
          />
          <path
            fill="#6EF0A0"
            d="M3 0a3 3 0 1 1-6 0 3 3 0 0 1 6 0z"
            transform="translate(561.856 355.013)"
          />
          <path
            fill="#6EF0A0"
            d="M3 0a3 3 0 1 1-6 0 3 3 0 0 1 6 0z"
            transform="translate(583.485 375.03)"
          />
          <path
            fill="#6EF0A0"
            d="M3 0a3 3 0 1 1-6 0 3 3 0 0 1 6 0z"
            transform="translate(525.679 405.267)"
          />
          <path
            fill="#6EF0A0"
            d="M3 0a3 3 0 1 1-6 0 3 3 0 0 1 6 0z"
            transform="translate(506.983 369.054)"
          />
          <path
            fill="#6EF0A0"
            d="M3 0a3 3 0 1 1-6 0 3 3 0 0 1 6 0z"
            transform="translate(528.679 273.704)"
          />
          <path
            fill="#6EF0A0"
            d="M3 0a3 3 0 1 1-6 0 3 3 0 0 1 6 0z"
            transform="translate(525.679 302.117)"
          />
          <path
            fill="#6EF0A0"
            d="M3 0a3 3 0 1 1-6 0 3 3 0 0 1 6 0z"
            transform="translate(545.856 292.362)"
          />
          <path
            fill="#6EF0A0"
            d="M3 0a3 3 0 1 1-6 0 3 3 0 0 1 6 0z"
            transform="translate(558.856 270.704)"
          />
          <path
            fill="#6EF0A0"
            d="M3 0a3 3 0 1 1-6 0 3 3 0 0 1 6 0z"
            transform="translate(567.856 280.924)"
          />
          <path
            fill="#6EF0A0"
            d="M3 0a3 3 0 1 1-6 0 3 3 0 0 1 6 0z"
            transform="translate(564.856 310.117)"
          />
          <path
            fill="#6EF0A0"
            d="M3 0a3 3 0 1 1-6 0 3 3 0 0 1 6 0z"
            transform="translate(576.407 280.924)"
          />
          <path
            fill="#6EF0A0"
            d="M3 0a3 3 0 1 1-6 0 3 3 0 0 1 6 0z"
            transform="translate(596.369 270.704)"
          />
          <path
            fill="#54FFF2"
            d="M3 0a3 3 0 1 1-6 0 3 3 0 0 1 6 0z"
            transform="translate(569.407 138.802)"
          />
          <path
            fill="#54FFF2"
            d="M3 0a3 3 0 1 1-6 0 3 3 0 0 1 6 0z"
            transform="translate(783.125 156.729)"
          />
          <path
            fill="#54FFF2"
            d="M3 0a3 3 0 1 1-6 0 3 3 0 0 1 6 0z"
            transform="translate(789.125 228.012)"
          />
          <path
            fill="#54FFF2"
            d="M3 0a3 3 0 1 1-6 0 3 3 0 0 1 6 0z"
            transform="translate(840.863 265.294)"
          />
          <path
            fill="#54FFF2"
            d="M3 0a3 3 0 1 1-6 0 3 3 0 0 1 6 0z"
            transform="translate(682.146 141.802)"
          />
          <path
            fill="#54FFF2"
            d="M3 0a3 3 0 1 1-6 0 3 3 0 0 1 6 0z"
            transform="translate(673.254 145.802)"
          />
          <path
            fill="#54FFF2"
            d="M3 0a3 3 0 1 1-6 0 3 3 0 0 1 6 0z"
            transform="translate(872.36 331.815)"
          />
          <path
            fill="#54FFF2"
            d="M3 0a3 3 0 1 1-6 0 3 3 0 0 1 6 0z"
            transform="translate(837.863 244.07)"
          />
          <path
            fill="#54FFF2"
            d="M3 0a3 3 0 1 1-6 0 3 3 0 0 1 6 0z"
            transform="translate(775.117 231.012)"
          />
          <path
            fill="#54FFF2"
            d="M3 0a3 3 0 1 1-6 0 3 3 0 0 1 6 0z"
            transform="translate(722.855 210.983)"
          />
          <path
            fill="#54FFF2"
            d="M3 0a3 3 0 1 1-6 0 3 3 0 0 1 6 0z"
            transform="translate(786.125 259.237)"
          />
          <path
            fill="#54FFF2"
            d="M3 0a3 3 0 1 1-6 0 3 3 0 0 1 6 0z"
            transform="translate(791.125 293.362)"
          />
          <path
            fill="#54FFF2"
            d="M3 0a3 3 0 1 1-6 0 3 3 0 0 1 6 0z"
            transform="translate(710.393 292.362)"
          />
          <path
            fill="#54FFF2"
            d="M3 0a3 3 0 1 1-6 0 3 3 0 0 1 6 0z"
            transform="translate(722.855 185.3)"
          />
          <path
            fill="#54FFF2"
            d="M3 0a3 3 0 1 1-6 0 3 3 0 0 1 6 0z"
            transform="translate(772.117 113.531)"
          />
          <path
            fill="#54FFF2"
            d="M3 0a3 3 0 1 1-6 0 3 3 0 0 1 6 0z"
            transform="translate(741.916 195.427)"
          />
          <path
            fill="#54FFF2"
            d="M3 0a3 3 0 1 1-6 0 3 3 0 0 1 6 0z"
            transform="translate(720.855 174.362)"
          />
          <path
            fill="#54FFF2"
            d="M3 0a3 3 0 1 1-6 0 3 3 0 0 1 6 0z"
            transform="translate(786.125 207.983)"
          />
          <path
            fill="#54FFF2"
            d="M3 0a3 3 0 1 1-6 0 3 3 0 0 1 6 0z"
            transform="translate(783.125 267.704)"
          />
          <path
            fill="#54FFF2"
            d="M3 0a3 3 0 1 1-6 0 3 3 0 0 1 6 0z"
            transform="translate(797.125 355.086)"
          />
          <path
            fill="#54FFF2"
            d="M3 0a3 3 0 1 1-6 0 3 3 0 0 1 6 0z"
            transform="translate(555.856 207.983)"
          />
          <path
            fill="#54FFF2"
            d="M3 0a3 3 0 1 1-6 0 3 3 0 0 1 6 0z"
            transform="translate(747.916 280.924)"
          />
          <path
            fill="#54FFF2"
            d="M3 0a3 3 0 1 1-6 0 3 3 0 0 1 6 0z"
            transform="translate(561.856 251.731)"
          />
          <path
            fill="#54FFF2"
            d="M3 0a3 3 0 1 1-6 0 3 3 0 0 1 6 0z"
            transform="translate(555.856 234.012)"
          />
          <path
            fill="#54FFF2"
            d="M3 0a3 3 0 1 1-6 0 3 3 0 0 1 6 0z"
            transform="translate(806.64 283.924)"
          />
          <path
            fill="#54FFF2"
            d="M3 0a3 3 0 1 1-6 0 3 3 0 0 1 6 0z"
            transform="translate(750.916 361.458)"
          />
          <path
            fill="#54FFF2"
            d="M3 0a3 3 0 1 1-6 0 3 3 0 0 1 6 0z"
            transform="translate(636.461 249.936)"
          />
          <path
            fill="#54FFF2"
            d="M3 0a3 3 0 1 1-6 0 3 3 0 0 1 6 0z"
            transform="translate(656.763 267.704)"
          />
          <path
            fill="#54FFF2"
            d="M3 0a3 3 0 1 1-6 0 3 3 0 0 1 6 0z"
            transform="translate(627.461 302.117)"
          />
          <path
            fill="#54FFF2"
            d="M3 0a3 3 0 1 1-6 0 3 3 0 0 1 6 0z"
            transform="translate(673.254 290.362)"
          />
          <path
            fill="#54FFF2"
            d="M3 0a3 3 0 1 1-6 0 3 3 0 0 1 6 0z"
            transform="translate(698.42 326.815)"
          />
          <path
            fill="#54FFF2"
            d="M3 0a3 3 0 1 1-6 0 3 3 0 0 1 6 0z"
            transform="translate(677.883 277.924)"
          />
          <path
            fill="#54FFF2"
            d="M3 0a3 3 0 1 1-6 0 3 3 0 0 1 6 0z"
            transform="translate(682.146 290.362)"
          />
          <path
            fill="#54FFF2"
            d="M3 0a3 3 0 1 1-6 0 3 3 0 0 1 6 0z"
            transform="translate(691.454 263.307)"
          />
          <path
            fill="#54FFF2"
            d="M3 0a3 3 0 1 1-6 0 3 3 0 0 1 6 0z"
            transform="translate(619.657 331.815)"
          />
          <path
            fill="#54FFF2"
            d="M3 0a3 3 0 1 1-6 0 3 3 0 0 1 6 0z"
            transform="translate(691.454 369.03)"
          />
          <path
            fill="#54FFF2"
            d="M3 0a3 3 0 1 1-6 0 3 3 0 0 1 6 0z"
            transform="translate(694.454 395.711)"
          />
          <path
            fill="#54FFF2"
            d="M3 0a3 3 0 1 1-6 0 3 3 0 0 1 6 0z"
            transform="translate(541.453 260.307)"
          />
          <path
            fill="#54FFF2"
            d="M3 0a3 3 0 1 1-6 0 3 3 0 0 1 6 0z"
            transform="translate(551.856 386.711)"
          />
          <path
            fill="#54FFF2"
            d="M3 0a3 3 0 1 1-6 0 3 3 0 0 1 6 0z"
            transform="translate(655.367 408.267)"
          />
          <path
            fill="#54FFF2"
            d="M3 0a3 3 0 1 1-6 0 3 3 0 0 1 6 0z"
            transform="translate(789.125 392.711)"
          />
          <path
            fill="#54FFF2"
            d="M3 0a3 3 0 1 1-6 0 3 3 0 0 1 6 0z"
            transform="translate(733.855 245.983)"
          />
          <path
            fill="#FBAD6F"
            d="M3 0a3 3 0 1 1-6 0 3 3 0 0 1 6 0z"
            transform="translate(1106.306 386.711)"
          />
          <path
            fill="#FBAD6F"
            d="M3 0a3 3 0 1 1-6 0 3 3 0 0 1 6 0z"
            transform="translate(972.86 343.086)"
          />
          <path
            fill="#FBAD6F"
            d="M3 0a3 3 0 1 1-6 0 3 3 0 0 1 6 0z"
            transform="translate(1075.097 451.578)"
          />
          <path
            fill="#FBAD6F"
            d="M3 0a3 3 0 1 1-6 0 3 3 0 0 1 6 0z"
            transform="translate(978.86 442.93)"
          />
          <path
            fill="#FBAD6F"
            d="M3 0a3 3 0 1 1-6 0 3 3 0 0 1 6 0z"
            transform="translate(1014.635 465.578)"
          />
          <path
            fill="#FBAD6F"
            d="M3 0a3 3 0 1 1-6 0 3 3 0 0 1 6 0z"
            transform="translate(894.514 448.578)"
          />
          <path
            fill="#FBAD6F"
            d="M3 0a3 3 0 1 1-6 0 3 3 0 0 1 6 0z"
            transform="translate(887.514 515.926)"
          />
          <path
            fill="#FBAD6F"
            d="M3 0a3 3 0 1 1-6 0 3 3 0 0 1 6 0z"
            transform="translate(1078.097 468.578)"
          />
          <path
            fill="#FBAD6F"
            d="M3 0a3 3 0 1 1-6 0 3 3 0 0 1 6 0z"
            transform="translate(1024.601 444.437)"
          />
          <path
            fill="#FBAD6F"
            d="M3 0a3 3 0 1 1-6 0 3 3 0 0 1 6 0z"
            transform="translate(1103.306 395.711)"
          />
          <path
            fill="#FBAD6F"
            d="M3 0a3 3 0 1 1-6 0 3 3 0 0 1 6 0z"
            transform="translate(978.86 475.179)"
          />
          <path
            fill="#FBAD6F"
            d="M3 0a3 3 0 1 1-6 0 3 3 0 0 1 6 0z"
            transform="translate(859.123 478.179)"
          />
          <path
            fill="#FBAD6F"
            d="M3 0a3 3 0 1 1-6 0 3 3 0 0 1 6 0z"
            transform="translate(917.621 395.711)"
          />
          <path
            fill="#FBAD6F"
            d="M3 0a3 3 0 1 1-6 0 3 3 0 0 1 6 0z"
            transform="translate(894.514 346.013)"
          />
          <path
            fill="#FBAD6F"
            d="M3 0a3 3 0 1 1-6 0 3 3 0 0 1 6 0z"
            transform="translate(901.595 343.086)"
          />
          <path
            fill="#FBAD6F"
            d="M3 0a3 3 0 1 1-6 0 3 3 0 0 1 6 0z"
            transform="translate(944.744 392.711)"
          />
          <path
            fill="#FBAD6F"
            d="M3 0a3 3 0 1 1-6 0 3 3 0 0 1 6 0z"
            transform="translate(929.993 492.228)"
          />
          <path
            fill="#FBAD6F"
            d="M3 0a3 3 0 1 1-6 0 3 3 0 0 1 6 0z"
            transform="translate(950.744 426.878)"
          />
          <path
            fill="#FBAD6F"
            d="M3 0a3 3 0 1 1-6 0 3 3 0 0 1 6 0z"
            transform="translate(874.82 352.086)"
          />
          <path
            fill="#FBAD6F"
            d="M3 0a3 3 0 1 1-6 0 3 3 0 0 1 6 0z"
            transform="translate(878.9 344.622)"
          />
          <path
            fill="#FBAD6F"
            d="M3 0a3 3 0 1 1-6 0 3 3 0 0 1 6 0z"
            transform="translate(826.305 276.704)"
          />
          <path
            fill="#FBAD6F"
            d="M3 0a3 3 0 1 1-6 0 3 3 0 0 1 6 0z"
            transform="translate(881.9 276.704)"
          />
          <path
            fill="#FBAD6F"
            d="M3 0a3 3 0 1 1-6 0 3 3 0 0 1 6 0z"
            transform="translate(854.714 317.198)"
          />
          <path
            fill="#FBAD6F"
            d="M3 0a3 3 0 1 1-6 0 3 3 0 0 1 6 0z"
            transform="translate(856.123 293.362)"
          />
          <path
            fill="#FBAD6F"
            d="M3 0a3 3 0 1 1-6 0 3 3 0 0 1 6 0z"
            transform="translate(836.307 378.03)"
          />
          <path
            fill="#FBAD6F"
            d="M3 0a3 3 0 1 1-6 0 3 3 0 0 1 6 0z"
            transform="translate(846.863 335.704)"
          />
          <path
            fill="#FBAD6F"
            d="M3 0a3 3 0 1 1-6 0 3 3 0 0 1 6 0z"
            transform="translate(824.475 346.086)"
          />
          <path
            fill="#FBAD6F"
            d="M3 0a3 3 0 1 1-6 0 3 3 0 0 1 6 0z"
            transform="translate(819.556 334.815)"
          />
          <path
            fill="#FBAD6F"
            d="M3 0a3 3 0 1 1-6 0 3 3 0 0 1 6 0z"
            transform="translate(848.714 425.527)"
          />
          <path
            fill="#FBAD6F"
            d="M3 0a3 3 0 1 1-6 0 3 3 0 0 1 6 0z"
            transform="translate(783.125 384.03)"
          />
          <path
            fill="#FEDE69"
            d="M3 0a3 3 0 1 1-6 0 3 3 0 0 1 6 0z"
            transform="translate(961.157 506.388)"
          />
          <path
            fill="#FEDE69"
            d="M3 0a3 3 0 1 1-6 0 3 3 0 0 1 6 0z"
            transform="translate(1021.601 504.467)"
          />
          <path
            fill="#FEDE69"
            d="M3 0a3 3 0 1 1-6 0 3 3 0 0 1 6 0z"
            transform="translate(756.916 531.197)"
          />
          <path
            fill="#FEDE69"
            d="M3 0a3 3 0 1 1-6 0 3 3 0 0 1 6 0z"
            transform="translate(859.123 495.228)"
          />
          <path
            fill="#FEDE69"
            d="M3 0a3 3 0 1 1-6 0 3 3 0 0 1 6 0z"
            transform="translate(809.64 517.816)"
          />
          <path
            fill="#FEDE69"
            d="M3 0a3 3 0 1 1-6 0 3 3 0 0 1 6 0z"
            transform="translate(738.916 602.79)"
          />
          <path
            fill="#FEDE69"
            d="M3 0a3 3 0 1 1-6 0 3 3 0 0 1 6 0z"
            transform="translate(730.855 591.983)"
          />
          <path
            fill="#FEDE69"
            d="M3 0a3 3 0 1 1-6 0 3 3 0 0 1 6 0z"
            transform="translate(662.763 552.197)"
          />
          <path
            fill="#FEDE69"
            d="M3 0a3 3 0 1 1-6 0 3 3 0 0 1 6 0z"
            transform="translate(797.125 537.197)"
          />
          <path
            fill="#FEDE69"
            d="M3 0a3 3 0 1 1-6 0 3 3 0 0 1 6 0z"
            transform="translate(941.744 534.197)"
          />
          <path
            fill="#FEDE69"
            d="M3 0a3 3 0 1 1-6 0 3 3 0 0 1 6 0z"
            transform="translate(753.916 546.197)"
          />
          <path
            fill="#FEDE69"
            d="M3 0a3 3 0 1 1-6 0 3 3 0 0 1 6 0z"
            transform="translate(741.916 691.259)"
          />
          <path
            fill="#FEDE69"
            d="M3 0a3 3 0 1 1-6 0 3 3 0 0 1 6 0z"
            transform="translate(750.916 512.926)"
          />
          <path
            fill="#FEDE69"
            d="M3 0a3 3 0 1 1-6 0 3 3 0 0 1 6 0z"
            transform="translate(675.811 547.71)"
          />
          <path
            fill="#FEDE69"
            d="M3 0a3 3 0 1 1-6 0 3 3 0 0 1 6 0z"
            transform="translate(833.307 537.197)"
          />
          <path
            fill="#FEDE69"
            d="M3 0a3 3 0 1 1-6 0 3 3 0 0 1 6 0z"
            transform="translate(619.657 563.142)"
          />
          <path
            fill="#FEDE69"
            d="M3 0a3 3 0 1 1-6 0 3 3 0 0 1 6 0z"
            transform="translate(797.125 574.822)"
          />
          <path
            fill="#FEDE69"
            d="M3 0a3 3 0 1 1-6 0 3 3 0 0 1 6 0z"
            transform="translate(837.931 566.142)"
          />
          <path
            fill="#FEDE69"
            d="M3 0a3 3 0 1 1-6 0 3 3 0 0 1 6 0z"
            transform="translate(801.52 486.228)"
          />
          <path
            fill="#FEDE69"
            d="M3 0a3 3 0 1 1-6 0 3 3 0 0 1 6 0z"
            transform="translate(730.855 534.197)"
          />
          <path
            fill="#FEDE69"
            d="M3 0a3 3 0 1 1-6 0 3 3 0 0 1 6 0z"
            transform="translate(727.55 543.451)"
          />
          <path
            fill="#FEDE69"
            d="M3 0a3 3 0 1 1-6 0 3 3 0 0 1 6 0z"
            transform="translate(727.55 523.816)"
          />
          <path
            fill="#FEDE69"
            d="M3 0a3 3 0 1 1-6 0 3 3 0 0 1 6 0z"
            transform="translate(645.461 543.124)"
          />
          <path
            fill="#FEDE69"
            d="M3 0a3 3 0 1 1-6 0 3 3 0 0 1 6 0z"
            transform="translate(738.916 483.228)"
          />
          <path
            fill="#FEDE69"
            d="M3 0a3 3 0 1 1-6 0 3 3 0 0 1 6 0z"
            transform="translate(700.137 496.132)"
          />
          <path
            fill="#FEDE69"
            d="M3 0a3 3 0 1 1-6 0 3 3 0 0 1 6 0z"
            transform="translate(703.137 429.295)"
          />
          <path
            fill="#FEDE69"
            d="M3 0a3 3 0 1 1-6 0 3 3 0 0 1 6 0z"
            transform="translate(637.545 428.527)"
          />
          <path
            fill="#FEDE69"
            d="M3 0a3 3 0 1 1-6 0 3 3 0 0 1 6 0z"
            transform="translate(759.916 474.474)"
          />
          <path
            fill="#FEDE69"
            d="M3 0a3 3 0 1 1-6 0 3 3 0 0 1 6 0z"
            transform="translate(867.123 363.054)"
          />
          <path
            fill="#FEDE69"
            d="M3 0a3 3 0 1 1-6 0 3 3 0 0 1 6 0z"
            transform="translate(800.842 515.926)"
          />
          <path
            fill="#E66BFF"
            d="M3 0a3 3 0 1 1-6 0 3 3 0 0 1 6 0z"
            transform="translate(589.485 501.467)"
          />
          <path
            fill="#E66BFF"
            d="M3 0a3 3 0 1 1-6 0 3 3 0 0 1 6 0z"
            transform="translate(786.125 483.228)"
          />
          <path
            fill="#E66BFF"
            d="M3 0a3 3 0 1 1-6 0 3 3 0 0 1 6 0z"
            transform="translate(791.125 477.474)"
          />
          <path
            fill="#E66BFF"
            d="M3 0a3 3 0 1 1-6 0 3 3 0 0 1 6 0z"
            transform="translate(727.55 462.578)"
          />
          <path
            fill="#E66BFF"
            d="M3 0a3 3 0 1 1-6 0 3 3 0 0 1 6 0z"
            transform="translate(777.125 468.815)"
          />
          <path
            fill="#E66BFF"
            d="M3 0a3 3 0 1 1-6 0 3 3 0 0 1 6 0z"
            transform="translate(716.393 463.035)"
          />
          <path
            fill="#E66BFF"
            d="M3 0a3 3 0 1 1-6 0 3 3 0 0 1 6 0z"
            transform="translate(737.5 445.578)"
          />
          <path
            fill="#E66BFF"
            d="M3 0a3 3 0 1 1-6 0 3 3 0 0 1 6 0z"
            transform="translate(713.393 454.578)"
          />
          <path
            fill="#E66BFF"
            d="M3 0a3 3 0 1 1-6 0 3 3 0 0 1 6 0z"
            transform="translate(823.305 501.467)"
          />
          <path
            fill="#E66BFF"
            d="M3 0a3 3 0 1 1-6 0 3 3 0 0 1 6 0z"
            transform="translate(627.149 514.816)"
          />
          <path
            fill="#E66BFF"
            d="M3 0a3 3 0 1 1-6 0 3 3 0 0 1 6 0z"
            transform="translate(673.254 456.578)"
          />
          <path
            fill="#E66BFF"
            d="M3 0a3 3 0 1 1-6 0 3 3 0 0 1 6 0z"
            transform="translate(794.125 494.656)"
          />
          <path
            fill="#E66BFF"
            d="M3 0a3 3 0 1 1-6 0 3 3 0 0 1 6 0z"
            transform="translate(640.545 471.474)"
          />
          <path
            fill="#E66BFF"
            d="M3 0a3 3 0 1 1-6 0 3 3 0 0 1 6 0z"
            transform="translate(694.454 432.295)"
          />
          <path
            fill="#E66BFF"
            d="M3 0a3 3 0 1 1-6 0 3 3 0 0 1 6 0z"
            transform="translate(719.393 372.054)"
          />
          <path
            fill="#E66BFF"
            d="M3 0a3 3 0 1 1-6 0 3 3 0 0 1 6 0z"
            transform="translate(831.912 442.93)"
          />
          <path
            fill="#E66BFF"
            d="M3 0a3 3 0 1 1-6 0 3 3 0 0 1 6 0z"
            transform="translate(682.401 353.776)"
          />
          <path
            fill="#E66BFF"
            d="M3 0a3 3 0 1 1-6 0 3 3 0 0 1 6 0z"
            transform="translate(597.369 386.711)"
          />
        </g>
        <g
          clipPath="url(#c)"
          transform="translate(-100)"
          opacity={0.24}
          fill="#FFF"
        >
          <g
            clipPath="url(#d)"
            transform="rotate(-112 187.336 367.773)"
            opacity={1}
          >
            <path
              d="M.125-8-4 8h8L.125-8z"
              transform="translate(4 228.34)"
              opacity={1}
            />
            <path
              d="M1-565.675h-2V2.368h2v-568.043z"
              transform="translate(4 797.618)"
              opacity={1}
            />
          </g>
          <g
            clipPath="url(#e)"
            transform="rotate(-120 207.957 399.495)"
            opacity={1}
          >
            <path
              d="M.125-8-4 8h8L.125-8z"
              transform="translate(4 314.66)"
              opacity={1}
            />
            <path
              d="M1-479.332h-2v481.7h2v-481.7z"
              transform="translate(4 797.618)"
              opacity={1}
            />
          </g>
          <g
            clipPath="url(#f)"
            transform="rotate(25 1287.898 2060.747)"
            opacity={1}
          >
            <path
              d="M.125-8-4 8h8L.125-8z"
              transform="translate(4 392.66)"
              opacity={1}
            />
            <path
              d="M1-401.312h-2V2.368h2v-403.68z"
              transform="translate(4 797.618)"
              opacity={1}
            />
          </g>
          <g
            clipPath="url(#g)"
            transform="rotate(41 898.188 1461.264)"
            opacity={1}
          >
            <path
              d="M.125-8-4 8h8L.125-8z"
              transform="translate(4 323.5)"
              opacity={1}
            />
            <path
              d="M1-470.49h-2V2.368h2V-470.49z"
              transform="translate(4 797.618)"
              opacity={1}
            />
          </g>
          <g
            clipPath="url(#h)"
            transform="rotate(79 587.98 984.076)"
            opacity={1}
          >
            <path
              d="M.125-8-4 8h8L.125-8z"
              transform="translate(4 280.34)"
              opacity={1}
            />
            <path
              d="M1-513.661h-2V2.368h2v-516.029z"
              transform="translate(4 797.618)"
              opacity={1}
            />
          </g>
          <g
            clipPath="url(#i)"
            transform="rotate(98 515.006 871.822)"
            opacity={1}
          >
            <path
              d="M.125-8-4 8h8L.125-8z"
              transform="translate(4 167.5)"
              opacity={1}
            />
            <path
              d="M1-626.53h-2V2.368h2V-626.53z"
              transform="translate(4 797.618)"
              opacity={1}
            />
          </g>
          <g
            clipPath="url(#j)"
            transform="rotate(104 496.328 843.09)"
            opacity={1}
          >
            <path
              d="M.125-8-4 8h8L.125-8z"
              transform="translate(4 254.34)"
              opacity={1}
            />
            <path
              d="M1-539.668h-2V2.368h2v-542.036z"
              transform="translate(4 797.618)"
              opacity={1}
            />
          </g>
          <g
            clipPath="url(#k)"
            transform="rotate(111 476.375 812.397)"
            opacity={1}
          >
            <path
              d="M.125-8-4 8h8L.125-8z"
              transform="translate(4 184.66)"
              opacity={1}
            />
            <path
              d="M1-609.366h-2V2.368h2v-611.734z"
              transform="translate(4 797.618)"
              opacity={1}
            />
          </g>
          <g
            clipPath="url(#l)"
            transform="rotate(133 422.789 729.966)"
            opacity={1}
          >
            <path
              d="M.125-8-4 8h8L.125-8z"
              transform="translate(4 297.5)"
              opacity={1}
            />
            <path
              d="M1-496.497h-2V2.368h2v-498.865z"
              transform="translate(4 797.618)"
              opacity={1}
            />
          </g>
          <g
            clipPath="url(#m)"
            transform="rotate(142 403.584 700.423)"
            opacity={1}
          >
            <path
              d="M.125-8-4 8h8L.125-8z"
              transform="translate(4 297.5)"
              opacity={1}
            />
            <path
              d="M1-496.497h-2V2.368h2v-498.865z"
              transform="translate(4 797.618)"
              opacity={1}
            />
          </g>
          <text
            textAnchor="end"
            letterSpacing={0}
            transform="rotate(-29 1263.013 -277.724)"
            fontSize={20}
            fontFamily="Roboto"
            fontWeight={400}
            aria-label="Reach"
            opacity={1}
          >
            <tspan
              x={0}
              y={0}
              style={{
                display: 'inherit',
              }}
            >
              Reach
            </tspan>
          </text>
          <text
            textAnchor="end"
            letterSpacing={0}
            transform="rotate(-22 1524.186 -205.588)"
            fontSize={20}
            fontFamily="Roboto"
            fontWeight={400}
            aria-label="Height"
            opacity={1}
          >
            <tspan
              x={0}
              y={0}
              style={{
                display: 'inherit',
              }}
            >
              Height
            </tspan>
          </text>
          <text
            textAnchor="middle"
            letterSpacing={0}
            transform="rotate(-65 441.166 -597.235)"
            fontSize={20}
            fontFamily="Roboto"
            fontWeight={400}
            aria-label="Skater Hip Velo Avg"
            opacity={1}
          >
            <tspan
              x={0}
              y={0}
              style={{
                display: 'inherit',
              }}
            >
              Skater Hip
            </tspan>
            <tspan
              x={0}
              y={30}
              style={{
                display: 'inherit',
              }}
            >
              Velo Avg
            </tspan>
          </text>
          <text
            textAnchor="middle"
            letterSpacing={0}
            transform="rotate(-49 523.44 -985.766)"
            fontSize={20}
            fontFamily="Roboto"
            fontWeight={400}
            aria-label="Skater Hip Abduction"
            opacity={1}
          >
            <tspan
              x={0}
              y={0}
              style={{
                display: 'inherit',
              }}
            >
              Skater Hip
            </tspan>
            <tspan
              x={0}
              y={30}
              style={{
                display: 'inherit',
              }}
            >
              Abduction
            </tspan>
          </text>
          <text
            textAnchor="end"
            letterSpacing={0}
            transform="rotate(-11 1977.53 -5841.796)"
            fontSize={20}
            fontFamily="Roboto"
            fontWeight={400}
            aria-label="Knee Velo Avg"
            opacity={1}
          >
            <tspan
              x={0}
              y={0}
              style={{
                display: 'inherit',
              }}
            >
              Knee Velo Avg
            </tspan>
          </text>
          <text
            textAnchor="middle"
            letterSpacing={0}
            transform="rotate(8 -2584.198 8975.508)"
            fontSize={20}
            fontFamily="Roboto"
            fontWeight={400}
            aria-label="Lat Force BW Avg"
            opacity={1}
          >
            <tspan
              x={0}
              y={0}
              style={{
                display: 'inherit',
              }}
            >
              Lat Force
            </tspan>
            <tspan
              x={0}
              y={30}
              style={{
                display: 'inherit',
              }}
            >
              BW Avg
            </tspan>
          </text>
          <text
            textAnchor="end"
            letterSpacing={0}
            transform="rotate(14 -1554.64 5012.69)"
            fontSize={20}
            fontFamily="Roboto"
            fontWeight={400}
            aria-label="Delta"
            opacity={1}
          >
            <tspan
              x={0}
              y={0}
              style={{
                display: 'inherit',
              }}
            >
              Delta
            </tspan>
          </text>
          <text
            textAnchor="end"
            letterSpacing={0}
            transform="rotate(21 -1040.132 3571.986)"
            fontSize={20}
            fontFamily="Roboto"
            fontWeight={400}
            aria-label="Ecc Rel FF"
            opacity={1}
          >
            <tspan
              x={0}
              y={0}
              style={{
                display: 'inherit',
              }}
            >
              Ecc Rel FF
            </tspan>
          </text>
          <text
            textAnchor="end"
            letterSpacing={0}
            transform="rotate(43 -382.835 1644.941)"
            fontSize={20}
            fontFamily="Roboto"
            fontWeight={400}
            aria-label="Conc Rel FF"
            opacity={1}
          >
            <tspan
              x={0}
              y={0}
              style={{
                display: 'inherit',
              }}
            >
              Conc Rel FF
            </tspan>
          </text>
          <text
            textAnchor="middle"
            letterSpacing={0}
            transform="rotate(52 -270.11 1319.542)"
            fontSize={20}
            fontFamily="Roboto"
            fontWeight={400}
            aria-label="Net Concentric Force"
            opacity={1}
          >
            <tspan
              x={0}
              y={0}
              style={{
                display: 'inherit',
              }}
            >
              Net Rel
            </tspan>
            <tspan
              x={0}
              y={30}
              style={{
                display: 'inherit',
              }}
            >
              Concentric Force
            </tspan>
          </text>
        </g>
        <g
          clipPath="url(#n)"
          transform="translate(-50)"
          fill="#FFF"
          fontSize={24}
          fontFamily="Roboto"
          fontWeight={700}
          textAnchor="middle"
          letterSpacing={0}
        >
          <text transform="translate(303 355)" aria-label="Traditional Bigs">
            <tspan
              x={0}
              y={0}
              style={{
                display: 'inherit',
              }}
            >
              Traditional Bigs
            </tspan>
          </text>
          <text transform="translate(519 301)" aria-label="Misc. Perimeter">
            <tspan
              x={0}
              y={0}
              style={{
                display: 'inherit',
              }}
            >
              Misc.
            </tspan>
            <tspan
              x={0}
              y={28.8}
              style={{
                display: 'inherit',
              }}
            >
              Perimeter
            </tspan>
          </text>
          <text transform="translate(683 183)" aria-label="Kinematic Movers">
            <tspan
              x={0}
              y={0}
              style={{
                display: 'inherit',
              }}
            >
              Kinematic Movers
            </tspan>
          </text>
          <text transform="translate(483 521)" aria-label="Bigs Plus">
            <tspan
              x={0}
              y={0}
              style={{
                display: 'inherit',
              }}
            >
              Bigs Plus
            </tspan>
          </text>
          <text transform="translate(687 449)" aria-label="Force Movers">
            <tspan
              x={0}
              y={0}
              style={{
                display: 'inherit',
              }}
            >
              Force Movers
            </tspan>
          </text>
          <text transform="translate(763 621)" aria-label="Specimens">
            <tspan
              x={0}
              y={0}
              style={{
                display: 'inherit',
              }}
            >
              Specimens
            </tspan>
          </text>
          <text
            transform="translate(1003 395)"
            aria-label="Hyper-Athletic Guards"
          >
            <tspan
              x={0}
              y={0}
              style={{
                display: 'inherit',
              }}
            >
              Hyper-Athletic
            </tspan>
            <tspan
              x={0}
              y={28.8}
              style={{
                display: 'inherit',
              }}
            >
              Guards
            </tspan>
          </text>
        </g>
      </g>
    </svg>
  );
};
