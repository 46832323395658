import terms from 'views/Assessments/terms.json';
import {
  AssessmentGroup,
  AssessmentPaginatedList,
  BasketballAssessment,
  SoccerAssessment,
} from 'types/api';
import { formatDate } from 'util/formatDate';
import { trimDecimals } from 'util/trimDecimals';
import { AListTrend } from 'components/AssessmentsListTable';
import { AssessmentGroupJoined } from './Table/Admin';

export const parseTableData = (
  assessmentGroups: AssessmentPaginatedList | undefined,
) => {
  if (assessmentGroups?.items) {
    return assessmentGroups.items.map(
      (group: AssessmentGroup): AssessmentGroupJoined => {
        const { archetypes } = terms.filter((term) => term.archetypes)[0];
        // default values
        let currentAS = 0;
        let prevAS = 0;
        let currentMS = 0;
        let prevMS = 0;
        let athleticismScoreTrend = AListTrend.EQUAL;
        let mechanicsScoreTrend = AListTrend.EQUAL;
        let archetype;
        let position;
        let latestAssessmentId;
        let assessmentType;

        const assessments = group.assessments as
          | (BasketballAssessment | SoccerAssessment)[]
          | null;
        if (assessments) {
          if (assessments.length > 0) {
            // TODO - add SoccerAssessment support @rmaturano
            const current = assessments[0] as BasketballAssessment;

            position = current.position;
            archetype = archetypes?.find((arch) => {
              return arch.id === current.archetype;
            })?.term;

            currentAS =
              current.assessmentDetails?.assessmentSummary?.athleticismScore ||
              currentAS;
            currentMS =
              current.assessmentDetails?.assessmentSummary?.mechanicsScore ||
              currentMS;

            latestAssessmentId = current.id;
            assessmentType = current.assessmentType;

            // at least two assessments are needed to compare
            if (assessments.length > 1) {
              // TODO Add Soccer support @rmaturano
              const prev = assessments[1] as BasketballAssessment;

              prevAS =
                prev.assessmentDetails?.assessmentSummary?.athleticismScore ||
                prevAS;
              prevMS =
                prev.assessmentDetails?.assessmentSummary?.mechanicsScore ||
                prevMS;

              // score trends
              athleticismScoreTrend =
                currentAS > prevAS
                  ? AListTrend.UP
                  : currentAS < prevAS
                  ? AListTrend.DOWN
                  : AListTrend.EQUAL;

              mechanicsScoreTrend =
                currentMS > prevMS
                  ? AListTrend.UP
                  : currentMS < prevMS
                  ? AListTrend.DOWN
                  : AListTrend.EQUAL;
            }
          }
        }

        return {
          name: group.name,
          type: archetype,
          position,
          latestDate: formatDate(group.latestDate) || undefined,
          assessmentCount: group.assessmentCount,
          athleticismScore: {
            value: trimDecimals(currentAS).toFixed(),
            trend: athleticismScoreTrend,
          },
          mechanicsScore: {
            value: trimDecimals(currentMS).toFixed(),
            trend: mechanicsScoreTrend,
          },
          assessments: group.assessments as (
            | BasketballAssessment
            | SoccerAssessment
          )[],
          latestAssessmentId,
          assessmentType,
        };
      },
    );
  }

  return [];
};
