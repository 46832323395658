import { DateOnly } from 'types/api';

export const formatDate = (date?: DateOnly | ''): DateOnly | '' => {
  if (!date) {
    return '';
  }
  const toTypecast: Date = date as Date;
  const toDate = new Date(toTypecast);
  return toDate
    .toLocaleDateString('en-US', {
      month: '2-digit',
      day: '2-digit',
      year: '2-digit',
      timeZone: 'UTC',
    })
    .replace(/\//g, '-') as DateOnly;
};
