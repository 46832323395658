import React from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { Organization, OrganizationResponseModel, Role } from 'types/api';
import { useLoggedInUser } from 'hooks/query/useLoggedInUser';
import { useOrganizationContext } from 'hooks/util/useOrganizationContext';
import { isAdmin } from 'util/isAdmin';
import { Button, ButtonVariants } from 'components/Button';
import { ProfilePicture } from 'components/ProfilePicture';
import styles from './Summary.module.css';

interface SummaryProps extends OrganizationResponseModel {
  organization: OrganizationResponseModel;
  role: Role | undefined;
  activeAthletes?: number;
  assessmentsCount?: number;
}

const Summary = React.memo((props: SummaryProps) => {
  const { organization, role } = props;
  const { setContext } = useOrganizationContext();
  const navigate = useNavigate();
  const { data: loggedInUser } = useLoggedInUser();

  const handleSelectOrg = (organization: Organization) => {
    // To set context properly, check for the user's role in the selected organization
    const userRole: Role | undefined = loggedInUser?.organizationRoles?.find(
      (x) => x.organizationId === organization?.id,
    )?.role;
    setContext(organization, userRole);
    navigate('/organization', { replace: true });
  };

  return (
    <div className={styles.main}>
      <div className={styles.avatar}>
        <ProfilePicture
          size={160}
          src={organization?.blobImageUri as string}
          firstName={organization?.organizationName as string}
        />
      </div>

      <div className={styles['details-container']}>
        {organization && (
          <div className={styles.details}>
            <h1 className={styles.title}>{organization?.organizationName}</h1>
            <p className={styles.description}>Organization / Team</p>
            <p
              className={styles.location}
            >{`${organization?.city}, ${organization?.state}, ${organization?.country}`}</p>
          </div>
        )}
        {isAdmin(role) ? (
          <div className={styles['admin-buttons']}>
            <Button
              variant={ButtonVariants.Secondary}
              onClick={() => handleSelectOrg(organization)}
            >
              Manage Organization
            </Button>
            {role === Role.SUPER_ADMIN && (
              <Link to="organizations">
                <Button
                  className={styles['attach-btn']}
                  variant={ButtonVariants.Secondary}
                >
                  Attach Assessments
                </Button>
              </Link>
            )}
          </div>
        ) : (
          <>
            <div className={styles.totals}>
              {!!props.activeAthletes && (
                <div className={styles.count}>
                  <span>Athletes Active</span>
                  <strong>{props.activeAthletes}</strong>
                </div>
              )}

              {!!props.activeAthletes && !!props.assessmentsCount && (
                <span className={styles.separator} />
              )}
              <div className={styles.count}>
                <span>Assessments</span>
                <strong>{props.assessmentsCount}</strong>
              </div>
            </div>
          </>
        )}
      </div>
    </div>
  );
});

export { Summary };
