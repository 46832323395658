import cuid from 'cuid';
import * as faker from 'faker';
import { ObjectGenerator } from 'lib/api-mocking/interfaces/ObjectGenerator';
import { AssessmentGroup } from 'types/api';
import { AssessmentGenerator } from './AssessmentGenerator';

export const AssessmentListGenerator: ObjectGenerator<AssessmentGroup, string> =
  {
    generateId: (): string => {
      return cuid();
    },

    generateList: (count: number): AssessmentGroup[] => {
      return Array.from({ length: count }, (): AssessmentGroup => {
        return {
          assessmentCount: count,
          assessments: AssessmentGenerator.generateList(
            faker.datatype.number({ min: 1, max: 5 }),
          ),
          /* eslint-disable-next-line */
          // @ts-ignore
          latestDate: faker.datatype
            .datetime()
            .toLocaleDateString('en-us', {
              month: '2-digit',
              day: '2-digit',
              year: '2-digit',
            })
            .replaceAll('/', '-'),
          name: `${faker.name.firstName()} ${faker.name.lastName()}`,
        };
      }) as AssessmentGroup[];
    },
  };
