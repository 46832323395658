export const cumulativeYOffset = (element: HTMLElement) => {
  let top = 0;

  do {
    top += element.offsetTop || 0;
    element = element.offsetParent as HTMLElement;
  } while (element);

  return top;
};
