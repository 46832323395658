import { ReactComponent as WireframeImage } from 'assets/images/wireframe-man.svg';
import classNames from 'classnames';
import { useRef } from 'react';
import {
  AssessmentType,
  BaseAssessment,
  BasketballAssessment,
  ExerciseSide,
  SoccerAssessment,
} from 'types/api';
import { labels } from 'util/graphConstants';
import styles from './InjuryRisk.module.css';
import { Zone } from './Zone';

export enum InjuryRiskZones {
  LeftFoot = 'left-foot',
  LeftKnee = 'left-knee',
  LowerBack = 'lower-back',
  RightFoot = 'right-foot',
  RightKnee = 'right-knee',
}

interface InjuryRiskProps {
  assessment?: BaseAssessment;
  className?: string;
  // If readonly=true, the hotspots on the wireman will NOT pulse, and will NOT be clickable.
  readonly?: boolean;
}

const attributesByZone = (
  assessment: BaseAssessment | undefined,
  zone: InjuryRiskZones,
): { label: string; value: number | undefined }[] => {
  const result: { label: string; value: number | undefined }[] = [];
  switch (assessment?.assessmentType) {
    case AssessmentType.BASKETBALL: {
      const summary = (assessment as BasketballAssessment)?.assessmentDetails
        ?.assessmentBasketballMechanicsSummary;
      return {
        [InjuryRiskZones.LeftFoot]: [
          {
            label: labels.activeDorsiFlexL,
            value: summary?.percActiveDorsiflexionLeft,
          },
          {
            label: labels.ankleDecelL,
            value: summary?.percAnkleActiveDecelerationLeft,
          },
          {
            label: labels.inversionL,
            value: summary?.percInversionLeft,
          },
          {
            label: labels.translationL,
            value: summary?.percTranslationLeft,
          },
        ],
        [InjuryRiskZones.LeftKnee]: [
          {
            label: labels.deltaFemoralRotateL,
            value: summary?.percDeltaFemoralRotationLeft,
          },
          {
            label: labels.hipActiveDecelL,
            value: summary?.percMaxHipActiveDecelerationLeft,
          },
          {
            label: labels.maxRelRotationL,
            value: summary?.percMaxRelativeRotationLeft,
          },
          {
            label: labels.translationL,
            value: summary?.percTranslationLeft,
          },
        ],
        [InjuryRiskZones.LowerBack]: [
          {
            label: labels.deltaHipFlexionAverage,
            value: summary?.percDeltaHipFlexionAverage,
          },
          {
            label: labels.impactRawL,
            value: summary?.percImpact2LeftRaw,
          },
          {
            label: labels.impactRawR,
            value: summary?.percImpact2RightRaw,
          },
          {
            label: labels.impulseAsym,
            value: summary?.percImpulseAsymmetry,
          },
        ],
        [InjuryRiskZones.RightFoot]: [
          {
            label: labels.activeDorsiFlexR,
            value: summary?.percActiveDorsiflexionRight,
          },
          {
            label: labels.ankleDecelR,
            value: summary?.percAnkleActiveDecelerationRight,
          },
          {
            label: labels.inversionR,
            value: summary?.percInversionRight,
          },
          {
            label: labels.translationR,
            value: summary?.percTranslationRight,
          },
        ],
        [InjuryRiskZones.RightKnee]: [
          {
            label: labels.deltaFemoralRotateR,
            value: summary?.percDeltaFemoralRotationRight,
          },
          {
            label: labels.hipActiveDecelR,
            value: summary?.percMaxHipActiveDecelerationRight,
          },
          {
            label: labels.maxRelRotationR,
            value: summary?.percMaxRelativeRotationRight,
          },
          {
            label: labels.translationR,
            value: summary?.percTranslationRight,
          },
        ],
      }[zone];
    }
    case AssessmentType.SOCCER: {
      const summary = (assessment as SoccerAssessment)?.assessmentDetails
        ?.assessmentSoccerMechanicsSummary;
      const { kickLeg, stanceLeg } = summary || {};

      const stanceKnee = [
        {
          label: labels.translationStance,
          value: summary?.percTranslationStance,
        },
        {
          label: labels.relativeRotationStance,
          value: summary?.percRelativeRotationStance,
        },
        {
          label: labels.deltaHipFlexionStance,
          value: summary?.percDeltaHipFlexionStance,
        },
      ];

      const stanceAnkle = [
        {
          label: labels.activeAnkleDecelerationStance,
          value: summary?.percActiveAnkleDecelerationStance,
        },
        {
          label: labels.activeDorsiflexionStance,
          value: summary?.percActiveDorsiflexionStance,
        },
        {
          label: labels.inversionStance,
          value: summary?.percInversionStance,
        },
        {
          label: labels.eversionStance,
          value: summary?.percEversionStance,
        },
      ];

      const kickKnee = [
        {
          label: labels.relativeRotationKick,
          value: summary?.percRelativeRotationKick,
        },
        {
          label: labels.deltaHipFlexionKick,
          value: summary?.percDeltaHipFlexionKick,
        },
        {
          label: labels.translationKick,
          value: summary?.percTranslationKick,
        },
      ];

      const kickAnkle = [
        {
          label: labels.activeAnkleDecelerationKick,
          value: summary?.percActiveAnkleDecelerationKick,
        },
        {
          label: labels.activeDorsiflexionKick,
          value: summary?.percActiveDorsiflexionKick,
        },
        {
          label: labels.inversionKick,
          value: summary?.percInversionKick,
        },
        {
          label: labels.eversionKick,
          value: summary?.percEversionKick,
        },
      ];

      return {
        [InjuryRiskZones.LeftFoot]:
          kickLeg === ExerciseSide.LEFT
            ? kickAnkle.map((x) => ({
                ...x,
                label: x.label.replace('#', 'L'),
              }))
            : stanceAnkle.map((x) => ({
                ...x,
                label: x.label.replace('#', 'L'),
              })),
        [InjuryRiskZones.LeftKnee]:
          kickLeg === ExerciseSide.LEFT
            ? kickKnee.map((x) => ({
                ...x,
                label: x.label.replace('#', 'L'),
              }))
            : stanceKnee.map((x) => ({
                ...x,
                label: x.label.replace('#', 'L'),
              })),
        [InjuryRiskZones.LowerBack]: [
          {
            label: labels.ankleDorsiflexionAverage,
            value: summary?.percAnkleDorsiflexionAverage,
          },
          {
            label: labels.deltaHipFlexionAverage,
            value: summary?.percDeltaHipFlexionAverage,
          },
        ],
        [InjuryRiskZones.RightFoot]:
          stanceLeg === ExerciseSide.RIGHT
            ? stanceAnkle.map((x) => ({
                ...x,
                label: x.label.replace('#', 'R'),
              }))
            : kickAnkle.map((x) => ({
                ...x,
                label: x.label.replace('#', 'R'),
              })),
        [InjuryRiskZones.RightKnee]:
          stanceLeg === ExerciseSide.RIGHT
            ? stanceKnee.map((x) => ({
                ...x,
                label: x.label.replace('#', 'R'),
              }))
            : kickKnee.map((x) => ({
                ...x,
                label: x.label.replace('#', 'R'),
              })),
      }[zone];
    }
  }
  return result;
};

export const InjuryRisk = (props: InjuryRiskProps) => {
  const { assessment, className, readonly } = props;
  const containerRef = useRef<HTMLDivElement>(null);
  return (
    <div className={classNames(styles.container, className)} ref={containerRef}>
      <div className={styles.model}>
        <WireframeImage className={styles.image} />
        <Zone
          attributes={attributesByZone(assessment, InjuryRiskZones.LeftFoot)}
          containerRef={containerRef}
          title="Left Foot Mechanics Intervention"
          zone={InjuryRiskZones.LeftFoot}
          readonly={readonly}
        />
        <Zone
          attributes={attributesByZone(assessment, InjuryRiskZones.RightFoot)}
          containerRef={containerRef}
          title="Right Foot Mechanics Intervention"
          zone={InjuryRiskZones.RightFoot}
          readonly={readonly}
        />
        <Zone
          attributes={attributesByZone(assessment, InjuryRiskZones.LeftKnee)}
          containerRef={containerRef}
          title="Left Knee Mechanics Intervention"
          zone={InjuryRiskZones.LeftKnee}
          readonly={readonly}
        />
        <Zone
          attributes={attributesByZone(assessment, InjuryRiskZones.RightKnee)}
          containerRef={containerRef}
          title="Right Knee Mechanics Intervention"
          zone={InjuryRiskZones.RightKnee}
          readonly={readonly}
        />
        <Zone
          attributes={attributesByZone(assessment, InjuryRiskZones.LowerBack)}
          containerRef={containerRef}
          title="Back Mechanics Intervention"
          zone={InjuryRiskZones.LowerBack}
          readonly={readonly}
        />
      </div>
    </div>
  );
};
