export const SpecimenContent = (props: { name: string }) => {
  const showName = props.name ? (
    <h2>{props.name} is a Specimen</h2>
  ) : (
    <h2>Specimens</h2>
  );
  return (
    <div>
      {showName}
      <p>
        Generally, athletes in this cluster possess an impressive mix of size
        for their position and power output. Force production in all planes is
        'elite', and this quality is paired with above-average kinematic
        efficiency - suggesting that athletes in this cluster can take advantage
        of their endemic power.
      </p>

      <ul>
        <li>
          Height/reach characteristics closely resemble the typical NBA wing.
        </li>
        <li>Paired with elite explosiveness vertically.</li>
        <li>"Plus" lateral explosiveness.</li>
        <li>"Plus" movement efficiency.</li>
      </ul>
      <h2>Specimens</h2>

      <p>
        Athletes in the Specimen cluster stick in the NBA at the highest rate
        among guard/wing centric clusters.
      </p>
    </div>
  );
};
