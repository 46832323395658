import { Error } from 'models/Error';
import { useQuery } from 'react-query';
import { toast } from 'react-toastify';
import { UserResponseModel } from 'types/api';
import {
  ContentType,
  useAuthenticatedFetch,
} from 'hooks/util/useAuthenticatedFetch';

export const useUserByEmail = (email?: string) => {
  const fetch = useAuthenticatedFetch(ContentType.NO_ORGANIZATION_HEADER);
  const encodedUriEmail = email ? encodeURIComponent(email) : undefined;
  return useQuery<UserResponseModel | undefined, Error>(
    ['userbyemail', email],
    () =>
      fetch(
        `${process.env.REACT_APP_API_HOST}/users/byemail?email=${encodedUriEmail}`,
      ),
    {
      enabled: !!email,
      onError: () => {
        toast.error('Error fetching profile.');
      },
    },
  );
};
