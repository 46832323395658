import { SoccerAssessment } from 'types/api';
import { formatDate } from 'util/formatDate';
import { labels } from 'util/graphConstants';
import { Radar } from 'components/Radar';
import styles from './../Charts.module.css';

interface Props {
  assessment: SoccerAssessment;
}

export const PerformanceFactorComparison = (props: Props) => {
  const { assessment } = props;
  const {
    concRelFF = 0,
    eccRelFF = 0,
    leftLateralDrive = 0,
    rightLateralDrive = 0,
    leftHipExtensionVelocity = 0,
    rightHipExtensionVelocity = 0,
    leftMaxHipAbduction = 0,
    rightMaxHipAbduction = 0,
    averageTotalMovementTime = 0,
    kneeExtensionAcceleration = 0,
    kneeExtensionVelocity = 0,
  } = assessment?.assessmentDetails
    ?.assessmentSoccerPerformanceFactorComparison || {};

  const keys = [
    'concRelFF',
    'leftLateralDrive',
    'leftHipExtensionVelocity',
    'leftMaxHipAbduction',
    'kneeExtensionAcceleration',
    'averageTotalMovementTime',
    'kneeExtensionVelocity',
    'rightMaxHipAbduction',
    'rightHipExtensionVelocity',
    'rightLateralDrive',
    'eccRelFF',
  ];

  const indicator: { max: number; name: string }[] = keys.map((name) => ({
    max: 100,
    name: (labels as { [key: string]: string })[name],
  }));

  return (
    <div className={styles['chart-container']}>
      <h2 className={styles['chart-title']}>Performance Factor Comparison</h2>
      <p className={styles['chart-subtext']}>
        Athlete relative to average DFB Male
      </p>

      <Radar
        className={styles['radar-chart']}
        data={[
          {
            name: `${assessment.name || ''} ${formatDate(assessment.date)}`,
            value: [
              concRelFF,
              leftLateralDrive,
              leftHipExtensionVelocity,
              leftMaxHipAbduction,
              kneeExtensionAcceleration,
              averageTotalMovementTime,
              kneeExtensionVelocity,
              rightMaxHipAbduction,
              rightHipExtensionVelocity,
              rightLateralDrive,
              eccRelFF,
            ],
          },
          {
            value: keys.map(() => 50),
            name: `${assessment?.position} Average`,
          },
        ]}
        fontSize={11}
        indicator={indicator}
        center={['50%', '45%']}
        radius="70%"
      />
    </div>
  );
};
