import React, { useCallback, useEffect, useState } from 'react';
import { useOrganization } from '../../hooks/query/useOrganization';
import {
  BaseAssessment as Assessment,
  OrganizationResponseModel,
} from '../../types/api';
import { radarGraphLegendText } from '../../util/radarGraphLegendText';
import { Button } from '../Button';
import { Loading } from '../Loading';
import { SelectedPill } from '../SelectedPill';
import styles from './AssessmentToOrgModal.module.css';

interface IAssessmentToOrgModal {
  assessments: Assessment[];
  onRemoveAssessment: (assessment: Assessment) => void;
  onAttachOrgs: (selectedOrgs: OrganizationResponseModel[]) => void;
  setIsAttachModalOpen: React.Dispatch<React.SetStateAction<boolean>>;
}

export const AsssessmentToOrgModal = ({
  assessments,
  onRemoveAssessment,
  onAttachOrgs,
  setIsAttachModalOpen,
}: IAssessmentToOrgModal) => {
  const { data: orgList } = useOrganization(false);
  const [selectedOrgs, setSelectedOrgs] = useState<OrganizationResponseModel[]>(
    [],
  );
  const [areAllOrgsSelected, setAreAllOrgsSelected] = useState<boolean>(false);
  const onChangeSelOrg = useCallback((org: OrganizationResponseModel) => {
    setSelectedOrgs((sel) => {
      if (sel?.find((s) => s.id === org.id)) {
        return sel.filter((s) => s.id !== org.id);
      } else {
        return [...sel, org];
      }
    });
  }, []);

  useEffect(() => {
    if (orgList && orgList.items) {
      if (areAllOrgsSelected) {
        setSelectedOrgs(orgList.items);
      } else {
        setSelectedOrgs([]);
      }
    }
  }, [areAllOrgsSelected, orgList]);

  return (
    <>
      <div className={styles.header}>
        <h2>Select Organizations</h2>
        <Button
          disabled={selectedOrgs.length < 1}
          onClick={() => {
            onAttachOrgs(selectedOrgs);
          }}
        >
          Attach
        </Button>
      </div>
      <ul className={styles['selected-assessments']}>
        <li className={styles['selected-assessments-legend']}>
          These assesments will be attached to the same organization(s).
        </li>
        {assessments.map((assessment, index, allAssessments) => {
          return (
            <SelectedPill
              id={assessment.id as string}
              key={`${assessment.id}-assessment-org`}
              label={radarGraphLegendText(assessment.name, assessment.date)}
              onClose={() => {
                if (allAssessments.length === 1) {
                  setIsAttachModalOpen(false);
                }

                onRemoveAssessment(assessment);
              }}
            />
          );
        })}
      </ul>

      {!orgList && <Loading />}

      {orgList && (
        <div className={styles['orgs-list-container']}>
          <p>An assessment can be attached to one or more organizations</p>
          <ul className={styles['selected-orgs']}>
            <li>Selected:</li>
            {selectedOrgs.map((org) => (
              <SelectedPill
                id={org.id as string}
                key={`org-${org.id}`}
                label={org.organizationName as string}
                onClose={() => {
                  onChangeSelOrg(org);
                }}
              />
            ))}
          </ul>
          <ul className={styles['orgs-list']}>
            <li>
              <input
                type="checkbox"
                id="select-all-orgs"
                onChange={() => {
                  setAreAllOrgsSelected((prev) => !prev);
                }}
              />
              <label htmlFor="select-all-orgs">Select all</label>
            </li>
            {orgList.items?.map((org) => {
              const current = `checkbox-${org.id}`;
              return (
                <li key={current}>
                  <input
                    type="checkbox"
                    id={current}
                    checked={Boolean(
                      selectedOrgs.find((sel) => sel.id === org.id),
                    )}
                    onChange={() => onChangeSelOrg(org)}
                  />
                  <label htmlFor={current}>{org.organizationName}</label>
                </li>
              );
            })}
          </ul>
        </div>
      )}
    </>
  );
};
