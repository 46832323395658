import { ReactComponent as FeedbackError } from 'assets/icons/feedback-error.svg';
import { ReactComponent as FeedbackInfo } from 'assets/icons/feedback-info.svg';
import { ReactComponent as FeedbackSuccess } from 'assets/icons/feedback-success.svg';
import { ReactComponent as FeedbackWarning } from 'assets/icons/feedback-warning.svg';
import { ToastContainer as ReactToastifyContainer } from 'react-toastify';
import type { BuiltInIconProps } from 'react-toastify/dist/components';

export const ToastContainer = () => {
  return (
    <ReactToastifyContainer
      icon={({ type }: BuiltInIconProps) => {
        switch (type) {
          case 'error':
            return <FeedbackError />;
          case 'info':
            return <FeedbackInfo />;
          case 'success':
            return <FeedbackSuccess />;
          case 'warning':
            return <FeedbackWarning />;
          default:
            return <></>;
        }
      }}
    />
  );
};
