import { useEffect, useRef } from 'react';
import videojs from 'video.js';
import 'video.js/dist/video-js.css';

export const VideoJS = ({ options }) => {
  const videoRef = useRef(null);
  const playerRef = useRef(null);

  useEffect(() => {
    if (!playerRef.current) {
      const videoElement = videoRef.current;
      if (!videoElement) return;
      playerRef.current = videojs(videoElement, {
        autoplay: true,
        controls: true,
        responsive: false,
        muted: true,
        loop: true,
        fluid: true,
        nativeControlsForTouch: false,
        controlBar: {
          pictureInPictureToggle: false,
          volumePanel: false,
        },
        playbackRates: [0.1, 0.2, 0.3, 0.4, 0.5, 1],
        ...options,
      });
    } else {
      playerRef.current.autoplay(options.autoplay);
      playerRef.current.src(options.sources);
    }
  }, [options, videoRef]);

  useEffect(() => {
    const player = playerRef.current;
    return () => {
      if (player) {
        player.dispose();
        playerRef.current = null;
      }
    };
  }, [playerRef]);

  return (
    <div data-vjs-player>
      <video
        ref={videoRef}
        playsInline
        className="video-js vjs-big-play-centered"
      />
    </div>
  );
};

export default VideoJS;
