/* eslint-disable @typescript-eslint/no-unused-vars */
import cx from 'classnames';
import React, { MutableRefObject, forwardRef, useEffect, useRef } from 'react';
import { TableToggleCommonProps } from 'react-table';
import { toast } from 'react-toastify';
import styles from './IndeterminateCheckbox.module.css';

interface ITableToggleCheckbox extends TableToggleCommonProps {
  isDisabled?: boolean;
  notAllowedMessage?: string;
}
export const IndeterminateCheckbox = forwardRef<
  HTMLInputElement,
  ITableToggleCheckbox
>((props: ITableToggleCheckbox, ref) => {
  const defaultRef = useRef<HTMLInputElement>(null);
  const resolvedRef = (ref as MutableRefObject<HTMLInputElement>) || defaultRef;

  useEffect(() => {
    if (props.indeterminate) {
      resolvedRef.current.indeterminate = props.indeterminate;
    }
  }, [resolvedRef, props.indeterminate]);

  return (
    <div style={{ zIndex: 2, position: 'relative' }}>
      <input
        type="checkbox"
        className={cx({ [styles['not-allowed']]: props.isDisabled })}
        ref={resolvedRef}
        onChange={(e) => {
          // allows firing click event but prevents checking on checkboxes with `isDisabled` prop
          if (props.isDisabled) {
            e.preventDefault();
            return;
          }

          props.onChange && props.onChange(e);
        }}
        onClick={(e) => {
          if (props.isDisabled) {
            toast.warning(props.notAllowedMessage);
          }
        }}
        checked={props.checked}
      />
    </div>
  );
});
