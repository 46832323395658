import { FetchMockSandbox } from 'fetch-mock';

/* eslint-disable @typescript-eslint/no-explicit-any */
export const get = async (url: string, options = {}): Promise<any> => {
  return await doFetch(currentFetchImpl(), url, 'GET', null, options);
};

export const post = async (
  url: string,
  payload = {},
  options = {},
): Promise<any> => {
  return await doFetch(currentFetchImpl(), url, 'POST', payload, options);
};

export const patch = async (
  url: string,
  payload = {},
  options = {},
): Promise<any> => {
  return await doFetch(currentFetchImpl(), url, 'PATCH', payload, options);
};

export const head = async (url: string, options = {}): Promise<any> => {
  return await doFetch(currentFetchImpl(), url, 'HEAD', null, options);
};

const defaultHeaders = {
  Accept: 'application/json',
  'Content-Type': 'application/json',
};

const doFetch = async (
  fetchImpl: typeof fetch,
  url: string,
  method = 'GET',
  payload?: any,
  headers: any = {},
): Promise<any> => {
  const headersToSend = { ...defaultHeaders, ...headers };

  const fetchConfig: any = {
    method,
    headers: headersToSend,
  };

  if (payload) {
    fetchConfig.body = JSON.stringify(payload);
  }

  const response = await fetchImpl(url, fetchConfig);

  const data = await response.json();

  if (process.env.NODE_ENV === 'test') {
    const fetchMock = fetchImpl as FetchMockSandbox;
    const lastOptions = fetchMock.lastOptions();
    const payload = lastOptions ? lastOptions.body : {};
    if (process.env.REACT_APP_FETCH_TRACE_LOGGING === 'true') {
      console.log(
        url,
        'handled by fetchMock\nmethod:',
        method,
        '\nheaders:\n',
        headersToSend,
        '\npayload:\n',
        payload,
      );
    }
  }

  if (response.ok) {
    return data;
  } else {
    throw data;
  }
};

const fetchInstances: Array<typeof fetch> = [];

export const pushFetchImpl = (fetchImpl: typeof fetch): void => {
  fetchInstances.push(fetchImpl);
};

export const popFetchImpl = (): typeof fetch | undefined => {
  if (fetchInstances.length < 1) {
    throw Error('There is nothing to popFetchImpl');
  }
  return fetchInstances.pop();
};

const currentFetchImpl = (): typeof fetch => {
  if (fetchInstances.length < 1) {
    throw Error(
      "No implementations of Fetch exist. You must first call pushFetchImpl with the implementation you'd like to use.",
    );
  }

  return fetchInstances[fetchInstances.length - 1];
};
