import { trimDecimals } from 'util/trimDecimals';

interface ConvertWeightProps {
  weight: number;
}

// Weight in kg from the API
export const ConvertWeightToLb = ({ weight }: ConvertWeightProps) => {
  const kgToLbs = 2.20462;
  const convertedWeight = trimDecimals(weight * kgToLbs);

  return <span> {convertedWeight} lb</span>;
};
