import apiAssessmentId from 'mockApi/contracts/api-assessment-id';
import apiAssessments from 'mockApi/contracts/api-assessments';
import apiEditUser from 'mockApi/contracts/api-edit-user';
import apiOrganizations from 'mockApi/contracts/api-organizations';
import apiUser from 'mockApi/contracts/api-user-id';

/**
 * There is no automatic discovery of apiContracts. You have to import and them and
 * add each of them to the array below:
 */
export const apiContracts = [
  apiAssessments,
  apiAssessmentId,
  apiUser,
  apiEditUser,
  apiOrganizations,
];
