import { useAuth0 } from '@auth0/auth0-react';
import { Error } from 'models/Error';
import { useQuery } from 'react-query';
import { toast } from 'react-toastify';
import { UserResponseModel } from 'types/api';
import {
  ContentType,
  useAuthenticatedFetch,
} from 'hooks/util/useAuthenticatedFetch';

export const useLoggedInUser = (enabled = true) => {
  const fetch = useAuthenticatedFetch(ContentType.NO_ORGANIZATION_HEADER);
  const { logout } = useAuth0();

  return useQuery<UserResponseModel, Error>(
    ['loggedInUser'],
    () => fetch(`${process.env.REACT_APP_API_HOST}/users/my`),
    {
      enabled,
      onError: () => {
        toast.error('Error fetching user profile.');
      },
      onSuccess: (response: UserResponseModel | undefined) => {
        if (response === (undefined || '')) {
          logout({
            returnTo: window.location.origin,
          });
        }
      },
    },
  );
};
