import animationData from 'assets/animations/archetypes.json';
import lottie from 'lottie-web';
import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { Archetype } from 'types/api';
import { ArchetypeSVG } from './ArchetypeSVG';
import './Archetypes.css';
import styles from './Archetypes.module.css';
import {
  BigPlusContent,
  ForceMoverContent,
  HyperAthleticGuardContent,
  KinematicMoverContent,
  MiscPerimeterContent,
  SpecimenContent,
  TraditionalBigContent,
} from './content';

// const content = {
//   [Archetype.BIG_PLUS]: <BigPlusContent />,
//   [Archetype.FORCE_MOVER]: <ForceMoverContent />,
//   [Archetype.HYPER_ATHLETIC_GUARD]: <HyperAthleticGuardContent />,
//   [Archetype.KINEMATIC_MOVER]: <KinematicMoverContent />,
//   [Archetype.MISC_PERIMETER]: <MiscPerimeterContent />,
//   [Archetype.SPECIMEN]: <SpecimenContent />,
//   [Archetype.TRADITIONAL_BIG]: <TraditionalBigContent />,
// };

export const Archetypes = ({ archetype, name }) => {
  const isAnimating = useRef(false);
  const containerRef = useRef(null);
  const [isVisible, setIsVisible] = useState(false);
  const [svg, setSvg] = useState(null);
  const [hideAxes, setHideAxes] = useState(false);
  const [currentArchetype, setCurrentArchetype] = useState(archetype);
  const showName = useCallback(
    (chosenArchetype) => {
      if (archetype === chosenArchetype) {
        return {
          name: name,
          position: chosenArchetype,
        };
      }
    },
    [archetype, name],
  );

  const content = useMemo(
    () => ({
      [Archetype.BIG_PLUS]: (
        <BigPlusContent {...showName(Archetype.BIG_PLUS)} />
      ),
      [Archetype.FORCE_MOVER]: (
        <ForceMoverContent {...showName(Archetype.FORCE_MOVER)} />
      ),
      [Archetype.HYPER_ATHLETIC_GUARD]: (
        <HyperAthleticGuardContent
          {...showName(Archetype.HYPER_ATHLETIC_GUARD)}
        />
      ),
      [Archetype.KINEMATIC_MOVER]: (
        <KinematicMoverContent {...showName(Archetype.KINEMATIC_MOVER)} />
      ),
      [Archetype.MISC_PERIMETER]: (
        <MiscPerimeterContent {...showName(Archetype.MISC_PERIMETER)} />
      ),
      [Archetype.SPECIMEN]: (
        <SpecimenContent {...showName(Archetype.SPECIMEN)} />
      ),
      [Archetype.TRADITIONAL_BIG]: (
        <TraditionalBigContent {...showName(Archetype.TRADITIONAL_BIG)} />
      ),
    }),
    [showName],
  );

  if (isVisible && !svg && !isAnimating.current) {
    isAnimating.current = true;
    async function tick() {
      await new Promise((resolve) => setTimeout(resolve, 1000));
      const animation = lottie.loadAnimation({
        container: document.getElementById('lottie'),
        renderer: 'svg',
        loop: false,
        animationData: animationData,
        autoplay: true,
      });
      animation.addEventListener('complete', () => {
        setSvg(true);
      });
    }
    tick();
  }

  useEffect(() => {
    const container = containerRef.current;
    const observer = new window.IntersectionObserver(handleVisible, {
      root: null,
      rootMargin: '0px',
      threshold: 1,
    });
    if (container) observer.observe(container);

    return () => {
      if (container) observer.unobserve(container);
    };
  }, []);

  const handleVisible = (entries) => {
    const [entry] = entries;
    setIsVisible(entry.isIntersecting);
  };

  const handleOver = ({ currentTarget }) => {
    currentTarget.classList.add('over');
    document.getElementById('description').classList.add('over');
    setCurrentArchetype(currentTarget.dataset.archetype);
  };

  const handleOut = ({ currentTarget }) => {
    currentTarget.classList.remove('over');
    document.getElementById('description').classList.remove('over');
    setCurrentArchetype(archetype);
  };

  const toggleAxes = ({ target }) => {
    setHideAxes(!target.checked);
  };

  return (
    <div className={styles.archetype}>
      <div className={styles.header}>
        <h2>Archetype Analysis</h2>
        <p>
          Athletes are grouped into clusters using a machine learning technique
          that leverages 10 anthropometric, performance, and mechanical
          variables in order to generate the seven archetypes shown.
        </p>
      </div>
      <div className={styles.layout}>
        <div ref={containerRef} className={styles.visual}>
          {svg ? (
            <>
              <ArchetypeSVG
                archetype={archetype}
                handleOver={handleOver}
                handleOut={handleOut}
                hideAxes={hideAxes}
              />
              <div className={styles.legend}>
                <label className={styles.toggle}>
                  <input
                    id="axes"
                    type="checkbox"
                    onClick={toggleAxes}
                    defaultChecked
                  />
                  <span className={styles.slider} />
                </label>
                <label htmlFor="axes">Axes</label>
              </div>
            </>
          ) : (
            <div id="lottie" />
          )}
        </div>
        <div id="description" className="description">
          {content[currentArchetype]}
        </div>
      </div>
    </div>
  );
};
