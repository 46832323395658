import { useParams } from 'react-router-dom';
import { AssessmentWrapper } from 'types/api';
import { useAssessment } from 'hooks/query/useAssessment';
import { useLoggedInUser } from 'hooks/query/useLoggedInUser';
import { AssessmentsDetail } from 'components/AssessmentDetail';
import { Loading } from 'components/Loading';

export const AssessmentsDetails = () => {
  const { id } = useParams();
  const { data: user } = useLoggedInUser();
  const { data: assessmentWrapper, isLoading } = useAssessment(id);

  if (isLoading) return <Loading />;

  return (
    <AssessmentsDetail
      assessmentWrapper={assessmentWrapper as AssessmentWrapper}
      user={user}
    />
  );
};
