import { ReactComponent as InfoIcon } from 'assets/icons/info.svg';
import { ReactComponent as RadarIcon } from 'assets/icons/radar.svg';
import { ReactComponent as TableIcon } from 'assets/icons/table.svg';
import { ReactComponent as VideoIcon } from 'assets/icons/video.svg';
import { useMemo } from 'react';
import { BasketballAssessment } from 'types/api';
import { radarGraphLegendText } from 'util/radarGraphLegendText';
import { GraphTable } from 'components/GraphTable';
import { GraphTabs } from 'components/GraphTabs';
import { Radar, RadarData } from 'components/Radar';
import { Video } from 'components/Video';
import {
  infoTermsVertDrop,
  radarLabels,
  vertDropJumpTableGraphTerms,
} from './VertDropJump';

interface VertDropJumpCompareProps {
  assessments: BasketballAssessment[];
  className?: string;
}

export const VertDropJumpCompare = (props: VertDropJumpCompareProps) => {
  const { assessments, className } = props;

  const radarData = useMemo(() => {
    const result: RadarData[] = [];
    assessments.forEach((a: BasketballAssessment) => {
      if (
        !a.assessmentDetails ||
        !a.assessmentDetails.assessmentDropJumpSummary
      ) {
        return;
      }

      const {
        percNetRelConcForce = 0,
        percMaxKneeExtensionVelocityAvg = 0,
        percNetImpact2RightRaw = 0,
        percNetImpact1Avg = 0,
        percNetImpact2LeftRaw = 0,
        percMaxKneeExtensionAccelerationAvg = 0,
      } = a.assessmentDetails.assessmentDropJumpSummary;

      const radarObject: RadarData = {
        name: radarGraphLegendText(a.name, a.date),
        value: [
          percNetRelConcForce,
          percMaxKneeExtensionVelocityAvg,
          percNetImpact2RightRaw,
          percNetImpact1Avg,
          percNetImpact2LeftRaw,
          percMaxKneeExtensionAccelerationAvg,
        ],
      };
      result.push(radarObject);
    });
    return result;
  }, [assessments]);

  const config = [
    {
      id: 'radarTab',
      name: 'Percentile',
      Icon: RadarIcon,
      content: <Radar data={radarData} indicator={radarLabels} />,
    },
    {
      id: 'tableTab',
      name: 'Test Results',
      Icon: TableIcon,
      content: (
        <GraphTable
          assessments={assessments}
          definitions={vertDropJumpTableGraphTerms}
        />
      ),
    },
    {
      id: 'infoTab',
      name: 'Terminology',
      Icon: InfoIcon,
      content: infoTermsVertDrop,
    },
  ];

  const videos = assessments[0]?.videos?.filter(
    (video) => video.exercise === 'DropJump',
  );
  if (videos?.length) {
    config.push({
      id: 'videoTab',
      name: 'Video',
      Icon: VideoIcon,
      content: <Video videos={videos} />,
    });
  }

  return (
    <GraphTabs
      className={className}
      config={config}
      defaultTab="radarTab"
      heading="Vertical: Drop Jump"
    />
  );
};
