/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

export enum AssessmentSortOption {
    PLAYER_NAME = 'PlayerName',
    TYPE = 'Type',
    POSITION = 'Position',
    LAST = 'Last',
    COUNT = 'Count',
    ATHLETICISM_SCORE = 'AthleticismScore',
    MECHANICS_SCORE = 'MechanicsScore',
    ASSESSMENT_TYPE = 'AssessmentType',
}