import { useQueries } from 'react-query';
import {
  ContentType,
  useAuthenticatedFetch,
} from 'hooks/util/useAuthenticatedFetch';

export const useOrganizationByIds = (ids: string[]) => {
  const fetch = useAuthenticatedFetch(ContentType.NO_ORGANIZATION_HEADER);

  return useQueries(
    ids.map((id) => {
      return {
        queryKey: ['organizationsById', id],
        queryFn: () =>
          fetch(`${process.env.REACT_APP_API_HOST}/Organizations/${id}`),
        enabled: ids.length > 0,
      };
    }),
  );
};
