import { Error } from 'models/Error';
import { useQuery } from 'react-query';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import { AssessmentType } from 'types/api';
import { useAuthenticatedFetch } from 'hooks/util/useAuthenticatedFetch';

export const useAssessmentTypes = () => {
  const fetch = useAuthenticatedFetch();
  const navigate = useNavigate();

  return useQuery<AssessmentType[], Error>(
    ['assessmentTypes'],
    () => fetch(`${process.env.REACT_APP_API_HOST}/Assessments/Types`),
    {
      onError: () => {
        toast.error('Error fetching assessment types.');
        return navigate('/assessments');
      },
    },
  );
};
