import { ReactComponent as InfoIcon } from 'assets/icons/info.svg';
import { ReactComponent as RadarIcon } from 'assets/icons/radar.svg';
import { ReactComponent as TableIcon } from 'assets/icons/table.svg';
import { ReactComponent as VideoIcon } from 'assets/icons/video.svg';
import {
  AssessmentSoccerStandingJumpSummary,
  AssessmentStandingJumpSummary,
  AssessmentType,
  BaseAssessment,
  BasketballAssessment,
  SoccerAssessment,
} from 'types/api';
import { labels } from 'util/graphConstants';
import { unitsOfMeasure } from 'util/unitsOfMeasure';
import { GraphInfo } from 'components/GraphInfo';
import { GraphTable } from 'components/GraphTable';
import { GraphTabs } from 'components/GraphTabs';
import { Radar } from 'components/Radar';
import { Video } from 'components/Video';

interface VertStandingProps {
  assessment: BasketballAssessment | SoccerAssessment;
  className?: string;
}

interface ITerms {
  key: string;
  unit?: string;
  name?: string;
}

const { ankleExtAccel, kneeExtAccel, concRelFF, loadRelFF, kneeExtVel } =
  labels;

export const vertStandingTableGraphTerms = (
  type?: AssessmentType,
): ITerms[] => {
  let path = '';
  const terms = [];
  switch (type) {
    case AssessmentType.BASKETBALL:
      path = 'assessmentDetails.assessmentStandingJumpSummary';
      terms.push({
        key: `${path}.maxAnklePlantarFlexionAccelerationAvg`,
        unit: unitsOfMeasure('acceleration'),
        name: ankleExtAccel,
      });
      break;
    case AssessmentType.SOCCER:
      path = 'assessmentDetails.assessmentSoccerStandingJumpSummary';
  }
  terms.push(
    ...[
      {
        key: `${path}.maxKneeExtensionAccelerationAvg`,
        unit: unitsOfMeasure('acceleration'),
        name: kneeExtAccel,
      },
      {
        key: `${path}.maxKneeExtensionVelocityAvg`,
        unit: unitsOfMeasure('velocity'),
        name: kneeExtVel,
      },
      {
        key: `${path}.concentricRelativeFF`,
        unit: unitsOfMeasure('force'),
        name: concRelFF,
      },
      {
        key: `${path}.loadRelativeFF`,
        unit: unitsOfMeasure('force'),
        name: loadRelFF,
      },
    ],
  );
  return terms;
};

export const radarLabelsVert = [
  { max: 100, name: ankleExtAccel },
  { max: 100, name: kneeExtAccel },
  { max: 100, name: concRelFF },
  { max: 100, name: loadRelFF },
  { max: 100, name: kneeExtVel },
];

export const InfoTermsVertStanding = ({
  type,
}: {
  type: AssessmentType | undefined;
}) => {
  const terms = [];
  if (type === AssessmentType.BASKETBALL) {
    terms.push('ankleExtAccel');
  }
  terms.push(...['kneeExtAccel', 'kneeExtVel', 'loadRelFF', 'concRelFF']);
  return <GraphInfo terms={terms} />;
};

export type StandingJumpSummary = AssessmentStandingJumpSummary &
  AssessmentSoccerStandingJumpSummary & {
    average?: { value: number[]; name?: string }[];
  };

export const getSummary = (
  assessment: BaseAssessment,
): StandingJumpSummary | undefined => {
  let summary: StandingJumpSummary | undefined;
  switch (assessment.assessmentType) {
    case AssessmentType.BASKETBALL:
      summary = (assessment as BasketballAssessment)?.assessmentDetails
        ?.assessmentStandingJumpSummary;
      break;
    case AssessmentType.SOCCER: {
      summary = (assessment as SoccerAssessment)?.assessmentDetails
        ?.assessmentSoccerStandingJumpSummary;
    }
  }
  return summary;
};

export const VertStanding = (props: VertStandingProps) => {
  const { assessment, className } = props;

  const summary = getSummary(assessment);

  const {
    percMaxAnklePlantarFlexionAccelerationAvg = 0,
    percLoadRelFF = 0,
    percConcRelFF = 0,
    percMaxKneeExtensionAccelerationAvg = 0,
    percMaxKneeExtensionVelocityAvg = 0,
  } = summary || {};

  const data: { value: number[]; name?: string }[] = [
    {
      value: [
        percMaxAnklePlantarFlexionAccelerationAvg,
        percMaxKneeExtensionAccelerationAvg,
        percConcRelFF,
        percLoadRelFF,
        percMaxKneeExtensionVelocityAvg,
      ],
    },
  ];

  if (assessment.assessmentType === AssessmentType.SOCCER) {
    data.push({
      name: `${assessment?.position} Average`,
      value: Array(data[0].value.length).fill(50),
    });
  }

  const config = [
    {
      id: 'radarTab',
      name: 'Percentile',
      Icon: RadarIcon,
      content: <Radar data={data} indicator={radarLabelsVert} />,
    },
    {
      id: 'tableTab',
      name: 'Test Results',
      Icon: TableIcon,
      content: (
        <GraphTable
          assessments={[assessment]}
          definitions={vertStandingTableGraphTerms(assessment?.assessmentType)}
        />
      ),
    },
    {
      id: 'infoTab',
      name: 'Terminology',
      Icon: InfoIcon,
      content: <InfoTermsVertStanding type={assessment.assessmentType} />,
    },
  ];

  const videos = assessment.videos?.filter(
    (video) => video.exercise === 'StandingVertical',
  );
  if (videos?.length) {
    config.push({
      id: 'videoTab',
      name: 'Video',
      Icon: VideoIcon,
      content: <Video videos={videos} />,
    });
  }

  return (
    <GraphTabs
      className={className}
      config={config}
      defaultTab="radarTab"
      heading="Vertical: Standing"
    />
  );
};
