import { Link } from 'react-router-dom';
import { CellProps } from 'react-table';
import { AssessmentSortOption, AssessmentType } from 'types/api';
import { useBreakpoints } from 'hooks/util/useBreakpoints';
import {
  AListTableColumns,
  FilteredColumn,
} from 'components/AssessmentsListTable';
import styles from 'components/AssessmentsListTable/AssessmentsListTable.module.css';
import { AssessmentGroupJoined } from './Admin';

const columns: FilteredColumn<AssessmentGroupJoined>[] = AListTableColumns;
export const ViewColumnID = 'view';

columns.splice(2, 0, {
  Header: 'Sport',
  accessor: 'assessmentType',
  Footer: '',
  id: AssessmentSortOption.ASSESSMENT_TYPE,
  includedIn: [AssessmentType.ALL],
});

columns.push({
  Header: () => null,
  id: ViewColumnID,
  Cell: ({ row }: CellProps<AssessmentGroupJoined>) => {
    const { tabletDown } = useBreakpoints();
    let id;

    if (row.original.assessments && row.original.assessments.length > 0) {
      id = row.original.assessments[0].id;
    }

    return (
      <Link to={`/assessments/${id}`} className={styles['view-link']}>
        <span>View {!tabletDown && 'Assessment'}</span>
      </Link>
    );
  },
  Footer: '',
});

export { columns };
