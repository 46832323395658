export const MiscPerimeterContent = (props: { name: string }) => {
  const showName = props.name ? (
    <h2>{props.name} is a Misc. Perimeter</h2>
  ) : (
    <h2>Misc. Perimeter</h2>
  );
  return (
    <div>
      {showName}
      <p>
        Athletes in this cluster possess adequate Height and Length measures
        relative to the NBA population but fail to pair these metrics with
        'plus' movement skills. Both force production and kinematic variables
        grade-out at as below-average in all planes.
      </p>
      <ul>
        <li>
          Adequate (unspectacular) size for an athlete playing on the perimeter.
        </li>
        <li>Limited lateral plane movement power/efficiency.</li>
        <li>Below-average jumpers.</li>
        <li>May need an elite skill to accompany current movement tools.</li>
      </ul>
      <h2>Misc. Perimeters</h2>
      <p>
        Athletes in the Misc. Perimeter cluster stick in the NBA at a rate lower
        than any other cluster, regardless of position.
      </p>
    </div>
  );
};
