import { useEffect } from 'react';
import { OrganizationResponseModel, Role, UserResponseModel } from 'types/api';
import { useOrganizationById } from 'hooks/query/useOrganizationById';
import { ILocalStorageContext } from '../../util/getContextFromLocalStorage';
import {
  OrgAndRoleResponse,
  getDefaultOrgAndRole,
} from '../../util/getDefaultOrgAndRole';

export const useDefaultContextAssignment = (
  contextFromLocalStorage: ILocalStorageContext,
  setContext: (
    contextOrg: OrganizationResponseModel | undefined,
    contextRole: Role | undefined,
  ) => void,
  setNoOrgAssigned: (param: boolean) => void,
  loggedInUser: UserResponseModel | undefined,
) => {
  // If no selected organization is found in local storage, get a list of the logged in user's organizations, then set global context with the first org returned in the array.
  const isLocalStoragePopulated = !!contextFromLocalStorage.localStorageOrg;
  let defaultOrgAndRole: OrgAndRoleResponse | undefined;

  // Only get the default organization ID if local storage is not populated.
  if (!isLocalStoragePopulated) {
    defaultOrgAndRole = getDefaultOrgAndRole(loggedInUser);
  }

  // We store the entire OrganizationResponseModel object in state, not just the organization ID, which was retrieved above.
  // Disable the useOrganizationById query until a defaultOrgId has been retrieved from the useLoggedInUser query.
  const { data: organization } = useOrganizationById(
    defaultOrgAndRole?.defaultOrgId,
  );

  const defaultOrgObject = organization;

  useEffect(() => {
    if (
      !isLocalStoragePopulated &&
      !defaultOrgObject &&
      defaultOrgAndRole?.role === Role.SUPER_ADMIN
    ) {
      setNoOrgAssigned(true);
    }
    if (
      !isLocalStoragePopulated &&
      (defaultOrgObject || defaultOrgAndRole?.role)
    ) {
      setContext(defaultOrgObject, defaultOrgAndRole?.role);
    }
  }, [
    defaultOrgObject,
    defaultOrgAndRole?.role,
    isLocalStoragePopulated,
    setContext,
    setNoOrgAssigned,
  ]);
};
