import { ReactElement } from 'react';
import {
  AssessmentType,
  BaseAssessment,
  BasketballAssessment,
  BasketballAssessmentDetails,
  DateOnly,
  Position,
  SoccerAssessment,
  SoccerAssessmentDetails,
} from 'types/api';
import { getPercentageDifference } from 'util/getPercentageDifference';
import { tooltipText } from 'util/tooltipText';
import { trimDecimals } from 'util/trimDecimals';
import { unitsOfMeasure } from 'util/unitsOfMeasure';
import { FormatHeight } from 'components/FormatHeight';

interface IStat {
  title: string;
  value: number | ReactElement;
  difference: number;
  unit?: string;
  tooltip?: string;
}

interface Info {
  height: number;
  weight: number;
  firstName: string;
  lastName: string;
  name: string | null | undefined;
  date: DateOnly | undefined;
  position: Position | undefined;
  athleticismScore?: number;
  athleticismScoreDelta?: number | undefined;
  mechanicsScore?: number | undefined;
  mechanicsScoreDelta?: number | undefined;
}
interface BasketballStats {
  vertJump: IStat;
  dropJump: IStat;
  latForce: IStat;
  reach: IStat;
}

interface SoccerStats {
  verticalJump: IStat;
  relativePower: IStat;
  lateralForce: IStat;
}

const getBasketballData = (
  currentAssessment: BaseAssessment,
  assessments: BasketballAssessment[],
): { info: Info; stats: BasketballStats } => {
  const { assessmentDetails, name, date, position } =
    currentAssessment as BasketballAssessment;
  const { assessmentBasketballSummary } =
    (assessmentDetails as BasketballAssessmentDetails) || {};
  const [firstName, lastName] = name?.split(' ') || [];
  const {
    weight = 0,
    height = 0,
    athleticismScore = 0,
    mechanicsScore = 0,
  } = assessmentBasketballSummary || {};

  const getDate = (date: string | number | Date) => new Date(date).getTime();
  const sortAssessments = assessments.sort(
    (a, b) => getDate(b?.date as number) - getDate(a?.date as number),
  );
  const findCurrentAssessmentIndex = sortAssessments.indexOf(currentAssessment);

  let athleticismScoreDelta = 0;
  let mechanicsScoreDelta = 0;

  let previousAssessment: BasketballAssessment | undefined | null;
  if (assessments && assessments.length > 1) {
    previousAssessment = sortAssessments[findCurrentAssessmentIndex + 1];
    const {
      athleticismScore: prevAthleticismScore = 0,
      mechanicsScore: prevMechanicsScore = 0,
    } =
      previousAssessment?.assessmentDetails?.assessmentBasketballSummary || {};

    if (!previousAssessment) {
      previousAssessment = null;
    }

    if (!previousAssessment) {
      athleticismScoreDelta = 0;
      mechanicsScoreDelta = 0;
    } else {
      athleticismScoreDelta = athleticismScore - prevAthleticismScore;
      mechanicsScoreDelta = mechanicsScore - prevMechanicsScore;
    }
  }

  const summary1 = assessmentBasketballSummary || {};
  const summary2 =
    previousAssessment?.assessmentDetails?.assessmentSummary || {};

  return {
    stats: {
      vertJump: {
        title: 'Vert Jump',
        value: summary1.vertJump || 0,
        difference: getPercentageDifference(
          summary1.vertJump,
          summary2.vertJump,
        ),
        unit: unitsOfMeasure('jump'),
        tooltip: tooltipText.VertJump,
      },
      dropJump: {
        title: 'Drop Jump',
        value: summary1.dropJump || 0,
        difference: getPercentageDifference(
          summary1.dropJump,
          summary2.dropJump,
        ),
        unit: unitsOfMeasure('jump'),
        tooltip: tooltipText.DropJump,
      },
      latForce: {
        title: 'Lat Force',
        value: trimDecimals(summary1.latForce || 0),
        difference: getPercentageDifference(
          summary1.latForce,
          summary2.latForce,
        ),
        unit: unitsOfMeasure('force'),
        tooltip: tooltipText.LatForce,
      },
      reach: {
        title: 'Reach',
        value: FormatHeight({ height: summary1.reach as number }),
        difference: getPercentageDifference(summary1.reach, summary2.reach),
        unit: '',
        tooltip: tooltipText.Reach,
      },
    },
    info: {
      height,
      weight,
      firstName,
      lastName,
      athleticismScoreDelta,
      mechanicsScoreDelta,
      athleticismScore,
      mechanicsScore,
      date,
      position,
      name,
    },
  };
};

const getSoccerData = (
  currentAssessment: SoccerAssessment,
  assessments: SoccerAssessment[],
): { info: Info; stats: SoccerStats } => {
  const { assessmentDetails, name, date, position } =
    currentAssessment as SoccerAssessment;
  const { assessmentSoccerSummary } =
    (assessmentDetails as SoccerAssessmentDetails) || {};
  const [firstName, lastName] = name?.split(' ') || [];
  const { weight = 0, height = 0 } = assessmentSoccerSummary || {};

  const getDate = (date: string | number | Date) => new Date(date).getTime();
  const sortAssessments = assessments.sort(
    (a, b) => getDate(b?.date as number) - getDate(a?.date as number),
  );
  const findCurrentAssessmentIndex = sortAssessments.indexOf(currentAssessment);

  let previousAssessment: SoccerAssessment | undefined;
  if (assessments && assessments.length > 1) {
    previousAssessment = sortAssessments[findCurrentAssessmentIndex + 1];
  }
  const summary1 = assessmentSoccerSummary || {};
  const summary2 =
    previousAssessment?.assessmentDetails?.assessmentSoccerSummary || {};
  return {
    stats: {
      verticalJump: {
        title: 'Vert Jump',
        value: trimDecimals(summary1.verticalJump || 0),
        difference: getPercentageDifference(
          summary1.verticalJump,
          summary2.verticalJump,
        ),
        unit: unitsOfMeasure('jump'),
        tooltip: tooltipText.VertJump,
      },
      lateralForce: {
        title: 'Avg Lateral Drive',
        value: trimDecimals(summary1.lateralForce || 0),
        difference: getPercentageDifference(
          summary1.lateralForce,
          summary2.lateralForce,
        ),
        unit: unitsOfMeasure('jump'),
        tooltip: tooltipText.LatForce,
      },
      relativePower: {
        title: 'Relative Power',
        value: trimDecimals(summary1.relativePower || 0),
        difference: getPercentageDifference(
          summary1.relativePower,
          summary2.relativePower,
        ),
        unit: unitsOfMeasure('force'),
        tooltip: tooltipText.RelativePower,
      },
    },
    info: {
      height,
      weight,
      firstName,
      lastName,
      date,
      position,
      name,
    },
  };
};

export const useAssessmentsOverview = (
  currentAssessment: BaseAssessment,
  assessments: BaseAssessment[],
  index = 0,
): {
  type: AssessmentType | undefined;
  info: Info | undefined;
  stats: BasketballStats | SoccerStats | undefined;
} => {
  const { assessmentType: type } = assessments[index] || {};
  let info: Info | undefined;
  let stats: BasketballStats | SoccerStats | undefined;

  switch (type) {
    case AssessmentType.BASKETBALL:
      {
        const data = getBasketballData(currentAssessment, assessments);
        info = data.info;
        stats = data.stats;
      }
      break;
    case AssessmentType.SOCCER: {
      const data = getSoccerData(currentAssessment, assessments);
      info = data.info;
      stats = data.stats;
    }
  }
  return {
    type,
    info,
    stats,
  };
};
