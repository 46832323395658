import cx from 'classnames';
import { useEffect, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { CreateUserRequestModel, Organization, Role } from 'types/api';
import { Button, ButtonVariants } from 'components/Button';
import { Loading } from 'components/Loading';
import { ProfilePicture } from 'components/ProfilePicture';
import { TextField } from 'components/TextField';
import styles from './StepTwoCreateUserForm.module.css';

interface IFormProps {
  isLoading: boolean;
  newUserEmail: string | undefined;
  onSubmit: (form: FormData) => void;
  organization: Organization;
  setIsLoading: (value: boolean) => void;
}

type NonNullableCreateUserRequestModel = {
  [K in keyof CreateUserRequestModel]: NonNullable<CreateUserRequestModel[K]>;
};

export const StepTwoCreateUserForm = (props: IFormProps) => {
  const { organization, onSubmit, newUserEmail, setIsLoading, isLoading } =
    props;
  const { control, handleSubmit, register, watch } =
    useForm<NonNullableCreateUserRequestModel>({});
  const [imageUpload, setImageUpload] = useState<File>();
  const [formChangesDetected, setFormChangesDetected] =
    useState<boolean>(false);

  register('organizationRoles.0.organizationId', { value: organization.id });
  register('email', { value: newUserEmail });

  const onFormSubmit = (formData: CreateUserRequestModel) => {
    setIsLoading(true);
    let isSuperAdmin: boolean;
    if (formData.organizationRoles) {
      isSuperAdmin = formData.organizationRoles[0].role === Role.SUPER_ADMIN;
      const newUserData = {
        ...formData,
        isSuperAdmin,
      };
      const requestPayload = new FormData();
      if (imageUpload) {
        requestPayload.append('Image', imageUpload);
      }
      requestPayload.append('userData', JSON.stringify(newUserData));
      setFormChangesDetected(false);
      onSubmit(requestPayload);
    }
  };

  useEffect(() => {
    const subscription = watch((value) => {
      setFormChangesDetected(!!value);
    });
    return () => {
      subscription.unsubscribe();
    };
  }, [watch]);

  return (
    <section className={styles['form-section']}>
      <form
        className={styles.form}
        noValidate
        onSubmit={handleSubmit(onFormSubmit)}
      >
        <h2 className={styles['modal-title']}>Add Account Information</h2>

        <div className={styles['profile-picture-container']}>
          <ProfilePicture
            size={160}
            src={imageUpload ? URL.createObjectURL(imageUpload) : ''}
          />
          <input
            type="file"
            className={styles.upload}
            accept="image/png, image/jpeg"
            name="Image"
            onChange={({ target }) => {
              if (target.files) {
                const file = target.files[0];
                setImageUpload(file);
              }
            }}
          />
        </div>
        <div className="form-container">
          {isLoading ? (
            <div className="inline-cover">
              <Loading />
            </div>
          ) : null}
          <fieldset className={styles.fieldset}>
            <legend>{organization.organizationName}</legend>
            <TextField
              label="Organization Name"
              value={
                organization.organizationName
                  ? organization.organizationName
                  : ''
              }
              className={cx([styles['text-field'], styles.disabled])}
              readOnly
              required
            />
            <TextField
              label="Email"
              value={newUserEmail || ''}
              className={cx([styles['text-field'], styles.disabled])}
              readOnly
              required
            />
            <Controller
              control={control}
              name="firstName"
              render={({ field, fieldState }) => (
                <TextField
                  {...field}
                  error={fieldState.error?.message}
                  className={styles['text-field']}
                  label="First Name"
                  required
                />
              )}
              rules={{ required: 'Required' }}
            />
            <Controller
              control={control}
              name="lastName"
              render={({ field, fieldState }) => (
                <TextField
                  {...field}
                  error={fieldState.error?.message}
                  className={styles['text-field']}
                  label="Last Name"
                  required
                />
              )}
              rules={{ required: 'Required' }}
            />
            <Controller
              control={control}
              name="organizationRoles.0.role"
              render={({ field, fieldState }) => (
                <TextField
                  {...field}
                  error={fieldState.error?.message}
                  className={styles['text-field']}
                  label="Role"
                  tagName="select"
                  required
                >
                  <option value="" />
                  <option value={Role.SUPER_ADMIN}>Super Admin</option>
                  <option value={Role.ORGANIZATION_ADMIN}>Administrator</option>
                  <option value={Role.ORGANIZATION_STAFF}>Staff</option>
                </TextField>
              )}
            />
            <input
              {...register('organizationRoles.0.organizationId')}
              type="hidden"
            />
            <input {...register('email')} type="hidden" />
          </fieldset>
          <div className={styles.buttons}>
            <Button
              disabled={!formChangesDetected}
              variant={ButtonVariants.Primary}
              type="submit"
            >
              Save
            </Button>
          </div>
        </div>
      </form>
    </section>
  );
};
