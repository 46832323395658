interface FormatHeightProps {
  height: number;
  className?: string;
}

export const FormatHeight = ({ height, className }: FormatHeightProps) => {
  const heightInFeet = height / 12;
  const [feet, feetRatio = '0'] = String(heightInFeet).split('.');
  const inches = parseFloat(
    (Number(`.${feetRatio}`) * 12).toFixed(1),
  ).toString();
  return (
    <span className={className}>
      {feet}' {inches}"
    </span>
  );
};
