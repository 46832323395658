import { BarChart } from 'echarts/charts';
import {
  GridComponent,
  LegendComponent,
  TooltipComponent,
} from 'echarts/components';
import * as echarts from 'echarts/core';
import type { BarSeriesOption, CategoryAxisBaseOption } from ' echarts/echarts';
import { SVGRenderer } from 'echarts/renderers';
import config from 'themes/p3/p3.project.json';
import { useChart } from 'hooks/util/useChart';
import styles from './Bar.module.css';

echarts.use([
  SVGRenderer,
  TooltipComponent,
  GridComponent,
  LegendComponent,
  BarChart,
]);

interface BarProps {
  data?: BarSeriesOption.Data;
  labels?: CategoryAxisBaseOption.Data;
  blueBars?: boolean;
  widerWidth?: boolean;
}

const xLabelHeight = 20;
const yLabelWidth = 100;

export const Bar = (props: BarProps) => {
  const { data = [], labels = [], blueBars, widerWidth } = props;

  let dataSeriesAndColor = data.map((value) => ({
    itemStyle: {
      color:
        value <= 25
          ? config.theme.color[3]
          : value <= 75
          ? config.theme.color[2]
          : config.theme.color[1],
    },
    value,
  }));

  if (blueBars) {
    dataSeriesAndColor = data.map((value) => ({
      value,
    }));
  }

  const chartRef = useChart({
    grid: {
      bottom: xLabelHeight,
      left: widerWidth ? 40 : yLabelWidth,
      right: widerWidth ? 10 : 20,
      top: 0,
    },
    series: [
      {
        barGap: 2,
        barWidth: 12,
        data: dataSeriesAndColor,
        type: 'bar',
      },
    ],
    tooltip: {
      show: false,
    },
    /* @TODO: Figure out how to properly add split line options to theme */
    xAxis: {
      axisLabel: {
        height: xLabelHeight,
      },
      interval: 25,
      min: 0,
      max: 100,
      minorSplitLine: {
        lineStyle: {
          color: '#979797',
        },
        show: true,
      },
      minorTick: {
        splitNumber: 2,
      },
      splitLine: {
        lineStyle: {
          color: '#979797',
        },
      },
      splitNumber: 4,
    },
    yAxis: {
      axisLabel: {
        lineHeight: 16,
        overflow: 'break',
        width: yLabelWidth,
      },
      axisTick: {
        alignWithLabel: true,
        lineStyle: {
          color: '#979797',
        },
      },
      data: labels,
      inverse: true,
      splitLine: {
        alignWithLabel: true,
        lineStyle: {
          color: '#979797',
        },
        show: true,
      },
    },
  });

  return <div className={styles.chart} ref={chartRef} />;
};
