import { useMutation } from 'react-query';
import { toast } from 'react-toastify';
import {
  BaseAssessment,
  RemoveAssessmentAssignmentRequestModel,
} from 'types/api';
import { useAuthenticatedFetch } from 'hooks/util/useAuthenticatedFetch';

export const useDeleteAssessmentOwner = () => {
  const fetch = useAuthenticatedFetch();
  return useMutation(
    (body: RemoveAssessmentAssignmentRequestModel) =>
      fetch<BaseAssessment[]>(
        `${process.env.REACT_APP_API_HOST}/assessments/owners`,
        {
          body,
          method: 'DELETE',
        },
      ),
    {
      onError: () => {
        toast.error('Error detaching organization.');
      },
      onSuccess: () => {
        toast.success('Organization detached.');
      },
    },
  );
};
