import {
  AssessmentType,
  BaseAssessment,
  BasketballAssessment,
} from 'types/api';

export function getScores(assessment: BaseAssessment): {
  athleticismScore: number | undefined;
  mechanicsScore: number | undefined;
} {
  let athleticismScore: number | undefined;
  let mechanicsScore: number | undefined;

  if (assessment.assessmentType === AssessmentType.BASKETBALL) {
    // Basketball assessments have scores
    const { assessmentSummary } =
      (assessment as BasketballAssessment).assessmentDetails || {};
    const { athleticismScore: aScore, mechanicsScore: mScore } =
      assessmentSummary || {};
    athleticismScore = aScore;
    mechanicsScore = mScore;
  }
  return {
    athleticismScore,
    mechanicsScore,
  };
}
