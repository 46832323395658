import type {
  ApiContract,
  Response,
} from 'lib/api-mocking/interfaces/ApiContract';
import { defaultErrorResponse } from 'mockApi/responses/defaultErrorResponse';
import type { Error } from 'models/Error';
import { UserResponseModel } from 'types/api/';

const contract: ApiContract = {
  url: '/users/:id',
  method: 'PUT',
  success: (url: string, opts): Response<UserResponseModel> => {
    return {
      status: 200,
      body: JSON.parse(opts.body),
    };
  },
  error: (): Response<Error> => {
    return {
      status: 500,
      body: defaultErrorResponse,
    };
  },
  scope: {
    test: true,
    runtime: false,
  },
};

export default contract;
