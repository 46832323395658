import { ReactElement } from 'react';
import { trimDecimals } from 'util/trimDecimals';
import { unitsOfMeasure } from 'util/unitsOfMeasure';
import { Tooltip } from 'components/Tooltip';
import styles from './Stat.module.css';

interface IStatProps {
  title: string | undefined;
  value: number | ReactElement | undefined;
  difference: number;
  unit?: string;
  tooltip?: string;
}

const trend = (score: number): string => {
  if (Math.sign(score) === 1) {
    return 'up';
  } else {
    return 'down';
  }
};

const Stat = (props: IStatProps) => {
  const { title, value, difference, unit, tooltip } = props;
  return (
    <div className={styles.main}>
      {tooltip ? (
        <h5 className={styles.title}>
          <Tooltip triggerText={title} tooltipText={tooltip} />
        </h5>
      ) : (
        <h5 className={styles.title}>{title}</h5>
      )}
      <p className={styles.value}>
        {value}
        <span className={styles.unit}> {unit}</span>
      </p>
      {difference !== 0 && (
        <p className={styles[`trend-${trend(difference)}`]}>
          <span>
            {trimDecimals(difference as number)} {unitsOfMeasure('percentage')}
          </span>
        </p>
      )}
    </div>
  );
};

export { Stat };
