import { Error } from 'models/Error';
import { useQuery } from 'react-query';
import { useMatch, useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import { AssessmentWrapper } from 'types/api';
import { useAuthenticatedFetch } from 'hooks/util/useAuthenticatedFetch';

export const useAssessment = (id?: string) => {
  const fetch = useAuthenticatedFetch();
  const navigate = useNavigate();
  const isShared = useMatch('/share/:linkId/:assessmentId');

  let enabled = true;
  if (!id) {
    enabled = false;
  }
  if (isShared) {
    enabled = false;
  }

  return useQuery<AssessmentWrapper, Error>(
    ['assessments', id],
    () => fetch(`${process.env.REACT_APP_API_HOST}/Assessments/${id}`),
    {
      enabled,
      onError: () => {
        toast.error('Error fetching assessment.');
        return navigate('/assessments');
      },
    },
  );
};
