import cx from 'classnames';
import React, { useMemo } from 'react';
import { CellProps, Column, UseSortByColumnOptions } from 'react-table';
import { AssessmentSortOption, BaseAssessment } from 'types/api';
import { useBreakpoints } from 'hooks/util/useBreakpoints';
import { abbreviateName } from 'util/abbreviateName';
import { IndeterminateCheckbox } from 'components/IndeterminateCheckbox';
import { SelectedPill } from 'components/SelectedPill';
import styles from './MapOrgsColumns.module.css';
import { IMapOrgsTableRow } from './MapOrgsTable';

export interface IMapOrgsColumns {
  onToggleSelect: (assessment?: BaseAssessment) => void;
  detachOrg: ({
    assessmentId,
    organizationId,
  }: {
    assessmentId: string;
    organizationId: string;
  }) => void;
}

export const MapOrgsColumns = ({
  onToggleSelect,
  detachOrg,
}: IMapOrgsColumns): (Column<IMapOrgsTableRow> &
  UseSortByColumnOptions<IMapOrgsTableRow>)[] => {
  const { isMobile } = useBreakpoints();

  return useMemo(
    () => [
      {
        id: 'select',
        Header: false,
        Cell: ({ row }: CellProps<IMapOrgsTableRow>) => {
          const { onChange, ...props } = row.getToggleRowSelectedProps();
          return (
            <IndeterminateCheckbox
              {...props}
              title={`select-${row.id}`}
              onChange={(e) => {
                if (onChange && onToggleSelect) {
                  onChange(e);
                  onToggleSelect(row.original.latestAssessment);
                }
              }}
            />
          );
        },
      },
      {
        accessor: (row: IMapOrgsTableRow) => row.name,
        Header: 'Name',
        Cell: ({ row }: CellProps<IMapOrgsTableRow>) => {
          if (isMobile) {
            return abbreviateName(row.original.name);
          }

          return row.original.name;
        },
        Footer: '',
        id: AssessmentSortOption.PLAYER_NAME,
      },
      {
        Header: 'Sport',
        accessor: 'sport',
        Footer: '',
        id: 'sport',
        disableSortBy: true,
      },
      {
        Header: 'Date',
        accessor: 'date',
        Footer: '',
        id: AssessmentSortOption.LAST,
      },
      {
        Header: 'Training Targets',
        accessor: 'trainingTargets',
        Cell: ({ row }: CellProps<IMapOrgsTableRow>) => {
          return (
            <span
              className={cx({
                [styles.assigned]: row.original.trainingTargets === 'Assigned',
              })}
            >
              {row.original.trainingTargets}
            </span>
          );
        },
        Footer: '',
        id: 'trainingTargets',
        disableSortBy: true,
      },
      {
        Header: 'Owners',
        accessor: 'organizations',
        Cell: ({ row }: CellProps<IMapOrgsTableRow>) => {
          if (row.original.organizations.length > 0) {
            return (
              <ul className={styles.selected}>
                {row.original.organizations.map(({ organization }) => (
                  <SelectedPill
                    id={`${row.original.id}-${organization?.organizationName}`}
                    key={`${row.original.id}-${organization?.organizationName}`}
                    label={organization?.organizationName as string}
                    onClose={() =>
                      detachOrg({
                        organizationId: organization?.id as string,
                        assessmentId: row.id,
                      })
                    }
                  />
                ))}
              </ul>
            );
          } else return 'Unattached';
        },
        disableSortBy: true,
      },
    ],
    [onToggleSelect, detachOrg, isMobile],
  );
};
