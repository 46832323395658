import classNames from 'classnames';
import { useMemo, useState } from 'react';
import terms from 'views/Assessments/terms.json';
import {
  AssessmentType,
  BasketballAssessment,
  BasketballAssessmentDetails,
  SoccerAssessment,
  SoccerAssessmentDetails,
} from 'types/api';
import { useAssessmentsOverview } from 'hooks/util/useAssessmentsOverview';
import { formatDate } from 'util/formatDate';
import { InjuryRisk } from 'components/AssessmentDetail/InjuryRisk';
import { Bar } from 'components/Bar/';
import { ConvertHeightToMeters } from 'components/ConvertHeightToMeters';
import { ConvertWeightToLb } from 'components/ConvertWeight';
import { FormatHeight } from 'components/FormatHeight';
import { LargeScore } from 'components/LargeScore';
import { Stat } from 'components/Stat';
import { tooltipText } from '../../../../util/tooltipText';
import styles from './RowExpanded.module.css';

interface IListRowExpandedProps {
  assessments: Array<BasketballAssessment | SoccerAssessment>;
  index: number;
  isAdmin?: boolean;
}

interface IBarchartDetails {
  data?: Array<number>;
  labels?: Array<string>;
  blueBars?: boolean;
  widerWidth?: boolean;
  title?: string;
  type?: string;
}

const RowExpanded = ({
  assessments,
  index,
  isAdmin,
}: IListRowExpandedProps) => {
  const [assessment, setAssessment] = useState(assessments[index]);

  const previousAssessment = useMemo(() => {
    const selectedIndex = assessments.findIndex(
      (instance) => instance.id === assessment.id,
    );
    return assessments[selectedIndex + 1];
  }, [assessment, assessments]);

  const trainingTargets = terms.find(
    (term) => term.trainingTargets,
  )?.trainingTargets;

  const { type, info, stats } = useAssessmentsOverview(
    assessment,
    [assessment, previousAssessment].filter(Boolean),
  );

  const barChart = useMemo(() => {
    const result: IBarchartDetails = {
      blueBars: true,
      widerWidth: true,
      type: 'Percentile',
    };

    if (type === AssessmentType.BASKETBALL) {
      const { assessmentOverallPerformanceFactors: oPFactors } =
        assessment.assessmentDetails as BasketballAssessmentDetails;

      return {
        ...result,
        title: 'Overall Performance Factors',
        data: [
          oPFactors?.verticalPercentile as number,
          oPFactors?.lateralPercentile as number,
          oPFactors?.accelerationPercentile as number,
          oPFactors?.decelerationPercentile as number,
        ],
        labels: ['Vert', 'Lat', 'Accel', 'Decel'],
      };
    } else if (type === AssessmentType.SOCCER) {
      const { assessmentSoccerRawPerformanceFactors: rPFactors } =
        assessment.assessmentDetails as SoccerAssessmentDetails;

      return {
        ...result,
        title: 'Raw Performance Factors',
        data: [
          rPFactors?.percVerticalJump as number,
          rPFactors?.percSLStance as number,
          rPFactors?.percSLKick as number,
        ],
        labels: ['Ver', 'SL \n Stance', 'SL \n Kick'],
      };
    }
  }, [assessment, type]);

  return (
    <div
      className={`${styles.main} ${
        styles[assessment.assessmentType as string]
      }`}
    >
      {isAdmin && (
        <div className={styles['assessment-date-list']}>
          <h5>Assessments</h5>
          {assessments.map((x) => (
            <button onClick={() => setAssessment(x)} key={`${x.id}-${x.date}`}>
              <span
                className={classNames(styles['assessment-text'], {
                  [styles.selected]: x.id === assessment.id,
                })}
              >
                {formatDate(x.date)}
              </span>
            </button>
          ))}
        </div>
      )}
      <div className={styles['left-column']}>
        <div className={styles.measurements}>
          <div>
            <p className={styles['measurement-type']}>Height</p>
            <p className={styles['measurement-value']}>
              <FormatHeight height={info?.height as number} />
              <ConvertHeightToMeters height={info?.height as number} />
            </p>
          </div>
          <div>
            <p className={styles['measurement-type']}>Weight</p>
            <p className={styles['measurement-value']}>
              <ConvertWeightToLb weight={info?.weight as number} /> (
              {info?.weight}kg)
            </p>
          </div>
        </div>
        {/* ends measurements */}
        <div className={styles.stats}>
          {stats &&
            Object.entries(stats).map(([key, item], index) => {
              const { title, value, difference, unit, tooltip } = item;
              return (
                <Stat
                  title={title}
                  value={value}
                  unit={unit}
                  difference={difference}
                  key={`${key}-${assessment.id}-${index}`}
                  tooltip={tooltip}
                />
              );
            })}
        </div>
        {/* ends stats */}
        {assessment?.trainingTargets &&
          assessment?.trainingTargets?.length > 0 && (
            <div className={styles['training-targets']}>
              <h5>Training Targets</h5>
              {assessment?.trainingTargets?.map((tt) => {
                const targetTerm = trainingTargets?.find(
                  (term) => term.id === tt,
                );

                return (
                  <p key={`${tt}-${assessment.id}`} className={styles.factor}>
                    <span>{targetTerm?.term}</span> − {targetTerm?.definition}
                  </p>
                );
              })}
            </div>
          )}
      </div>
      <div className={styles['injury-risk']}>
        <InjuryRisk
          assessment={assessment}
          className={styles['injury-risk-chart']}
          readonly
        />
      </div>
      {/* ends wired man */}
      <div className={styles['right-column']}>
        <div className={styles.scores}>
          <div className={styles.score}>
            <LargeScore
              title="Athleticism Score"
              score={info?.athleticismScore || 0}
              scoreSizeMedium
              delta={info?.athleticismScoreDelta || 0}
              tooltip={tooltipText.AthleticismScore as string}
            />
          </div>
          <div className={styles.score}>
            <LargeScore
              title="Mechanics Score"
              score={info?.mechanicsScore || 0}
              scoreSizeMedium
              delta={info?.mechanicsScoreDelta || 0}
              tooltip={tooltipText.MechanicsScore as string}
            />
          </div>
        </div>
        {/* ends scores */}
        <div className={styles['performance-factors']}>
          <h5 className={styles['performance-factors-title']}>
            {barChart?.title}
          </h5>
          <p>{barChart?.type}</p>
          <p className={styles['performance-factors-desc']}>
            To further interact with the data view the full assessment
          </p>
          <Bar {...barChart} />
        </div>
      </div>
    </div>
  );
};

export { RowExpanded };
