import { ReactComponent as InfoIcon } from 'assets/icons/info.svg';
import { ReactComponent as RadarIcon } from 'assets/icons/radar.svg';
import { ReactComponent as TableIcon } from 'assets/icons/table.svg';
import { useMemo } from 'react';
import { AssessmentType, BaseAssessment, SoccerAssessment } from 'types/api';
import { radarGraphLegendText } from 'util/radarGraphLegendText';
import { GraphTable } from 'components/GraphTable';
import { GraphTabs } from 'components/GraphTabs';
import { Radar, RadarData } from 'components/Radar';
import {
  ChartType,
  Terms,
  definitions,
  getSummary,
  radarLabelsVert,
} from './DropStanceKick';

interface Props {
  assessments: BaseAssessment[];
  className?: string;
  chartType: ChartType;
}

export const DropStanceKickCompare = (props: Props) => {
  const { assessments, className, chartType } = props;

  const radarData = useMemo(() => {
    const result: RadarData[] = [];

    assessments.forEach((a: SoccerAssessment) => {
      const summary = getSummary(a, chartType);
      if (!summary) {
        return;
      }

      const {
        percContactTime = 0,
        percAnkleROM = 0,
        percKneeActiveDeceleration = 0,
        percPeakConcForce = 0,
      } = summary || {};

      const radarObject: RadarData = {
        name: radarGraphLegendText(a.name, a.date),
        value: [
          percContactTime,
          percAnkleROM,
          percKneeActiveDeceleration,
          percPeakConcForce,
        ],
      };
      result.push(radarObject);
    });
    if (
      assessments.length &&
      assessments[0].assessmentType === AssessmentType.SOCCER
    ) {
      result.push({
        name: `${assessments[0]?.position} Average`,
        value: Array(result[0].value.length).fill(50),
      });
    }
    return result;
  }, [assessments, chartType]);

  const config = [
    {
      id: 'radarTab',
      name: 'Percentile',
      Icon: RadarIcon,
      content: <Radar data={radarData} indicator={radarLabelsVert} />,
    },
    {
      id: 'tableTab',
      name: 'Test Results',
      Icon: TableIcon,
      content: (
        <GraphTable
          assessments={assessments}
          definitions={definitions(chartType)}
        />
      ),
    },
    {
      id: 'infoTab',
      name: 'Terminology',
      Icon: InfoIcon,
      content: Terms,
    },
  ];

  return (
    <GraphTabs
      className={className}
      config={config}
      defaultTab="radarTab"
      heading={`SL DROP ${chartType}`}
    />
  );
};
