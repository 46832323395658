import { BaseAssessment } from 'types/api';
import { GraphTabs } from 'components/GraphTabs';
import styles from './Mechanics.module.css';
import { useConfig } from './useConfig';
import { useLeftFootData } from './useLeftFootData';

interface LeftFootProps {
  assessments: BaseAssessment[];
  className?: string;
}

export const LeftFootCompare = (props: LeftFootProps) => {
  const { assessments, className } = props;

  const data = useLeftFootData(assessments);
  const config = useConfig('Left Foot Mechanics', assessments, data);
  return (
    <GraphTabs
      className={className}
      config={config}
      defaultTab="lineTab"
      headerClassName={styles.header}
      heading="Left Foot Mechanics"
    />
  );
};
