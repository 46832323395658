import animationData from 'assets/animations/spinner-bg.json';
import lottie from 'lottie-web';
import { useEffect } from 'react';

interface LoadingProps {
  size?: number;
  className?: string;
}

const Loading = ({ size = 60, className = 'center' }: LoadingProps) => {
  useEffect(() => {
    lottie.loadAnimation({
      container: document.getElementById('lottie') as Element,
      renderer: 'svg',
      loop: true,
      animationData: animationData,
      autoplay: true,
    });
  }, []);

  return (
    <div
      id="lottie"
      style={{ width: size, height: size }}
      className={className}
    />
  );
};

export { Loading };
