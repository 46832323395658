import { useMemo } from 'react';
import {
  AssessmentType,
  BasketballAssessment,
  BasketballAssessmentDetails,
  SoccerAssessment,
  SoccerAssessmentDetails,
} from 'types/api';
import { IComparisonGraphType } from '.';
import { tooltipText } from '../../../util/tooltipText';

export const useComparisonGraphData = (
  metric: IComparisonGraphType,
  assessment: SoccerAssessment | BasketballAssessment,
  vertKneeVelAvg: number | undefined,
  vertKneeAccAvg: number | undefined,
) =>
  useMemo(() => {
    if (assessment.assessmentType === AssessmentType.BASKETBALL) {
      const basketballAssessmentDetail =
        assessment.assessmentDetails as BasketballAssessmentDetails;

      if (metric === IComparisonGraphType.VERTICAL) {
        return [
          {
            label: 'Concentric Force',
            value:
              basketballAssessmentDetail.assessmentStandingJumpSummary
                ?.percConcRelFF,
            tooltip: tooltipText.ConcentricForce,
          },
          {
            label: 'Net Concentric Force',
            value:
              basketballAssessmentDetail.assessmentDropJumpSummary
                ?.percNetRelConcForce,
            tooltip: tooltipText.NetRelConcentricForce,
          },
          {
            label: 'Eccentric Force',
            value:
              basketballAssessmentDetail.assessmentStandingJumpSummary
                ?.percLoadRelFF,
            tooltip: tooltipText.EccentricForce,
          },
          {
            label: 'Knee Ext Velocity',
            value: vertKneeVelAvg,
            tooltip: tooltipText.KneeExtVelocity,
          },
          {
            label: 'Knee Ext Accel',
            value: vertKneeAccAvg,
            tooltip: tooltipText.KneeExtAccel,
          },
        ];
      }

      if (metric === IComparisonGraphType.LATERAL) {
        return [
          {
            label: 'Left Lat Force',
            value:
              basketballAssessmentDetail.assessmentSkaterSummary
                ?.percLeftLateralForce,
            tooltip: tooltipText.LeftLatForce,
          },
          {
            label: 'Left Hip \nExt Velocity',
            value:
              basketballAssessmentDetail.assessmentSkaterSummary
                ?.percLeftMaxHipExtensionVelocity,
            tooltip: tooltipText.LeftHipExtVelocity,
          },
          {
            label: 'Left Hip \nAbduction',
            value:
              basketballAssessmentDetail.assessmentSkaterSummary
                ?.percLeftMaxHipAbduction,
            tooltip: tooltipText.LeftHipAbduction,
          },
          {
            label: 'Right Lat Force',
            value:
              basketballAssessmentDetail.assessmentSkaterSummary
                ?.percRightLateralForce,
            tooltip: tooltipText.RightLatForce,
          },
          {
            label: 'Right Hip \nExt Velocity',
            value:
              basketballAssessmentDetail.assessmentSkaterSummary
                ?.percRightMaxHipExtensionVelocity,
            tooltip: tooltipText.RightHipExtVelocity,
          },
          {
            label: 'Right Hip \nAbduction',
            value:
              basketballAssessmentDetail.assessmentSkaterSummary
                ?.percRightMaxHipAbduction,
            tooltip: tooltipText.RightHipAbduction,
          },
        ];
      }

      if (metric === IComparisonGraphType.ACCEL) {
        return [
          {
            label: 'Concentric Force',
            value:
              basketballAssessmentDetail
                .assessmentAccelerationDecelerationDetails?.percConcRelFF,
            tooltip: tooltipText.ConcentricForce,
          },
          {
            label: 'Lat Force',
            value:
              basketballAssessmentDetail
                .assessmentAccelerationDecelerationDetails?.percLateralForceAvg,
            tooltip: tooltipText.LatForce,
          },
          {
            label: 'Load Rel Force',
            value:
              basketballAssessmentDetail
                .assessmentAccelerationDecelerationDetails?.percLoadRelFF,
            tooltip: tooltipText.LoadRelForce,
          },
          {
            label: 'Knee Ext \nAccel',
            value:
              basketballAssessmentDetail
                .assessmentAccelerationDecelerationDetails
                ?.percMaxKneeExtensionVelocityAvg,
            tooltip: tooltipText.KneeExtAccel,
          },
        ];
      }
    } else {
      const soccerAssessmentDetail =
        assessment.assessmentDetails as SoccerAssessmentDetails;
      if (metric === 'linear') {
        return [
          {
            label: 'Relative Power',
            value:
              soccerAssessmentDetail.assessmentSoccerStandingJumpSummary
                ?.percRelativePower,
            tooltip: tooltipText.RelativePower,
          },
          {
            label: 'Conc Rel FF',
            value:
              soccerAssessmentDetail.assessmentSoccerStandingJumpSummary
                ?.percConcRelFF,
            tooltip: tooltipText.NetRelConcentricForce,
          },
          {
            label: 'Vertical \nDisplacement',
            value:
              soccerAssessmentDetail.assessmentSoccerRawPerformanceFactors
                ?.percVerticalJump,
            tooltip: tooltipText.EccentricForce,
          },
          {
            label: 'Knee Ext Velocity',
            value: vertKneeVelAvg,
            tooltip: tooltipText.KneeExtVelocity,
          },
          {
            label: 'Knee Ext Accel',
            value: vertKneeAccAvg,
            tooltip: tooltipText.KneeExtAccel,
          },
        ];
      }

      if (metric === 'lateral') {
        return [
          {
            label: 'Lateral Drive (L)',
            value:
              soccerAssessmentDetail.assessmentSkaterSummary
                ?.percLeftLateralForce,
            tooltip: tooltipText.LeftLatForce,
          },
          {
            label: 'Lateral Drive (R)',
            value:
              soccerAssessmentDetail.assessmentSkaterSummary
                ?.percRightLateralForce,
            tooltip: tooltipText.RightLatForce,
          },
          {
            label: 'Hip Ext \nVelocity (L)',
            value:
              soccerAssessmentDetail.assessmentSkaterSummary
                ?.percLeftMaxHipExtensionVelocity,
            tooltip: tooltipText.LeftHipExtVelocity,
          },
          {
            label: `Hip Ext \nVelocity (R)`,
            value:
              soccerAssessmentDetail.assessmentSkaterSummary
                ?.percRightMaxHipExtensionVelocity,
            tooltip: tooltipText.RightHipExtVelocity,
          },
          {
            label: 'Peak Hip Abduction (L)',
            value:
              soccerAssessmentDetail.assessmentSkaterSummary
                ?.percLeftMaxHipAbduction,
            tooltip: tooltipText.LeftHipAbduction,
          },
          {
            label: 'Peak Hip Abduction (R)',
            value:
              soccerAssessmentDetail.assessmentSkaterSummary
                ?.percRightMaxHipAbduction,
            tooltip: tooltipText.RightHipAbduction,
          },
        ];
      }
    }
  }, [metric, assessment, vertKneeAccAvg, vertKneeVelAvg]);
