import type {
  ApiContract,
  Response,
} from 'lib/api-mocking/interfaces/ApiContract';
import { defaultErrorResponse } from 'mockApi/responses/defaultErrorResponse';
import type { Error } from 'models/Error';
import { AssessmentGroup } from 'types/api';
import { AssessmentListGenerator } from '../generators/AssessmentListGenerator';

const contract: ApiContract = {
  url: '/Assessments',
  method: 'GET',
  success: (): Response<AssessmentGroup[]> => ({
    status: 200,
    body: AssessmentListGenerator.generateList(20),
  }),
  error: (): Response<Error> => ({
    status: 500,
    body: defaultErrorResponse,
  }),
  scope: {
    test: true,
    runtime: false,
  },
};

export default contract;
