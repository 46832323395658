/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

export enum AssessmentVideoExerciseAngle {
    UNKNOWN = 'Unknown',
    FRONT = 'Front',
    FRONT_LEFT = 'FrontLeft',
    LEFT = 'Left',
    BACK_LEFT = 'BackLeft',
    BACK = 'Back',
    BACK_RIGHT = 'BackRight',
    RIGHT = 'Right',
    FRONT_RIGHT = 'FrontRight',
}