import { useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';

export const useQueryParam = <T extends string | number | unknown = string>(
  param: string,
  defaultValue?: T,
): [T, React.Dispatch<React.SetStateAction<T | undefined>>] => {
  const navigate = useNavigate();
  const location = useLocation();
  const urlStringValue = new URLSearchParams(location.search).get(param);
  const urlNumericValue = Number(urlStringValue);
  const typedUrlValue =
    urlStringValue && !isNaN(urlNumericValue)
      ? urlNumericValue
      : urlStringValue;
  const [query, setQuery] = useState((typedUrlValue as T) ?? defaultValue);

  useEffect(() => {
    const params = new URLSearchParams(location.search);
    if (query) {
      if (params.has(param)) {
        params.set(param, query as string);
      } else {
        params.append(param, query as string);
      }
    } else {
      params.delete(param);
    }
    navigate({ search: params.toString() }, { replace: true });
  }, [query, navigate, param, location.search]);
  return [query || ('' as T), setQuery];
};

export const useClearQueryParams = () => {
  const navigate = useNavigate();
  return () => navigate({ search: '' }, { replace: true });
};
