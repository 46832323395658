/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

export enum Archetype {
    UNKNOWN = 'Unknown',
    TRADITIONAL_BIG = 'TraditionalBig',
    BIG_PLUS = 'BigPlus',
    MISC_PERIMETER = 'MiscPerimeter',
    KINEMATIC_MOVER = 'KinematicMover',
    HYPER_ATHLETIC_GUARD = 'HyperAthleticGuard',
    FORCE_MOVER = 'ForceMover',
    SPECIMEN = 'Specimen',
}