import { OrganizationResponseModel, Role, UserResponseModel } from 'types/api';

interface IConstants {
  localStorageOrg: 'selectedOrganization';
  localStorageRole: 'selectedRole';
}

export const constants: IConstants = {
  localStorageOrg: 'selectedOrganization',
  localStorageRole: 'selectedRole',
};
export const updateLocalStorage = (
  contextOrg: OrganizationResponseModel | undefined,
  contextRole: Role | undefined,
  loggedInUser: UserResponseModel | undefined,
) => {
  if (!contextRole) {
    localStorage.removeItem(constants.localStorageRole);
  }
  if (!contextOrg) {
    localStorage.removeItem('selectedOrganization');
  }
  if (loggedInUser?.isSuperAdmin) {
    localStorage.setItem(
      constants.localStorageRole,
      Role.SUPER_ADMIN.toString(),
    );
  }
  if (contextRole) {
    localStorage.setItem(constants.localStorageRole, contextRole?.toString());
  }
  if (contextOrg) {
    localStorage.setItem(constants.localStorageOrg, JSON.stringify(contextOrg));
  }
};
