import * as Popover from '@radix-ui/react-popover';
import { useState } from 'react';
import styles from './Tooltip.module.css';

interface TooltipProps {
  triggerText: string | undefined;
  tooltipText: string | undefined;
}

export const Tooltip = (props: TooltipProps) => {
  const { triggerText, tooltipText } = props;
  const [isPopoverOpen, setIsPopoverOpen] = useState(false);
  const handleTitleMouseEnter = () => setIsPopoverOpen(true);
  const handleTitleMouseOut = () => setIsPopoverOpen(false);

  return (
    <Popover.Root key="popover-title" open={isPopoverOpen}>
      <Popover.Trigger asChild>
        <dt
          className={styles.title}
          onMouseEnter={handleTitleMouseEnter}
          onMouseOut={handleTitleMouseOut}
        >
          <p className={styles['trigger-text']}>{triggerText}</p>
        </dt>
      </Popover.Trigger>
      <Popover.Content
        className={`${styles.tooltip}`}
        sideOffset={12}
        side="top"
      >
        <Popover.Arrow className={styles.arrow} />
        <small>{tooltipText}</small>
      </Popover.Content>
    </Popover.Root>
  );
};
