/* soccer  table tab  terms */
import { labels } from 'util/graphConstants';
import { unitsOfMeasure } from 'util/unitsOfMeasure';

interface ITerms {
  key: string;
  unit?: string;
  name?: string;
}

const {
  translationKick,
  relativeRotationKick,
  deltaHipFlexionKick,
  translationStance,
  relativeRotationStance,
  deltaHipFlexionStance,
  activeAnkleDecelerationKick,
  activeDorsiflexionKick,
  inversionKick,
  eversionKick,
  activeAnkleDecelerationStance,
  activeDorsiflexionStance,
  inversionStance,
  eversionStance,
  ankleDorsiflexionAverage,
  deltaHipFlexionAverage,
  trunkStabilityL,
  trunkStabilityR,
} = labels;

export const kickKneeTableGraphTerms: ITerms[] = [
  {
    key: 'assessmentDetails.assessmentSoccerMechanicsSummary.percRelativeRotationKick',
    unit: unitsOfMeasure('percentage'),
    name: relativeRotationKick,
  },
  {
    key: 'assessmentDetails.assessmentSoccerMechanicsSummary.percDeltaHipFlexionKick',
    unit: unitsOfMeasure('percentage'),
    name: deltaHipFlexionKick,
  },
  {
    key: 'assessmentDetails.assessmentSoccerMechanicsSummary.percTranslationKick',
    unit: unitsOfMeasure('percentage'),
    name: translationKick,
  },
];

export const kickAnkleTableGraphTerms: ITerms[] = [
  {
    key: 'assessmentDetails.assessmentSoccerMechanicsSummary.percActiveAnkleDecelerationKick',
    unit: unitsOfMeasure('percentage'),
    name: activeAnkleDecelerationKick,
  },
  {
    key: 'assessmentDetails.assessmentSoccerMechanicsSummary.percActiveDorsiflexionKick',
    unit: unitsOfMeasure('percentage'),
    name: activeDorsiflexionKick,
  },
  {
    key: 'assessmentDetails.assessmentSoccerMechanicsSummary.percInversionKick',
    unit: unitsOfMeasure('percentage'),
    name: inversionKick,
  },
  {
    key: 'assessmentDetails.assessmentSoccerMechanicsSummary.percEversionKick',
    unit: unitsOfMeasure('percentage'),
    name: eversionKick,
  },
];

export const stanceKneeTableGraphTerms: ITerms[] = [
  {
    key: 'assessmentDetails.assessmentSoccerMechanicsSummary.percTranslationStance',
    unit: unitsOfMeasure('percentage'),
    name: translationStance,
  },
  {
    key: 'assessmentDetails.assessmentSoccerMechanicsSummary.percRelativeRotationStance',
    unit: unitsOfMeasure('percentage'),
    name: relativeRotationStance,
  },
  {
    key: 'assessmentDetails.assessmentSoccerMechanicsSummary.percDeltaHipFlexionStance',
    unit: unitsOfMeasure('percentage'),
    name: deltaHipFlexionStance,
  },
];

export const stanceAnkleGraphTerms: ITerms[] = [
  {
    key: 'assessmentDetails.assessmentSoccerMechanicsSummary.percActiveAnkleDecelerationStance',
    unit: unitsOfMeasure('percentage'),
    name: activeAnkleDecelerationStance,
  },
  {
    key: 'assessmentDetails.assessmentSoccerMechanicsSummary.percActiveDorsiflexionStance',
    unit: unitsOfMeasure('percentage'),
    name: activeDorsiflexionStance,
  },
  {
    key: 'assessmentDetails.assessmentSoccerMechanicsSummary.percInversionStance',
    unit: unitsOfMeasure('percentage'),
    name: inversionStance,
  },
  {
    key: 'assessmentDetails.assessmentSoccerMechanicsSummary.percEversionStance',
    unit: unitsOfMeasure('percentage'),
    name: eversionStance,
  },
];

export const soccerLowBackTableGraphTerms: ITerms[] = [
  {
    key: 'assessmentDetails.assessmentSoccerMechanicsSummary.percAnkleDorsiflexionAverage',
    unit: unitsOfMeasure('percentage'),
    name: ankleDorsiflexionAverage,
  },
  {
    key: 'assessmentDetails.assessmentSoccerMechanicsSummary.percDeltaHipFlexionAverage',
    unit: unitsOfMeasure('percentage'),
    name: deltaHipFlexionAverage,
  },
  {
    key: 'assessmentDetails.assessmentSoccerMechanicsSummary.percLeftTrunkStability',
    unit: unitsOfMeasure('percentage'),
    name: trunkStabilityL,
  },
  {
    key: 'assessmentDetails.assessmentSoccerMechanicsSummary.percRightTrunkStability',
    unit: unitsOfMeasure('percentage'),
    name: trunkStabilityR,
  },
];
