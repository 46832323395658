import type {
  ApiContract,
  Response,
} from 'lib/api-mocking/interfaces/ApiContract';
import { UserResponseGenerator } from 'mockApi/generators/UserGenerator';
import { defaultErrorResponse } from 'mockApi/responses/defaultErrorResponse';
import type { Error } from 'models/Error';
import { UserResponseModel } from 'types/api/';

const contract: ApiContract = {
  url: '/users/:id',
  method: 'GET',
  success: (): Response<UserResponseModel> => ({
    status: 200,
    body: UserResponseGenerator.generate(),
  }),
  error: (): Response<Error> => ({
    status: 500,
    body: defaultErrorResponse,
  }),
  scope: {
    test: true,
    runtime: false,
  },
};

export default contract;
