import { useMutation } from 'react-query';
import { toast } from 'react-toastify';
import { useAuthenticatedFetch } from 'hooks/util/useAuthenticatedFetch';

export const useCreateAssessmentShareLink = (assessmentId?: string) => {
  const fetch = useAuthenticatedFetch();
  return useMutation(
    (values: { password: string; isSingle: boolean }) =>
      fetch(`${process.env.REACT_APP_API_HOST}/AssessmentLink`, {
        body: {
          assessmentId,
          password: values.password,
          isSingle: values.isSingle,
        },
        method: 'POST',
      }),
    {
      onError: () => {
        toast.error('Error generating share link.');
      },
    },
  );
};
