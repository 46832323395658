import classNames from 'classnames';
import styles from './SubHeaderH2.module.css';

interface ISubHeaderProps {
  h2: string;
  p?: string;
  hideMobile?: boolean;
}

export const SubHeaderH2 = ({ h2, p, hideMobile }: ISubHeaderProps) => {
  return (
    <div
      className={classNames(styles.subheader, hideMobile ? 'hide-mobile' : '')}
    >
      <h2>{h2}</h2>
      {p && <p>{p}</p>}
    </div>
  );
};
