import * as Popover from '@radix-ui/react-popover';
import cx from 'classnames';
import React, { useCallback, useState } from 'react';
import { Cell, CellProps, Column } from 'react-table';
import { IMapOrgsTableRow } from 'views/Assessments/MapOrganizations/MapOrgsTable';
import terms from 'views/Assessments/terms.json';
import { AssessmentSortOption, AssessmentType } from 'types/api';
import { useBreakpoints } from 'hooks/util/useBreakpoints';
import { abbreviateName } from 'util/abbreviateName';
import tooltipStyles from 'components/Tooltip/Tooltip.module.css';
import { AListTrend, IAListTableRow } from './AssessmentsListTable';
import styles from './AssessmentsListTable.module.css';

export type FilteredColumn<T extends IAListTableRow> = Column<T> & {
  includedIn?: AssessmentType[];
};

const { tooltips } = terms.filter((term) => term.tooltips)[0];
export const Score = ({
  score,
}: {
  score: { value: string; trend: AListTrend };
}) => {
  const [isPopoverOpen, setIsPopoverOpen] = useState(false);
  const handleTogglePopover = useCallback((trend: AListTrend) => {
    return () => {
      setIsPopoverOpen((isOpen) => !isOpen && trend !== AListTrend.EQUAL);
    };
  }, []);
  const { value, trend } = score;

  return (
    <>
      <span>{value}</span>
      <Popover.Root open={isPopoverOpen}>
        <Popover.Trigger asChild>
          <span
            className={cx([styles[`trend-${trend}`], styles[`trend-icon`]])}
            onMouseEnter={handleTogglePopover(trend)}
            onMouseOut={handleTogglePopover(trend)}
            onTouchStart={handleTogglePopover(trend)}
            onTouchEnd={handleTogglePopover(trend)}
          />
        </Popover.Trigger>
        <Popover.Content
          className={`${tooltipStyles.tooltip}`}
          side="top"
          sideOffset={12}
        >
          <Popover.Arrow className={tooltipStyles.arrow} />
          <small>{tooltips?.percentageChange as string}</small>
        </Popover.Content>
      </Popover.Root>
    </>
  );
};

export const AListTableColumns: FilteredColumn<IAListTableRow>[] = [
  {
    Header: () => null,
    id: 'expander',
    Cell: (props: Cell) => {
      const { row } = props;
      return (
        <span
          {...row.getToggleRowExpandedProps()}
          className={cx({
            [styles.expanded]: row.isExpanded,
            [styles.expand]: true,
          })}
        />
      );
    },
    Footer: '',
  },
  {
    Header: 'Name',
    Cell: ({ row }: CellProps<IMapOrgsTableRow>) => {
      const { isDesktop } = useBreakpoints();

      return isDesktop ? row.original.name : abbreviateName(row.original.name);
    },
    accessor: 'name',
    Footer: '',
    id: AssessmentSortOption.PLAYER_NAME,
  },
  {
    Header: 'Type',
    accessor: 'type',
    Footer: '',
    id: AssessmentSortOption.TYPE,
    includedIn: [AssessmentType.BASKETBALL],
  },
  {
    Header: 'Position',
    accessor: 'position',
    Footer: '',
    id: AssessmentSortOption.POSITION,
  },
  {
    Header: 'Last',
    accessor: 'latestDate',
    Footer: '',
    id: AssessmentSortOption.LAST,
  },
  {
    Header: '#',
    accessor: 'assessmentCount',
    Footer: '',
    id: AssessmentSortOption.COUNT,
  },
  {
    Header: 'AS',
    accessor: (row: IAListTableRow) => row.athleticismScore.value,
    Cell: (row: CellProps<IAListTableRow>) => {
      const { athleticismScore } = row.row.original;

      return <Score score={athleticismScore} />;
    },
    id: AssessmentSortOption.ATHLETICISM_SCORE,
    includedIn: [AssessmentType.BASKETBALL],
  },
  {
    Header: 'MS',
    accessor: (row: IAListTableRow) => row.mechanicsScore.value,
    Cell: (row: CellProps<IAListTableRow>) => {
      const { mechanicsScore } = row.row.original;

      return <Score score={mechanicsScore} />;
    },
    id: AssessmentSortOption.MECHANICS_SCORE,
    includedIn: [AssessmentType.BASKETBALL],
  },
];

export function columnsByAssessmentType<T extends IAListTableRow>(
  columns: FilteredColumn<T>[],
  assessmentType: AssessmentType | undefined,
) {
  return columns.filter((row) => {
    return !row.includedIn || row.includedIn.some((x) => x === assessmentType);
  });
}
