import { ReactComponent as Arrow } from 'assets/icons/arrow-right.svg';
import classNames from 'classnames';
import { useCallback, useMemo } from 'react';
import { Link } from 'react-router-dom';
import { Empty, EmptyMessages } from 'views/Assessments/List/Table/Empty';
import {
  CreateOrUpdateOrganizationRequestModel,
  UserResponseModel,
} from 'types/api';
import { useUpdateOrganization } from 'hooks/mutation/useUpdateOrganization';
import { useOrganizationById } from 'hooks/query/useOrganizationById';
import { useUsersList } from 'hooks/query/useUsersList';
import { useOrganizationContext } from 'hooks/util/useOrganizationContext';
import { useQueryParam } from 'hooks/util/useQueryParam';
import { roleDisplayName } from 'util/roleDisplayName';
import { Loading } from 'components/Loading';
import { OrganizationHeader } from 'components/OrganizationHeader';
import { Pagination } from 'components/Pagination';
import { ProfilePicture } from 'components/ProfilePicture';
import { Table } from 'components/Table';
import { CreateUser } from './CreateUser';
import { EditOrganizationForm } from './EditOrganizationForm';
import styles from './Organization.module.css';

export const Organization = () => {
  const { contextOrg } = useOrganizationContext();

  const [page, setPage] = useQueryParam('page', 1);
  const { data: users, isPreviousData } = useUsersList({
    page,
    contextOrg,
  });

  const { mutate } = useUpdateOrganization(contextOrg?.id);
  const { data: organization } = useOrganizationById(contextOrg?.id, true);

  const parsedData = useMemo(() => {
    if (users && users.items) {
      return users.items;
    } else return [];
  }, [users]);

  const handleNextPage = useCallback(() => {
    setPage(page + 1);
  }, [page, setPage]);

  const handlePreviousPage = useCallback(() => {
    setPage(page - 1);
  }, [page, setPage]);

  const updateForm = useCallback(
    (formData: CreateOrUpdateOrganizationRequestModel) => {
      if (formData.primaryAdminId === '') {
        formData.primaryAdminId = null;
      }
      mutate(formData);
    },
    [mutate],
  );

  return (
    <div>
      {organization ? (
        <>
          <OrganizationHeader organization={organization} />
          <div
            className={classNames(
              styles['table-background'],
              styles['remove-padding'],
            )}
          >
            {!organization?.isAdmin && (
              <div className={styles['table-container']}>
                <EditOrganizationForm
                  organization={organization}
                  onSubmit={updateForm}
                />
              </div>
            )}
          </div>
          <div className={styles['table-background']}>
            <div className={styles['table-container']}>
              <div className={styles['create-account-button']}>
                {contextOrg && <CreateUser organization={contextOrg} />}
              </div>
              {!users ||
                !users.items ||
                (!users?.items?.length ? (
                  <Empty message={EmptyMessages.NO_USERS_IN_ORG} hideCTA />
                ) : (
                  <Table
                    data={parsedData}
                    className={styles['org-accounts']}
                    columns={[
                      {
                        Header: 'Accounts',
                        accessor: (data: UserResponseModel) =>
                          `${data.firstName} ${data.lastName}`,
                        // eslint-disable-next-line @typescript-eslint/no-explicit-any
                        Cell: (data: any) => {
                          const user = data.row?.original as UserResponseModel;
                          return (
                            <Link
                              to={`/organization/account/${user.id}/edit`}
                              state={{ account: user }}
                            >
                              <div className={styles['profile-row']}>
                                <ProfilePicture
                                  firstName={user.firstName as string}
                                  lastName={user.lastName as string}
                                  src={user?.blobImageUri as string}
                                  size={50}
                                />
                                <span className={styles.name}>
                                  {user.firstName} {user.lastName}
                                </span>
                              </div>
                            </Link>
                          );
                        },
                      },
                      {
                        Header: ' ',
                        accessor: (data: UserResponseModel) =>
                          roleDisplayName(
                            data.organizationRoles?.find(
                              (x) => x.organizationId === contextOrg?.id,
                            )?.role,
                          ),
                        // eslint-disable-next-line @typescript-eslint/no-explicit-any
                        Cell: (data: any) => {
                          const user = data.row?.original as UserResponseModel;
                          return (
                            <div className={styles['arrow-container']}>
                              <Link
                                to={`/organization/account/${user.id}/edit`}
                                state={{ account: user }}
                              >
                                {data.value} <Arrow />
                              </Link>
                            </div>
                          );
                        },
                      },
                    ]}
                  />
                ))}
              <Pagination
                hasNextPage={!isPreviousData && users?.hasNextPage}
                hasPreviousPage={!isPreviousData && users?.hasPreviousPage}
                onNext={handleNextPage}
                onPrevious={handlePreviousPage}
                page={page}
                totalCount={users?.totalCount}
              />
            </div>
          </div>
        </>
      ) : (
        <Loading />
      )}
    </div>
  );
};
