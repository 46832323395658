import { AssessmentGroup } from 'types/api';
import { formatDate } from 'util/formatDate';
import { IMapOrgsTableRow } from './MapOrgsTable';

export const parseMapToOrgs = (groups: AssessmentGroup[]) => {
  if (groups.length > 0) {
    return groups.map((group): IMapOrgsTableRow => {
      const latest = group.assessments && group.assessments[0];
      return {
        id: latest?.id as string,
        name: group.name as string,
        sport: 'Basketball',
        date: formatDate(group.latestDate) || undefined,
        trainingTargets:
          latest?.trainingTargets && latest.trainingTargets.length > 0
            ? 'Assigned'
            : 'Unassigned',
        organizations: latest?.owners ? latest?.owners : [],
        latestAssessment: latest || undefined,
      };
    });
  } else return [];
};
