import {
  forwardRef,
  useCallback,
  useEffect,
  useImperativeHandle,
  useMemo,
  useRef,
  useState,
} from 'react';
import { useForm } from 'react-hook-form';
import { useMatch } from 'react-router-dom';
import { parseTableData } from 'views/Assessments/List/ParseTableData';
import { ViewColumnID } from 'views/Assessments/List/Table/AdminColumns';
import {
  AssessmentSortOption,
  BaseAssessment,
  OrganizationResponseModel,
  Role,
  SortDirection,
} from 'types/api';
import { useAssessmentList } from 'hooks/query/useAssessmentList';
import { useOrganization } from 'hooks/query/useOrganization';
import { ContentType } from 'hooks/util/useAuthenticatedFetch';
import { useOrganizationContext } from 'hooks/util/useOrganizationContext';
import { radarGraphLegendText } from 'util/radarGraphLegendText';
import { AsessmentsListTable } from 'components/AssessmentsListTable';
import {
  AListTableColumns,
  columnsByAssessmentType,
} from 'components/AssessmentsListTable/AssessmentsListColumns';
import { ISelectedRows } from 'components/AssessmentsListTable/AssessmentsListTable';
import { Button } from 'components/Button';
import { Loading } from 'components/Loading';
import { Pagination } from 'components/Pagination';
import { ProfilePicture } from 'components/ProfilePicture';
import { DefaultMinLength, SearchBar } from 'components/SearchBar';
import { SelectedPill } from 'components/SelectedPill';
import { useAssessmentListSharedLink } from '../../../../hooks/query/useAssessmentListSharedLink';
import styles from './SelectionModal.module.css';
import { SelectionModalExpandedRow } from './SelectionModalExpandedRow';

interface ISelectionModalProps {
  setComparedAssessments: (value: string[][]) => void;
  currentAssessment: BaseAssessment;
  comparedAssessments?: BaseAssessment[];
  password?: string;
  onSubmit: (form: object) => void;
}

export interface FormHandle {
  submit: VoidFunction;
}

export const SelectionModal = forwardRef<FormHandle, ISelectionModalProps>(
  (props: ISelectionModalProps, ref) => {
    const { currentAssessment, onSubmit, setComparedAssessments, password } =
      props;
    const [sortBy, setSortBy] = useState(AssessmentSortOption.LAST);
    const [sortDirection, setSortDirection] = useState(
      SortDirection.DESCENDING,
    );
    const [selectedSubRows, setSelectedSubRows] = useState<BaseAssessment[]>([
      currentAssessment,
    ]);
    const [searchTerm, setSearchTerm] = useState<string | undefined>();
    const [page, setPage] = useState(1);
    const { contextOrg, contextRole } = useOrganizationContext();
    const [selectedOrg, setSelectedOrg] = useState<
      OrganizationResponseModel | undefined
    >(contextOrg);
    let enableUseOrganizationQuery = false;
    const orderByFirst = currentAssessment.name ? currentAssessment.name : '';

    if (!contextOrg && contextRole === Role.SUPER_ADMIN) {
      enableUseOrganizationQuery = true;
    }
    const { data: organizations } = useOrganization(
      false,
      true,
      enableUseOrganizationQuery,
    );
    const isShared = useMatch('/share/:linkId/:assessmentId');

    // If the user is a super admin with no org/roles, then display the P3 organization in the header of the modal
    useEffect(() => {
      if (organizations?.items) {
        setSelectedOrg(organizations?.items[0]);
      } else {
        setSelectedOrg(contextOrg);
      }
    }, [contextOrg, organizations]);

    const {
      data: allAssessmentData,
      isPreviousData: isPreviousAssessmentData,
      isFetching: isFetchingAssessmentData,
    } = useAssessmentList({
      page,
      sortDirection,
      searchTerm,
      sortBy,
      orderByFirst,
      assessmentType: currentAssessment?.assessmentType,
      athleteName: isShared ? currentAssessment.name : undefined,
      contentType: ContentType.NONE,
    });

    const {
      data: sharedData,
      isPreviousData: isPreviousDataShared,
      isFetching: isFetchingShared,
    } = useAssessmentListSharedLink({
      password,
      page,
      sortBy,
      sortDirection,
      assessmentType: currentAssessment?.assessmentType,
      athleteName: isShared ? currentAssessment.name : undefined,
      contentType: ContentType.NONE,
    });

    let data = allAssessmentData;
    let isPreviousData = isPreviousAssessmentData;
    let isFetching = isFetchingAssessmentData;
    if (isShared) {
      data = sharedData;
      isPreviousData = isPreviousDataShared;
      isFetching = isFetchingShared;
    }

    const { handleSubmit } = useForm();
    const searchInputRef = useRef<HTMLInputElement>(null);

    const onSortBy = useCallback(
      (sortOption: AssessmentSortOption, sortDirection: SortDirection) => {
        setSortBy(sortOption);
        setSortDirection(sortDirection);
      },
      [],
    );

    const onSearchSubmit = useCallback(() => {
      const term = searchInputRef.current?.value;
      const isTermValid = term && term.length >= DefaultMinLength;

      if (isTermValid) {
        setSearchTerm(term);
      } else {
        setSearchTerm(undefined);
      }
    }, []);

    const onClear = useCallback(() => {
      setSearchTerm(undefined);
    }, []);

    const onToggleSelect = useCallback((assessment: BaseAssessment) => {
      setSelectedSubRows((prevState) => {
        if (prevState.find((prev) => prev.id === assessment.id)) {
          return prevState.filter((prev) => prev.id !== assessment.id);
        } else {
          return [...prevState, assessment];
        }
      });
    }, []);

    useImperativeHandle(ref, () => ({
      submit: handleSubmit(onSubmit),
    }));

    const handleNextPage = useCallback(() => {
      setPage(page + 1);
    }, [page, setPage]);

    const handlePreviousPage = useCallback(() => {
      setPage(page - 1);
    }, [page, setPage]);

    const assessmentsArrayToSelObject = (assessments: BaseAssessment[]) => {
      return assessments.reduce((selected: ISelectedRows, assessment) => {
        const name = assessment.name as string;
        const id = assessment.id as string;
        return {
          ...selected,
          [name]: selected[name] ? [...selected[name], id] : [id],
        };
      }, {});
    };

    return (
      <div className={styles.main}>
        <div className={styles.fixed}>
          {!isShared && (
            <div className={styles['modal-table-header']}>
              <div className={styles['organization-info']}>
                <ProfilePicture
                  size={48}
                  src={selectedOrg?.blobImageUri || ''}
                />
                {selectedOrg?.organizationName || ''}
              </div>
              <div className={styles['search-bar']}>
                <SearchBar
                  onSearch={onSearchSubmit}
                  onToggleOpen={() => {
                    searchInputRef.current?.focus();
                  }}
                  inputRef={searchInputRef}
                  clear={onClear}
                  inputProps={{
                    minLength: DefaultMinLength,
                    placeholder: 'Search',
                  }}
                />
              </div>
            </div>
          )}
        </div>
        {/* starts pills container: */}
        <div className={styles['selected-pills-container']}>
          <ul>
            <li>Selected:</li>
            {selectedSubRows.map((assessment) => {
              return (
                <SelectedPill
                  id={assessment.id as string}
                  key={`${assessment.id}-selected`}
                  label={radarGraphLegendText(assessment.name, assessment.date)}
                  onClose={() => {
                    onToggleSelect(assessment);
                  }}
                />
              );
            })}
          </ul>
        </div>
        {isFetching ? (
          <div className={styles.spinner}>
            <Loading />
          </div>
        ) : (
          <>
            <div className={styles['table-container']}>
              <AsessmentsListTable
                onSortBy={onSortBy}
                useTableOptions={{
                  data: parseTableData(data),
                  columns: columnsByAssessmentType(
                    AListTableColumns,
                    currentAssessment?.assessmentType,
                  ).filter((column) => column.id !== ViewColumnID),
                  getRowId: (originalRow) =>
                    `${originalRow.name?.replace(/\s+/, '')}_${
                      originalRow.latestAssessmentId
                    }`,
                  useControlledState: (state) => {
                    return useMemo(() => {
                      return {
                        ...state,
                        sortBy: [
                          {
                            id: sortBy,
                          },
                        ],
                        selectedSubRows,
                      };
                      // eslint-disable-next-line -- linter complains these deps aren't being used, but they are
                    }, [state, sortBy, selectedSubRows]);
                  },
                }}
                renderSubRowContent={({ state: tableState, assessments }) => {
                  return (
                    <SelectionModalExpandedRow
                      assessments={assessments}
                      selectedSubRows={tableState.selectedSubRows}
                      onToggleSelect={onToggleSelect}
                    />
                  );
                }}
              />
            </div>
            <div className={styles['table-bottom-toolbar']}>
              <form onSubmit={handleSubmit(onSubmit)}>
                <Button
                  type="submit"
                  disabled={!(selectedSubRows.length > 1)}
                  onClick={() => {
                    setComparedAssessments(
                      Object.values(
                        assessmentsArrayToSelObject(selectedSubRows),
                      ),
                    );
                  }}
                >
                  Compare
                </Button>
              </form>

              <Pagination
                hasNextPage={!isPreviousData && data?.hasNextPage}
                hasPreviousPage={!isPreviousData && data?.hasPreviousPage}
                onNext={handleNextPage}
                onPrevious={handlePreviousPage}
                page={page}
                totalCount={data?.totalCount}
              />
            </div>
          </>
        )}
      </div>
    );
  },
);
