import termsDictionary from 'views/Assessments/terms.json';
import styles from './GraphInfo.module.css';

const GraphInfo = ({ terms = [] }) => {
  return (
    <dl className={styles.terms}>
      {terms.map((termId) => {
        const match = termsDictionary[0].general?.find(
          ({ id }) => id === termId,
        );
        return match ? (
          <div key={match.id}>
            <dt>{match.term}</dt>
            <dd>{match.definition}</dd>
          </div>
        ) : null;
      })}
    </dl>
  );
};

export { GraphInfo };
